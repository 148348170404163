<template>

  <div>
    <v-card outlined>
      <hb-header one-action :align-top="true" :padding="false" :divider="false">
          <template v-slot:left>
            <mini-profile-view 
              v-if="contact?.id"
              :contact-id="contact.id"
              :use-contact="false"
              :contact-data="contact"
              class="pa-4"
            />
          </template>
      </hb-header>
      <HbBtn
        icon
        tooltip="Close"
        @click="closeContactModel"
        active-state-off
        mdi-icon="mdi-close"
        class="pa-2"
        style="position:absolute;top:0;right:0;"
      />
      <v-divider v-if="view !== 'payment-methods'"></v-divider>
      <div v-if="view !== 'payment-methods'" class="py-2 pl-6 d-flex align-center">
        <hb-btn class="mr-2" v-if="view" icon tooltip="Back" @click="view = null" active-state-off>mdi-chevron-left</hb-btn>
        <span class="hb-larger-font-size font-weight-medium">{{selected_tab}}</span>
      </div>
      <v-divider></v-divider>
      <v-card-text v-if="!view" class="card-body py-3 px-6">
        <v-row>
          <v-col cols="6"
            class="pb-0"
            v-for="menuOption in menuOptions"
            :key="menuOption.key"
          >
            <hb-btn accordion @click="view = menuOption.key" :prepend-icon="menuOption.icon">
              {{menuOption.label}}
            </hb-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="view" class="pa-0">

        <v-sheet class="edit-panel-hasSideBar">
            <contacts-overview
              v-if="view === 'overview' && contact.id"
              :contact="contact"
              @close="$emit('close')"
              :application="application"
            ></contacts-overview>
            <chat-window hideHeader chatWindowHeight="small" v-if="view === 'interactions' && contact.id" :InteractionObj="contact" :context="interactionContext"></chat-window>
            <view-lead v-if="view === 'lead' && contact.id" :contact_id="contact.id"></view-lead>
            <contact v-if="view === 'contact' && contact.id" :contact="contact"></contact>
            <access v-if="view === 'access' && contact.id" :contact="contact" from-contact-modal></access>
            <!-- <payment-methods v-if="view === 'payment-methods' && contact.id" :contact="contact" @close="view = null"> </payment-methods> -->

        </v-sheet>
      </v-card-text>
      <!--
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <hb-btn class="mr-2 my-1" color="secondary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="closeContactModel">Close</hb-btn>
      </v-card-actions>
      -->
    </v-card>
  </div>
</template>

<script type="text/babel">
    import Interactions from './Interactions.vue'
    import ViewLead from '../leads/ViewLead.vue'
    import CreditCheck from '../applications/CreditCheck.vue'
    import ContactsOverview from './ContactsOverview.vue'
    import Contact from './Contact.vue'
    import Access from './Access.vue'
    import Leases from './Leases.vue'
    import Dropdown from '../assets/Dropdown.vue'
    import DropdownMenu from '../assets/DropdownMenu.vue'
    import AddInteraction from './AddInteraction.vue'
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import ChatWindow from '../communication_center/ChatWindow.vue'
    import PaymentMethods from './PaymentMethods'
    import { mapMutations, mapGetters } from 'vuex';
    import MiniProfileView from '../includes/LeadIntake/MiniProfileView.vue'

    export default {
        name: "ContactView",
        data(){
            return {
                contact: {
                    id: null
                },
                application: {
                    id: null
                },
                selectedApplication:{},
                contactTab: 'tab-overview',
                interactionContext: 'contact',
                recordInteraction: false,
                showContact: false,
                view: null,
                styleProps: {width: '75px'},
                lead_statuses: ['active', 'new', 'prospect'],
                menuOptions: [
                    {

                        key: 'overview',
                        label: 'Leases / Reservations',
                        icon: 'mdi-calendar-today'
                    },
                    {

                        key: 'contact',
                        label: 'Contact Details',
                        icon: 'mdi-contacts'
                    },
                    {
                        key: 'interactions',
                        label: 'Communication',
                        icon: 'mdi-forum'
                    },
                    /*
                    {
                        key: 'payment-methods',
                        label: 'Payment Methods',
                        icon: 'payment'
                    },
                    */
                    // {
                    //     key: 'transaction-history',
                    //     label: 'Transaction History',
                    //     icon: 'monetization_on'
                    // },
                    {
                        key: 'access',
                        label: 'Access',
                        icon: 'mdi-access-point'
                    },


                ]
            }
        },
        props: {
            contact_id: {
                type: String
            },
            application_id:{
                type: String,
                required: false
            },
            show: {
                type: String
            },
            fromLeaseWidget: {
                type: Boolean
            }
        },
        computed: {
          selected_tab() {
            if(this.view === null || this.view == '') {
              return 'Tenant Information'
            } else {
              let option = this.menuOptions.find(x=> x.key === this.view)
              return option ? option.label : ''
            }
          },
          computedContactTitle() {
            return (this.contact?.first ? this.contact?.first : '') + (this.contact?.middle ? ' ' + this.contact?.middle : '') + (this.contact?.last ? ' ' + this.contact?.last : '');
          },
          computedContactPhone() {
            if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone){
              return this.contact.Phones[0].phone;
            } else {
              return undefined;
            }
          },
          computedContactPhoneVerified() {
            if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone_verified && this.contact.Phones[0].phone_verified == 1){
                return true;
            } else {
                return false;
            }
          },
          computedContactAddress() {
            if(this.contact.Addresses && this.contact.Addresses[0] && this.contact.Addresses[0].Address){
              const address_1 = this.contact.Addresses[0].Address.address ? this.contact.Addresses[0].Address.address : '';
              const address_2 = this.contact.Addresses[0].Address.address2 ? this.contact.Addresses[0].Address.address2 : '';

              let address = address_1;
              if(address_2 != '') address += ' ' + address_2;
              
              address += ', ' + this.$options.filters.formatAddress(this.contact.Addresses[0].Address); 
              
              return address;
            } else {
              return undefined;
            }
          },
          computedRepresentativeName(){
                if(this.contact.Representative) {
                    return (this.contact?.Representative?.Contact?.first ? this.contact?.Representative?.Contact?.first : '') + (this.contact?.Representative?.Contact?.last ? ' ' + this.contact?.Representative?.Contact?.last : '');
                }
                return undefined;
          },
        },
        components:{
            Interactions,
            ViewLead,
            ContactsOverview,
            Contact,
            Dropdown,
            DropdownMenu,
            Access,
            AddInteraction,
            CreditCheck,
            ChatWindow,
            PaymentMethods,
            MiniProfileView
        },
        created(){

            //this.view = this.show || 'overview';
            this.contactTab = 'tab-' + this.view;
            EventBus.$on('contact_updated', this.fetchData);
            EventBus.$on('priority_action', this.closeWindow);
            EventBus.$on('view_access', this.viewAccess);
            EventBus.$on('view_payment_methods', this.viewPaymentMethods);

            this.fetchData();

            if(this.application_id){
                this.fetchApplication()
            }
        },
        destroyed(){
            EventBus.$off('contact_updated', this.fetchData);
            EventBus.$off('priority_action', this.closeWindow);
            EventBus.$off('view_access', this.viewAccess);
            EventBus.$off('view_payment_methods', this.viewPaymentMethods);
        },
        methods:{
            ...mapMutations({
              setContact: 'onBoardingStore/setContact'
            }),
            async moveIn(contact_id){

                await this.$store.dispatch('getContactInfo', {contact_id: contact_id});
                EventBus.$emit('unit_new_lead');

            },
            closeWindow(){
                this.$emit('close');
            },
            closeContactModel(){
              this.$emit('close')
            },
            fetchData(){
                api.get(this, api.CONTACTS + this.contact_id + '/?event=OnModalInit').then(r => {
                   this.contact = r.contact;
                   EventBus.$emit('tenant_updated',r.contact);
                });
            },
            fetchApplication(){
                api.get(this, api.APPLICATIONS + this.application_id).then(r => {
                    this.application = r.application;
                });
            },
            closeContactWindow(){
                this.$refs.contactDropdown.hideListbox();
            },
            closeInteractionWindow(){
                this.$refs.interactionDropdown.hideListbox();
            },
            updateInteractions(){
                // TODO Update interactions window
            },
            addToCurrentLease(){
                this.$store.commit('addContact', this.contact);
                this.$router.push('/configure-lease');
            },
            resetContact(){
                this.contact = {
                    id: null
                }
            },
            sendMessageEvent(){
              this.view = 'interactions';
              setTimeout(() => {
                EventBus.$emit('contact_modal_go_email');
              }, 200);
            },
            viewAccess(){
              this.view = 'access';
            },
            viewPaymentMethods(){
              this.view = 'payment-methods';
            }
        },
        watch:{
            contact_id(){
                this.resetContact();
                this.fetchData();
            },
            application_id(){
                this.fetchApplication();
            },
            show(){
                this.view = this.show;
            },
            '$route': function(){
                this.$emit('close');
            }
        }

    }


</script>

<style scoped>
  .fixed-content {
    height: 48vh;
    background: #F9FAFB;
  }
  .remove-btn {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border: 1px solid #C4CDD5;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
    border-radius: 4px;
  }
  .card-label {
    font-family: 'Graphik Web';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #101318;
  }
  .card-body {
    background: #F9FAFB;
    overflow: auto;
    max-height: 48vh;
    height: 48vh;
  }
    .slide-out-tenant-header{
        border-bottom: none;
    }

    .property-light-black{
        color:#677380;
    }

    .property-transparent{
        background: transparent;
    }

    .property-mailto{
        color:#306FB6;
        text-decoration:none;
    }
</style>
