export const validateEmailPhoneMixin = {
  methods: {
    showEmailPhoneValidation(isNotValid, errors) {
      const emailPhoneError = "Either Email or Phone is required";
      // const emailPhoneRequired = this.errors.items.indexOf(emailPhoneError);

      // Check if the validateEmailPhone is returning true and the respective error is not added already then add it
      if (isNotValid && !this.errors.items.some(error => error.field === "requireddata" && error.rule === "manualorcustom")) {
        this.$validator.errors.add({
          field: 'requireddata',  // The field name associated with the error
          msg: emailPhoneError,  // The error message
          scope: null,  // The scope, can be omitted if not using scopes
          rule: 'manualorcustom'  // Optional, can be any string to identify the rule
        });
        return true;
      } else {
        // Check if the validateEmailPhone is returning false and the respective error is added then remove it
        if (!isNotValid && this.errors.items.some(error => error.field === "requireddata" && error.rule === "manualorcustom")) {
          errors.clear()
          // errors.splice(emailPhoneRequired);
        }
      }
    },
  },
};
