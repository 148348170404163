<template>
</template>


<script type="text/babel">
    export default {
        data() {
            return {
                type: '',
                payment_sources: [
                    'Telephone/Moto',
                    'In-Store',
                ],
                account_types:['Checking', 'Savings']
            }
        },
        props: ['payment_type', 'amount'],
        computed:{
          
        }, 
        mounted(){

            this.type = this.payment_type;
        },
        methods:{
            focusIf(num, ref, evt){
                if(evt.target.value.length === num){
                    this.$refs[ref].focus();
                }
            },
        },
        watch:{
            amount(){
                this.payment.amount = this.amount;
            },
            payment_type(val){
                this.type = this.payment_type;
                this.payment.type = this.payment_type;
            }
        }

    }
</script>
