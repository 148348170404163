<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
  <span>
    <hb-tooltip :disabled="!enableTooltip || !type">
      <template v-slot:item>
        <v-avatar
          v-if="avatar"
          height="48"
          class="hb-space-icon-avatar-wrapper"
        >
          <HbIcon
            :mdi-code="iconType.icon"
            :color="color"
          />
        </v-avatar>

        <HbIcon
          v-else
          :small="small"
          :color="color"
          :mdi-code="iconType.icon"
          :small-size-position-fix="small && !disableSmallSizePositionFix"
          :class="{ 'hb-space-icon-position-relative' : !disablePositionRelative }"
          :style="!disableTopPositionAdjustment && top ? 'top:' + top : ''"
        />
      </template>
      <template v-slot:body>
        <span class="text-capitalize">{{ typeTooltip }}</span>
      </template>
    </hb-tooltip>
  </span>
</template>

<script type="text/babel">
import HbIcon from './HbIcon.vue';
import HbTooltip from './HbTooltip.vue';
import tokens from './tokens.js';

export default {

  name: "HbSpaceIcon",
  data() {
    return {
      spaceTypes: tokens.icons.spaces
    };
  },
  components:{
    HbIcon,
    HbTooltip
  },
  computed: {

    iconType(){
      if(this.type){
        let match = this.spaceTypes.find( i => this.type.toLowerCase() === i.type.toLowerCase() );

        if(match){
          this.$emit('match-found');
          return match;
        } else {
          this.$emit('match-not-found');
          return this.spaceTypes.find( i => i.type === 'default' );
        }
      } else {
        this.$emit('match-not-found');
        return this.spaceTypes.find( i => i.type === 'default' );
      }
    },

    typeTooltip(){
      if(this.type){
        let replaceChar = this.type.split("_").join(" ");
        return replaceChar;
      } else {
        return '';
      }
    }

  },
  props: {
    type: {
      type: String,
      default: undefined
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#637381'
    },
    small: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: undefined
    },
    disablePositionRelative: {
      type: Boolean,
      default: false
    },
    disableSmallSizePositionFix: {
      type: Boolean,
      default: false
    },
    disableTopPositionAdjustment: {
      type: Boolean,
      default: false
    },
    enableTooltip: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>

.hb-space-icon-avatar-wrapper {
  border: 1px solid #DFE3E8 !important;
}

.hb-space-icon-position-relative {
  position: relative;
}

</style>