<template>
  <hb-form label="Descriptive Tags" class="descriptive-tags" full required :medium="medium">
    <div style="color: #637381" class="pb-2">
      These tags are for descriptive purposes only. They will have no overall
      effect on the plan you are creating.
    </div>

    <div v-for="(type) in unit_types">
      <v-checkbox
        class="mt-0"
        :value="type.unit_type_id"
        v-model="selected_unit_type"
        hide-details
        dense
      >
        <template v-slot:label>
          <span class="hb-text-night">
            <HbUnitIcon
              small
              :type="getUnitTypeLabel(type)"
            />

            {{ getUnitTypeTitle(type) }}

          </span>
        </template>
      </v-checkbox>
    </div>

  </hb-form>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../assets/api.js";

export default {
  name: "DescriptiveTagForm",
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    medium: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isError: false,
      unit_types:[],
      selected_unit_type:[]
    }
  },
  async created() {
    await this.fetchUnitTypes();
    if(this.value.length) this.selected_unit_type = this.value;
  },
  computed: {
    ...mapGetters({
      property: "revManStore/getSelectedProperty"
    }),

  },
  methods: {
    async fetchUnitTypes(){
      if(this.property){
        let response = await api.get(this, api.PROPERTIES + this.property + "/space-types");
        this.unit_types = response?.space_types;
      }
    },
  },
  watch: {
    "selected_unit_type"() {
      this.$emit("input", this.selected_unit_type);
    },
  }
};
</script>

<style lang="scss">	
.descriptive-tags {	
  .hb-aviary-v-checkbox.v-input--selection-controls:not(.v-input--is-label-active)	
    .plan-icon	
    i.v-icon {	
    color: rgb(99, 115, 129) !important;	
  }	
}	
</style>