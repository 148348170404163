// import MonthlyBills from '../components/billing/MonthlyBills.vue';
// import AgingDetail from '../components/billing/AgingDetail.vue';

export default [

	{
		name: "MonthlyBills",
		path: '/billing',
		component: () => import('../components/billing/MonthlyBills.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      layout: 'master'
		}
	},

	{
		name: "AgingDetail",
		path: '/billing/aging-detail',
		component: () => import('../components/billing/AgingDetail.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      layout: 'master'
		}
	}
]
