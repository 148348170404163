
import { mapGetters } from 'vuex';

const authRole = {
    install(Vue) {
        Vue.mixin({
            computed: {
                ...mapGetters({
                    primaryRoleId: 'authenticationStore/getPrimaryRoleId'
                }),
                rolesIDs() {
                    return [this.primaryRoleId];
                }
            }
        })
    }
};

export default authRole;