<template>
	<div class="jidei">
		<hb-data-table
			class="refund-table"
			show-select 
			item-key="id"
			v-model="selectedInvoices"
			:single-select="false"
			:headers="propHeaders" 
			:items="propItems"
			:disable-sort="true"
		>				
			<template v-slot:item.data-table-select="{ isSelected, select }">
      			<v-simple-checkbox color="primary" v-ripple :value="isSelected" @input="select($event)"></v-simple-checkbox>
		    </template>
			<template v-slot:[`header.data-table-select`]="{ props, on }">
				<span></span>
			</template>
			<template v-slot:item.refund_amount="props">
				<v-text-field
					v-if="shouldShowInputField(props.item.id)"
					prepend-inner-icon="mdi-currency-usd"
					dense
					flat
					hide-details="auto"
					v-model="props.item.refund_amount"
					name="refund"
					data-vv-scope="refund"
					:ref="'element_' + props.item.id"
					@input="restrictDecimal($event,props.item.id)"
				>
				</v-text-field>
				<span v-else>
					${{ props.item.refund_amount }}
				</span>
      		</template>
			<template v-slot:item.amount="props">
				<span>
					${{ props.item.amount }}
				</span>
      		</template>
			<template v-slot:item.number="props">
				<span class="hb-link-secondary">
					#{{ props.item.number }}
				</span>
      		</template>
			<template v-slot:body.append>
				<tr>
					<td colspan="4" class="align-right">
						<span class="hb-font-body-medium">Total Refund:</span>
						<span class="ml-4 hb-font-body-medium">${{ totalRefund }}</span>
					</td>
				</tr>
			</template>  
		</hb-data-table>
	</div>
</template>

<script type="text/babel">
import { EventBus } from "../../EventBus.js";

export default {
  name: "Refund",
  data() {
    return {
		selectedInvoices: [],
      propHeaders: [
        { text: "Invoice #", value: "number" },
        { text: "Payment Applied", value: "amount" },
        { text: "Refund Amount", value: "refund_amount", width: '125'},
      ],
      propItems: []
    };
  },
  components: {},
	props: [
		"payments"
	],
  async created() {
		this.propItems = [];
		this.payments.map(p => {
			if(p.amount > 0) {
				return this.propItems.push({
					id: p.id,
					number: p.Invoice.number,
					amount: p.amount,
					refund_amount: 0
				});
			}
		});
	},
  computed: {
	  totalRefund() {
		return this.selectedInvoices.reduce((acc, i) => { return acc + +i.refund_amount }, 0).toFixed(2);
	  }
  },
  methods: {
		shouldShowInputField(itemId) {
			if(this.selectedInvoices.some(s => s.id === itemId)) {
				return true;
			} 

			return false;
		},
		restrictDecimal(value,id){
			if(value && value.indexOf('.') >= 0){
				if(value.split('.')[1].length > 2){
					this.$refs['element_' + id].internalValue = value.substr(0,value.indexOf('.')) + value.substr(value.indexOf('.'), 3);
				}
			}
		}
	},
  watch: {
	  selectedInvoices: {
			handler(value) {
			    EventBus.$emit('setInvoices', value);
			},
			deep : true
		}
  },
};
</script>

<style>
.refund-table .theme--light.v-data-table tbody tr.v-data-table__selected {
	background: white !important
}
</style>