export default [{
    name: "DownloadCenter",
    path: '/download-center',
    component: () => import('../components/download_center/Index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master',
      re_fetch:true
    }
  }]
  