<template>    
    <hb-text-field
        v-model="number"
        box
        :class="{'custom-field-error' : errors.first('card_number')}"
        v-validate="`required|lengthValidation:${counter}`"
        data-vv-name="card_number"
        data-vv-as="Card Number"
        :error="errors.has('card_number')"
        label="Card Number*"
        id="card-number"
        name="card-number"
        autocomplete="cc-card-number"
        v-mask="`${mask}`"
        :counter-value="v => v.replace(/\D/g,'').length"
        :counter="counter"
        :maxlength="maxlength"
        hide-details
        prepend-inner-icon="mdi-credit-card"
        append-icon="mdi-lock"
        @blur="$validator.validate('card_number')"
        @input="updateCardNumber($event)"
    >
    </hb-text-field>
   </template>

<script type="text/babel">
    import creditCardType from 'credit-card-type';
    import { EventBus } from '../../EventBus';
    import { mapGetters, mapMutations } from "vuex";
    import { notificationMixin } from  '../../mixins/notificationMixin';
    export default {
        name: "CardNumber",
        mixins: [ notificationMixin ],
        data: () => ({
            incall:false,
            numberIvr:"",
            number: "",
            counter: 16,
            maxlength: 19,
            mask: '####-####-####-####',
        }),
        created(){
        },
        destroyed() {
          EventBus.$emit("setErrors", []);
        },
        // created(){
        //     this.number=this.getCardNumberFromIvr
        // },
        mounted() {
                     this.$validator.extend('lengthValidation',{
                getMessage: () => `The Card Number length must be ${this.counter}`,
                validate: (value) => {   
                    return value.replace(/\D/g,'').length == this.counter;
                }  
            });
        },
        methods: {
            ...mapMutations({
                setCheckErrors: 'paymentsStore/setCheckErrors',
                setPaymentMethod: 'paymentsStore/setPaymentMethod',
            }),
            update_values(number) {
                let card_results = creditCardType(number);
                if(card_results && card_results.length){
                    let card = card_results[0];
                    if(card.type === 'maestro' && card.lengths.length) {
                        card.lengths[0] = 16;
                    }
                    this.counter = card.lengths.length && card.lengths[0] || 16;
                    this.maxlength = this.counter + card.gaps.length;

                    let mask = "#".repeat(this.counter);
                    for(let i = 0; i < card.gaps.length; i++){
                        let index = card.gaps[i] + i;
                        mask = mask.substr(0, index) + "-" + mask.substr(index);
                    }
                    this.mask = mask;
                }
            },

            length_validation(value){
                return value.replace(/\D/g,'').length == this.counter || `The Card Number field must be ${this.counter} characters`
                    },
            updateCardNumber : _.debounce(async function(value) {
                let card_number = this.number.replace(/\D/g,'');
                this.update_values(card_number);
                this.setPaymentMethod({property: 'card_number', propertyValue: card_number});  
            }, 1000),
            validateCardNumber(){
                return this.$validator.validateAll();  
                } 
            },
            watch: {

            method(val){
                if(val !== 'new'){
                    this.$validator.reset();
                }
            }
        },
        props: ['method','card_number'],
        computed:{
          ...mapGetters({
              getErrors: 'paymentsStore/getErrors',
              payment_method: 'paymentsStore/getPaymentMethod',
            })
        },
    };
</script>

<style scoped>
.hb-text-field-width-370{
    max-width: 100% !important;
}

</style>