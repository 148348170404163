<template>
  <div>
    <v-container class="payment-method-container">
      <v-row>
        <v-col><strong>Account Credit</strong></v-col>
      </v-row>

<!--      <v-row>-->
<!--        <v-col>-->
<!--          <v-textarea-->
<!--            rows="2"-->
<!--            :hide-details="!errors.collect('notes').length"-->
<!--            v-model="payment.notes"-->
<!--            data-vv-name="notes"-->
<!--            :error-messages="errors.collect('notes')"-->
<!--            label="Notes"-->
<!--          >-->
<!--          </v-textarea>-->
<!--        </v-col>-->
<!--      </v-row>-->

    </v-container>


  </div>
</template>


<script type="text/babel">
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import PaymentMethodBase from './PaymentMethodBase.vue';
    export default {
        extends: PaymentMethodBase,
        data() {
            return {
                type: '',
            }
        },
        components:{
            HbDatePicker
        },
        computed:{

        },
        mounted(){
        },
        props: [],
        methods:{
        },
        watch:{
        }


    }
</script>
<style scoped>

</style>
