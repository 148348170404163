<template>
    <div class="session-timeout-error">
      <div class="timeout-message">
        <h1>This Link Has Expired</h1>
        <p>Please request new link from property manager.</p>
      </div>
    </div>
  </template>
  
<script>
  export default {
    data() {
      return {
      };
    },
    created() {
       window.history.replaceState(null, null, '/invalid');
    },
  };
</script>
  
<style scoped>
  .session-timeout-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f1f1f1;
  }
  
  .timeout-message {
    text-align: center;
  }
  
  h1 {
    font-size: 36px;
    color: #333;
  }
</style>
