<template>
    <div class="custom-border">
        <!--
        <p class="interaction ma-0 pa-6 subdued">This is a description for interactions!</p>
        -->
        <div class="pagination">
            <div class="pagination-search">
                <input
                    type="text"
                    class="pagination-search-input input"
                    maxlength="9999"
                    name="interaction-search"
                    data-name="interaction-search"
                    placeholder="Search Interactions"
                    id="interaction-search"
                    v-model="filters.search.description"
                >
                <v-btn class="text-capitalize btn-style secondary-button" @click="fetchData">Update</v-btn>
            </div>
            <div class="section-header-right">

                <pagination
                        v-model="filters.page"
                        :limit="filters.limit"
                        :offset="filters.offset"
                        :result_count="result_count"
                        @input="fetchData"
                ></pagination>

            </div>
        </div>
        <div class="interaction" v-for="(interaction, i) in interactions" :key="i">
            <div class="interaction-details">
                <div class="interaction-type"><span class="text-span">Interaction Type<br></span>{{interaction.method | capitalize}}</div>
            </div>
            <div class="interaction-text" v-if="interaction && interaction.Email && interaction.Email.id">
                <span class="email-status status" :class="'status_' + interaction.Email.status">{{ interaction.Email.status }}</span>
                <div class="date">{{interaction.created | formatLocalDateTime }}</div>
                <p>Subject: <strong>{{interaction.Email.subject}}</strong></p>
                <!--<p>Preview:</p>-->
                <!--<p class="message-preview" v-if="a.Email.message" v-html="$options.filters.nl2br(a.Email.message)"></p>-->
                <!--<p v-else><em>No preview available</em></p>-->
                <p class="subdued detail-subtext">

                    <span v-if="interaction.Contact.id">Sent By: &nbsp;{{interaction.Contact.first + " " + interaction.Contact.last  }}</span>
                    <span v-else>Generated Automatically</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span v-if="interaction.Email.opened">Opened: {{interaction.Email.opened | formatLocalDateTime}}</span>
                    <span v-else>Not Opened</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;<a href="" class="trigger-link" @click.prevent="showEmail(interaction.Email)">View Email Details</a>
                </p>
            </div>

            <div v-else class="interaction-text">
                <p> {{interaction.content}}</p>
                <p class="subdued detail-subtext">Entered By: &nbsp;{{interaction.Admin.first + " " + interaction.Admin.last  }} {{interaction.created | formatLocalDateTime}}</p>
            </div>

        </div>

        <div style="margin: 20px auto; text-align: center; width: 400px; padding: 10px 20px;" v-if="!interactions.length"><em>No Interactions found</em></div>

        <v-dialog
          width="700"
          v-model="showEmailContent">
          <v-card v-if="showEmailContent">
            <v-toolbar flat dark color="primary">
              <v-toolbar-title>View Email</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="showEmailContent = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
              <div class="email-meta">
                <div class="email-detail interaction">
                  <div class="interaction-details" style="text-align: right">From:</div>
                  <div class="interaction-text">{{selected.from_name}}</div>
                </div>

                <div class="email-detail interaction">
                  <div class="interaction-details" style="text-align: right">To:</div>
                  <div class="interaction-text">{{selected.to}} ({{selected.email_address}})</div>
                </div>

                <div class="email-detail interaction">
                  <div class="interaction-details" style="text-align: right">Status:</div>
                  <div class="interaction-text">
                    <span class="status" :class="'status_' + selected.status">{{selected.status}}</span>
                    &nbsp;&nbsp;{{selected.sent | formatLocalDateTime }}
                  </div>
                </div>
                <div class="email-detail interaction">
                  <div class="interaction-details" style="text-align: right">Opened:</div>
                  <div class="interaction-text" v-show="selected.opened">{{selected.opened | formatLocalDateTime}}</div>
                  <div class="interaction-text" v-show="!selected.opened">Not opened</div>

                </div>

                <div class="email-detail interaction" v-show="selected.opened">
                  <div class="interaction-details" style="text-align: right">IP Address:</div>
                  <div class="interaction-text">{{selected.ip}}</div>
                </div>
                <!--<div class="email-detail interaction" v-show="selected.opened">-->
                <!--<div class="interaction-details" style="text-align: right">User Agent:</div>-->
                <!--<div class="interaction-text">{{selected.user_agent}}</div>-->
                <!--</div>-->
                <div class="email-detail interaction" v-show="selected.opened">
                  <div class="interaction-details" style="text-align: right">Type:</div>
                  <div class="interaction-text">{{selected.UserAgentDetails.type}} - {{selected.UserAgentDetails.ua_name}}</div>
                </div>
                <div class="email-detail interaction" v-show="selected.opened">
                  <div class="interaction-details" style="text-align: right">From Mobile:</div>
                  <div class="interaction-text">{{selected.UserAgentDetails.mobile? 'Yes' : 'No' }}</div>
                </div>
                <div class="email-detail interaction" v-show="selected.opened">
                  <div class="interaction-details" style="text-align: right">Clicked:</div>
                  <div class="interaction-text">{{selected.clicked ? 'Yes' : 'No' }}</div>
                </div>
                <div class="email-detail interaction" v-show="selected.opened">
                  <div class="interaction-details" style="text-align: right">Location:</div>
                  <div class="interaction-text">
                    {{selected.Location.city}} {{selected.Location.region}} {{selected.Location.country_short}} {{selected.Location.postal_code}}&nbsp;&nbsp;&nbsp;&nbsp;
                    <a class="trigger-link" :href="'https://www.google.com/maps/search/?api=1&query=' + selected.Location.latitude + ',' + selected.Location.longitude" target="_blank" ><span class="icon"></span> View On Map</a>
                  </div>
                </div>

              </div>
              <div class="email-body" v-if="selected.html_body" v-html="selected.html_body"></div>
              <div v-else style="padding: 20px;"><em>Email content not available</em></div>
            </v-card-text>
          </v-card>
        </v-dialog>

    </div>
</template>


<script type="text/babel">

    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';
    import Loader from '../assets/CircleSpinner.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import Modal from '../assets/Modal.vue';
    import Datepicker from 'vuejs-datepicker';
    import Pagination from '../assets/Pagination.vue';
    import { EventBus } from '../../EventBus.js';


    export default {
        name: "Interactions",
        data() {
            return {
                loaded:false,
                lead:{
                    id: null
                },
                filters:{
                    search: {
                        description: '',
                    },
                    page: 1,
                    limit: 20,
                    offset: 0
                },
                selected:{},
                result_count: 0,
                activity:[],
                convertSelector: '',
                id: null,
                interactions:[],
                showEmailContent: false,
                viewMore:true

            }
        },
        created(){
            this.id = this.contact_id;
            this.fetchData();
            EventBus.$on('interaction_saved', this.fetchData);
            EventBus.$on('message_sent_to_contact', this.fetchData);
        },
        destroyed(){
            EventBus.$off('interaction_saved', this.fetchData);
            EventBus.$off('message_sent_to_contact', this.fetchData);
        },
        components:{
            Loader,
            Status,
            Dropdown,
            Datepicker,
            Pagination,
            Modal
        },
        filters:{

            formatDate(value){
                if(!value) return null;
                return moment(value).format('MM/DD/YYYY');
            }
        },

        methods: {

            fetchData(){
                var _this = this;
                this.lead = {};

                this.filters.offset = (this.filters.page - 1) * this.filters.limit;

                var data = {
                    offset: (this.filters.page - 1) * this.filters.limit,
                    limit: this.filters.limit,
                    description: this.filters.search.description
                }

                api.get(this, api.CONTACTS + this.contact_id + '/interactions', data).then(r => {


                    this.interactions = r.interactions;
                    this.result_count = r.result_count;
                    this.loaded = true;
                });
            },

            closeDropdown(){
                //TODO JQUERY FIX
                //$(event.target).closest('.dropdown-menu').dropdown('toggle');
            },

            saveInteraction(){

                // TODO Validate form!

                api.post(this, api.CONTACTS + this.contact_id + '/activity').then(r => {
                    this.fetchData();
                });
            },
            showEmail(email){
                this.showEmailContent = true;
                this.selected = email;


                try{
                    this.selected.UserAgentDetails = JSON.parse(this.selected.user_agent_details) || {}
                } catch(err){
                    this.selected.UserAgentDetails = {};
                }
                try{
                    this.selected.Location = JSON.parse(this.selected.location) || {}
                } catch(err){
                    this.selected.Location = {};
                }
            }

        },
        props:['contact_id'],
        watch:{
            contact_id(contact_id){
                this.id = contact_id;
                this.fetchData();
            },
            'filters.search': _.debounce(function(){
                this.fetchData();
            }, 150)
        }
    }

</script>

<style scoped>

    @media (max-width: 767px) {
        .pagination-search {
            -webkit-flex: 0 75%;
            -ms-flex: 0 75%;
            flex: 0 75%;
        }
    }


    .email-status{
        float: right;
    }
    .email-detail.interaction{
        border-bottom: none;
    }
    .email-detail.interaction .interaction-text,
    .email-detail.interaction .interaction-details{

        padding-top: 5px;
        padding-bottom: 5px;
    }

    .email-body{
        border: 1px solid #e2e2e2;
        margin-top: 20px;
        box-shadow: 3px 3px 4px 0px #dee5e7;
    }
    .message-preview{
        height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        display: block;
        background: #e2e2e2;
        padding: 0px 15px;
        line-height: 35px;
    }

    p.detail-subtext{
        margin-bottom: 0;
        padding: 5px 10px;
        background: #f5f7f8;
        font-size: 12px;
    }

    .email-meta {
        margin-bottom: 0;
        padding: 10px 10px;
        background: #f5f7f8;
        font-size: 12px;
    }

    .trigger-link{
        font-weight: bold;
        text-decoration: none;

    }

    .pagination-search-input {
        border: 1px solid lightgray;
        border-radius: 4px;
    }
</style>
