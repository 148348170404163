// import Dashboard from '../components/dashboard/Index.vue';
// import DynamicForm from '../components/DynamicForm';
// import Master from '../views/Master.vue';
export default [

    {
        name: "Dashboard",
        path: '/dashboard',
        component: () => import('../components/dashboard/Index.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master'
        },
        children: [
            {
                path: "/dashboard/app/:gds_id",
                name: "dashboard-gds",
                component: () => import('../components/dashboard/Apps.vue'),
                meta: {
                  requiresAuth: true,
                  hasAccess: ['admin'],
                  layout: 'master',
                  re_fetch:true
                }
            },
            {
                path: "/dashboard/products",
                name: "dashboard-products",
                component: () => import('../components/dashboard/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                    layout: 'master',
                    re_fetch:true
                }
            },
            {
                path: "/dashboard/access-control",
                name: "dashboard-access-control",
                component: () => import('../components/dashboard/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                    layout: 'master',
                    re_fetch:true
                }
            },
            {
                path: "/dashboard/payments",
                name: "dashboard-payments",
                component: () => import('../components/dashboard/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                    layout: 'master',
                    re_fetch:true
                }
            },
            {
                path: "/dashboard/lease-configuration",
                name: "lease-configuration",
                component: () => import('../components/dashboard/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                    layout: 'master',
                    re_fetch:true
                }
            },
            {
                path: "/dashboard/fees",
                name: "dashboard-fees",
                component: () => import('../components/settings/Fees/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                    layout: 'master',
                    re_fetch:true
                }
            },
            {
                path: "/dashboard/rent",
                name: "dashboard-rent",
                component: () => import('../components/settings/Rent/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                    layout: 'master',
                    re_fetch:true
                }
            }
        ]
    },
    // {
    // 	name: "DynamicForm",
    // 	path: '/dynamicform',
    // 	component: () => import('../components/DynamicForm'),
    // 	meta: {
    // 		requiresAuth: true,
    // 		hasAccess: ['admin'],
  //     layout: 'master'
    // 	}
    // }
]
