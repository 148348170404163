<template>
  <div>
    <hb-modal
      size="medium"
      title="Remove Service"
      v-model="showRemoveServiceConfirmation"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          You are about to remove the applied service. This change will affect
          upcoming invoices on this account.
          <br /><br />
          Are you sure you want to remove the service?
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="removeService">Remove Service</hb-btn>
      </template>
    </hb-modal>
    
		<add-new-service v-if="showAddServiceModal" v-model="showAddServiceModal" :contact="contact" :lease="lease" @close="closeAddServiceModal" />

    <all-services :displayRemoveOption="(is_closed || isAuctioned) ? false : true" :services="services.future" />
    <all-services :displayRemoveOption="(is_closed || isAuctioned) ? false : true" :services="services.present" />

    <div class="hb-text-light text-body-2 pb-2" style="padding-top: 1px;">
      <hb-tooltip>
        <template slot="item">
          <span style="border-bottom: thin dashed; cursor: pointer">
            Past Services ({{ services.past && services.past.length }})
          </span>
        </template>
        <template slot="body">
          <all-services
            :services="services.past"
            durationPeriodColor="#fff"
            displayNoServiceMessage
          />
        </template>
      </hb-tooltip>
    </div>

    <hb-link @click="addService" v-if="!(is_closed || isAuctioned)">+ Add a Service</hb-link>
  </div>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import AllServices from "./AllServices.vue";
import AddNewService from "./AddNewService.vue";
import moment from 'moment';
import { EventBus } from '../../EventBus';
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "ServicesInfo",
  mixins: [notificationMixin],
  data() {
    return {
      services: {
        past: [],
        present: [],
        future: [],
      },
      showAddServiceModal: false,
      showRemoveServiceConfirmation: false,
			removingService: null
    };
  },

  props: {
    lease: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true
    }
  },

  components: {
    AllServices,
		AddNewService
  },

  async created() {
  	EventBus.$on('closeAddServiceModal', this.closeAddServiceModal);
  	EventBus.$on('removeService', this.openRemoveServiceConfirmation);
  	EventBus.$on('fetchServices', this.fetchServices);

    await this.fetchServices();
  },

  destroyed() {
    EventBus.$off('closeAddServiceModal', this.closeAddServiceModal);
  	EventBus.$off('removeService', this.openRemoveServiceConfirmation);
  	EventBus.$off('fetchServices', this.fetchServices);
  },

  computed: {
    is_closed(){
      return moment(this.lease.end_date).startOf('day') <= moment().startOf('day');
    },
    isAuctioned(){
      return ( ['auction_payment','move_out'].includes(this.lease.auction_status))
    }
  },

  methods: {
    closeAddServiceModal() {
      this.showAddServiceModal = false;
    },
    addService() {
      this.showAddServiceModal = true;
    },
		openRemoveServiceConfirmation(service) {
			this.removingService = service;
			this.showRemoveServiceConfirmation = true;
		},
		async removeService() {
      try {
        let res = await api.delete(
          this,
          `${api.LEASES}/${this.lease.id}/services/${this.removingService.id}`
        );

        this.showMessageNotification({ type: "success", description: "Removed Service" });
        await this.fetchServices();
      } catch(err) {
        this.showMessageNotification({ description: err });
      }

			this.showRemoveServiceConfirmation = false;
		},
    async fetchServices() {
      try {
        let res = await api.get(
          this,
          `${api.LEASES}/${this.lease.id}/all-services`
        );

        let { services } = res;
        this.services = services;
      } catch(err) {
        console.log('err ', err);
      }
    },
  },
};
</script>

<style>
</style>