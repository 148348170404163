<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <vue-draggable-resizable
        :resizable="false"
        class-name="vdr-custom"
        drag-handle=".hb-toast-sheet"
        :draggable="type == 'mini-charm' && vueDraggableResizable"
        :z="99999"
        @dragging="onDragging"
        @dragstop="onDragstop"
        :x="x"
        :y="y"
        class="hb-toast-vue-draggable-resizable-wrapper"
        :class="boxShadow && !switchingInProgress ? 'hb-toast-vue-draggable-resizable-wrapper-box-shadow' : ''"
        :style="type == 'communication' ? 'width:328px;' : !expanded ? 'width:336px;' : ''"
    >
        <div class="hb-toast-wrapper" @click="$emit('click', payload)">
            <div v-if="$slots.toastItems && type == 'mini-charm'" class="hb-toast-toast-items-wrapper" style="position:relative; z-index: 1">
                <slot name="toastItems"></slot>
            </div>

            <v-sheet
                rounded
                class="hb-toast-sheet"
                :class="switchingInProgress ? 'hb-toast-slide-in-out' : ''"
                style="position:relative; z-index: 2; overflow: hidden"
            >

                <div
                    v-if="type !== 'communication'"
                    class="hb-toast-wrapper-header d-flex justify-center align-center"
                    :class="headerBackgroundColor"
                >
                    <slot name="title"></slot>
                    <hb-icon
                        v-if="type !== 'communication' && type !== 'missed calls' && type !== 'voicemails' && type !== 'reservations'"
                        :color="status == 'hold' && view !== 'list' ? '#101318' : '#ffffff'"
                        class="hb-toast-wrapper-header-drag"
                        pointer
                        @click="$emit('drag')"
                    >
                        mdi-drag-custom
                    </hb-icon>
                    <div
                        v-if="(type == 'missed calls' || type == 'voicemails' || type =='reservations') && view !== 'list'"
                        class="hb-toast-back-to-list font-weight-medium white--text hb-toast-pointer"
                        @click="goBack()">
                        <hb-icon
                            color="#ffffff"
                            class="mr-n1"
                            style="position:relative;top:-.5px;"
                        >
                            mdi-chevron-left
                        </hb-icon>
                        <span style="position:relative;top:1px;margin-left:2px;">Back to List</span>
                    </div>

                    <div
                        v-if="view == 'list' && type !== 'mini-charm'"
                        class="hb-toast-wrapper-header-counter white--text mr-1 d-flex justify-center"
                        :style="count > 99 ? 'min-width: 26px;' : count > 9 ? 'min-width: 22px;' : 'min-width: 16px;'"
                        align="center"
                    >
                        {{ count > 99 ? '99+' : count }}
                    </div>
                    <div
                        :class="[status == 'hold' && view !== 'list' ? 'hb-toast-text-night' : 'white--text']"
                        class="font-weight-medium"
                    >
                        {{ setTitle }}
                    </div>

                    <hb-icon
                        v-show="(((type == 'missed calls' || type == 'voicemails' || type =='reservations') && view == 'list') || ((type == 'missed calls' || type == 'voicemails' || type =='reservations') && !status) || status == 'missed' || status == 'voicemail' || status == 'reservation')"
                        :color="status == 'hold' && view !== 'list' ? '#101318' : '#ffffff'"
                        class="hb-toast-wrapper-header-close"
                        pointer
                        @click="$emit('close')"
                    >
                        mdi-close
                    </hb-icon>

                </div>

                <div :class="type !== 'communication' ? 'hb-toast-wrapper-border ' + wrapperBorderClass : ''">

                    <div
                        v-if="(type == 'missed calls' || type == 'voicemails' || type =='reservations') && view == 'list'"
                        class="hb-toast-list-items-wrapper"
                    >
                        <slot name="listItems"></slot>
                    </div>

                    <div
                        v-else
                        class="hb-toast-main-content-wrapper"
                        :class="{'hb-toast-wrapper-border-selected' : selected, 'hb-toast-wrapper-border-communication' : type == 'communication', 'hb-toast-cursor-pointer' : type == 'communication' && !expanded}"
                        @click="type == 'communication' && !expanded ? $emit('notification-clicked') : ''"
                    >

                        <div style="position:relative;">

                            <div style="position:relative;">
                                <div
                                    class="hb-toast-communication-section-wrapper hb-toast-overflow-handler pb-1 px-3"
                                    :class="{'hb-toast-communication-section-max-height-72' : !expanded, 'pt-1' : type !== 'communication' && !expanded }"
                                >
                                    <v-row no-gutters class="mt-1">
                                        <v-col cols="6" no-gutters class="hb-toast-overflow-handler pr-2">
                                            <hb-icon v-if="communicationType == 'system email' || communicationType == 'system text'" small :color="setCommunicationIconColor" :mdi-code="setCommunicationIcon" style="position:relative;top:4px;"></hb-icon>
                                            <hb-icon v-else small :color="setCommunicationIconColor" :mdi-code="setCommunicationIcon"></hb-icon>
                                            <span class="font-weight-medium pl-2" :style="'color:' + setCommunicationTitleColor + ';position:relative;top:1px;'">{{ setCommunicationTitle }}</span>
                                        </v-col>
                                        <v-col cols="6" no-gutters class="d-flex justify-end">
                                            <span class="hb-toast-text-night-light hb-toast-font-caption mt-1">{{ communicationTime }}</span>
                                            <hb-icon
                                                small
                                                v-if="((attachments && attachments.length > 0) || hasAttachments) && type == 'communication'"
                                                style="margin-left:3px;"
                                            >
                                                mdi-paperclip
                                            </hb-icon>
                                            <hb-icon
                                                small
                                                pointer
                                                v-if="expanded && type == 'communication'"
                                                @click="$emit('pinned-icon-clicked')"
                                                :style="(attachments && attachments.length > 0) || hasAttachments ? 'margin-left:0px;' : 'margin-left:3px;'"
                                                :mdi-icon="pinned ? 'mdi-pin' : 'mdi-pin-outline'"
                                            >
                                            </hb-icon>
                                            <div
                                                v-if="unread && type == 'communication'"
                                                class="hb-toast-communication-unread-dot"
                                                :style="expanded ? 'margin-left:3px;' : 'margin-left:5px;'"
                                            >
                                                &nbsp;
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters class="hb-toast-font-caption mt-1" v-if="property">
                                        <v-col class="hb-toast-overflow-handler">
                                            <span class="font-weight-medium">Property:</span>
                                            <span class="pl-1 hb-toast-text-night-light">{{ property }}</span>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters class="hb-toast-font-caption hb-toast-row-custom-margin-top" v-if="source">
                                        <v-col class="hb-toast-overflow-handler">
                                            <span class="font-weight-medium">Source:</span>
                                            <span class="pl-1 hb-toast-text-night-light">{{ source }}</span>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div
                                    v-if="!expanded && contactUnknown"
                                    class="hb-toast-communication-contact-info-wrapper hb-toast-background-cloud-lighter px-3"
                                >

                                    <v-row no-gutters>
                                        <v-col cols="11" no-gutters class="hb-toast-overflow-handler py-1 d-flex align-center">

                                            <hb-icon small color="#101318">mdi-account</hb-icon>

                                            <div class="font-weight-medium px-2 hb-toast-overflow-handler" style="margin-top:3px;">Unknown {{ phoneNumber }}</div>

                                        </v-col>

                                        <v-col cols="1" v-if="type !== 'communication'" no-gutters class="py-1 d-flex align-center justify-end">

                                            <hb-tooltip v-if="selectedContactId  && addAsAdditionalPhoneNumberButton">
                                                <template v-slot:item>
                                                    <hb-icon
                                                        small
                                                        color="#02AD0F"
                                                        @click="$emit('add-as-additional-phone-number')"
                                                        pointer
                                                    >
                                                        mdi-plus-circle
                                                    </hb-icon>
                                                </template>
                                                <template v-slot:body>
                                                    Add as Additional Phone Number to Selected Contact
                                                </template>
                                            </hb-tooltip>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div
                                    v-if="!expanded && contactId && !contactUnknown"
                                    class="hb-toast-communication-contact-info-wrapper px-3"
                                    :class="[communicationInfoBackgroundClass, type == 'communication' ? 'hb-toast-communication-contact-info-border-radius' : '']"
                                >

                                    <v-row no-gutters>
                                        <v-col cols="11" no-gutters class="hb-toast-overflow-handler py-1 d-flex align-center">

                                            <div
                                                v-if="type == 'communication' && (communicationType == 'system email' || communicationType == 'system text' || communicationType == 'email out' || communicationType == 'text out' || communicationType == 'call out')"
                                                class="font-weight-medium pr-2 hb-toast-overflow-handler"
                                                style="margin-top:3px;"
                                            >
                                                To:
                                            </div>

                                            <hb-icon small color="#101318">mdi-account</hb-icon>

                                            <div class="font-weight-medium pl-2 hb-toast-overflow-handler" style="margin-top:3px;">{{ contactName }}</div>

                                            <HbStatus
                                                class="pl-2"
                                                @emitColor="contactInfoBackgroundColor = $event"
                                                style="position:relative;top:1.5px;"
                                                :status="contactStatus"
                                                :pointer="type == 'communication' && !expanded"
                                            />
                                        </v-col>
                                        <v-col cols="1" v-if="type !== 'communication' && type !== 'reservations' && status !== 'reservation'" no-gutters class="py-1 d-flex align-center justify-end">

                                            <hb-tooltip v-if="!selectedContactId && searchContactsButton" :disabled="searchContacts">
                                                <template v-slot:item>
                                                    <hb-icon
                                                        small
                                                        :color="searchContacts ? '#101318' : '#637381'"
                                                        @click="searchContacts = !searchContacts"
                                                        pointer
                                                        class="hb-toast-contact-search-icon-wrapper"
                                                    >
                                                        mdi-magnify
                                                    </hb-icon>
                                                </template>
                                                <template v-slot:body>
                                                    Select a Different Contact
                                                </template>
                                            </hb-tooltip>

                                            <hb-tooltip v-else-if="selectedContactId && addAsAdditionalContactButton">
                                                <template v-slot:item>
                                                    <hb-icon
                                                        small
                                                        color="#02AD0F"
                                                        @click="$emit('add-as-additional-contact')"
                                                        pointer
                                                        mdi-code="mdi-custom-mini-charm-add-contact"
                                                    >
                                                    </hb-icon>
                                                </template>
                                                <template v-slot:body>
                                                    Add as Additional Contact to Selected Contact
                                                </template>
                                            </hb-tooltip>

                                        </v-col>
                                    </v-row>

                                </div>
                                
                                <div
                                    v-if="type !== 'communication' && type !== 'reservations' && status !== 'reservation' && ((!contactUnknown && searchContacts) || contactUnknown) && !selectedContactId"
                                    class="hb-toast-communication-contact-info-wrapper px-3 pb-1"
                                    :class="contactUnknown ? 'hb-toast-background-cloud-lighter' : communicationInfoBackgroundClass"
                                >

                                    <v-row no-gutters class="py-1">
                                        <v-col cols="12" no-gutters>
                                            <div v-if="$slots.searchContacts" style="z-index:99999;">
                                                <slot name="searchContacts"></slot>
                                            </div>
                                        </v-col>
                                    </v-row>

                                </div>

                                <div
                                    v-if="type !== 'communication' && contactId && !contactUnknown && !selectedContactId"
                                    class="hb-toast-communication-contact-info-wrapper px-3"
                                    :class="communicationInfoBackgroundClass"
                                    :style="((!contactUnknown && searchContacts) || contactUnknown) && !selectedContactId ? 'padding-bottom:10px;' : 'position:relative;top:-2px;padding-bottom:10px;'"
                                >
                                    <v-row no-gutters v-if="phoneNumber || contactPhone || contactEmail">
                                        <v-col cols="12" no-gutters class="hb-toast-font-caption hb-toast-text-night-light hb-toast-overflow-handler">
                                            {{ phoneNumber ? phoneNumber : contactPhone ? contactPhone : '' }}<span v-if="(phoneNumber || contactPhone) && contactEmail" class="px-1">|</span> {{ contactEmail }}
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="contactAddress">
                                        <v-col cols="12" no-gutters class="hb-toast-font-caption hb-toast-text-night-light hb-toast-overflow-handler">
                                            {{ contactAddress }}
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="contactAccessCodes && contactAccessCodes.length">
                                        <v-col cols="12" no-gutters class="hb-toast-font-caption hb-toast-text-night-light hb-toast-overflow-handler">
                                            <span>{{ contactAccessCodes.length > 1 ? 'Access Codes:' : 'Access Code:' }}</span>
                                            <span
                                                v-for="(code, i) in contactAccessCodes"
                                                :key="'contact_access_code' + i"
                                            >
                                                {{ code }}<span v-if="i < contactAccessCodes.length - 1">,</span>
                                            </span>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="contactUnits && contactUnits.length">
                                        <v-col
                                            cols="12"
                                            v-for="(unit, i) in contactUnits"
                                            :key="'contact_units' + i"
                                            no-gutters
                                            class="hb-toast-font-caption hb-toast-text-night-light d-flex align-center"
                                        >
                                            <div class="hb-toast-show-units-wrapper" v-if="unit.type" :style="unit.type == 'storage' ? 'margin-top:-2.5px;margin-bottom:1.5px' : 'margin-top:1.5px;margin-bottom:-1.5px'">
                                                <HbSpaceIcon small class="pr-1" :type="unit.type" enable-tooltip />
                                            </div>
                                            <div v-if="unit.number || unit.address" class="hb-toast-overflow-handler">{{ unit.number ? '#' + unit.number : '' }}<span class="px-1" v-if="unit.number && unit.address">|</span>{{ unit.address }}</div>
                                        </v-col>
                                    </v-row>

                                    <div v-if="reservationId" class="mt-2">
                                        <v-row no-gutters class="hb-toast-font-caption" v-if="reservationSpaceInformation">
                                            <v-col class="hb-toast-overflow-handler">
                                                <span class="font-weight-medium">Space:</span>
                                                <span class="pl-1 hb-toast-text-night-light">{{ reservationSpaceInformation }}</span>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="hb-toast-font-caption" v-if="reservationMoveInDate">
                                            <v-col class="hb-toast-overflow-handler">
                                                <span class="font-weight-medium">Move-In Date:</span>
                                                <span class="pl-1 hb-toast-text-night-light">{{ reservationMoveInDate }}</span>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="hb-toast-font-caption" v-if="reservationCode">
                                            <v-col class="hb-toast-overflow-handler">
                                                <span class="font-weight-medium">Reservation Code:</span>
                                                <span class="pl-1 hb-toast-text-night-light">{{ reservationCode }}</span>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="hb-toast-font-caption" v-if="reservationInStorePrice">
                                            <v-col class="hb-toast-overflow-handler">
                                                <span class="font-weight-medium">In-Store:</span>
                                                <span class="pl-1 hb-toast-text-night-light hb-toast-line-through">{{ reservationInStorePrice }}</span>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="hb-toast-font-caption" v-if="reservationWebOnlyPrice">
                                            <v-col class="hb-toast-overflow-handler">
                                                <span class="font-weight-medium">Web Only:</span>
                                                <span class="pl-1 hb-toast-text-night-light">{{ reservationWebOnlyPrice }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>

                                <div
                                    v-if="type !== 'communication' && selectedContactId"
                                    class="hb-toast-communication-contact-info-wrapper px-3"
                                    style="padding-top:px;padding-bottom:10px;"
                                    :class="communicationInfoSelectedContactBackgroundClass"
                                >

                                    <v-row no-gutters>
                                        <v-col cols="11" no-gutters class="hb-toast-overflow-handler py-1 d-flex align-center">

                                            <hb-icon small color="#101318">mdi-account</hb-icon>

                                            <div class="font-weight-medium pl-2 hb-toast-overflow-handler" style="margin-top:3px;">{{ selectedContactName }}</div>

                                            <HbStatus
                                                class="pl-2"
                                                @emitColor="contactInfoSelectedContactBackgroundColor = $event"
                                                style="position:relative;top:1.5px;"
                                                :status="selectedContactStatus"
                                            />
                                        </v-col>
                                        <v-col cols="1" no-gutters class="py-1 d-flex align-center justify-end">

                                            <hb-tooltip>
                                                <template v-slot:item>
                                                    <hb-icon
                                                        small
                                                        @click="$emit('remove-selected-contact')"
                                                        pointer
                                                    >
                                                        mdi-close
                                                    </hb-icon>
                                                </template>
                                                <template v-slot:body>
                                                    Remove as Selected Contact
                                                </template>
                                            </hb-tooltip>

                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="selectedContactPhone || selectedContactEmail">
                                        <v-col cols="12" no-gutters class="hb-toast-font-caption hb-toast-text-night-light hb-toast-overflow-handler">
                                            {{ selectedContactPhone }}<span v-if="selectedContactPhone && selectedContactEmail" class="px-1">|</span> {{ selectedContactEmail }}
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="selectedContactAddress">
                                        <v-col cols="12" no-gutters class="hb-toast-font-caption hb-toast-text-night-light hb-toast-overflow-handler">
                                            {{ selectedContactAddress }}
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="selectedContactAccessCodes && selectedContactAccessCodes.length">
                                        <v-col cols="12" no-gutters class="hb-toast-font-caption hb-toast-text-night-light hb-toast-overflow-handler">
                                            <span>{{ selectedContactAccessCodes.length > 1 ? 'Access Codes:' : 'Access Code:' }}</span>
                                            <span
                                                v-for="(code, i) in selectedContactAccessCodes"
                                                :key="'selected_contact_access_code' + i"
                                            >
                                                {{ code }}<span v-if="i < selectedContactAccessCodes.length - 1">,</span>
                                            </span>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters v-if="selectedContactUnits && selectedContactUnits.length">
                                        <v-col
                                            cols="12"
                                            v-for="(unit, i) in selectedContactUnits"
                                            :key="'selected_contact_units' + i"
                                            no-gutters
                                            class="hb-toast-font-caption hb-toast-text-night-light d-flex align-center"
                                            :style="unit.type == 'storage' ? 'margin-top:-.5px;margin-bottom:-.5px;' : 'margin-top:-1.5px;'"
                                        >
                                            <div class="hb-toast-show-units-wrapper">
                                                <hb-icon small class="pr-1" v-if="unit.type == 'storage'" mdi-icon="mdi-facility-custom-3" style="position:relative;top:.5px;"></hb-icon>
                                                <hb-icon small class="pr-1" v-else-if="unit.type == 'parking'" style="position:relative;top:1px;">mdi-truck</hb-icon>
                                                <hb-icon small class="pr-1" v-else-if="unit.type == 'company'" style="position:relative;top:1px;">mdi-domain</hb-icon>
                                                <hb-icon small class="pr-1" v-else-if="unit.type == 'residential'" style="position:relative;top:1px;">mdi-home</hb-icon>
                                            </div>
                                            <div class="hb-toast-overflow-handler" :style="unit.type !== 'storage' ? 'padding-top:2px;' : ''">{{ unit.number ? '#' + unit.number : '' }}<span class="px-1" v-if="unit.number && unit.address">|</span>{{ unit.address }}</div>
                                        </v-col>
                                    </v-row>
                                </div>

                                <v-expand-transition>
                                    <div
                                        v-show="(status == 'hold' || status == 'ended') && type == 'mini-charm'"
                                        class="hb-toast-overlay"
                                    >
                                        &nbsp;
                                    </div>
                                </v-expand-transition>
                            </div>

                            <div v-if="type !== 'communication'">
                                <div class="hb-toast-links-wrapper">

                                    <span v-if="status == 'reservation' || type == 'reservations'">
                                        <v-row no-gutters>
                                            <v-col cols="6" class="pl-3">
                                                <hb-btn :focused="loading == 'lead-button-clicked'" color="secondary" small width="143" class="d-flex justify-center" :disabled="leadButtonDisabled || isPending || isActiveLien || isAuction || isBalanceDue || isBankruptcy || (contactUnknown && !selectedContactId)" @click="handleActionClick('lead-button-clicked')">Lead</hb-btn>
                                            </v-col>

                                            <v-col cols="6" class="pr-3">
                                                <hb-btn :focused="loading == 'move-in-button-clicked'" color="secondary" small width="143" class="d-flex justify-center" :disabled="moveInButtonDisabled" @click="handleActionClick('move-in-button-clicked')">Move-In</hb-btn>
                                            </v-col>
                                        </v-row>
                                    </span>

                                    <span v-else-if="status == 'ended'">
                                        <v-row no-gutters>
                                            <v-col cols="4" class="pl-3">
                                                <hb-btn :focused="loading == 'call-notes-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="callNotesButtonDisabled" @click="handleActionClick('call-notes-button-clicked')">Call Notes</hb-btn>
                                            </v-col>

                                            <v-col cols="4">
                                                <hb-btn :focused="loading == 'create-task-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="createTaskButtonDisabled" @click="handleActionClick('create-task-button-clicked')">Create a Task</hb-btn>
                                            </v-col>

                                            <v-col cols="4" class="pr-3">
                                                <hb-btn :focused="loading == 'profile-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="profileButtonDisabled || (contactUnknown && !selectedContactId) || isLead" @click="handleActionClick('profile-button-clicked')">Profile</hb-btn>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="mt-2">
                                            <v-col cols="4" class="pl-3">
                                                <hb-btn :focused="loading == 'email-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="emailButtonDisabled" @click="handleActionClick('email-button-clicked')">Email</hb-btn>
                                            </v-col>

                                            <v-col cols="4">
                                                <hb-btn :focused="loading == 'text-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="textButtonDisabled" @click="handleActionClick('text-button-clicked')">Text</hb-btn>
                                            </v-col>
                                        </v-row>
                                    </span>

                                    <span v-else>
                                        <v-row no-gutters>
                                            <v-col cols="4" class="pl-3">
                                                <hb-btn :focused="loading == 'lead-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="leadButtonDisabled || isCurrent || isDelinquent || isPending || isActiveLien || isAuction || isBalanceDue || isBankruptcy || (contactUnknown && !selectedContactId)" @click="handleActionClick('lead-button-clicked')">Lead</hb-btn>
                                            </v-col>

                                            <v-col cols="4">
                                                <hb-btn :focused="loading == 'move-in-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="moveInButtonDisabled" @click="handleActionClick('move-in-button-clicked')">Move-In</hb-btn>
                                            </v-col>

                                            <v-col cols="4" class="pr-3">
                                                <hb-btn :focused="loading == 'move-out-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="moveOutButtonDisabled || (contactUnknown && !selectedContactId) || isLeaseClosed || isPending || isActive || isBalanceDue || isRetired" @click="handleActionClick('move-out-button-clicked')">Move-Out</hb-btn>
                                            </v-col>
                                        </v-row>


                                        <v-row no-gutters class="mt-2">
                                            <v-col cols="4" class="pl-3">
                                                <hb-btn :focused="loading == 'payment-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="paymentButtonDisabled || (contactUnknown && !selectedContactId) || isActive || isRetired" @click="handleActionClick('payment-button-clicked')">Payment</hb-btn>
                                            </v-col>

                                            <v-col cols="4">
                                                <hb-btn :focused="loading == 'profile-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="profileButtonDisabled || (contactUnknown && !selectedContactId) || isLead" @click="handleActionClick('profile-button-clicked')">Profile</hb-btn>
                                            </v-col>

                                            <v-col cols="4" class="pr-3">
                                                <hb-btn :focused="loading == 'transfer-button-clicked'" color="secondary" small width="95" class="d-flex justify-center" :disabled="transferButtonDisabled || (contactUnknown && !selectedContactId) || isDelinquent || isLeaseClosed || isPending || isActiveLien || isAuction || isActive || isBalanceDue || isRetired" @click="handleActionClick('transfer-button-clicked')">Transfer</hb-btn>
                                            </v-col>
                                        </v-row>
                                    </span>
                                </div>

                            </div>

                            <v-expand-transition>
                                <div
                                    v-show="confirmation"
                                    class="hb-toast-confirmation-overlay"
                                >
                                    <div class="hb-toast-confirmation-overlay-caution-bar d-flex align-center px-4">
                                        <hb-icon class="pr-2" color="#101318">mdi-alert-circle-outline</hb-icon>
                                        <span class="hb-modal-title-font-size">Put Active Call on Hold</span>
                                    </div>
                                    <v-divider></v-divider>
                                    <div class="py-5 px-6">
                                        <div>
                                            You are currently on an active call with
                                            <br />
                                            <span class="font-weight-medium">{{ activeCallContactName ? activeCallContactName : 'Unknown' }}</span>.
                                        </div>
                                        <div class="mt-5">
                                            Would you like to put that call on hold{{ confirmation == 'answer' ? ' and answer this incoming call?' : confirmation == 'call back' ? ' and make this outgoing call?' : confirmation == 'take off hold' ? ' and take this call off hold?' : '?' }}</div>
                                    </div>
                                </div>
                            </v-expand-transition>

                        </div>

                        <div v-if="loading">
                            <v-progress-linear
                                indeterminate
                                color="#00848E"
                            ></v-progress-linear>
                        </div>

                        <div v-if="type !== 'communication'">

                            <div class="hb-toast-footer-wrapper hb-toast-background-cloud-lighter">

                                <v-row no-gutters>
                                    <v-col
                                        :cols="status == 'ended' ? 5 : 4"
                                        class="pl-3 hb-toast-footer-left-actions-wrapper"
                                        v-if="$slots.footerLeftActions"
                                    >
                                        <slot name="footerLeftActions"></slot>
                                    </v-col>

                                    <v-col
                                        cols="1"
                                        class="pl-3 hb-toast-footer-left-actions-wrapper"
                                        v-if="confirmation"
                                    >
                                        
                                    </v-col>

                                    <v-col
                                        :cols="status == 'ended' ? 5 : 4"
                                        class="pl-3 hb-toast-footer-left-actions-wrapper"
                                        v-else
                                    >

                                        <span v-if="status == 'active' || status == 'hold'">
                                            <div class="hb-toast-font-caption">Call Duration</div>
                                            <div class="font-weight-medium">
                                                <div v-if="$slots.callDurationTime">
                                                    <slot name="callDurationTime"></slot>
                                                </div>
                                                <div v-else>
                                                    {{ callDurationTime }}
                                                </div>
                                            </div>
                                        </span>

                                        <span v-if="status == 'ended' && playback && !hidePlayback">
                                            <div class="d-flex align-top">
                                                <hb-btn
                                                    icon
                                                    active-by-default
                                                    always-active
                                                    active-background-color="#637381"
                                                    color="#ffffff"
                                                    active-color="#ffffff"
                                                    hover-color="#ffffff"
                                                    hover-opacity
                                                    :disabled="!playbackLoaded || activeCallId.length > 0"
                                                    @click="playbackPlayPause()"
                                                    :tooltip="!playbackLoaded ? 'Please Wait - Call Playback is Loading' : activeCallId ? 'Call Playback Is Disabled When on an Active Call' : 'Play/Pause Call Playback'"
                                                >
                                                    mdi-play-pause
                                                </hb-btn>

                                                <div class="pl-1">
                                                    <div class="hb-toast-font-caption">Call Playback</div>
                                                    <div class="hb-toast-font-caption hb-toast-ended-playback-time-wrapper">
                                                        <div v-if="$slots.playbackTime">
                                                            <slot name="playbackTime"></slot>
                                                        </div>
                                                        <div v-else>
                                                            {{ playbackCurrentTime }}<span class="hb-toast-text-night-light">/{{ playbackTotalTime }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <audio
                                                    style="display:none;"
                                                    ref="playback"
                                                    controls
                                                    :src="playback"
                                                    preload
                                                    @canplay="playbackLoaded = true"
                                                    @ended="playbackPaused = true"
                                                    @loadedmetadata="updateTotalTime('playback')"
                                                    @timeupdate="onTimeUpdate('playback')"
                                                ></audio>
                                            </div>
                                        </span>

                                        <span v-if="(status == 'voicemail' || type == 'voicemails') && voicemail && !hideVoicemail">
                                            <div class="d-flex align-top">
                                                <hb-btn
                                                    icon
                                                    active-by-default
                                                    always-active
                                                    active-background-color="#637381"
                                                    color="#ffffff"
                                                    active-color="#ffffff"
                                                    hover-color="#ffffff"
                                                    hover-opacity
                                                    :disabled="!voicemailLoaded || activeCallId.length > 0"
                                                    @click="voicemailPlayPause()"
                                                    :tooltip="!voicemailLoaded ? 'Please Wait - Voicemail is Loading' : activeCallId ? 'Voicemail Is Disabled When on an Active Call' : 'Play/Pause Voicemail'"
                                                >
                                                    mdi-play-pause
                                                </hb-btn>

                                                <div class="pl-1">
                                                    <div class="hb-toast-font-caption">Voicemail</div>
                                                    <div class="hb-toast-font-caption hb-toast-ended-playback-time-wrapper">
                                                        <div v-if="$slots.voicemailTime">
                                                            <slot name="voicemailTime"></slot>
                                                        </div>
                                                        <div v-else>
                                                            {{ voicemailCurrentTime }}<span class="hb-toast-text-night-light">/{{ voicemailTotalTime }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <audio
                                                    style="display:none;"
                                                    ref="voicemail"
                                                    controls
                                                    :src="voicemail"
                                                    preload
                                                    @canplay="voicemailLoaded = true"
                                                    @ended="voicemailPaused = true"
                                                    @loadedmetadata="updateTotalTime('voicemail')"
                                                    @timeupdate="onTimeUpdate('voicemail')"
                                                ></audio>
                                            </div>
                                        </span>

                                    </v-col>

                                    <v-col
                                        :cols="status == 'ended' ? 7 : 8"
                                        class="d-flex justify-end pr-3 hb-toast-footer-right-actions-wrapper"
                                        v-if="$slots.footerRightActions"
                                    >
                                        <slot name="footerRightActions"></slot>
                                    </v-col>

                                    <v-col
                                        cols="11"
                                        class="d-flex align-center justify-end pr-3 hb-toast-footer-right-actions-wrapper"
                                        style="padding-top:3px;"
                                        v-else-if="confirmation"
                                    >
                                        <hb-link @click="$emit('update:confirmation', '')" class="pr-3">Cancel</hb-link>
                                        <hb-btn color="primary" @click="confirmation == 'answer' || confirmation == 'take off hold' ? $emit('confirmed', 'active') : confirmation == 'call back' ? $emit('confirmed', 'call out') : ''">
                                        Yes
                                        </hb-btn>
                                    </v-col>

                                    <v-col
                                        :cols="status == 'ended' ? 7 : 8"
                                        class="d-flex justify-end pr-1 hb-toast-footer-right-actions-wrapper"
                                        v-else
                                    >

                                        <hb-btn
                                            v-show="(status == 'active' || status == 'call in' || status == 'ended') && blockCallerButton && contactUnknown"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#101318"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('block-caller-button-clicked')"
                                            tooltip="Block Caller"
                                        >
                                            mdi-flag
                                        </hb-btn>

                                        <span v-show="status == 'missed' || status == 'voicemail' ||  status == 'reservation' || !status" class="pr-1">
                                            <div class="hb-toast-font-caption">Call Back</div>
                                            <div class="font-weight-medium" :class="callBackTimeColorClass" align="right">
                                                <div v-if="$slots.callBackTime">
                                                    <slot name="callBackTime"></slot>
                                                </div>
                                                <div v-else>
                                                    {{ callBackTime }}
                                                </div>
                                            </div>
                                        </span>

                                        <hb-btn
                                            v-show="(status == 'ended' || status == 'missed' || status == 'voicemail' ||  status == 'reservation' || !status) && callBackButton"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#02AD0F"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('call-back-button-clicked')"
                                            tooltip="Call Back"
                                        >
                                            mdi-phone-forward
                                        </hb-btn>

                                        <hb-btn
                                            v-show="status == 'ended' && closeButton"
                                            color="primary"
                                            @click="$emit('close')"
                                            style="position:relative;top:2px;"
                                        >
                                            Close
                                        </hb-btn>

                                        <hb-btn
                                            v-show="(status == 'missed' || status == 'voicemail' ||  status == 'reservation' || !status) && dismissButton"
                                            color="secondary"
                                            @click="$emit('dismiss', payload)"
                                            style="position:relative;top:2px;"
                                        >
                                            Dismiss
                                        </hb-btn>

                                        <hb-btn
                                            v-show="status == 'active' && putOnHoldButton"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#637381"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('put-on-hold-button-clicked')"
                                            tooltip="Put on Hold"
                                        >
                                            mdi-pause
                                        </hb-btn>

                                        <hb-btn
                                            v-show="(status == 'active' || status == 'call out')  && endCallButton"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#FB4C4C"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('end-call-button-clicked')"
                                            tooltip="End Call"
                                        >
                                            mdi-phone-hangup
                                        </hb-btn>

                                        <hb-btn
                                            v-show="status == 'call in' && sendToVoicemailButton"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#FB4C4C"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('send-to-voicemail-button-clicked')"
                                            tooltip="Send to Voicemail"
                                        >
                                            mdi-voicemail
                                        </hb-btn>

                                        <hb-btn
                                            v-show="status == 'call in' && answerCallButton"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#02AD0F"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('answer-call-button-clicked')"
                                            tooltip="Answer Call"
                                            class="hb-toast-answer-call-button"
                                        >
                                            mdi-phone-in-talk
                                        </hb-btn>

                                        <span v-show="status == 'hold'" class="pr-1">
                                            <div class="hb-toast-font-caption">Hold Timer</div>
                                            <div class="font-weight-medium" :class="holdDurationTimeColorClass" align="right">
                                                <div v-if="$slots.holdDurationTime">
                                                    <slot name="holdDurationTime"></slot>
                                                </div>
                                                <div v-else>
                                                    {{ holdDurationTime }}
                                                </div>
                                            </div>
                                        </span>

                                        <hb-btn
                                            v-show="status == 'hold' && takeOffHoldButton"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#02AD0F"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            @click="$emit('take-off-hold-button-clicked')"
                                            tooltip="Take Off Hold"
                                        >
                                            mdi-phone-return
                                        </hb-btn>

                                        <hb-btn
                                            v-show="status == 'never-will-show'"
                                            icon
                                            active-by-default
                                            always-active
                                            active-background-color="#02AD0F"
                                            color="#ffffff"
                                            active-color="#ffffff"
                                            hover-color="#ffffff"
                                            hover-opacity
                                            tooltip="Placeholder"
                                        >
                                            mdi-phone
                                        </hb-btn>

                                    </v-col>
                                </v-row>
                            </div>

                        </div>

                    </div>
                
                </div>

            </v-sheet>
        </div>
    </vue-draggable-resizable>
</template>

<script type="text/babel">

import VueDraggableResizable from "vue-draggable-resizable";

import HbBtn from './HbBtn.vue';
import HbIcon from './HbIcon.vue';
import HbStatus from './HbStatus.vue';
import HbSpaceIcon from './HbSpaceIcon.vue'

export default {
    name:'HbToast',
    data(){
        return {
            contactInfoBackgroundColor: '#F4F6F8',
            contactInfoSelectedContactBackgroundColor: '#F4F6F8',
            unknownSearch: false,
            searchContacts: false,
            x: null,
            y: null,
            isInSnappedZone: true,
            voicemailPaused: true,
            voicemailCurrentTime: '0:00',
            voicemailTotalTime: '0:00',
            voicemailLoaded: false,
            playbackPaused: true,
            playbackCurrentTime: '0:00',
            playbackTotalTime: '0:00',
            playbackLoaded: false
        }
    },
    components:{
        HbBtn,
        HbIcon,
        HbStatus,
        HbSpaceIcon,
        VueDraggableResizable
    },
    computed: {
        wrapperBorderClass() {
            if(this.type == 'missed calls' || this.type == 'reservations' || this.type == 'voicemails' || this.view == 'list'){
                return 'hb-toast-wrapper-border-default';
            } else if(this.status == 'call in' || this.status == 'call out'){
                return 'hb-toast-wrapper-border-success';
            } else if(this.status == 'missed' || this.status == 'voicemail' || this.status == 'reservation'){
                return 'hb-toast-wrapper-border-task';
            } else if(this.status == 'hold'){
                return 'hb-toast-wrapper-border-caution';
            } else {
                return 'hb-toast-wrapper-border-default';
            }
        },
        headerBackgroundColor(){
            if(this.type == 'missed calls' || this.type == 'reservations' || this.type == 'voicemails' || this.view == 'list'){
                return 'hb-toast-header-default';
            } else if(this.status == 'call in' || this.status == 'call out'){
                return 'hb-toast-header-success';
            } else if(this.status == 'missed' || this.status == 'voicemail' || this.status == 'reservation'){
                return 'hb-toast-header-task';
            } else if(this.status == 'hold'){
                return 'hb-toast-header-caution';
            } else {
                return 'hb-toast-header-default';
            }
        },
        setTitle() {
            var tempTitle = '';

            if(this.title){
                tempTitle = this.title;
            } else if((this.type == 'missed calls' || this.type == 'voicemails' || this.type == 'reservations') && this.view !== 'list'){
                tempTitle = '';
            } else if(this.type == 'missed calls' && this.view == 'list'){
                tempTitle = 'Missed Calls';
            } else if(this.type == 'voicemails' && this.view == 'list'){
                tempTitle = 'Voicemails';
            } else if(this.type == 'reservations' && this.view == 'list'){
                tempTitle = 'Reservations';
            } else if(this.status == 'call in'){
                tempTitle = 'Incoming Call...';
            } else if(this.status == 'call out'){
                tempTitle = 'Calling...';
            } else if(this.status == 'active'){
                tempTitle = 'Active Call';
            } else if(this.status == 'hold'){
                tempTitle = 'On Hold';
            } else if(this.status == 'ended'){
                tempTitle = 'Call Ended';
            } else if(this.status == 'missed'){
                tempTitle = 'Missed Call';
            } else if(this.status == 'voicemail'){
                tempTitle = 'Voicemail';
            } else if(this.status == 'reservation'){
                tempTitle = 'Reservation';
            } else {
                tempTitle = '';
            }

            this.$emit('update:title', tempTitle);
            return tempTitle;
        },
        setCommunicationIcon() {
            var tempIcon = '';

            if(this.communicationIcon){
                tempIcon = this.communicationIcon;
            } else if(this.communicationType == 'call in'){
                tempIcon = 'mdi-phone-incoming';
            } else if(this.communicationType == 'call out'){
                tempIcon = 'mdi-phone-outgoing';
            } else if(this.communicationType == 'missed'){
                tempIcon = 'mdi-phone-missed';
            } else if(this.communicationType == 'reservation'){
                tempIcon = 'mdi-calendar-clock';
            } else if(this.communicationType == 'voicemail'){
                tempIcon = 'mdi-voicemail';
            } else if(this.communicationType == 'email in'){
                tempIcon = 'mdi-email-receive';
            } else if(this.communicationType == 'email out'){
                tempIcon = 'mdi-email-send-outline';
            } else if(this.communicationType == 'text in'){
                tempIcon = 'mdi-message-arrow-left';
            } else if(this.communicationType == 'text out'){
                tempIcon = 'mdi-message-arrow-right-outline';
            } else if(this.communicationType == 'system email'){
                tempIcon = 'mdi-custom-hummingbird';
            } else if(this.communicationType == 'system text'){
                tempIcon = 'mdi-custom-hummingbird';
            } else if(this.communicationType == 'document'){
                tempIcon = 'mdi-file';
            } else {
                tempIcon = ''
            }

            this.$emit('update:communicationIcon', tempIcon);
            return tempIcon;
        },
        setCommunicationIconColor() {
            if(this.communicationIconColor){
                return this.communicationIconColor;
            } else if(this.communicationType == 'system email' || this.communicationType == 'system text'){
                return '#00848E';
            } else {
                return '#101318';
            }
        },
        setCommunicationTitleColor() {
            if(this.communicationTitleColor){
                return this.communicationTitleColor;
            } else if(this.communicationType == 'system email' || this.communicationType == 'system text'){
                return '#00848E';
            } else {
                return '#101318';
            }
        },
        setCommunicationTitle() {
            var tempCommunicationTitle = '';

            if(this.communicationTitle){
                tempCommunicationTitle = this.communicationTitle;
            } else if(this.communicationType == 'call in'){
                tempCommunicationTitle = 'Call (In)';
            } else if(this.communicationType == 'call out'){
                tempCommunicationTitle = 'Call (Out)';
            } else if(this.communicationType == 'missed'){
                tempCommunicationTitle = 'Missed Call';
            } else if(this.communicationType == 'reservation'){
                tempCommunicationTitle = 'Reservation';
            } else if(this.communicationType == 'voicemail'){
                tempCommunicationTitle = 'Voicemail';
            } else if(this.communicationType == 'email in'){
                tempCommunicationTitle = 'Email (In)';
            } else if(this.communicationType == 'email out'){
                tempCommunicationTitle = 'Email (Out)';
            } else if(this.communicationType == 'text in'){
                tempCommunicationTitle = 'Text (In)';
            } else if(this.communicationType == 'text out'){
                tempCommunicationTitle = 'Text (Out)';
            } else if(this.communicationType == 'system email'){
                tempCommunicationTitle = 'System Email';
            } else if(this.communicationType == 'system text'){
                tempCommunicationTitle = 'System Text';
            } else if(this.communicationType == 'document'){
                tempCommunicationTitle = 'Document';
            } else {
                tempCommunicationTitle = ''
            }

            this.$emit('update:communicationTitle', tempCommunicationTitle);
            return tempCommunicationTitle;
        },
        communicationInfoBackgroundClass() {
            if(this.contactInfoBackgroundColor == '#02AD0F'){
                return 'hb-toast-background-success-shade';
            } else if(this.contactInfoBackgroundColor == '#FFD600'){
                return 'hb-toast-background-caution-shade';
            } else if(this.contactInfoBackgroundColor == '#FB4C4C'){
                return 'hb-toast-background-error-shade';
            } else if(this.contactInfoBackgroundColor == '#919EAB') {
                return 'hb-toast-background-cloud-light';
            } else {
                return 'hb-toast-background-cloud-lighter';
            }
        },
        communicationInfoSelectedContactBackgroundClass() {
            if(this.contactInfoSelectedContactBackgroundColor == '#02AD0F'){
                return 'hb-toast-background-success-shade';
            } else if(this.contactInfoSelectedContactBackgroundColor == '#FFD600'){
                return 'hb-toast-background-caution-shade';
            } else if(this.contactInfoSelectedContactBackgroundColor == '#FB4C4C'){
                return 'hb-toast-background-error-shade';
            } else if(this.contactInfoSelectedContactBackgroundColor == '#919EAB') {
                return 'hb-toast-background-cloud-light';
            } else {
                return 'hb-toast-background-cloud-lighter';
            }
        },
        holdDurationTimeColorClass(){
            if(this.holdDurationTimeColor == 'caution'){
                return 'hb-toast-font-color-caution';
            } else if(this.holdDurationTimeColor == 'error'){
                return 'hb-toast-font-color-error';
            } else {
                return 'hb-toast-font-color-success';
            }
        },
        callBackTimeColorClass(){
            if(this.callBackTimeColor == 'caution'){
                return 'hb-toast-font-color-caution';
            } else if(this.callBackTimeColor == 'error'){
                return 'hb-toast-font-color-error';
            } else {
                return 'hb-toast-font-color-success';
            }
        },
        payload() {
            let data = {
                notification_id: this.id,
                contact_unknown: this.contactUnknown,
                contact_id: this.contactId,
                contact_name: this.contactName,
                contact_phone: this.contactPhone,
                contact_email: this.contactEmail,
                selected_contact_id: this.selectedContactId,
                selected_contact_name: this.selectedContactName,
                selected_contact_phone: this.selectedContactPhone,
                selected_contact_email: this.selectedContactEmail,
                reservation_id: this.reservationId,
                reservation_space_information: this.reservationSpaceInformation,
                reservation_move_in_date: this.reservationMoveInDate,
                reservation_code: this.reservationCode,
                reservation_in_store_price: this.reservationInStorePrice,
                reservation_web_only_price: this.reservationWebOnlyPrice,
            }
            return data;
        },
        voicemail(){
            if(this.voicemailUrl){
                return this.voicemailUrl;
            } else {
                return '';
            }
        },
        playback(){
            if(this.playbackUrl){
                return this.playbackUrl;
            } else {
                return '';
            }
        },
        isCurrent(){
            if(this.selectedContactId && (this.selectedContactStatus == 'current' || this.selectedContactStatus == 'Current')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'current' || this.contactStatus == 'Current')) {
                return true;
            } else {
                return false;
            }
        },
        isDelinquent(){
            if(this.selectedContactId && (this.selectedContactStatus == 'delinquent' || this.selectedContactStatus == 'Delinquent')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'delinquent' || this.contactStatus == 'Delinquent')) {
                return true;
            } else {
                return false;
            }
        },
        isLead(){
            if(this.selectedContactId && (this.selectedContactStatus == 'active' || this.selectedContactStatus == 'Active' || this.selectedContactStatus == 'active lead' || this.selectedContactStatus == 'Active Lead' || this.selectedContactStatus == 'retired' || this.selectedContactStatus == 'Retired' || this.selectedContactStatus == 'retired lead' || this.selectedContactStatus == 'Retired Lead')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'active' || this.contactStatus == 'Active' || this.contactStatus == 'active lead' || this.contactStatus == 'Active Lead' || this.contactStatus == 'retired' || this.contactStatus == 'Retired' || this.contactStatus == 'retired lead' || this.contactStatus == 'Retired Lead')) {
                return true;
            } else {
                return false;
            }
        },
        isLeaseClosed(){
            if(this.selectedContactId && (this.selectedContactStatus == 'lease closed' || this.selectedContactStatus == 'Lease Closed')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'lease closed' || this.contactStatus == 'Lease Closed')) {
                return true;
            } else {
                return false;
            }
        },
        isPending(){
            if(this.selectedContactId && (this.selectedContactStatus == 'pending' || this.selectedContactStatus == 'Pending')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'pending' || this.contactStatus == 'Pending')) {
                return true;
            } else {
                return false;
            }
        },
        isActiveLien(){
            if(this.selectedContactId && (this.selectedContactStatus == 'active lien' || this.selectedContactStatus == 'Active Lien')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'active lien' || this.contactStatus == 'Active Lien')) {
                return true;
            } else {
                return false;
            }
        },
        isAuction(){
            if(this.selectedContactId && (this.selectedContactStatus == 'auction' || this.selectedContactStatus == 'Auction')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'auction' || this.contactStatus == 'Auction')) {
                return true;
            } else {
                return false;
            }
        },
        isActive(){
            if(this.selectedContactId && (this.selectedContactStatus == 'active' || this.selectedContactStatus == 'Active' || this.selectedContactStatus == 'active lead' || this.selectedContactStatus == 'Active Lead')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'active' || this.contactStatus == 'Active' || this.contactStatus == 'active lead' || this.contactStatus == 'Active Lead')) {
                return true;
            } else {
                return false;
            }
        },
        isBankruptcy(){
            if(this.selectedContactId && (this.selectedContactStatus == 'bankruptcy' || this.selectedContactStatus == 'Bankruptcy')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'bankruptcy' || this.contactStatus == 'Bankruptcy')) {
                return true;
            } else {
                return false;
            }
        },
        isBalanceDue(){
            if(this.selectedContactId && (this.selectedContactStatus == 'balance due' || this.selectedContactStatus == 'Balance Due')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'balance due' || this.contactStatus == 'Balance Due')) {
                return true;
            } else {
                return false;
            }
        },
        isRetired(){
            if(this.selectedContactId && (this.selectedContactStatus == 'retired' || this.selectedContactStatus == 'Retired' || this.selectedContactStatus == 'retired lead' || this.selectedContactStatus == 'Retired Lead')){
                return true;
            } else if (this.contactId && !this.selectedContactId && (this.contactStatus == 'retired' || this.contactStatus == 'Retired' || this.contactStatus == 'retired lead' || this.contactStatus == 'Retired Lead')) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        goBack(){
            if(this.type == 'voicemails' && this.$refs.voicemail){
                this.voicemailLoaded = false;
                this.$refs.voicemail.pause();
                this.voicemailPaused = true;
                this.$refs.voicemail.currentTime = 0;
                this.voicemailCurrentTime = '0:00';
                this.voicemailTotalTime = '0:00';
            }
            this.$emit('update:view', 'list');
        },
        onDragging(x, y){ 
            if(x < -336 && this.isInSnappedZone){
                this.isInSnappedZone = false;
                this.$emit('in-snapped-zone', false);
            } else if(x > -336 && !this.isInSnappedZone){
                this.isInSnappedZone = true;
                this.$emit('in-snapped-zone', true);
            }
        },
        onDragstop(x, y){
            if(x > -336){
                this.x = 0;
                this.y = 0;
                setTimeout(() => {
                    this.x = null;
                    this.y = null;
                }, 500);
            } else {
                this.x = x;
                this.y = y;
				//TODO: Added to emit drag stopped event
                this.$emit('drag-stopped', true);
            }
        },
        voicemailPlayPause() {
            if(this.$refs.voicemail){
                if(this.voicemailPaused){
                    this.$refs.voicemail.play();
                    this.voicemailPaused = false;
                    this.$emit('voicemail-played');
                } else {
                    this.$refs.voicemail.pause();
                    this.voicemailPaused = true;
                    this.$emit('voicemail-paused');
                }
            }
        },
        playbackPlayPause() {
            if(this.$refs.playback){
                if(this.playbackPaused){
                    this.$refs.playback.play();
                    this.playbackPaused = false;
                    this.$emit('playback-played');
                } else {
                    this.$refs.playback.pause();
                    this.playbackPaused = true;
                    this.$emit('playback-paused');
                }
            }
        },
        onTimeUpdate (type) {
            if(this.$refs.voicemail && type == 'voicemail'){
                this.voicemailCurrentTime = this.convertTime(this.$refs.voicemail.currentTime);
            } else if(this.$refs.playback && type == 'playback'){
                this.playbackCurrentTime = this.convertTime(this.$refs.playback.currentTime);
            }
        },
        updateTotalTime(type){
            if(this.$refs.voicemail && type == 'voicemail'){
                this.voicemailTotalTime = this.convertTime(this.$refs.voicemail.duration);
            } else if(this.$refs.playback && type == 'playback'){
                this.playbackTotalTime = this.convertTime(this.$refs.playback.duration);
            }
        },
        convertTime(time) {    
            var mins = Math.floor(time / 60);

            var secs = Math.floor(time % 60);
                if (secs < 10) {
            secs = '0' + String(secs);
            }

            return mins + ':' + secs;
        },
        handleActionClick(event) {
            this.$emit('update:loading', event);
            this.$emit(event, this.payload);
        }
    },
    props: {
        id: {
            type: String,
            default: undefined
        },
        type: {
            type: String,
            default: undefined
        },
        view: {
            type: String,
            default: 'default'
        },
        activeCallId: {
            type: String,
            default: undefined
        },
        activeCallContactName: {
            type: String,
            default: undefined
        },
        status: {
            type: String,
            default: undefined
        },
        phoneNumber: {
            type: String,
            default: undefined
        },
        contactUnknown: {
            type: Boolean,
            default: false
        },
        contactId: {
            type: String,
            default: undefined
        },
        contactName: {
            type: String,
            default: undefined
        },
        contactStatus: {
            type: String,
            default: undefined
        },
        contactPhone: {
            type: String,
            default: undefined
        },
        contactEmail: {
            type: String,
            default: undefined
        },
        contactAddress: {
            type: String,
            default: undefined
        },
        contactAccessCodes: {
            type: Array,
        },
        contactUnits: {
            type: Array,
        },
        communicationType: {
            type: String,
            default: undefined
        },
        communicationTime: {
            type: String,
            default: undefined
        },
        property: {
            type: String,
            default: undefined
        },
        source: {
            type: String,
            default: undefined
        },
        callBackTimeColor: {
            type: String,
            default: undefined
        },
        callBackTime: {
            type: String,
            default: '0:00'
        },
        callDurationTime: {
            type: String,
            default: '0:00'
        },
        holdDurationTimeColor: {
            type: String,
            default: undefined
        },
        holdDurationTime: {
            type: String,
            default: '0:00'
        },
        title: {
            type: String,
            default: undefined
        },
        count: {
            type: Number,
            default: 0
        },
        expanded: {
            type: Boolean,
            default: false
        },
        attachments: {
            type: Array
        },
        hasAttachments: {
            type: Boolean,
            default: false
        },
        unread: {
            type: Boolean,
            default: true
        },
        pinned: {
            type: Boolean,
            default: false
        },
        communicationIcon: {
            type: String,
            default: undefined
        },
        communicationIconColor: {
            type: String,
            default: undefined
        },
        communicationTitle: {
            type: String,
            default: undefined
        },
        communicationTitleColor: {
            type: String,
            default: undefined
        },
        selectedContactId: {
            type: String,
            default: undefined
        },
        selectedContactName: {
            type: String,
            default: undefined
        },
        selectedContactStatus: {
            type: String,
            default: undefined
        },
        selectedContactPhone: {
            type: String,
            default: undefined
        },
        selectedContactEmail: {
            type: String,
            default: undefined
        },
        selectedContactAddress: {
            type: String,
            default: undefined
        },
        selectedContactAccessCodes: {
            type: Array,
        },
        selectedContactUnits: {
            type: Array,
        },
        reservationId: {
            type: String,
            default: undefined
        },
        reservationSpaceInformation: {
            type: String,
            default: undefined
        },
        reservationMoveInDate: {
            type: String,
            default: undefined
        },
        reservationCode: {
            type: String,
            default: undefined
        },
        reservationInStorePrice: {
            type: String,
            default: undefined
        },
        reservationWebOnlyPrice: {
            type: String,
            default: undefined
        },
        blockCallerButton: {
            type: Boolean,
            default: true
        },
        sendToVoicemailButton: {
            type: Boolean,
            default: true
        },
        answerCallButton: {
            type: Boolean,
            default: true
        },
        putOnHoldButton: {
            type: Boolean,
            default: true
        },
        takeOffHoldButton: {
            type: Boolean,
            default: true
        },
        endCallButton: {
            type: Boolean,
            default: true
        },
        callBackButton: {
            type: Boolean,
            default: true
        },
        closeButton: {
            type: Boolean,
            default: true
        },
        dismissButton: {
            type: Boolean,
            default: true
        },
        searchContactsButton: {
            type: Boolean,
            default: true
        },
        addAsAdditionalPhoneNumberButton: {
            type: Boolean,
            default: true
        },
        addAsAdditionalContactButton: {
            type: Boolean,
            default: true
        },
        leadButtonDisabled: {
            type: Boolean,
            default: false
        },
        moveInButtonDisabled: {
            type: Boolean,
            default: false
        },
        moveOutButtonDisabled: {
            type: Boolean,
            default: false
        },
        paymentButtonDisabled: {
            type: Boolean,
            default: false
        },
        profileButtonDisabled: {
            type: Boolean,
            default: false
        },
        transferButtonDisabled: {
            type: Boolean,
            default: false
        },
        callNotesButtonDisabled: {
            type: Boolean,
            default: false
        },
        createTaskButtonDisabled: {
            type: Boolean,
            default: false
        },
        emailButtonDisabled: {
            type: Boolean,
            default: false
        },
        textButtonDisabled: {
            type: Boolean,
            default: false
        },
        boxShadow: {
            type: Boolean,
            default: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        switchingInProgress: {
            type: Boolean,
            default: false
        },
        vueDraggableResizable: {
            type: Boolean,
            default: true
        },
        confirmation: {
            type: String,
            default: undefined
        },
        voicemailUrl: {
            type: String,
            default: undefined
        },
        playbackUrl: {
            type: String,
            default: undefined
        },
        hideVoicemail: {
            type: Boolean,
            default: false
        },
        hidePlayback: {
            type: Boolean,
            default: false
        },
        loading: {
            type: [ Boolean, String ],
            default: null
        }
    },
    watch:{
        type(){
            if(this.type == 'mini-charm'){
                this.view = 'default';
            }
        },
        selectedContactId(){
            if(this.selectedContactId){
                this.searchContacts = false;
            }
        },
        status(){
            this.voicemailPaused = true;
            this.voicemailCurrentTime = '0:00';
            this.playbackPaused = true;
            this.playbackCurrentTime = '0:00';
        },
        switchingInProgress(){
            if(this.$refs.voicemail && this.switchingInProgress){
                this.voicemailLoaded = false;
                this.$refs.voicemail.pause();
                this.voicemailPaused = true;
                this.$refs.voicemail.currentTime = 0;
                this.voicemailCurrentTime = '0:00';
            }
            if(this.$refs.playback && this.switchingInProgress){
                this.playbackLoaded = false;
                this.$refs.playback.pause();
                this.playbackPaused = true;
                this.$refs.playback.currentTime = 0;
                this.playbackCurrentTime = '0:00';
            }
        },
        voicemailUrl(){
            if(this.$refs.voicemail && !this.switchingInProgress){
                this.voicemailLoaded = false;
                this.$refs.voicemail.pause();
                this.voicemailPaused = true;
                this.$refs.voicemail.currentTime = 0;
                this.voicemailCurrentTime = '0:00';
                this.voicemailTotalTime = '0:00';
                this.$refs.voicemail.setAttribute('src', this.voicemailUrl )
                this.$refs.voicemail.load();
            }
        },
        playbackUrl(){
            if(this.$refs.playback && !this.switchingInProgress){
                this.playbackLoaded = false;
                this.$refs.playback.pause();
                this.playbackPaused = true;
                this.$refs.playback.currentTime = 0;
                this.playbackCurrentTime = '0:00';
                this.playbackTotalTime = '0:00';
                this.$refs.playback.setAttribute('src', this.playbackUrl )
                this.$refs.playback.load();
            }
        }
    },
}
</script>

<style scoped>

.hb-toast-wrapper {
    font-size: 14px;
    color: #101318;
    overflow: hidden;
}

.hb-toast-wrapper-header {
    position:relative;
    width: 336px;
    top: 0;
    left: 0;
    min-height: 40px;
    max-height: 40px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.hb-toast-wrapper-header-drag, .hb-toast-back-to-list {
    position:absolute;
    left:8px;
    top:8px;
}

.hb-toast-wrapper-header-close {
    position:absolute;
    right:8px;
}

.hb-toast-wrapper-header-counter {
    background-color: #f26500;
    height: 16px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: .5px;
}

.hb-toast-wrapper-border {
    border-left: 4px solid;
    border-right: 4px solid;
    border-bottom: 4px solid;
}

.hb-toast-wrapper-border-communication {
    border: 1px solid #C4CDD5;
    border-radius: 4px;
}

.hb-toast-wrapper-border-selected {
    border: 2px solid #00848E;
}

.hb-toast-wrapper-border-success {
    border-color: #02AD0F;
}

.hb-toast-font-color-success {
    color: #02AD0F;
}

.hb-toast-font-color-caution {
    color: #FFD600;
}

.hb-toast-font-color-error {
    color: #FB4C4C;
}

.hb-toast-wrapper-border-task {
    border-color: #f26500;
}

.hb-toast-wrapper-border-caution {
    border-color: #FFD600;
}

.hb-toast-wrapper-border-default {
    border-color: #003135;
}

.hb-toast-header-success {
    background-color: #02AD0F;
}

.hb-toast-header-task {
    background-color: #f26500;
}

.hb-toast-header-caution {
    background: repeating-linear-gradient(135deg, #f8d748, #f8d748 5px, #dabe40 0px, #dabe40 11px);
}

.hb-toast-header-default {
    background-color: #003135;
}

.hb-toast-text-night {
    color: #101318;
}

.hb-toast-list-items-wrapper {
    max-height: 248px;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: none;
}

.hb-toast-pointer {
    cursor: pointer;
}

.hb-toast-links-wrapper {
    padding: 6px 0 8px 0;
}

.hb-toast-footer-wrapper {
    height: 52px;
    padding: 6px 0;
}

.hb-toast-footer-wrapper .hb-toast-footer-right-actions-wrapper .hb-btn-wrapper {
    padding-right: 8px !important;
}

.hb-toast-footer-wrapper .hb-toast-footer-right-actions-wrapper .hb-btn-wrapper:last-of-type {
    padding-right: 0 !important;
}

.hb-toast-main-content-wrapper {
    position: relative;
}

.hb-toast-overlay {
  background-color: #454F5B;
  opacity: .4;      
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  height: 100%;
  overflow: hidden;
}

.hb-toast-communication-section-wrapper {
    min-height: 72px;
    max-height: 72px;
}

.hb-toast-communication-contact-info-wrapper {
    min-height: 36px;
}

.hb-toast-communication-contact-info-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.hb-toast-background-cloud-lighter {
    background-color: #F9FAFB;
}

.hb-toast-background-cloud-light {
    background-color: #F4F6F8;
}

.hb-toast-background-error-shade {
    background-color: #F8E5E1;
}

.hb-toast-background-caution-shade {
    background-color: #FFF9DA;
}

.hb-toast-background-success-shade {
    background-color: #E1FAE3;
}

.hb-toast-font-caption {
    font-size: 12px;
    line-height: 16px;
}

.hb-toast-text-night-light {
    color: #637381;
}

.hb-toast-ended-playback-time-wrapper {
    margin-top: 3px;
}

.hb-toast-vue-draggable-resizable-wrapper {
    user-select: none;
    overscroll-behavior: none;
}

.hb-toast-vue-draggable-resizable-wrapper-box-shadow {
    -moz-box-shadow: 0 8px 8px -8px #202225;
    -webkit-box-shadow: 0 8px 8px -8px #202225;
    box-shadow: 0 8px 8px -8px #202225;
}

.hb-toast-wrapper .hb-toast-list-items-wrapper >>> .hb-toast-item-div-wrapper:not(:last-of-type) {
    border-bottom: 2px solid #003135;
}

.hb-toast-overflow-handler {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-toast-line-through {
    text-decoration: line-through;
}

.hb-toast-communication-unread-dot {
    background-color: #f26500;
    height: 8px;
    min-width: 8px;
    border-radius: 4px;
    position: relative;
    top: 9px;
}

.hb-toast-row-custom-margin-top {
    margin-top: 1px;
}

.hb-toast-cursor-pointer {
    cursor: pointer;
}

.hb-toast-show-units-wrapper {
    height: 18px;
}

.hb-toast-answer-call-button >>> .mdi-phone-in-talk {
    animation: ring 2.5s .3s ease-in-out infinite;
}

.hb-toast-confirmation-overlay {
    background-color: #ffffff;      
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    height: 100%;
    overflow: hidden;
}

.hb-toast-confirmation-overlay-caution-bar {
    height: 52px;
    background-color: #FFF9DA;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

@-webkit-keyframes ring {
  0% { transform: rotate(0); }
  5% { transform: rotate(0); }
  10% { transform: rotate(0); }
  15% { transform: rotate(10deg); }
  20% { transform: rotate(-10deg); }
  25% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  35% { transform: rotate(10deg); }
  40% { transform: rotate(0); }
  45% { transform: rotate(0); }
  50% { transform: rotate(0); }
  55% { transform: rotate(0); }
  60% { transform: rotate(0); }
  65% { transform: rotate(10deg); }
  70% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  80% { transform: rotate(-10deg); }
  85% { transform: rotate(10deg); }
  90% { transform: rotate(0); }
  95% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  5% { transform: rotate(0); }
  10% { transform: rotate(0); }
  15% { transform: rotate(10deg); }
  20% { transform: rotate(-10deg); }
  25% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  35% { transform: rotate(10deg); }
  40% { transform: rotate(0); }
  45% { transform: rotate(0); }
  50% { transform: rotate(0); }
  55% { transform: rotate(0); }
  60% { transform: rotate(0); }
  65% { transform: rotate(10deg); }
  70% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  80% { transform: rotate(-10deg); }
  85% { transform: rotate(10deg); }
  90% { transform: rotate(0); }
  95% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

.hb-toast-slide-in-out {
    position: relative;
    top: 0px;
    opacity: 1;
    -webkit-animation: slide-in-out 0.35s linear;
    animation: slide-in-out 0.35s linear;
}

@-webkit-keyframes slide-in-out {
    99% { top: -50px; opacity: .8; }
    100% { top: 0; opacity: 1; }
}

@keyframes slide-in-out {
    99% { top: -50px; opacity: .8; }
    100% { top: 0; opacity: 1; }
}

</style>