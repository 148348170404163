// import ListAccount from '../components/admin/ListAccounts.vue';

export default [
	{
		name: "Accounts",
		path: '/admin/accounts',
		component: () => import(/* webpackChunkName: "adminAccounts" */'../components/admin/ListAccounts.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      layout: 'master'
		}
	}
]
