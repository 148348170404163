<template>
  <div>
    <div class="form-section">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <div class="horizontal-form-row">
        <label class="horizontal-form-label" for="name_on_card">Name On Card</label>

        <div class="form-input-container">
          <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('name_on_card') }">
            <input
              name="name_on_card"
              id="name_on_card"
              v-model.lazy="creditCard.name_on_card"
              class="w-input input"
              data-vv-as="name on card"
              v-validate="'required'"
            />
          </div>

          <span v-show="errors.has('name_on_card')"   class="status-block error-block field-error">{{ errors.first('name_on_card') }}</span>
        </div>
      </div>
      <div class="horizontal-form-row">
        <label class="horizontal-form-label" for="name_on_card">Card Number*</label>
        <div class="form-input-container">
          <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('name_on_card') }">

            <div id="tsep-cardNumDiv"></div>

          </div>
          <span v-show="errors.has('card_number')"  class="status-block error-block field-error">{{ errors.first('card_number') }}</span>
        </div>
      </div>


      <div class="horizontal-form-row">
        <label class="horizontal-form-label" for="exp_mo">Expiration</label>
        <div class="horizontal-form-input-container">
          <div id="tsep-datepickerDiv"></div>
          <span v-show="errors.has('exp_mo') || errors.has('exp_yr')" class="status-block error-block field-error">You have entered an invalid expiration date.</span>
        </div>
      </div>

      <div class="horizontal-form-row">
        <label class="horizontal-form-label" for="name_on_card">CVV2</label>
        <div class="form-input-container">
          <div class="horizontal-form-input" style="width: 75px;" :class="{'has-error': errors.has('cvv2') }">
            <div id="tsep-cvv2Div"></div>
          </div>
          <span v-show="errors.has('cvv2')" class="status-block error-block field-error">{{ errors.first('cvv2') }}</span>
        </div>
      </div>

    </div>
    <div class="form-section">
      <div class="horizontal-form-row">
        <label class="horizontal-form-label" for="credit_first">Billing Info</label>
        <div class="form-input-container">
          <div class="horizontal-form-input" style="width: 198px;" :class="{'has-error': errors.has('first') }">
            <input
              id="credit_first"
              name="first"
              v-model.lazy="creditCard.first"
              v-validate="'required|alpha_spaces'"
              placeholder="First"
              class="w-input input"
            />
          </div>
          <div class="horizontal-form-input"
               style="width: 198px;"
               :class="{'has-error': errors.has('last') }">
            <input
              id="credit_last"
              name="last"
              v-model.lazy="creditCard.last"
              placeholder="Last"
              v-validate="'required|alpha_spaces'"
              class="w-input input"
            />
          </div>
          <span v-show=" errors.has('first') || errors.has('last')" class="status-block error-block field-error">Please enter the first and last name of the person making the payment. Only letters are allowed</span>
        </div>
      </div>
      <div class="horizontal-form-row">
        <label class="horizontal-form-label" for="credit_first">Company</label>
        <div class="form-input-container">
          <div class="horizontal-form-input"  style="width: 400px;" :class="{'has-error': errors.has('company') }">
            <input
              id="credit_company"
              name="company"
              v-model.lazy="creditCard.company"
              class="w-input input"
            />
          </div>
          <span v-show=" errors.has('company')" class="status-block error-block field-error">{{ errors.first('company') }}</span>
        </div>
      </div>
      <div class="horizontal-form-row">
        <label class="horizontal-form-label"  for="credit_address">Address</label>
        <div class="form-input-container">
          <div class="horizontal-form-input" style="width:270px;" :class="{'has-error': errors.has('credit_address') }">
            <input
              name="credit_address"
              id="credit_address"
              v-validate="'required'"
              placeholder="Address"
              v-model="creditCard.address"
              class="w-input input"
            />
          </div>
          <div class="horizontal-form-input" style="width:125px;" :class="{'has-error': errors.has('credit_address2') }">
            <input
              id="credit_address2"
              name="credit_address2"
              placeholder="Apt. Ste. etc."
              v-model="creditCard.address2"
              class="w-input input"
            />
          </div>
        </div>
      </div>

      <div class="horizontal-form-row">
        <span class="horizontal-form-label" >&nbsp;</span>
        <div class="form-input-container">

          <div class="horizontal-form-input" :class="{'has-error': errors.has('credit_city') }">

            <input
              name="credit_city"
              id="credit_city"
              data-vv-as="city"
              v-validate="'required'"
              placeholder="city"
              v-model="creditCard.city"
              class="w-input input"
            />
          </div>


          <div class="horizontal-form-input" style="width: 130px;" :class="{'has-error': errors.has('credit_state') }">

            <vuetify-statepicker
                name="credit_state"
                id="credit_state"
                data-vv-as="state"
                v-validate="'required'"
                v-model="creditCard.state"
              ></vuetify-statepicker>
          </div>


          <div class="horizontal-form-input" style="width: 125px;" :class="{'has-error': errors.has('credit_zip') }">

            <input
              name="credit_zip"
              id="credit_zip"
              placeholder="zip"
              data-vv-as="zip"
              v-validate="'required'"
              v-model="creditCard.zip"
              class="w-input input"
            />
          </div>
          <span v-show="errors.has('address')" class="status-block error-block field-error">{{ errors.first('address') }}</span>
          <span v-show="errors.has('credit_city')" class="status-block error-block field-error">{{ errors.first('credit_city') }}</span>
          <span v-show="errors.has('credit_state')" class="status-block error-block field-error">{{ errors.first('credit_state') }}</span>
          <span v-show="errors.has('credit_zip')" class="status-block error-block field-error">{{ errors.first('credit_zip') }}</span>
        </div>
      </div>




    </div>

    <div style="text-align: right;">
      <a class="close-link" @click="$emit('close')">Cancel</a>
      <button :disabled="is_loading"
              class="w-button primary-btn"
              @click="savePaymentMethod"
      >Save Payment Method</button>
      <span v-show="is_loading" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
    </div>


  </div>
</template>

<script type="text/babel">
    import Dropdown from '../../assets/Dropdown.vue';
    import Status from '../../includes/Messages.vue';
    import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';

    export default {
        name: "AddTenantPaymentsCard",
        data() {
            return {
                creditCard:{
                    // card_on_file: false,
                    auto_charge: false,
                    first: '',
                    last:'',
                    company: '',
                    exp_mo:'',
                    exp_yr: '',
                    name_on_card: '',
                    card_number:'',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                creds: {
                    deviceId: '',
                    manifest: ''
                },
                debug: true,
                debugData: {},
                payment_sources: [
                    'Telephone/Moto',
                    'In-Store',
                    'E-commerce',

                ],
            }
        },

        filters:{},
        computed:{},
        components:{
            VuetifyStatepicker,
            Dropdown,
            Status,
            Loader
        },
        async mounted(){

            await this.getCredentials();

            // write tsys script to header
            this.manifest = this.active.config;

            if(document.getElementById("tsys-script")) return;

            var newScript = document.createElement("script");

            newScript.src = "https://stagegw.transnox.com/transit-tsep-web/jsView/" + this.creds.deviceId + "?" + this.creds.manifest;
            newScript.id = "tsys-script";
            document.head.appendChild(newScript);

        },


        destroyed(){
            window.tsepObject = {};
            let tsysScript = document.getElementById("tsys-script");
            if(!tsysScript) return;
            tsysScript.parentNode.removeChild(tsysScript);

        },
        methods: {
            async getCredentials(){
                let r = await api.get(this, api.PAYMENTS + 'tsys/generate-manifest/card?lease_id=' + this.lease_id);
                this.creds = r.creds;

            },
            async savePaymentMethod(){
                try {
                    let status = await this.$validator.validateAll();
                    if(!status) return;

                    let tsepFields = window.tsepObject;
                    if(tsepFields.status !== 'PASS'){
                        this.errorSet(this.$options.name, tsepFields.message);
                        return;
                    }
                    let data = this.creditCard

                    data.card_number = tsepFields.maskedCardNumber;
                    data.cvv2 = tsepFields.cvv2.trim();
                    try{
                        let exp = tsepFields.expirationDate.split('/');
                        data.exp_mo = exp[0];
                        data.exp_yr = exp[1];
                    } catch(err) {
                        this.errorSet(this.$options.name, "Invalid expiration date");
                        return;
                    }

                    data.card_type = tsepFields.cardType;
                    data.transactionID = tsepFields.transactionID;
                    data.token = tsepFields.tsepToken;
                    data.type = 'card';
                    if(this.debug){
                        this.debugData = data;
                        return;
                    }
                    this.$emit('save', data);

                } catch(err){
                    console.log(err);
                    this.errorSet(this.$options.name, err);

                }
            },

        },
        props:[ "is_loading", 'active', 'lease_id' ]
    }
</script>

<style>
  #tsep-cvv2,
  #tsep-datepicker,
  #tsep-cardNum {
    display: inline-block;
    margin-bottom: 0;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
  }
</style>
