<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div class="hb-switch-top-level-wrapper d-flex align-center">
        <div
            v-if="right"
            class="mr-4 hb-switch-cursor"
            :class="{'hb-switch-custom-disabled' : $attrs.disabled === '' || $attrs.disabled}"
            @click="vmodel = !vmodel"
        >
            <div class="d-flex">
                <span v-if="$attrs.label && !hasLabelSlot">{{$attrs.label}}</span>
                <slot name="label" v-if="hasLabelSlot"></slot>
            </div>
        </div>
        <v-switch
            v-model="vmodel"
            class="hb-aviary-v-switch mt-0 pt-0"
            v-bind="$attrs"
            v-on="$listeners"
            color="#00848E"
            inset
            hide-details
        >
            <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                <slot :name="scopedSlot" v-bind="slotData"/>
            </template>
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
                <slot :name="slot"/>
            </template>

            <template v-slot:label>
                <div class="d-flex">
                    <span v-if="$attrs.label && !hasLabelSlot && !right">{{$attrs.label}}</span>
                    <slot name="label" v-if="hasLabelSlot && !right"></slot>
                </div>
            </template>
        </v-switch>

        <div
            v-if="tooltipHeader || $slots.tooltipHeader || tooltipBody || $slots.tooltipBody"
            class="my-0 py-0"
            :class="right ? 'ml-n2' : 'ml-1'"
        >
            <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                <template v-slot:header>
                    <span v-if="$slots.tooltipHeader">
                        <slot name="tooltipHeader"></slot>
                    </span>
                    <span v-else-if="tooltipHeader">
                        {{ tooltipHeader }}
                    </span>
                </template>
                <template v-slot:body>
                    <span v-if="$slots.tooltipBody">
                        <slot name="tooltipBody"></slot>
                    </span>
                    <span v-else-if="tooltipBody">
                        {{ tooltipBody }}
                    </span>
                </template>
            </hb-tooltip>
            <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                <template v-slot:body>
                    <span v-if="$slots.tooltipBody">
                        <slot name="tooltipBody"></slot>
                    </span>
                    <span v-else-if="tooltipBody">
                        {{ tooltipBody }}
                    </span>
                </template>
            </hb-tooltip>
            <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
                <template v-slot:header>
                    <span v-if="$slots.tooltipHeader">
                        <slot name="tooltipHeader"></slot>
                    </span>
                    <span v-else-if="tooltipHeader">
                        {{ tooltipHeader }}
                    </span>
                </template>
            </hb-tooltip>
        </div>
    </div>
</template>

<script type="text/babel">

import HbTooltip from './HbTooltip.vue';

export default {
    name:'HbSwitch',
    data(){
        return {

        }
    },
    components:{
        HbTooltip
    },
    computed: {
        vmodel: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        hasLabelSlot(){
            return !!this.$slots['label'];
        },
    },
    props: {
        tooltipHeader: {
            type: String
        },
        tooltipBody: {
            type: String
        },
        right: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

.hb-aviary-v-switch.v-input--is-label-active.v-input--switch--inset .v-input--switch__track {
    background-color: #00848E !important;
    opacity: .17 !important;
}

.hb-aviary-v-switch:not(.v-input--is-label-active).v-input--switch--inset .v-input--switch__track {
    background-color: #637381 !important;
    opacity: .17 !important;
}

.hb-aviary-v-switch.v-input--is-disabled, .hb-switch-custom-disabled {
    opacity: .35 !important;
}

.hb-aviary-v-switch .v-label {
    color: #101318 !important;
    font-size: 14px !important;
}

.hb-switch-cursor {
    cursor: pointer;
}

</style>

<style>

.hb-aviary-v-switch:not(.v-input--is-label-active) .v-input--switch__thumb {
    background-color: #637381 !important;
}

</style>