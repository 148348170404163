<template>
  <hb-form
    :class="{ 'stored-content-item': !showFormLabel }"
    class="stored-contents-form-input-wrapper"
    :label="formLabel"
    :editable="!is_closed"
    :active.sync="active"
    :start-open="onlyEditable"
    :hide-actions="hideActions"
    :divider="false"
    @save="saveStoredContent"
    @cancel="cancel"
  >
    <template v-slot:display>
      <div
        class="mt-0 pt-0"
        :class="{ 'ml-4': is_closed }"
      >
        <div v-if="!selectedStoredContents.length && !hasOthers">
          <span class="hb-link">Click to Add</span>
        </div>
        <div id="stored-contents-list">
          <div v-for="item in sortSelectedContents" :key="item.name">
            <div class="mb-2" v-if="item.id">{{ item.name }}
              <hb-tooltip v-if="item.note">
                <template v-slot:body>
                  <div style="width: 200px">
                    {{ item.note }}
                  </div>
                </template>
              </hb-tooltip>
            </div>
          </div>
        </div>

        <span v-if="notes && hasOthers" class="pt-1 stored-content-notes">
          <span>{{ notes }}</span>
        </span>
      </div>
    </template>
    <template v-slot:edit>
      <div
        class="mb-3 hb-form-description-text-night-light"
        v-if="showDescription"
      >
        Select the type of contents that will be stored.
      </div>

      <div id="stored-contents-list">
        <div v-for="item in filteredStoredContents" :key="item.id">
          <v-checkbox
            class="ml-n1 my-0 stored-content"
            data-name="selectedStoredContents"
            flat
            hide-details
            id="selectedStoredContents"
            name="selectedStoredContents"
            single-line
            solo
            v-model="selectedStoredContents"
            :value="item"
          >
            <template v-slot:label>
              <span>{{ item.name }}</span>
              <div v-if="item.note" class="pl-1">
                <hb-tooltip>
                  <template v-slot:body>
                    <div style="width: 200px">
                      {{ item.note }}
                    </div>
                  </template>
                </hb-tooltip>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>

      <v-checkbox
        :class="filteredStoredContents.length % 2 === 0 ? 'pt-1' : 'pt-5'"
        class="ml-n1 stored-content"
        data-name="selectedStoredContents"
        label="Others"
        flat
        hide-details
        id="selectedStoredContents"
        name="selectedStoredContents"
        single-line
        solo
        v-model="hasOthers"
      >
      </v-checkbox>
      <div v-if="hasOthers">
        <div class="hb-form-description-text-night-light mt-3 pt-0 mb-4">
          Describe stored contents other than what is listed above. *256
          characters max
        </div>
        <hb-textarea
          :rows="2"
          ref="stored-content"
          v-validate="'required|max:256'"
          hide-details
          v-model="notes"
          data-vv-as="Stored Content Notes"
          :error="errors.collect('notes').length > 0"
          label="Notes"
          id="notes"
          name="notes"
        >
        </hb-textarea>
      </div>
    </template>
  </hb-form>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';
export default {
  name: "StoredContents",
  mixins: [ notificationMixin ],
  data() {
    return {
      active: "",
      storedContents: [],
      selectedStoredContents: [],
      filteredStoredContents: [],
      notes: "See Photos at Auction Website",
      hasOthers: false,
      leaseStoredContentList: [],
      others: {},
      initialOtherValue: {},
    };
  },

  props: {
    leaseId: {
      type: String,
      required: true,
    },
    onlyEditable: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    formLabel: {
      type: String,
      default: "Contents",
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    showSuccessMessage: {
      type: Boolean,
      default: true,
    },
    refetchOnSave: {
      type: Boolean,
      default: true,
    },
    showFormLabelTooltip: {
      type: Boolean,
      default: true,
    },
    showFormLabel: {
      type: Boolean,
      default: false,
    },
    is_closed: {
      type: Boolean,
      default: false,
    },
  },

  async destroyed() {
    EventBus.$off("saveStoredContent", this.saveStoredContent);
  },

  async created() {
    await this.fetchStoredContent();
    await this.getLeaseStoredContents();
    EventBus.$on("saveStoredContent", this.saveStoredContent);
  },
  computed: {
    sortSelectedContents() {
      let item = JSON.parse(
        JSON.stringify(this.sortStoredContents(this.selectedStoredContents))
      );
      return item;
    },
  },

  methods: {
    cancel() {
      this.selectedStoredContents = [];
      this.others = this.initialOtherValue;
      this.notes = this.initialOtherValue.value ?? this.notes;
      this.hasOthers = !!this.initialOtherValue.stored_content_id;
      this.selectedStoredContents = this.setAndFilterStoredContent(
        this.leaseStoredContentList
      ); /* Reset stored contents */
    },
    sortStoredContents(storedContents) {
      storedContents.sort((next, prev) => {
        if (next.name == "Others") {
          return 1; // comes last
        } else {
          return next.name < prev.name ? -1 : 1;
        }
      });
      return storedContents;
    },

    async fetchStoredContent() {
      try {
        this.storedContents = await api.get(
          this,
          `${api.SETTINGS}stored-contents`
        );
        let storedContentCopy = this.sortStoredContents(
          JSON.parse(JSON.stringify(this.storedContents?.stored_contents))
        );
        let filtered = [];
        storedContentCopy.forEach((storedContent) => {
          if (storedContent?.name === "Others") {
            this.others = storedContent;
          } else filtered.push(storedContent);
        });
        this.filteredStoredContents = filtered;
      } catch (e) {
        console.log("Failed to get stored contents");
      }
    },

    setAndFilterStoredContent(selectedItems) {
      let items = [];
      this.hasOthers = false;
      if (!selectedItems) return items;
      if (this.storedContents?.stored_contents.length) {
        selectedItems?.forEach((item) => {
          let foundItem = this.storedContents?.stored_contents?.find(
            ({ id }) => id === item.stored_content_id
          );
          // Set the value of custom stored contents when 'Others' is selected
          if (foundItem?.name === "Others" && item.value) {
            this.hasOthers = true;
            this.initialOtherValue = JSON.parse(JSON.stringify(item));
            this.others = item;
            this.notes = item?.value;
          }
          if (foundItem?.name != "Others") {
            items.push(foundItem);
          }
        });
      }
      return items;
    },

    async getLeaseStoredContents() {
      try {
        let selectedItems = await api.get(
          this,
          `${api.LEASES}${this.leaseId}/stored-contents`
        );
        this.leaseStoredContentList = JSON.parse(
          JSON.stringify(selectedItems?.stored_contents)
        );
        this.selectedStoredContents = this.setAndFilterStoredContent(
          selectedItems?.stored_contents
        );
        if (this.others.stored_content_id) {
          this.hasOthers = true;
        }
      } catch (e) {
        console.log("Failed to get stored contents");
      }
    },

    async saveStoredContent() {
      if (this.hasOthers && !this.notes) {
        let message = {
          type: "error",
          description:
            "There are errors in your form, correct them before continuing.",
          list: this.errors.items,
        };
        this.showMessageNotification({ type: "error",
          description: "There are errors in your form, correct them before continuing.",
          list: this.errors.items });
        return;
      }
      try {
        let body = [];
        if (this.hasOthers) {
          if (this.others?.stored_content_id) {
            body.push({
              id: this.others?.id,
              stored_content_id: this.others?.stored_content_id,
              name: this.others?.name,
              value: this.notes,
            });
          } else {
            body.push({
              stored_content_id: this.others?.id,
              name: this.others?.name,
              value: this.notes,
            });
          }
        }
        this.selectedStoredContents.forEach((selectedContent) => {
          let existingSelectedItem = this.leaseStoredContentList.find(
            (item) => item.stored_content_id === selectedContent.id
          );
          if (existingSelectedItem) {
            body.push({
              id: existingSelectedItem?.id,
              stored_content_id: existingSelectedItem?.stored_content_id,
              name: existingSelectedItem?.name,
              value: existingSelectedItem?.name === "Others" ? this.notes : "",
            });
          } else {
            body.push({
              stored_content_id: selectedContent?.id,
              name: selectedContent?.name,
              value: selectedContent.name === "Others" ? this.notes : "",
            });
          }
        });
        await api.post(
          this,
          `${api.LEASES}${this.leaseId}/stored-contents`,
          body
        );
        if (this.showSuccessMessage) {
		  this.showMessageNotification({ type: "success",
            description: "Stored contents have been updated successfully." });		  
        }
        this.active = "";
        if (this.refetchOnSave) {
          await this.fetchStoredContent();
          await this.getLeaseStoredContents();
        }
      } catch (err) {
        this.showMessageNotification({ description: err });	
      }
    },
  },
};
</script>

<style>
.stored-content-item .hb-aviary-form-label-150 {
  display: none !important;
}

.stored-content .v-label {
  margin-top: 0px !important;
}

.stored-contents-form-input-wrapper
  .v-input--selection-controls:not(.v-input--is-label-active)
  .v-input--selection-controls__input
  > i.v-icon,
.hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled:not(
    .v-input--is-label-active
  )
  .v-input--selection-controls__input
  > i.v-icon {
  color: #dfe3e8 !important;
}
.stored-content-notes {
  width: 370px;
}

#stored-contents-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
</style>
