export default [{
  name: "Accounting",
  path: '/accounting',
  component: () => import('../components/accounting/Index.vue'),
  meta: {
    requiresAuth: true,
    hasAccess: ['admin'],
    layout: 'master',
    re_fetch:true
  }
}]
