// import ReservationsIndex from '../components/reservations/Index.vue'


export default [
	// {
	// 	path: '/reservations',
	// 	component: ReservationsIndex,
	// 	meta: {
	// 		requiresAuth: true,
	// 		hasAccess: ['admin']
	// 	}
	// },
  //   {
  //       path: '/reservations/:id',
  //       name: 'View Reservation',
  //       component: ReservationsIndex,
  //       meta: {
  //           requiresAuth: true,
  //           hasAccess: ['admin']
  //       }
  //   }
]
