<template>
     
    
    <hb-text-field
        v-model="cvv"
        box
        :class="{'custom-field-error' : errors.first(`${label}`)}"
        v-validate="`required|numeric|length:${maxlength}`"
        data-vv-name="card_cvv"
        data-vv-as="Card CVV"
        :error="errors.has('card_cvv')"
        :label="`${label}*`"
        id="card-cvv"
        name="card-cvv"
        autocomplete="cc-card-cvv2"
        counter
        :maxlength="maxlength"
        hide-details
        append-icon="mdi-lock"
        @blur="$validator.validate('card_cvv')"
        @input="updateCVV($event)"
        
    >
    </hb-text-field>
</template>

<script type="text/babel">
    import creditCardType from 'credit-card-type';
    import { mapGetters, mapMutations } from "vuex";
    import { notificationMixin } from  '../../mixins/notificationMixin';
    export default {
        name: "CardCVV",
        mixins: [ notificationMixin ],
        data: () => ({
            maxlength: 3,
            label: 'CVV',
            cvv:""
        }),
        destroyed() {
                  },
        created(){

        },
        methods: {
             ...mapMutations({
                setCheckErrors: 'paymentsStore/setCheckErrors',
                setPaymentMethod: 'paymentsStore/setPaymentMethod',
            }),

            updateCVV : _.debounce(async function(value) {
                this.setPaymentMethod({property: 'cvv2', propertyValue: this.cvv});
            }, 1000),

            validateCardCVV(){
                return this.$validator.validateAll();  ;
            }
        },
        computed: {
            ...mapGetters({
              notification:'charmCallStore/notification',
              getErrors: 'paymentsStore/getErrors',
              payment_method: 'paymentsStore/getPaymentMethod',
            }),
            card_number(){
                return this.payment_method.card_number || '';
            }
        },
        watch: {
            card_number() {
                if(this.card_number) {
                    let card_results = creditCardType(this.card_number);
                    if(card_results && card_results.length){
                        let card = card_results[0];
                        if(card.code){
                            this.label = `${card.code.name}`;
                            this.maxlength = card.code.size;
                        }
                    }
                }
            },
            method(val){
                if(val !== 'new'){
                    this.$validator.reset();  
                }
            }
        },
        props: ['method'],
    };
</script>

<style>
</style>