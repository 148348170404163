<template>
    <div >
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>


        <div v-if="!payment.id">
            <span v-if="overPayment">
                <div class="info-section" >
                    There will be an overpayment of {{ overPayment | formatMoney}} remaining on this account.
                </div>
                <br />
                <br />
            </span>
            <div class="form-section payment-summary skinny-row" >
                <h4 class="notification">Payment Totals</h4>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Date:</span>
                    <span class="horizontal-form-text">{{payment.date | formatDate }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Amount:</span>
                    <span class="horizontal-form-text">{{payment.amount | formatMoney }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Method:</span>
                    <span class="horizontal-form-text" >

                        <img class="cc-icon" v-if="payment.method.type == 'check'"  src="/img/cc_icons/64/cheque.png" />
                        <img class="cc-icon" v-if="payment.method.type == 'cash'"  src="/img/cc_icons/64/dollar.png" />
                        <img class="cc-icon" v-if="payment.method.type == 'ach'"  src="/img/cc_icons/64/ach.png" />
                        <img class="cc-icon" v-if="payment.method.type == 'card'"  :src="payment.method | getCCIcon" />

                          <span class="card-type" v-if="payment.method.type == 'check'" >Check
                              <span v-if="payment.ref_name || payment.number"> ( {{payment.ref_name}}
                              <span v-if="payment.number">#{{payment.number}}</span> )</span>
                          </span>

                         <span class="card-type" v-if="payment.method.type == 'cash'" >Cash</span>
                         <span class="card-type" v-if="payment.method.type == 'ach' || payment.method.type == 'card'" >
                             *{{payment.method.card_end}} ({{payment.method.first}} {{payment.method.last}})
                         </span>
                    </span>


                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Notes:</span>
                    <span class="horizontal-form-text">{{payment.notes }}</span>
                </div>
            </div>


            <div class="form-section"v-if="isAdmin" v-show="totalApplied > 0" >


                <h4 class="notification">This payment will be applied to the following invoices:</h4>
                <br />
                <br />
                <div class="table">
                    <div class="table-row" v-for="i in invoices" v-if="i.payment_amount > 0">
                        <div class="table-cell">Invoice #{{i.number}}</div>
                        <div class="table-cell">{{i.payment_amount | formatMoney}}</div>
                    </div>
                </div>


            </div>

            <div style="padding: 30px;" v-show="!showLogout">
                <div id="AmazonPayButton"></div>
            </div>
            <button type="button" name="button" v-show="showLogout" class="w-button secondary-btn" id="Logout">Logout of Amazon</button>
            <br />
            <br />
            <div id="walletWidgetDiv" v-show="showWallet" style="height:250px"></div>


            <div class="modal-footer">



                <div v-if="!payment.method.id">

                    <!--<br />-->
                    <!--<div id="consentWidgetDiv" style="height:250px"></div>-->
                    <br />
                    <br />

                    <button class="w-button tertiary-btn" @click.prevent="$emit('close')">Cancel</button>


                    <button :disabled="isLoading($options.name) || !showOrderSubmit" class="w-button primary-btn" @click.prevent="saveAmazonPayment">Pay {{payment.amount | formatMoney}} Now</button>

                    <span v-show="isLoading($options.name)" >
                        <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                    </span>
                    <br /><br />
                    <span v-show="showOrderSubmit" class="subdued"><span class="icon"></span>&nbsp; Your account will be charged</span>

                </div>
             </div>

        </div>


        <div class=" payment-summary text-center skinny-row" v-if="payment.id">

            <div class="text-center form-section" >
                <h3 class="success" v-if="paymentType == 'open'">Payment Applied Successfully</h3>
                <h3 class="success" v-if="paymentType == 'new'">Payment Successful</h3>
                <span class="icon confirmation-check success" >
                    
                </span>

                <div v-if="paymentType == 'new'">
                    <p>Your payment of {{payment.amount | formatMoney}} as been processed.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

                <div v-if="paymentType == 'open'">
                    <p>Your payment has been applied to the selected open invoices.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

            </div>
            <button class="w-button secondary-btn float-right" @click.prevent="$emit('reload')"> Enter Another Payment</button>
            <button class="w-button primary-btn float-left" @click="$emit('finish')">Close Window</button>

        </div>


    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "AmazonPayments",
        data() {
            return {
                confirmed: false,
                orderReferenceId: '',
                buyerBillingAgreementConsentStatus: '',
                showWallet: false,
                showLogout: false,
                showOrderSubmit: false,
                endpoints: {
                    production:{
                        widgets: 'https:/-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js',
                        mws: 'https://mws.amazonservices.com/OffAmazonPayments/2013-01-01',
                        login: 'https://api.amazon.com',
                        profile: 'https://api.amazon.com/user/profile'
                    },
                    sandbox: {
                        widgets: 'https:/-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js',
                        mws: 'https://mws.amazonservices.com/OffAmazonPayments_Sandbox/2013-01-01',
                        login: 'https://api.sandbox.amazon.com',
                        profile: 'https://api.sandbox.amazon.com/user/profile'
                    }

                }


            }
        },
        components:{
            Status,
            Loader
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            }),
            totalApplied(){
                var total =  this.invoices.reduce(function(a,b){
                    return a += Math.round(b.payment_amount * 100);
                }, 0);
                return total / 100;
            },
            overPayment(){
                return  ((this.payment.amount * 100)/100) -  this.totalApplied;

            }

        },
        created(){
            this.setApiEndpoint();
        },
        filters:{

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
            },
        },
        mounted(){
            this.setupAmazon();


        },
        methods:{

            setApiEndpoint(){
                var paths = location.hostname.split('.');
                var subdomain = paths[0];
                if(paths[1] + '.' + paths[2] == 'leasecaptain.xyz'){
                    this.endpoint = 'https://api.leasecaptain.xyz/v1/';
                } else if(paths[1] + '.' + paths[2] == 'leasecaptain.com'){
                    this.endpoint = 'https://api.leasecaptain.com/v1/'
                }
            },
            setupAmazon(){


                var accessToken = this.getURLParameter("access_token", location.hash);
                if (typeof accessToken === 'string' && accessToken.match(/^Atza/)) {
                    document.cookie = "amazon_Login_accessToken=" + accessToken + ";path=/;secure";
                }


                window.onAmazonLoginReady = () => {
                    amazon.Login.setClientId(this.amazonClientId);

                };


                window.onAmazonPaymentsReady = () => {
                    this.showLoginButton();

                };

                document.getElementById('Logout').onclick = () => {
                    amazon.Login.logout();
                    document.cookie = "amazon_Login_accessToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
                };

                (d => {
                    var a = d.createElement('script'); a.type = 'text/javascript';
                    a.async = true; a.id = 'amazon-login-sdk';

                    if(this.endpoints[this.environment.toLowerCase()]){
                        a.src = this.endpoints[this.environment.toLowerCase()].widgets;
                    } else {
                        this.endpoints.sandbox.widgets;
                    }

                    d.getElementById('AmazonPayButton').appendChild(a);

                })(document);

            },

            saveAmazonPayment(){

                var params = {
                    payment: this.payment,
                    invoices: this.invoices,
                    lease_id: this.lease_id,
                    access_token: this.access_token,
                    expires_in: this.expires_in,
                    token_type:   this.token_type,
                    billingAgreementId:   this.billingAgreementId,
                    orderReferenceId:   this.OrderReferenceId,
                    AmazonPayment: {},
                };

                return api.post(this,  api.PAYMENTS , params).then(r => {
                    this.confimed = true;
                    this.payment.id = r.payment_id;
                });



            },
            showLoginButton() {
                var authRequest;
                OffAmazonPayments.Button("AmazonPayButton", this.amazonSellerId, {
                    type:  "PwA",
                    color: "Gold",
                    size:  "medium",

                    authorization: () => {
                        var loginOptions = {
                            scope: "profile payments:widget",
                            popup: true
                        };
                        authRequest = amazon.Login.authorize (loginOptions, t => {
                            this.access_token = t.access_token;
                            this.expires_in = t.expires_in;
                            this.token_type = t.token_type;
                            this.showWallet = true;
                            this.showLogout = true;
                            this.showWalletWidget(null);
                        });
                    }
                });
            },
            showWalletWidget(orderReferenceId) {
                // Wallet
                this.orderReferenceId = orderReferenceId;
                new OffAmazonPayments.Widgets.Wallet({
                    sellerId: this.amazonSellerId,
                    orderReferenceId: orderReferenceId,
                    onReady: orderReference => {
                        console.log("orderReference", orderReference.getAmazonOrderReferenceId());
                    },
                    onPaymentSelect: () => {
                        this.showOrderSubmit = true;
                        console.log("paymentSelectArguments", arguments);
                    },
                    onOrderReferenceCreate: orderReference => {
                        // Use the following cod to get the generated Order Reference ID.
                        this.OrderReferenceId = orderReference.getAmazonOrderReferenceId();
                    },
                    design: {
                        designMode: 'responsive'
                    },
                    onError: error => {
                        // Error handling code
                        // We also recommend that you implement an onError handler in your code.
                        // @see https://payments.amazon.com/documentation/lpwa/201954960
                        console.log('OffAmazonPayments.Widgets.Wallet', error.getErrorCode(), error.getErrorMessage());
                    }
                }).bind("walletWidgetDiv");
            },
//            showConsentWidget(billingAgreement) {
//                // Consent
//                new OffAmazonPayments.Widgets.Consent({
//                    sellerId: 'A1YFRFGSMJQLAR',
//                    amazonBillingAgreementId: billingAgreement.getAmazonBillingAgreementId(),
//                    onReady: billingAgreementConsentStatus => {
//                        this.buyerBillingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus();
//                    },
//                    onConsent: billingAgreementConsentStatus => {
//                        this.buyerBillingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus();
//                    },
//                    design: {
//                        designMode: 'responsive'
//                    },
//                    onError: error => {
//                        // Error handling code
//                        // We also recommend that you implement an onError handler in your code.
//                        // @see https://payments.amazon.com/documentation/lpwa/201954960
//                        console.log('OffAmazonPayments.Widgets.Consent', error.getErrorCode(), error.getErrorMessage());
//                    }
//                }).bind("consentWidgetDiv");
//            },

            savePayment(){

                if(!this.isLoading(this.$options.name) && !this.confimed){

                    var params = {
                        payment: this.payment,
                        invoices: this.invoices,
                        lease_id: this.lease_id
                    };

                    api.post(this, api.PAYMENTS, params).then(r => {
                        this.confimed = true;
                        this.payment.id = r.id;
                        EventBus.$emit('lease_payment_saved');
                    });

                }
            },
            getURLParameter(name, source) {
                return decodeURIComponent((new RegExp('[?|&amp;|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(source) || [, ""])[1].replace(/\+/g, '%20')) || null;
            }
        },
        props: {
            invoices: {
                type: Array
            },
            payment: {
                type: Object
            },
            paymentType:{
                type: String
            },
            lease_id:{
                type: String
            },
            onboard: {
                type: Boolean,
                default: false
            },
            global: {
                type: Boolean,
                default: false
            },
            amazonSellerId: {
                type: String,
                required: true
            },
            amazonClientId: {
                type: String,
                required: true
            },
            environment: {
                type: String,
                required: true
            }
        },
    }
</script>

<style scoped>
    label{
        margin: 0;
        padding: 0;
    }
    img.cc-icon {
        border: 1px solid #e2e2e2;
        border-radius: 2px;
        width: 32px;
        margin-right:5px;;
    }

    .confirmation-check{
        font-size: 115px;
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 115px;
    }

</style>
