export default [{
		name: "communicationCenter",
		path: '/chats',
		component: () => import(/* webpackChunkName: "communicationCenter" */ '../components/communication_center/index.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      layout: 'master'
		}
	}
]
