<template>
  <hb-modal
    v-model="dialog"
    title="Add New Payment Method"
    @close="reset"
    show-help-link
  >
    <template v-slot:content>
      <v-row no-gutters>
        <v-col cols="4" class="card-options pl-6">
          <hb-radio-group class="mt-1" v-model="payment_type" :mandatory="false">
            <hb-radio value="card"  v-if="cardProcessor">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-credit-card</hb-icon>Credit/Debit
              </template>
            </hb-radio>
            <hb-radio value="directdebit" v-if="directDebitProcessor">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Direct Debit
              </template>
            </hb-radio>
            <hb-radio value="ach" v-if="achProcessor">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>ACH/E-Check
              </template>
            </hb-radio>
          </hb-radio-group>
        </v-col>

        <v-col cols="8" class="payment-method-holder">

          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success" style="position:absolute;bottom:.5%;right:1.6%;width:40%"></status>
        
          <authnet-card :dense_header="true" :ten_address="tenant_address" v-if="payment_type === 'card' && cardProcessor === 'authorizenet'" ref="authnetCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></authnet-card>
          <tsys-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'tsys'" ref="tsysCard"></tsys-card>
          <tsys-ach :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'tsys'" ref="tsysAch"></tsys-ach> 
          <forte-ach :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'forte'" ref="forteAch"></forte-ach>
          <tenant-payments-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'tenant_payments'" ref="tenantPaymentsCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></tenant-payments-card>
          <tenant-payments-ach :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'tenant_payments'" ref="tenantPaymentsAch"></tenant-payments-ach>
          <fat-zebra-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'fatzebra'" ref="fatzebraCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></fat-zebra-card>
          <fat-zebra-direct-debit :ten_address="tenant_address" :property_id="property_id" :dense_header="true"  v-if="payment_type === 'directdebit' && directDebitProcessor === 'fatzebra'" ref="fatzebraDirectDebit"></fat-zebra-direct-debit>

        </v-col>

      </v-row>
    </template>

    <template v-slot:right-actions>
      <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " color="primary" @click="completeSession()" :disabled="!enableIvrProcessPayment" :loading="ivrPaymentButtonLoading">Save Card {{ivrTimeout}}</hb-btn>
      <hb-btn
        v-else
        color="primary"
        @click="save"
        :disabled="isLoading($options.name)"
        :loading="isLoading($options.name)"
      >
        Save
      </hb-btn>
    </template>
  </hb-modal>
</template>

<script>

import api from '../../assets/api.js'
import AuthnetCard from '../includes/PaymentMethods/AuthnetCard.vue'
import FatZebraCard from "../includes/PaymentMethods/FatzebraCard";
import FatZebraDirectDebit from "../includes/PaymentMethods/FatzebraDirectDebit";
import TsysCard from '../includes/PaymentMethods/TsysCard.vue'
import TsysAch from '../includes/PaymentMethods/TsysAch.vue'
import ForteAch from '../includes/PaymentMethods/ForteAch.vue'
import TenantPaymentsAch from '../includes/PaymentMethods/TenantPaymentsAch.vue'
import TenantPaymentsCard from '../includes/PaymentMethods/TenantPaymentsCard.vue'
import Status from '../includes/Messages.vue';
import { EventBus } from '../../EventBus.js'
import { mapMutations, mapGetters ,mapActions} from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: 'AddPaymentMethod',
  mixins: [notificationMixin],
  data() {
    return {
      connections: [],
      payment_type: '',
      pm_errors: []
    }
  },
  props: ['property_id','contact','close','value'],
  components: {
    AuthnetCard,
    TsysCard,
    TsysAch,
    ForteAch,
    FatZebraCard,
    FatZebraDirectDebit,
    TenantPaymentsAch,
    TenantPaymentsCard,
    Status
  },
  computed: {
    inCall() {
              return this.notification.status === 'active';
            },
          ivrTimeout(){
              return this.ivrTimer
          },
    ...mapGetters({
    ivrTimer:'charmCallStore/ivrTimer',
    ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
    paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
    enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
    IvrPaymentEnable:'charmCallStore/IvrPaymentEnable',   
    ivrPaymentMethod:'charmCallStore/ivrPaymentMethod',
    ivrPaymentType:'charmCallStore/ivrPaymentType',
    paymentData: 'paymentsStore/getPaymentInformation',
    notification:'charmCallStore/notification',
    payment: 'paymentsStore/getPayment',
    payment_method: 'paymentsStore/getPaymentMethod',
  }),
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    },
    cardProcessor(){
      let processor = this.connections.find(c => c.type === 'card' );
      return processor ? processor.name : null;
    },
    achProcessor(){
      let processor = this.connections.find(c => c.type === 'ach' );
      return processor ? processor.name : null;
    },
    directDebitProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'directdebit' );
                return processor ? processor.name : null;
            },
    tenant_address () {
      return this.contact.Addresses
    }
  },
  created () {
    this.setPayment({property: 'source', propertyValue: 'In-store'});
    this.setContact({contact: this.contact});
    this.contact_id = this.contact.id
    this.getPaymentMethodOptions()
    this.updateIvrPaymentEnable(true)
  },
  destroyed(){
    this.updateIvrPaymentEnable(false)
  },
  methods: {
    async completeSession(){
      EventBus.$emit('complete-ivr-payment-session');
          },
    ...mapMutations({
      updateIvrPaymentEnable:'charmCallStore/updateIvrPaymentEnable',
      setCheckErrors: 'paymentsStore/setCheckErrors',
      setPayment: 'paymentsStore/setPayment',
      setContact: 'paymentsStore/setContact',
      setPaymentMethod: 'paymentsStore/setPaymentMethod',
      setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
      resetPayments: 'paymentsStore/resetPayments',
      ivrPaymentTypeUpdate:'charmCallStore/ivrPaymentType'
    }),
    ...mapActions({     
            fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections',    
    }),

    async getPaymentMethodOptions(){
      this.fetchPropertyConnections({propertyID:this.property_id})
      let r = await api.get(this, api.PROPERTIES + this.property_id +  '/connections');
      this.connections = r.connections;
      if(!this.connections.length){
        this.showMessageNotification({type: 'error', description: "This account is not set up to accept online payments."});
      }
    },

   async reset(){
      this.resetPayments();
      this.payment_type = '';
      this.$emit('close');
    },

    async save(){
      
      let valid = await this.validatePaymentMethod();
      if(!valid) {
        return;
      }

      let data = {
        payment: this.generateObjectWithValues(this.payment, {}),
        property_id: this.property_id,
        contact_id: this.contact_id,
        payment_method: this.generateObjectWithValues(this.payment_method, {})
      }

      try{
        if(data.payment_method.type==='directdebit' && data.payment_method.bsb_number){
          data.payment_method.bsb_number = data.payment_method.bsb_number ? data.payment_method.bsb_number.slice(0, 3) + '-' + data.payment_method.bsb_number.slice(3) : "" 
        }
        let res = await api.post(this, api.PAYMENTS + 'payment-method', data);
        this.reset();
        this.$emit('refreshData', res.payment_method)
        EventBus.$emit('lease_edited');
        this.showMessageNotification({ type: 'success', description: 'New payment method has been successfully added.' });
      } catch(err){
        this.showMessageNotification({ list: [err] });
      }

    },

    cancel(){  
      this.reset();
      this.$emit('close');
    },

    validatePaymentMethod(){
      if(this.payment_type === 'card' && this.cardProcessor === 'authorizenet') return this.$refs.authnetCard.validateFields();
      if(this.payment_type === 'ach' && this.achProcessor === 'forte') return this.$refs.forteAch.validateFields();
      if(this.payment_type === 'card' && this.cardProcessor === 'tsys') return this.$refs.tsysCard.validateFields();
      if(this.payment_type === 'ach' && this.achProcessor === 'tsys') return this.$refs.tsysAch.validateFields();
      if(this.payment_type === 'card' && this.cardProcessor === 'tenant_payments') return this.$refs.tenantPaymentsCard.validateFields();
      if(this.payment_type === 'ach' && this.achProcessor === 'tenant_payments') return this.$refs.tenantPaymentsAch.validateFields();
      if(this.payment_type === 'card' && this.cardProcessor === 'fatzebra') return this.$refs.fatzebraCard.validateFields();
      if(this.payment_type === 'directdebit' && this.directDebitProcessor === 'fatzebra') return this.$refs.fatzebraDirectDebit.validateFields();

  
    },

    generateObjectWithValues(source, target) {
      Object.keys(source).forEach((key) => {
        const value = source[key];
        if (value !== undefined && value !== "" && value !== null) {
          target[key] = value;
          if(key === 'save_to_account') target[key] = true;
        }
      });
      return target;
    }
  },
  watch: {

    paymentConnectorResponse(payload){
            if(!payload) return
            if(payload.status == 200){
                this.reset();
                this.$emit('refreshData', payload.payment_method)
                EventBus.$emit('lease_edited');
                this.showMessageNotification({ type: 'success', description: 'New payment method has been successfully added.' });
                }else{
                  if(payload?.msg){
                    this.showMessageNotification({ list: [payload.msg] });
                  }else{
                    this.showMessageNotification({ type: 'error', description: 'Something went wrong.' });
                  }
                }
          },
    payment_type() {
      this.ivrPaymentTypeUpdate(this.payment_type);
      this.closeMessageNotification();
      this.setPaymentMethod({property: 'type', propertyValue: this.payment_type}); 
      this.setPayment({property: 'type', propertyValue: this.payment_type});
    }
  }
}
</script>
<style scoped>
  .card-options{
    border-right: 1px solid #C4CDD5;
    padding: 10px;
    font-size: 14px;
  }
  .payment-method-holder{
    padding: 10px 20px;
  }
</style>