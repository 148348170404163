export default Object.freeze({
  RANKS: [
    'General', 'Captain', 'Lieutenant colonel', 'Second lieutenant', 'Vice admiral', 'Flight lieutenant',
    'Pilot Officer', 'Lieutenant', 'Admiral', 'Major general', 'Field marshal', 'Wing commander', 'Admiral of the fleet',
    'Marshal of the air force', 'Colonel', 'Brigadier', 'Ensign', 'Officer Cadet', 'Squardon leader', 'Group captain', 'Major',
    'Commodore', 'Commander', 'Lieutenant commander', 'Sub-lieutenant', 'Flying Officer', 'Lieutenant junior grade', 'Sergeant'
  ],
  BRANCHES: [
    'Army', 'Air Force', 'Navy', 'Marines', 'Coast Guard'
  ]
});
