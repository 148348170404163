
export const tableKeys = {
	data() {
		return {
			cols: [

				{
					label: "Address",
					key: "address",
					showSmall: true,
					width: 150
				},
				{
					label: "Gate",
					key: "gate"
				},
				{
					label: "Status",
					key: "status",
					showSmall: true
				},
				{
					label: "Code",
					key: "code",
					width: 125

				},
				{
					label: "Actions",
					key: "actions",
					width: 250
				}

			],
			filters:{
				search:{
					address: [],
					gate:'',
					code: '',
					status: '',
					actions: ''
				},
				columns:[
					'address',
					'gate',
					'code',
					'status',
					'actions'
				],
				sort:{
					field: 'balance',
					dir: 'DESC'
				},
				page: 1,
				limit:25,
				offset:0
			},
		}
	}
};