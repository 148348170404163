<template>

    <div>
        <div class="modal-description">

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

            <p>Here you can edit the breakdown of charges applied to each payment method. This allows you to customize exactly how everyone is charged every month. Selecting "AutoPay" will cause the payment method to be billed automatically every month on the due date.
            </p>
        </div>

        <div class="info-section">
            <div class="info-section-body">
                Total rent must add up to: <strong>${{rent}}</strong><br />
                Total other charges must add up to <strong>100%</strong>
            </div>
        </div>

        <br />

        <div class="table ">
            <div class="table-row">
                <!--<div class="table-head" style="width: 50px;">-->
                    <!--&nbsp;-->
                <!--</div>-->
                <div class="table-head">
                    Payment Methods
                </div>
                <div class="table-head">
                    Rent
                </div>
                <div class="table-head">
                    Other Charges
                </div>
            </div>
            <div class="table-row" v-for="method, i in breakdown" v-if="method.type == 'card' || method.type == 'ach'">
                <!--<div class="table-cell" style="width: 50px;">-->
                    <!--<div class="checkbox">-->
                        <!--<input-->
                            <!--type="checkbox"-->
                            <!--v-model="method.auto_charge"-->
                            <!--:id="'checkbox-auto-' + i"-->
                            <!--:name="'checkbox-auto-' + i"-->
                            <!--:data-name="'checkbox-auto-' + i"-->
                            <!--class="w-checkbox-input">-->
                        <!--<label-->
                            <!--:for="'checkbox-auto-' + i"-->
                            <!--class="w-form-label">&nbsp;</label>-->
                    <!--</div>-->
                <!--</div>-->
                <div class="table-cell" v-if="method.type == 'card'">
                    <span class="cc-icon-holder">
                        <img class="cc-icon" :src="method | getCCIcon" />
                    </span>
                    <span class="cc-method-col">
                        <span class="card-detail">{{method.first}} {{method.last}}</span>
                        <br />
                        <span class="card-type">{{method.card_type}} *{{method.card_end}}</span>
                    </span>
                </div>

                <div class="table-cell"  v-else-if="method.type == 'ach'">
                    <img class="cc-icon" :src="'/img/cc_icons/64/ach.png'" />
                    <span class="cc-method-col">
                        <span class="card-detail">{{method.first}} {{method.last}}</span>
                        <br />
                        <span class="card-type">{{method.card_type}} *{{method.card_end}}</span>
                    </span>
                </div>


                <div class="table-cell">
                    <div class="form-group" :class="{'has-error': errors.has('rent') }">
                        <div class="form-group-icon icon">$</div>
                        <input type="text" name="rent" v-model="method.rent" v-validate="'decimal:2|max_value:99999999.99|min_value:0'" class="w-input input"   />
                    </div>
                    <span v-show="errors.has('rent')" class="status-block error-block field-error">{{ errors.first('rent') }}</span>
                </div>
                <div class="table-cell">
                    <div class="form-group" :class="{'has-error': errors.has('other') }">
                        <input type="text" name="other" v-model="method.utilities" v-validate="'integer|max_value:100|min_value:0'" class="w-input input"  />
                        <div class="form-group-icon icon">%</div>
                    </div>
                    <span v-show="errors.has('other')" class="status-block error-block field-error">{{ errors.first('other') }}</span>
                </div>
            </div>
            <div class="table-row">

                <div class="table-cell">
                    <strong>Total Auto payment</strong>
                </div>
                <!--<div class="table-cell">&nbsp;-->
                <!--</div>-->

                <div class="table-cell" :class="[checkRent ? 'success-block' : 'error-block']">
                    <strong>{{totalRent | formatMoney}}</strong>
                </div>

                <div class="table-cell" :class="[checkUtilities ? 'success-block' : 'error-block']">
                    <strong>{{totalUtilities}}%</strong>
                </div>
            </div>
        </div>

        <div style="text-align: right; padding:20px;">
            <a class="close-link" @click="$emit('close')">Cancel</a>
            <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click="saveNewBreakdown">Save</button>
            <span v-show="isLoading($options.name)" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </div>
    </div>

</template>
<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    export default {
        name:"EditBreakdown",
        data() {
            return {
                breakdown:[]
            }
        },
        props:['paymentMethods', 'rent', 'lease_id'],
        created (){
            this.breakdown = JSON.parse(JSON.stringify(this.paymentMethods));
        },
        filters:{
            getCCIcon(method){
                var icon = ( ['amex', 'visa', 'mastercard'].indexOf(method.card_type) >= 0 ) ? method.card_type: 'credit_card';
                return  '/img/cc_icons/64/'+ icon +'.png';
            }
        },
        components:{
            Loader,
            Status
        },
        computed:{
            totalRent(){
                var rent = 0;

                this.breakdown.forEach(function(b){


                    rent += Math.round(b.rent * 1e2) / 1e2;
//                    if(b.auto_charge){
//                    }
                });

                return rent;

            },
            totalUtilities(){
                var utilities = 0;
                this.breakdown.forEach(function(b){

                    utilities += Math.round(b.utilities * 100) / 100;
//                    if(b.auto_charge){
//                    }
                });
                return utilities;
            },
            checkRent(){
                return this.rent == this.totalRent;
            },
            checkUtilities(){

                return this.totalUtilities == 100;
            }
        },
        methods: {
            saveNewBreakdown(){
                if(this.caluclateAmounts()){
                    var data = {
                        payment_methods: this.breakdown.map(b => {
                            return {
                                id: b.id,
                                rent: b.rent,
                                utilities: b.utilities
                            }
                        })
                    };

                    api.put(this, api.LEASES + this.lease_id + '/payment-methods/autopay', data).then(r => {
                        this.$emit('refetch');
                        this.$emit('close');
                    });

                } else {
                    this.errorSet(this.$options.name, "The total rent payment must equal the rent due each month.  The total other charges must equal 100%");
                }
            },
            caluclateAmounts(){
                var rent = 0;
                var utilities = 0;

                var hasCharge = this.breakdown.filter(function(b){
                    return b.rent > 0 || b.utilities > 0;
                });
                if(!hasCharge.length) return true;
                if(this.checkRent && this.checkUtilities) return true;
                return false;
            },
            reset(method){
                if(!method.auto_charge){
                    method.rent = '';
                    method.utilities = '';
                } else {
                    if(method.rent == '') method.rent = 0;
                    if(method.utilities == '') method.utilities = 0;
                }
            }
        }
    }
</script>

<style scoped>
    .cc-icon{
        margin-right: 20px;
    }
    .container-fluid > .row{
        border-bottom: 1px solid #e2e2e2;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .container-fluid > .row:last-child{
        border-bottom: none;
    }
    input[type="checkbox"]{
        margin-top: 15px;
    }
</style>
