<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div
        class="hb-select-wrapper"
        :class="{'hb-select-width-370' : !full, 'hb-select-disabled' : disabled, 'hb-select-condensed-wrapper' : condensed}"
        :style="'width:' + computedWidth"
    >
        <v-select
            :value="value"
            @input="$emit('input', value)"
            class="ma-0 pa-0"
            v-bind="$attrs"
            v-on="$listeners"
            single-line
            hide-details
            :clearable="(clearable && !condensed || allowClear)"
            clear-icon="mdi-close-circle"
            :disabled="disabled"
            :menu-props="{
                contentClass: 'hb-select-menu',
                offsetY: true
            }"
            :rounded="rounded"
            :outlined="rounded || box"
            :dense="rounded || box"
            :class="{'hb-select-rounded' : rounded || box, 'hb-select-condensed' : condensed, 'hb-select-small' : small, 'hb-select-medium' : medium}"
            :background-color="backgroundColor"
        >
            <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                <slot :name="scopedSlot" v-bind="slotData"/>
            </template>
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
                <slot :name="slot"/>
            </template>
            <template v-slot:progress>
                <v-progress-circular indeterminate size="18" :style="condensed && (box || rounded) ? 'margin-top: 12px;' : box || rounded ? 'margin-top: 11px;' : 'margin-top: -3px;'" class="mb-0 pa-0 ml-3 mr-0" color="#00848E" width="2"></v-progress-circular>
            </template>
        </v-select>
    </div>
</template>

<script type="text/babel">

export default {
    name:'HbSelect',
    data(){
        return {

        }
    },
    computed: {
        /*
        select: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
        */
       computedWidth() {
            if(this.small) {
               return '60px';
            }
            else if(this.medium) {
                return '108px';
            } else if(this.width) {
                return this.width
            } else {
                return 'auto';
            }
       }
    },
    props: {
        value: {

        },
        full: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        rounded: {
            type: Boolean,
            default: false
        },
        box: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: undefined
        },
        condensed: {
            type: Boolean,
            default: undefined
        },
        small: {
            type: Boolean,
            default: false
        },
        medium: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: '#FFFFFF'
        }
    }
}
</script>

<style>

.hb-select-wrapper {
    margin-top: -1px;
}

.hb-select-wrapper * {
    font-size: 14px;
}

.hb-select-width-370 {
    max-width: 370px;
}

.hb-select-wrapper .v-select__slot {
    margin-bottom: 0 !important;
}

.hb-select-wrapper input {
    color: #101318 !important;
    font-size: 14px !important;
    padding-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-select-wrapper .hb-select-rounded:not(.hb-select-condensed) input {
    padding-top: 6px;
}

.hb-select-wrapper .v-select__selection {
    margin-top: 1px;
    padding-bottom: 2px !important;
}

.hb-select-wrapper .v-label {
    color: #637381 !important;
    top: 0;
    padding-bottom: 8px !important;
    font-size: 14px !important;
}

.hb-select-wrapper .v-input:not(.v-input--is-dirty) ::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
    opacity: 1;
}

.hb-select-wrapper .v-input.hb-select-condensed:not(.v-input--is-dirty) input {
    padding-bottom: 0;
    margin-top: 3px;
}

.hb-select-wrapper .v-input__icon--clear .v-icon {
    font-size: 16px !important;
    color: #C4CDD5 !important;
    padding-bottom: 10px;
}

.hb-select-wrapper .hb-select-rounded .v-input__icon--clear .v-icon {
    padding-bottom: 0px;
}

.hb-select-wrapper .v-input__icon--clear .v-icon:hover {
    color: #637381 !important;
}

.hb-select-wrapper :not(.hb-select-condensed) .v-input__prepend-inner {
    margin-top: 3px;
}

.hb-select-wrapper .v-input__prepend-inner, .hb-select-wrapper .v-input__append-inner {
    color: #637381 !important;
}

.hb-select-wrapper .v-input__icon.v-input__icon--prepend-inner .v-icon, .hb-select-wrapper .v-input__icon.v-input__icon--prepend .v-icon, .hb-select-wrapper .v-input__icon.v-input__icon--append-outer .v-icon, .hb-select-wrapper .v-input__icon.v-input__icon--append .v-icon {
    color: #637381 !important;
    padding-bottom: 8px;
}

.hb-select-wrapper .v-select--is-menu-active .v-input__append-inner .v-icon {
    padding-bottom: 0;
    margin-bottom: 8px;
}

.hb-select-wrapper .v-select--is-menu-active .v-input__icon--clear .v-icon {
    margin-bottom: 10px;
}

.hb-select-wrapper .hb-select-rounded .v-input__icon.v-input__icon--prepend-inner .v-icon, .hb-select-wrapper .hb-select-rounded .v-input__icon.v-input__icon--prepend .v-icon, .hb-select-wrapper .hb-select-rounded .v-input__icon.v-input__icon--append-outer .v-icon, .hb-select-wrapper .hb-select-rounded .v-input__icon.v-input__icon--append .v-icon {
    padding-bottom: 0;
}

.hb-select-menu {
    box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-select-menu .v-list-item {
    min-height: 40px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.hb-select-menu .v-list-item__title {
    color: #101318;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.hb-select-wrapper >>> .v-input.error--text input, .hb-select-wrapper >>> .v-input.error--text label, .hb-aviary-form-row >>> .v-label.v-label--active.error--text, .hb-select-wrapper .v-input.v-text-field--placeholder.error--text ::placeholder {
  color: #FB4C4C !important;
}

.hb-select-wrapper .v-input__prepend-outer .v-input__icon .v-icon.error--text, .hb-select-wrapper .v-input__prepend-inner .v-input__icon .v-icon.error--text, .hb-select-wrapper .v-input__append-inner .v-input__icon .v-icon.error--text, .hb-select-wrapper .v-input__append-outer .v-input__icon .v-icon.error--text, .hb-select-wrapper .error--text .v-input__append-inner, .hb-select-wrapper .error--text .v-input__prepend-inner {
    color: #FB4C4C !important;
}

.hb-select-wrapper .v-text-field--placeholder.v-input--is-dirty.error--text input {
    display: none;
}

.hb-select-wrapper .v-text-field:not(.error--text):hover > .v-input__control > .v-input__slot::before {
    border-color: #C4CDD5 !important;
}

.hb-select-wrapper .v-text-field:not(.error--text) > .v-input__control > .v-input__slot::before {
    border-color: #DFE3E8 !important;
}

.hb-select-wrapper fieldset {
    border-color: #DFE3E8;
    margin: 0;
}

.hb-select-menu .v-list-item__content {
    text-transform: none !important;
}

.hb-select-menu {
    margin-top: 1px;
}

.hb-select-menu .v-list-item--dense .v-list-item__title, .hb-select-menu .v-list-item--dense .v-list-item__subtitle, .hb-select-menu .v-list--dense .v-list-item .v-list-item__title, .hb-select-menu .v-list--dense .v-list-item .v-list-item__subtitle {
    line-height: 1.2rem;
}

.hb-select-wrapper input[disabled] {
    opacity: 1;
}

.hb-select-wrapper.hb-select-disabled {
    opacity: .3 !important;
}

.hb-select-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
    color: #637381;
    margin-top: 12px;
}

.hb-select-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner .v-icon {
    margin-bottom: 8px;
}

.hb-select-wrapper .hb-select-rounded .v-input__prepend-inner {
    margin-top: 12px !important;
    padding-right: 4px;
}

.hb-select-wrapper .v-input__prepend-outer {
    margin-right: 4px !important;
}

.hb-select-wrapper .hb-select-rounded .v-input__prepend-outer .v-icon {
    margin-bottom: -15px;
}

.hb-select-wrapper .v-input__prepend-outer .v-icon {
    margin-bottom: -5px;
}

.hb-select-wrapper .v-input__prepend-inner .v-icon {
    margin-bottom: 3px;
}

.hb-select-wrapper .hb-select-rounded:not(.hb-select-condensed) .v-input__prepend-inner .v-icon {
    margin-bottom: 8px;
}

.hb-select-wrapper .hb-select-rounded.hb-select-condensed .v-input__prepend-inner .v-icon {
    margin-bottom: 0;
}

.hb-select-wrapper .v-text-field--rounded .v-input__slot {
    padding: 0 12px 0 15px;
}

.hb-select-wrapper .hb-select-condensed {
    top: -5px;
    margin-bottom: 5px !important;
}

.hb-select-wrapper .hb-select-condensed .v-input__slot {
    padding: 0 8px !important;
    min-height: 38px !important;
}

.hb-select-wrapper .hb-select-condensed fieldset {
    top: 0;
    padding: 0 2px;
}

.hb-select-wrapper .hb-select-condensed .v-select__selection {
    padding-bottom: 0 !important;
    position:relative;
    top: .5px;
}

.hb-select-wrapper .hb-select-condensed .v-input__prepend-inner, .hb-select-wrapper .hb-select-condensed .v-input__append-inner {
    margin-top: 13.5px !important;
}

.hb-select-wrapper .hb-select-condensed .v-input__prepend-inner .v-input__icon--prepend-inner {
    margin-top: -1.5px !important;
    min-width: 18px;
    width: 18px;
    height: 18px;
}

.hb-select-wrapper .hb-select-condensed .v-input__append-inner {
    padding-left: 0;
}

.hb-select-wrapper .hb-select-condensed .v-input__append-inner .v-input__icon--append {
    margin-top: 3px;
    min-width: 18px;
    width: 18px;
    height: 18px;
}

.hb-select-wrapper .hb-select-condensed .v-input__prepend-inner .v-input__icon--prepend-inner .v-icon, .hb-select-wrapper .hb-select-condensed .v-input__append-inner .v-input__icon--append .v-icon {
    font-size: 18px;
}

.hb-select-condensed-wrapper {
    margin-right: 8px;
}

.hb-select-wrapper .v-text-field:not(.error--text):not(.v-input--is-focused):not(.v-input--is-loading):hover fieldset {
    border-color: #C4CDD5 !important;
}

.hb-select-wrapper .v-text-field:not(.error--text):not(.v-input--is-focused):not(.v-input--is-loading) fieldset {
    border-color: #DFE3E8 !important;
}

</style>