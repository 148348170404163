<template>
    <hb-modal 
        title="Confirm Access Hours"    
        v-model="dialog" 
        size="medium"
        content-padding
        confirmation
    >
        <template v-slot:content>
            <div class="hb-text-light hb-font-body">You are modifying the Access Hours for a space in Area {{ areaName }}</div>
            <div class="hb-text-light hb-font-body-medium">The following spaces in this Access Area will also have their hours changed{{ formattedHours }}:</div>
            <div class="hb-text-light hb-font-body-medium">{{ unitsInArea }}</div>
            <div class="hb-text-light hb-font-body">Are you sure you want to continue?</div>
        </template>
        <template v-slot:right-actions>
            <hb-btn 
                color="primary" 
                @click="$emit('confirm-change')"
                :disabled="changeInProgress"
                :loading="changeInProgress"
            >
                Change
            </hb-btn>       
        </template>
    </hb-modal>
</template>


<script type="text/babel">

export default {
    name: "ConfirmAccessHours",
    computed:{
        dialog: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
        formattedHours() {
            if (this.hoursName) {
                return ` (${this.hoursName})`;
            } else {
                return '';
            }
        }
    },
    props:{
        areaName:{
            type: String,
            default: ''
        },
        hoursName: {
            type: String,
            default: ''
        },
        unitsInArea:{
            type: String,
            default: ''
        },
        value:{
            type: Boolean,
            default: false
        },
        changeInProgress:{
            type: Boolean,
            default: false
        }
    }
}
</script>