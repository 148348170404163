<template>
    <div>
        <!--
        <p class="ma-0 custom-description">This is a description for overview!</p>
        <v-divider></v-divider>
        -->
        <div class="overview-panel px-1">
            
            <v-row justify="center" align="center" v-if="loading">
                <v-col class="text-center ma-12">
                    <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
                </v-col>
            </v-row>
        

            <div v-else class="overview-container" :class="{'tenant-overview-holder': !isAdmin}">
                <div class="slide-out-section">
                    <!-- Type should be  Lead, Retired Lead, Reservation, Pending, or Converted  -->
                    <div class="mb-5" v-if="activePendings.length">
                        <div class="hb-font-header-3-medium">Pending Move Ins</div>
                        <v-expansion-panels v-model="p_panel" multiple>
                            <hb-expansion-panel v-for="lead in activePendings" :key="lead.id">
                                <template v-slot:title>
                                    <span class="hb-font-body-medium">{{lead | leadType}} for #{{lead.Lease.Unit.number}} </span>
                                </template>
                                <template v-slot:content>
                                    <view-lead :hideActionButtons="hideActionButtons" :readonly="readonly" @lead_saved="getLeads" :contact="contact" :lead="lead" :type="lead | leadType"></view-lead>
                                </template>
                            </hb-expansion-panel>
                        </v-expansion-panels>
                    </div>
                  

                    <!-- Type should be  Lead, Retired Lead, Reservation, Pending, or Converted  -->
                    <div class="mb-5" v-if="activeReservations.length">
                        <div class="hb-font-header-3-medium">Reservations</div>
                        <v-expansion-panels v-model="r_panel" multiple>
                            <hb-expansion-panel v-for="lead in activeReservations" :key="lead.id">
                                <template v-slot:title>
                                    <span class="hb-font-body-medium">{{lead | leadType}} for #{{lead.Lease.Unit.number}}</span>
                                </template>
                                <template v-slot:content>
                                    <view-lead :hideActionButtons="hideActionButtons" :readonly="readonly" @lead_saved="getLeads" :contact="contact" :lead="lead" :type="lead | leadType"></view-lead>
                                </template>
                            </hb-expansion-panel>
                        </v-expansion-panels>
                    </div>


                    <!-- Type should be  Lead, Retired Lead, Reservation, Pending, or Converted  -->
                    <div class="mb-5" v-if="activeLeads.length">
                        <div class="hb-font-header-3-medium" v-if="activeLeads.length">Leads</div>
                        <v-expansion-panels v-model="l_panel" multiple>
                            <hb-expansion-panel v-for="lead in activeLeads" :key="lead.id">
                                <template v-slot:title>
                                    <span class="hb-font-body-medium">{{lead | leadType}} created {{lead.created | formatLocalDateTime }}</span>
                                </template>
                                <template v-slot:content>
                                    <view-lead :hideActionButtons="hideActionButtons" :readonly="readonly" @lead_saved="getLeads" :contact="contact" :lead="lead" :type="lead | leadType"></view-lead>
                                </template>
                            </hb-expansion-panel>
                        </v-expansion-panels>
                    </div>


                   

            
                    <div class="hb-font-header-3-medium" v-if="contact && contact.Leases && contact.Leases.length">Leases</div>
                    <v-card class="mt-4 mb-6" v-if="contact && contact.Leases && contact.Leases.length" elevation="0">
                        <v-data-table
                            :headers="headersLeases"
                            :items="contact.Leases"
                            disable-pagination
                            hide-default-footer
                            class="hb-data-table hb-data-table-cursor-on"
                            @click:row="goToLease"
                        >
                            <template v-slot:item.space="{ item }">
                                <div class="py-2">SPACE #{{item.Unit.number}}<br />
                                    <span class="hb-text-light">
                                        {{item.Unit.Address.address}}<br />
                                        {{ item.Unit.Address | formatAddress }}
                                    </span>
                                </div>
                            </template>
                            <template v-slot:item.rent="{ item }">
                                {{item.rent | formatMoney }}/mo
                            </template>
                            <template v-slot:item.status="{ item }">
                                <hb-status>{{ getLeaseStatus(item) }}</hb-status>
                            </template>
                            <template v-slot:item.balance="{ item }">
                                <div style="color:#ff5252;" v-if="item.balance > 0 ">
                                    {{ item.balance | formatMoney}}
                                </div>
                                <div v-else>
                                    {{ item.balance | formatMoney}}
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>




                    <!-- Type should be  Lead, Retired Lead, Reservation, Pending, or Converted  -->
                    <div class="mb-5" v-if="retiredLeads.length">
                        <div class="hb-font-header-3-medium" v-if="retiredLeads.length">Retired Leads</div>
                        <v-expansion-panels v-model="o_panel" multiple>
                            <hb-expansion-panel v-for="lead in retiredLeads" :key="lead.id">
                                <template v-slot:title>
                                    <span class="hb-font-body-medium">{{lead | leadType}} created on {{lead.created | formatLocalDateTime }}</span>
                                </template>
                                <template v-slot:content>
                                    <view-lead :hideActionButtons="hideActionButtons" :readonly="readonly" @lead_saved="getLeads" :contact="contact" :lead="lead" :type="lead | leadType"></view-lead>
                                </template>
                            </hb-expansion-panel>
                        </v-expansion-panels>
                    </div>
                    <div class="mb-5" v-if="deletedLeads">
                        <!-- <div class="hb-font-header-3-medium" v-if="deletedLeads.length">Not a Lead</div> -->
                        <hb-empty-state
                          message="Selected contact is not longer a lead"
                        >
                        </hb-empty-state>
                    </div>

                    <!-- <div class="hb-font-header-3-medium mt-5" v-if="active_reservations.length">Reservations</div>
                    
                    <v-card class="mt-4 mb-6" v-if="active_reservations.length" elevation="1">
                        <v-data-table
                            :headers="headersReservations"
                            :items="contact.Reservations"
                            disable-pagination
                            hide-default-footer
                            class="hb-data-table hb-data-table-cursor-on"
                            @click:row="goToLeadIntake"
                        >
                            <template v-slot:item.space="{ item }">
                                <div class="py-2">SPACE #{{item.unit_number}}<br />
                                    <span class="hb-text-light">
                                    </span>
                                </div>
                            </template>
                            <template v-slot:item.time="{ item }">
                                {{item.reservation_time | formatLocalDateTime }}
                            </template>
                            <template v-slot:item.expires="{ item }">
                                {{item.reservation_expires | formatLocalDateTime }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <hb-menu options>
                                    <v-list>
                                      <v-list-item>
                                          <v-list-item-title @click="goToLeadIntake(item)">Move-In</v-list-item-title>
                                      </v-list-item>
                                      <v-list-item v-if="false">
                                          <v-list-item-title>Change Space</v-list-item-title>
                                      </v-list-item>
                                      <v-list-item>
                                          <v-list-item-title @click="cancelReservation(item)">Cancel Reservation</v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </template>
                        </v-data-table>
                    </v-card>
                     -->
                    <!-- <div class="hb-font-header-3-medium" v-if="contact.Pending.length">Pending</div>
                    <v-card class="mt-4 mb-6" v-if="contact.Pending.length" elevation="1">
                        <v-data-table
                            :headers="headersPending"
                            :items="contact.Pending"
                            disable-pagination
                            hide-default-footer
                            class="hb-data-table hb-data-table-cursor-on"
                            @click:row="goToPending"
                        >
                            <template v-slot:item.space="{ item }">
                                <div class="py-2">SPACE #{{item.Unit.number}}<br />
                                    <span class="hb-text-light">
                                        {{item.Unit.Address.address}}<br />
                                        {{item.Unit.Address.city}} {{item.Unit.Address.state}} {{item.Unit.Address.zip}}
                                    </span>
                                </div>
                            </template>
                            <template v-slot:item.date="{ item }">
                                {{item.start_date | formatDate }}
                            </template>
                            <template v-slot:item.rent="{ item }">
                                {{item.rent | formatMoney }}
                            </template>
                        </v-data-table>
                    </v-card> -->

                </div>
            </div>
    </div>

        <hb-modal v-model="showCancelReservation" show-help-link v-if="showCancelReservation" size="small" title="Cancel Reservation">
            <template v-slot:content>
            <div class="pa-6">
                Canceling a reservation will add the selected space back into inventory.
            </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="confirmCancelReservation(selectedReservation)">Confirm</hb-btn>
            </template>
        </hb-modal>
    </div>


</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import Modal from '../assets/Modal.vue';
    import Contact from '../contacts/Contact.vue';
    import ApplicationSummary from '../applications/ManageApplication.vue';
    import CreditCheck from '../applications/CreditCheck.vue'
    import { EventBus } from '../../EventBus.js';
    import moment from 'moment';
    import ViewLead from '../leads/ViewLead.vue';
    import { mapGetters } from 'vuex';
    var qs = require('qs');
    export default{
        name: "ContactsOverview",
        data(){
            return {
                loading: false,
                p_panel: [], 
                l_panel: [], 
                r_panel: [], 
                o_panel: [],  
                leads: [],
                showCancelReservation: false,
                selectedReservation: {},
                headersLeases: [
                    { text: 'Space', value: 'space' },
                    { text: 'Rent', value: 'rent' },
                    { text: 'Status', value: 'status' },
                    { text: 'Balance', value: 'balance' }
                ],
                headersReservations: [
                    { text: 'Space', value: 'space' },
                    { text: 'Time', value: 'time' },
                    { text: 'Expires', value: 'expires' },
                    { text: 'Actions', align: 'right', value: 'actions' }
                ],
                headersPending: [
                    { text: 'Space', value: 'space' },
                    { text: 'Date', value: 'date' },
                    { text: 'Rent', value: 'rent' },
                ],
                retire_reason:"Not a lead"
            }
        },
        props: {
            contact: {
                type:Object
            },
            readonly: {
                type:Boolean
            }, 
            hideActionButtons: {
                type:Boolean
            },
            openPanel: {
                type: String
            }
        },
        filters:{
            leadType(lead){ 
                
                if(lead.status === "retired"){
                    return "Retired"
                }
                
                if(lead.status === "active"){ 
                    if(lead.lease_id && lead.Lease.status === 2){
                        return "Pending";
                    }
                
                    if(lead.lease_id && lead.Lease.status === 0){
                        return "Reservation";
                    }
                    return "Lead"
                }
            }
        },
        components:{
            Status,
            Loader,
            Modal,
            ApplicationSummary,
            Contact,
            CreditCheck,
            ViewLead
        },
        computed:{
            activeLeads(){
                return this.leads.filter( item => this.$options.filters.leadType(item) === "Lead");
            }, 
            activeReservations(){
                return this.leads.filter( item => this.$options.filters.leadType(item) === "Reservation");
            },
            activePendings(){
                return this.leads.filter( item => this.$options.filters.leadType(item) === "Pending");
            },
            retiredLeads(){
                return this.leads.filter( item => item.status === "retired");
            },
            deletedLeads(){
                return !(this.activeLeads.length || this.activeReservations.length || this.activePendings.length || this.retiredLeads.length)
            },
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                properties: 'propertiesStore/filtered',
				getApplications: "authenticationStore/getApplications",
            }),
            activeItems() {
                return this.items.filter( item => item.enabled);
            },
            
        },
    
        created(){
            if(this.contact.id){
            this.loading=true;
            this.fetchData();
            this.getLeads();
            }
        },
        methods:{

            getLeads(){
                api.get(this, api.CONTACTS + this.contact.id + '/leads').then( r => {
                    this.leads = r.leads;
                    let i = -1;
                    if(this.openPanel){
                        
                        i = this.activePendings.findIndex(p => p.id === this.openPanel)
                        if(i >= 0) { 
                            this.p_panel.push(i);
                            return;
                        } 

                        i = this.activeReservations.findIndex(p => p.id === this.openPanel)
                        if(i >= 0) { 
                            this.r_panel.push(i);
                            return;
                        } 
                        i = this.activeLeads.findIndex(p => p.id === this.openPanel)
                        if(i >= 0) { 
                            this.l_panel.push(i);
                            return;
                        } 

                        i = this.retiredLeads.findIndex(p => p.id === this.openPanel)
                        if(i >= 0) { 
                            this.o_panel.push(i);
                            return;
                        } 
                        
                
                    } else {
                        if(this.activePendings.length) { 
                            this.p_panel.push(0);
                        } else if (this.activeReservations.length){
                            this.r_panel.push(0);
                        } else if (this.activeLeads.length){
                            this.l_panel.push(0);
                        } 
                    }


                    
                })  

            },
    //         getReservations(){

    // //                api.get(this, api.CONTACTS + this.contact.id + '/reservations').then( r => {
    // //                    this.activity = r.activity
    // //                })

    //         },
            getLeaseStatus(lease){

                if(lease.status == 2){
                    return 'Pending';
                } else if(lease.status == 1){
                    if(lease.end_date && moment(lease.end_date, 'YYYY-MM-DD').format('x') < moment().startOf('day').format('x')){
                        return 'Ended';
                    }  else if(moment().startOf('day').format('x') < moment(lease.start_date, 'YYYY-MM-DD').format('x')){
                        return 'Innactive';
                    } else {
                        return 'Active';
                    }
                }
                return lease.status;
            },
            async goToLease(lease){
                if(lease.status === 1){
                    let path = `/contacts/${this.contact.id}`
                    if(lease?.Unit?.property_id) path += `?property_id=${lease?.Unit?.property_id}`;
                    if (this.$route.path !== path) this.$router.push(path);
                    this.$emit('close');
                    EventBus.$emit('hide_drawer');
                } else if(lease.status === 2){
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === lease.Unit.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: lease.unit_id });
                    EventBus.$emit('new_lead');
                    this.$emit('close');
                }
            },
            cancelReservation(r){
                this.selectedReservation = r;
                this.showCancelReservation = true;
            },
            async confirmCancelReservation(res) {
                api.delete(this, api.RESERVATIONS, res.id ).then(r => {
                    this.fetchData();
                });
            },
            fetchData(){
                api.get(this, api.CONTACTS + this.contact.id).then(r => {
                    this.contact = Object.assign({}, r.contact);
                    this.showCancelReservation = false;
                    this.loading = false;
                });
            },
            async goToPending(pending){
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === pending.Unit.property_id));
                await this.$store.dispatch('onBoardingStore/getPending', { unit_id: pending.unit_id });
                EventBus.$emit('new_lead');
                this.$emit('close');
            },

            async goToLeadIntake(r){

                //await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === r.Lease.Unit.property_id));

                var contact = this.contact;
                var data = {
                    id: r.id,
                    contact_id: contact.id,
                    lead_first: contact.first,
                    lead_last: contact.last,
                    expires: r.expires,
                    lease_id: r.Lease.id,
                    reservation_id: r.id,
                    reservation_time: r.created,
                    unit_id: r.Lease.Unit.id
                }

                EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                //await this.$store.dispatch('onBoardingStore/getReservation', data);
                //EventBus.$emit('new_lead');
                this.$emit('close');
            },
   
            slugify(text){
                return text.toString().toLowerCase()
                        .replace(/\s+/g, '-')           // Replace spaces with -
                        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                        .replace(/^-+/, '')             // Trim - from start of text
                        .replace(/-+$/, '');            // Trim - from end of text
            },

            showApplication(a){
                this.selectedApplication = a;
            },
            showBlade(){ // this method is not being invoked within this file should'nt it be removed !?
                if (this.contact.Pending && this.contact.Pending.length) {
                    this.goToPending(this.contact.Pending[0]);
                } else if (this.contact.Reservations && this.contact.Reservations.length) {
                    this.goToLeadIntake(this.contact.Reservations[0]);
                } else {
                    this.moveIn()
                }
            },
            async moveIn(){
                await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.contact.id});
                EventBus.$emit('new_lead',{location: "from_contact_view"});
                this.$emit('close');
            }
        },
        watch:{
            'contact.id'(){

//                this.getLeases();
//                this.getReservations();
                this.getApplications();
                this.getActivity();
            }
        }

    }
</script>

<style scoped>


    .application{
        padding: 15px;
        border: 1px solid #e2e2e2;
        background-color: #fff;
        box-shadow: 1px 1px 4px 0px #dee5e7;

    }

    .overview-panel{
        display: flex;
        align-items:stretch;
        background: #F9FAFB;
    }

    .overview-detail{
        font-size: 14px;
        background: #FFFFFF;
        box-shadow: 0px 1px 0px #dce8ef;
        border: 1px solid #dce8ef;
        border-radius: 3px;
    }

    .table-head {
        padding: 16px;
        color: #474F5A;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        background: unset;
        border-bottom: 1px solid #C4CDD5;
    }

    .table-cell {
        color: #474F5A;
        font-size: 12px;
        line-height: 16px;
        padding: 16px;
        border-bottom: 1px solid #dce8ef;
    }

    .table-row.clickable:nth-child(odd) {
        background-color: #F9FAFB;
    }

    .status_active {
        color: #02AD0F;
        border-color: #02AD0F;
    }

    .table-heading-custom {
        padding: 0px 23px 23px 1px;
        font-size: 16px;
        color: #101318;
    }

    .slide-out-section {
        padding: 18px 24px 4px;
    }
    .overview-container{
        flex: 1 0 0;
    }
    .v-icon {
        color: dimgrey;
    }
    .v-list-item {
        min-height: 32px;
    }
    .v-list-item__title {
        font-size: 14px;
    }
    .v-list-item:hover {
        background-color: #E0F5F5;
        cursor: pointer;
    }

    .overview-activity{
        flex: 0 1 250px;
    }


    .table-head.light{
        background: white;
        border-bottom: 2px solid #dce8ef;
        padding:8px 10px;
        text-transform: uppercase;
        font-size: 11px;
    }
    .overview-phone-label{
        color: #798f9a;
        font-size: 11px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }

    .contact-holder{
        border-bottom:1px dashed #e2e2e2;
        padding: 10px 0;

    }
    .contact-holder:last-child{
        border-bottom: none;
        padding-bottom: 0;
    }
    .contact-holder:first-child{
        padding-top: 0;
    }

    .overview-activity{
        padding: 15px 10px;
        border-left: 1px solid #e2e2e2;

    }

    @media (max-width: 767px) {
        .overview-panel{
            flex-direction: column;
        }


        .overview-activity{
            margin: 15px;
        }

    }

</style>

