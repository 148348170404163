<template>
    <div>
        <div>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

            <div class="contact-detail interaction" >
                <div class="interaction-text">

                    <div class="summary-subsection">
                        <div class="subsection-label">Status</div>
                        <div class="subsection-value">
                            <span class="status" :class="'status_' + getStatus(selected.status)">
                                {{getStatus(selected.status)}}
                            </span>
                            <a class="link" style="float: right" href="#" v-show="selected.status == 3">Make Active</a>

                        </div>
                    </div>

                    <div class="summary-subsection">
                        <div class="subsection-label">Submitted</div>
                        <div class="subsection-value">
                            {{selected.created | formatLocalDateTime}}
                        </div>
                    </div>


                    <div class="summary-subsection">
                        <div class="subsection-label">Property Address</div>
                        <div class="subsection-value">
                            {{Unit.Address.address}}
                            <span v-show="Unit.number">#{{Unit.number}}</span><br />
                            {{ Unit.Address | formatAddress }}
                        </div>
                    </div>

                    <div class="summary-subsection" v-show="selected.status == 1">
                        <div class="subsection-label">Rent</div>
                        <div class="subsection-value">
                            ${{ Number(Unit.price).toFixed(2)}}
                        </div>
                    </div>

                    <div class="summary-subsection" v-show="selected.status == 1">
                        <div class="subsection-label">Security Deposit</div>
                        <div class="subsection-value" v-show="Unit.security_deposit">
                            ${{ Number(Unit.security_deposit).toFixed(2)}}
                        </div>
                    </div>

                </div>
            </div>

            <div class="contact-detail interaction" v-show="selected.status == 1" >
                <div class="interaction-text">

                    <div class="summary-subsection" v-if="application.evicted.include">
                        <div class="subsection-label">
                            Have you ever been evicted from tenancy or been in a foreclosure?
                        </div>
                        <div class="subsection-value text-right">
                            {{parseInt(selected.evicted) === 1 ? 'Yes':'No'}}
                        </div>
                    </div>

                    <div class="summary-subsection" v-if="application.bankruptcy.include">
                        <div class="subsection-label">
                            Have you ever filed for bankruptcy?
                        </div>
                        <div class="subsection-value text-right">
                            {{parseInt(selected.bankruptcy) === 1 ? 'Yes':'No'}}
                        </div>
                    </div>

                    <div class="summary-subsection" v-if="application.refused_to_pay.include">
                        <div class="subsection-label">
                            Have you ever willfully or intentionally refused to pay rent when due?
                        </div>
                        <div class="subsection-value text-right">
                            {{parseInt(selected.refused_to_pay) === 1 ? 'Yes':'No'}}
                        </div>
                    </div>
                </div>
            </div>



        </div>

    </div>
</template>

<script type="text/babel">
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';

    export default {
        name: "ApplicationSummary",
        data() {
            return {
                Unit: {
                    Address: {
                        address: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    number: '',
                },
                Property: {},
                selected:{},
                application: {
                    allow_applications: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    applicant_info: {
                        id: '',
                        include: 1,
                        required: 0,
                        description: ''
                    },
                    cosigner: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    address_history: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    employment_history: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    general_questions: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    additional_info: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    first: {
                        id: '',
                        include: 1,
                        required: 1,
                        description: ''
                    },
                    last: {
                        id: '',
                        include: 1,
                        required: 1,
                        description: ''
                    },
                    email:  {
                        id: '',
                        include: 1,
                        required: 1,
                        description: ''
                    },
                    middle:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''

                    },
                    phone: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    phone_home: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    phone_cell: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    phone_business: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    ssn: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    gender: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    cosigner_name: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    cosigner_relationship:{
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    cosigner_dob:{
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    cosigner_phone:{
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    cosigner_email:{
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    previous_address: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    previous_address_dates: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    previous_address_rent: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    previous_address_reason: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    previous_address_landlord: {
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    previous_address_phone: {
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    employment_status: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    employer: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    supervisor: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    supervisor_phone: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    position: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    employment_dates: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    salary: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    refused_to_pay: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    bankruptcy: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    evicted: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },

                    dob: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    driver_license: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    vehicle_make_model: {
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    vehicle_plate: {
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    active_military:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    military_branch:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    emergency_contact:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    emergency_relationship:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    emergency_phone: {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    emergency_email:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    },
                    terms_and_conditions:  {
                        id: '',
                        include: 0,
                        required: 0,
                        description: ''
                    }
                }
            }
        },

        components: {
            Status,
            Loader
        },
        computed:{
            militaryContact(){
                var contact =  this.selected.Contact.Relationships.filter(r => r.is_military);
                return contact.length ? contact[0] : { Contact:{ Phones:[] }};
            },
            emergencyContact(){
                var contact =  this.selected.Contact.Relationships.filter(r => r.is_emergency);
                return contact.length ? contact[0] : { Contact:{ Phones:[] }};
            },
            cosigner(){
                var cosigner =  this.selected.Contact.Relationships.filter(r => r.is_cosigner);
                return cosigner.length ? cosigner[0] : { Contact:{ Phones:[] }};
            }
        },
        created(){
            if(this.application_id){
                api.get(this, api.APPLICATIONS + this.application_id).then(r => {
                    this.selected = r.application;
                    this.fetchApplicationStructure();
                })
            }
            if(this.form){
                this.selected = this.form;
                this.fetchApplicationStructure();
            }
        },
        filters:{
            maskNumbers(val){
                var s2 = (""+s).replace(/\D/g, '');
                return val.replace(/.(?=.{4,}$)/g, '#');
            },
            showJoined(val){
                if(typeof val === 'string') return val;
                return val.join(', ');
            },
            formatSSN(s){
                var s2 = (""+s).replace(/\D/g, '');
                var m = s2.match(/^(\d{3})(\d{2})(\d{4})$/);
                return (!m) ? null : m[1] + " - " + m[2] + " - " + m[3];
            },
            formatPhone(s){
                var s2 = (""+s).replace(/\D/g, '');
                var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
                return (!m) ? null : "(" + m[1] + ") " + m[2] + " - " + m[3];
            },
            formatDate(date){

                if(!date) return '';

                var d = moment(date);
                if(d == null || !d.isValid()) {
                    return d;
                } else {
                    return d.format('MM/DD/YYYY');
                }


            }
        },
        methods: {
            getStatus(status){
                if(status === 1) return 'active';
                if(status === 2) return 'accepted';
                if(status === 3) return 'rejected';
            },

            fetchApplicationStructure(){
                var _this = this;

                api.get(this, api.UNITS + this.selected.unit_id + '/application-structure').then(r => {


                    this.Unit = r.unit;
                    this.Property = r.property;
                    if(this.Property.Application){
                        this.Property.Application.forEach(f => {
                            _this.application[f.name] = {
                                id: f.id,
                                include: f.include,
                                required: f.required,
                                description: f.description
                            }
                        })
                    }

                });
            }
        },
        props: ['contact', 'application_id', 'form'],
        watch:{
        }
    }

</script>

<style scoped>
    .subsection-label{
        color: #798f9a;
        font-size: 11px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }

    .summary-subsection{
        display: flex;
        padding: 10px;
        border-bottom: 1px dashed #e2e2e2;
    }
    .summary-subsection div{
        flex:1;
    }
    .summary-subsection:first-child{
        padding-top: 0;
    }
    .summary-subsection:last-child{
        border-bottom: none;

    }
</style>
