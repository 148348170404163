export default [
  {
    name: "RateManagement",
    path: '/rate-management',
    component: () => import('../../components/revenue_management/rate_management/index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master',
      re_fetch:true
    }
  }
]
