<template>
  <div class="schedule-rent-plan" :class="!hasNotRecurring ? 'rent-plan-recurring' : ''">
    <v-timeline class="pt-0" align-top dense>
      <!-- Initial stage of rent plan increase -->
      <v-timeline-item small dense color="white" class="pt-1 first-child">
        <template v-slot:icon>
          <hb-icon
            color="primary"
            style="background-color: white; font-size: 21px"
            >mdi-check-circle-outline</hb-icon
          >
        </template>
        <span class="pl-5 hb-font-body-medium">Start of Lease</span>
      </v-timeline-item>
      <v-timeline-item
        color="#FFFFFF"
        v-for="(item, index) in rentPlanIncreases"
        :key="index"
        class="pb-4"
        :class="index == rentPlanIncreases.length - 1 ? 'last-child' : ''"
        small
      >
        <!-- <v-row>
          <v-col :cols="index == 0 ? '12' : '11'" class="pt-0 pl-8 mt-1">
            <HbSelect
              v-model="item.type"
              :items="stageList"
              item-text="label"
              item-value="type"
              :clearable="false"
              full
              attach
              @change="isRecurringStage(item, index)"
            />
          </v-col>
          <v-col v-if="index !== 0" cols="1" class="pa-0 pb-1">
            <hb-btn
              icon
              tooltip="Close"
              @click="removeRentStage(index)"
              active-state-off
              >mdi-close</hb-btn
            >
          </v-col>
        </v-row> -->
        <div class="d-flex ml-5 justify-space-between">
          <div class="rent-plan-setting flex-grow-1" style="max-width: 490px">
            <div class="rate-setting-wrap d-flex flex-column justify-space-between pl-1">
              <div class="rate-setting-content d-flex px-3 pt-3">
                <span class="pt-1">In</span>
                <div>
                  <hb-text-field
                    box
                    v-model="item.month"
                    placeholder="0"
                    v-validate
                    :error="item.error.month"
                    small
                  />
                </div>
                <span class="pt-1">months</span>
                <div style="width: 255px" class="pt-1">
                  <HbSelect
                    v-model="item.increase_method.type"
                    :items="getPlanTypes()"
                    item-value="item"
                    item-text="label"
                    v-validate
                    :data-vv-name="`method`"
                    data-vv-as="Rent Increase method"
                    return-object
                    :clearable="false"
                    placeholder="Choose Method"
                    :error="item.error.rentChangeMethodError"
                  />
                  <!-- @change="setToRecurring($event, index)" -->
                </div>
                <div class="pt-n1">
                  <hb-text-field
                    box
                    small
                    v-model="item.increase_method.value"
                    :disabled="disableRentValueField(item.increase_method.type)"
                    placeholder="0"
                    v-validate
                    :error="item.error.rentValue"
                    maxlength="10"
                  />
                </div>
              </div>
              <div v-if="item.increase_method.type.targetReached" class="d-flex px-3 pt-3">
                <span>If target is reached, </span>
                <div class="ml-1" style="width: 255px">
                  <HbSelect
                    v-model="item.target_method.target_type"
                    :items="getPlanTypes('target')"
                    item-value="item"
                    item-text="label"
                    v-validate
                    :data-vv-name="`target_method_${index}`"
                    data-vv-as="Target Method"
                    :clearable="false"
                    placeholder="Choose Method"
                    :error="item.error.targetMethodError"
                  />
                </div>
                <div class="ml-1">
                  <hb-text-field
                    box
                    small
                    v-model="item.target_method.target_value"
                    :disabled="!item.target_method.target_type"
                    placeholder="0"
                    v-validate
                    :error="item.error.afterTargetRentValue"
                    maxlength="10"
                  />
                </div>
              </div>
            </div>
            <v-divider class="my-2 mx-4"/>
            <div class="d-flex">
              <hb-radio-group 
                v-model="item.type"
                class="pt-0 pl-4 mb-3"
                row 
                hide-details
                @change="isRecurringStage(item, index)"
              >
                <hb-radio value="single" label="One Time Increase"></hb-radio>  
                <hb-radio class="mr-1" value="recurring" label="Recurring Increase"></hb-radio>  
              </hb-radio-group>
              <div style="width: 55px">
                <hb-text-field
                  box
                  v-model="item.recurrence_interval.value"
                  :disabled="item.type !== 'recurring'"
                  :value="item.type !== 'recurring' ? setRecurrenceIntervalNull(index) : item.recurrence_interval.active = true"
                  placeholder="0"
                  v-validate
                  :error="item.type === 'recurring' ? item.error.recurringMonth : ''"
                  height="32px"
                />
              </div>
              <span class="mt-1 ml-1">months</span>
            </div>
          </div>
          <div style="width: 24px">
            <hb-btn
              v-if="index !== 0"
              class="close-button"
              small
              icon
              tooltip="Close"
              @click="removeRentStage(index)"
              active-state-off
              >mdi-close</hb-btn
            >
          </div>
        </div>
      </v-timeline-item>
      <div v-if="hasNotRecurring">
        <hb-link @click="addRentStage">+ Add Another Rent Change Stage</hb-link>
      </div>
    </v-timeline>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "ScheduleRentPlan",
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      planTypes: [
      {
          item: "dollar_amount",
          label: "Increase Rent by - $ amount",
          targetReached: false,
          value_type: 'dollar_amount',
        },
        {
          item: "rent_percent",
          label: "Increase Rent by - % of Current Rent",
          targetReached: false,
          value_type: 'percent',
        },
        {
          item: "target_sell_rate_percent",
          label: "Target - % of Sell Rate",
          targetReached: true,
          value_type: 'percent',
        },
        // {
        //   item: "target_dollar_amount",
        //   label: "Target - $Amount",
        //   targetReached: true,
        //   value_type: 'dollar',
        // },
        // {
        //   item: "target_rent_percent",
        //   label: "Target - % of Current Rent",
        //   targetReached: true,
        //   value_type: 'percentage',
        // },
        {
          item: "target_set_rate_percent",
          label: "Target - % of Set Rate",
          targetReached: true,
          value_type: 'percent',
        },
        {
          item: "target_rent_sell_variance_percent",
          label: "Target - Variance % (Rent/Sell)",
          targetReached: true,
          value_type: 'rent_sell_variance_percent',
        },
        
      ],
      targetValues: [
        'target_set_rate_percent', 
        'target_rent_sell_variance_percent', 
        'target_sell_rate_percent', 
        'target_dollar_amount', 
        'target_rent_percent'
      ],
      defaultRentChange: {
        month: null,
        type:'single',
        recurrence_interval:{
          value: null,
          active: false,
        },
        increase_method: {
          type: {},
          value: null,
        },
        target_method: {
          target_value: null,
          target_type: ''
        },
        sort_order: null,
        error: {
          month: false,
          rentValue: false,
          recurringMonth: false,
          afterTargetRentValue: false,
          targetMethodError: false,
          rentChangeMethodError: false,
        },
      },
      stageList: [
        {
          type: "recurring",
          label: "Recurring Increase",
        },
        {
          type: "single",
          label: "One Time Increase Stage",
        },
      ],

      rentPlanIncreases: [],
      hasNotRecurring: true,
      deletedStages: [],
      mappedSettings: [],
    };
  },
  watch: {
    settings: {
      immediate: true,
      handler(stages) {
        if (stages?.length) {
          let filteredStages = stages?.filter(
            (stage) => stage?.month !== "Start of Lease"
          );
          this.$nextTick(() => {
            this.rentPlanIncreases = cloneDeep(filteredStages).map(stage => ({
              month: stage?.month,
              type: stage?.recurrence_interval ? 'recurring' : 'single',
              recurrence_interval: {
                value: stage?.recurrence_interval,
                active: !!stage?.recurrence_interval 
              },
              increase_method: {
                type: stage?.target?.before 
                  ? { 
                      item: `target_${stage?.target?.before?.target_type}`,
                      value_type: stage?.target?.before?.target_type,
                      targetReached: true, 
                    }
                  : { 
                      item: stage?.increase_by?.change_type, 
                      value_type: stage?.increase_by?.change_type,
                      targetReached: false, 
                    },
                value: stage?.target?.before 
                  ? stage?.target?.before?.target_value
                  : stage?.increase_by?.change_value,
              },
              target_method: {
                target_value: stage?.target?.after?.change_value,
                target_type: stage?.target?.after?.change_type
              },
              sort_order: stage?.sort_order,
              error: {
                month: false,
                rentValue: false,
                recurringMonth: false,
                afterTargetRentValue: false,
                targetMethodError: false,
                rentChangeMethodError: false,
              },
            }));
          });
        } else {
          this.rentPlanIncreases = [cloneDeep(this.defaultRentChange)];
        }
      },
      deep: true,
    },
    rentPlanIncreases: {
      handler(stages) {
        this.hasNotRecurring = !this.rentPlanIncreases.some(
          (stage) => stage.type === "recurring"
        );
        stages.forEach((rentPlan, index)=> {
          rentPlan.sort_order = index
        })
        let updatedMappedSetting = this.mapStagestoSettings(stages)
        this.mappedSettings = updatedMappedSetting
        this.$emit("updatedSettings", updatedMappedSetting);
      },
      deep: true,
    },
  },
  methods: {
    // setToRecurring(data, index) {
    //   if(this.targetValues.includes(data.item)) 
    //     this.rentPlanIncreases[index].type = "recurring"
    //   else this.rentPlanIncreases[index].type = "single"
    // },
    getPlanTypes(planlist='default') {
      return planlist === 'target' ?  this.planTypes.filter(type => !type.targetReached) : this.planTypes
    },
    isRecurringStage(item, index) {
      // let deletedStages = []
      if (item.type == "recurring") {
        this.deletedStages = this.rentPlanIncreases.splice(index + 1);
        this.hasNotRecurring = false;
      } else {
        // if (this.deletedStages?.length) this.rentPlanIncreases = [...this.rentPlanIncreases, ...this.deletedStages]
        this.hasNotRecurring = true;
      }
    },
    setRecurrenceIntervalNull(index) {
      this.rentPlanIncreases[index].recurrence_interval.value = null
      this.rentPlanIncreases[index].recurrence_interval.active = false
    },
    /**
     * Function to map form data to api structure
     * @param {Object} stages data from the form
     */
    mapStagestoSettings(stages) {
      return stages.map(stage => ({
        month: stage?.month,
        recurrence_interval: stage?.recurrence_interval?.value,
        increase_by: !stage?.increase_method?.type?.targetReached
          ? {change_type: stage?.increase_method?.type?.item, change_value: stage?.increase_method?.value}
          : null,
        target: stage?.increase_method?.type?.targetReached
          ? {
              before: {
                target_value: stage?.increase_method?.value,
                target_type: stage?.increase_method?.type?.item.replace('target_', '')
              },
              after: {
                change_value: stage?.target_method?.target_value,
                change_type: stage?.target_method?.target_type,
              }
            }
          : null,
        sort_order: stage?.sort_order
      }))
    },
    /**
     * Function to check whether rent value field should be disabled or not
     * @param {Object} methodSelected 
     */
    disableRentValueField(methodSelected){
      if(!methodSelected)
        return true;
      return Object.keys(methodSelected).length ? false : true;
    },
    addRentStage() {
      let defaultData = cloneDeep(this.defaultRentChange);
      this.rentPlanIncreases.push(defaultData);
    },
    removeRentStage(index) {
      this.rentPlanIncreases.splice(index, 1);
      // this.hasNotRecurring = !this.rentPlanIncreases.some(stage => stage.type === "recurring")
    },
    showError(value) {
      value.forEach((item) => {
        this.rentPlanIncreases[item.index]["error"] = item;
      });
    },
  },
};
</script>
<style lang="scss">
.schedule-rent-plan {
  .v-timeline::before {
    top: 26px;
    height: calc(100% - 61px);
  }
  .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(13px - 1px) !important;
  }
  .last-child.v-timeline:before {
    width: 5px !important;
  }
  .v-timeline-item__divider {
    min-width: 26px;
  }
  .v-timeline--dense .v-timeline-item {
    justify-content: start !important;
  }
  .rm-digit-field {
    width: 35px;
  }
  .rent-plan-setting {
    border: 1px solid #dfe3e8;
    border-radius: 4px;
  }
  .rate-setting-content {
    gap: 5px;
    width: 100%;
  }
  .rm-select-field {
    width: 50px;
  }
  .v-timeline--dense .v-timeline-item__body {
    max-width: calc(100%);
  }
  .first-child .v-timeline-item__body {
    margin-top: 3px;
  }
  .v-timeline-item__inner-dot {
    border: 2px solid #dfe3e8 !important;
  }
  .v-timeline-item__dot--small .v-timeline-item__inner-dot {
    height: 14px;
    margin: 5px;
    width: 14px;
}
  span i.v-icon.notranslate.mdi.mdi-check-circle.theme--light {
    font-size: 20px !important;
  }
  .first-child.v-timeline-item i.v-icon {
    font-size: 16px !important;
  }
  
  .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
}
.rent-plan-recurring {
  .v-timeline::before {
    top: 26px;
    height: calc(100% - 42px);
  }
}
</style>