<template>
    <div class="slide-out-section" style="width: 100%;">

        <div class="slide-out-section-heading" style="text-align: center" v-show="!isLoading('existingCreditCheck') && !loaded">
            <h2>Credit Check</h2>
            <p>Click below to run a credit check from TransUnion </p>
            <br />
            <button class="w-button primary-btn" @click="checkCredit">Run Credit Check Now</button>
        </div>
        <div class="loading-block" v-show="isLoading('existingCreditCheck')" >
            <p>
                <strong>Running Credit</strong>
                <br />
                <span class="subdued">Hang on a sec...</span>
            </p>
            <br />
             <loader color="#00b2ce" size="14px"></loader>
        </div>





        <modal v-if="showGetCreditCheck" allow-click-away="false" @close="showGetCreditCheck = false">
            <div slot="header">
                <h3>Check Credit</h3>
                <p class="subdued">The following data is pulled from the application and contact details on file. If any of the data is incorrect, close this window and edit the application details or the contact information </p>
            </div>
            <div slot="body" class="modal-padding">



                <div class="credit-check-holder">

                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

                    <div class="summary-subsection">
                        <div class="subsection-label">Name</div>
                        <div class="subsection-value">{{c.first}} {{c.middle}} {{c.last}} {{c.suffix}}</div>
                    </div>



                    <div class="summary-subsection">
                        <div class="subsection-label">Date Of Birth</div>
                        <div class="subsection-value">
                            <span v-if="c.dob">{{c.dob | formatDate}}</span>
                            <span v-else class="error"><span class="icon"></span>&nbsp; Missing required info</span>

                        </div>
                    </div>


                    <div class="summary-subsection">
                        <div class="subsection-label">Current Address</div>
                        <div class="subsection-value">
                            <span v-if="c.Addresses.length">
                                {{c.Addresses[0].Address.address}} <span v-show="c.Addresses[0].Address.address2">#{{c.Addresses[0].Address.address2}}</span><br />
                                {{ c.Addresses[0].Address |  formatAddress }}
                            </span>
                            <span v-else class="error"><span class="icon"></span>&nbsp; Missing required info</span>
                        </div>
                    </div>

                    <div class="summary-subsection">
                        <div class="subsection-label">SSN</div>
                        <div class="subsection-value">
                            <span v-if="c.ssn">{{c.ssn | formatSSN}}</span>
                            <span v-else class="error"><span class="icon"></span>&nbsp; Missing required info</span>
                        </div>
                    </div>



                    <div class="summary-subsection">
                        <div class="subsection-label">Employment Status</div>
                        <div class="subsection-value">{{employmentStatus}}</div>
                    </div>


                    <div class="summary-subsection">
                        <div class="subsection-label">Employment Income</div>
                        <div class="subsection-value">
                            <span v-if="employmentStatus.toLowerCase() == 'employed' && !employmentIncome" class="error">
                                <span class="icon"></span>&nbsp; Missing required info
                            </span>
                            <span v-else>{{employmentIncome}}</span>
                        </div>
                    </div>


                    <div class="summary-subsection">
                        <div class="subsection-label">Space</div>
                        <div class="subsection-value">
                            <strong>SPACE #{{a.Unit.number}}</strong><br />
                            <span class="subdued">{{ a.Unit.Address | formatAddress(',', true) }}</span>
                        </div>
                    </div>

                    <div class="summary-subsection">
                        <div class="subsection-label">Rent</div>
                        <div class="subsection-value">
                            <div class="form-group" style="width: 125px;">
                                <div class="form-group-icon icon">$</div>
                                <input type="text" v-model="rent" class="w-input">
                            </div>
                        </div>
                    </div>

                    <div class="summary-subsection">
                        <div class="subsection-label">Security Deposit</div>
                        <div class="subsection-value">
                            <div class="form-group" style="width: 125px;">
                                <div class="form-group-icon icon">$</div>
                                <input type="text" v-model="security_deposit" class="w-input">
                            </div>
                        </div>
                    </div>

                    <div class="summary-subsection">
                        <div class="subsection-label">Lease Term</div>
                        <div class="subsection-value">
                            <div class="form-group" style="width: 125px;">
                                <input type="text" v-model="lease_term" class="w-input">
                                <div class="form-group-icon icon">Months</div>
                            </div>
                        </div>
                    </div>


                    <div v-for="cosigner in cosigners" class="summary-subsection">
                        <div class="subsection-label">Cosigner</div>
                        <div class="subsection-value">
                            {{ cosigner.Contact.first }} {{ cosigner.Contact.last }}
                        </div>
                    </div>




                    <div class="summary-subsection">
                        <div class="subsection-label">&nbsp;</div>
                        <div class="subsection-value">
                            <checkbox
                                id="verified_photo"
                                name="verified_photo"
                                label="Verified applicant photo ID"
                                v-model="verified_photo"
                        >
                        </checkbox></div>
                    </div>




                    <div class="summary-subsection">
                        <div class="subsection-label">Screening Type</div>
                        <div class="subsection-value">
                            <checkbox
                                    id="credit"
                                    name="credit"
                                    label="Credit Check"
                                    v-model="credit"
                            >
                            </checkbox>

                            <checkbox
                                    id="criminal"
                                    name="criminal"
                                    label="Nationwide Criminal Check"
                                    v-model="criminal"
                            >
                            </checkbox>

                            <checkbox
                                    id="eviction"
                                    name="eviction"
                                    label="Eviction"
                                    v-model="eviction"
                            >
                            </checkbox>

                        </div>
                    </div>
                </div>


                <br />
                <div class="modal-footer">
                    <span v-if="canSubmit">
                        <button @click="runCredit" :disabled="isLoading($options.name)" class="w-button primary-btn">Run Credit Check</button>
                         <span v-show="isLoading($options.name)" >
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                         </span>
                    </span>
                    <span v-else class="error">
                        You have missing or incomplete data. Please fix the errors, and then try again.
                    </span>

                </div>
            </div>
        </modal>

    </div>
</template>


<script type="text/babel">
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import Modal from '../assets/Modal.vue';
    import api from '../../assets/api.js';
    import Checkbox from '../assets/Checkbox.vue';
    import moment from 'moment';
    export default {
        name: "CreditCheck",
        data() {
            return {
                rent: 0,
                security_deposit: 0,
                lease_term: 1,
                showGetCreditCheck: false,
                loaded: false,
                verified_photo: false,
                eviction: false,
                criminal: false,
                credit: false,
                cosigners: []
            }
        },
        created(){
            this.a = this.application;
            this.c = this.contact;
            this.cosigners = this.contact.Relationships.filter(r => r.is_cosigner);
            this.fetchTemplate();
            this.rent = this.a.Unit.price;
        },
        components:{
            Loader,
            Status,
            Modal,
            Checkbox
        },
        computed: {
            employmentStatus(){
              if(!this.c.Employment.length) return 'Other';
              if(this.c.Employment[0].end_date != null) return 'Other';
              if(this.c.Employment[0].status.indexOf('Full Time') >= 0) return 'Employed';
              if(this.c.Employment[0].status.indexOf('Part Time')  >= 0) return 'Employed';
              if(this.c.Employment[0].status.indexOf('Student') >= 0 ) return 'Student';
              if(this.c.Employment[0].status.indexOf('Retired') >= 0) return 'Retired';
              return 'Other';

            },

            employmentIncome(){
                if(!this.c.Employment.length) return null;
                if(this.c.Employment[0].end_date != null) return null;
                if(!this.c.Employment[0].salary) return null;
                if(!this.c.Employment[0].salary_timeframe) return null;
                return this.c.Employment[0].salary + ' per ' +  this.c.Employment[0].salary_timeframe;
            },
            canSubmit(){
                if(this.employmentStatus.toLowerCase() == 'employed' && !this.employmentIncome) return false;
                if(!this.c.dob || !this.c.ssn) return false;
                return true;
            },
        },
        filters:{

        },

        methods: {
            fetchTemplate(){
                api.get(this, api.PROPERTIES + this.a.Unit.property_id + '/templates').then(r => {

                    if(!r.templates[this.a.Unit.type] || !r.templates[this.a.Unit.type].Template) {
                        this.loaded = true;
                        return;
                    }
                    var template = r.templates[this.a.Unit.type].Template;


                    if(template.lease_type.toLowerCase() == "fixed length"){
                        if(template.lease_duration_type.toLowerCase() == 'years'){
                            this.lease_term = template.lease_duration * 12;
                        } else {
                            this.lease_term = template.lease_duration;
                        }
                    } else {
                        this.lease_term = 1;
                    }

                    if(template.security_deposit){
                        this.security_deposit = this.rent * template.security_deposit_months
                    }

                });
            },
            checkCredit(){
                this.showGetCreditCheck = true;
            },

            runCredit(){

                var data = {
                    verified_photo: this.verified_photo,
                    eviction: this.eviction,
                    criminal: this.criminal,
                    credit: this.credit,
                    rent: this.rent,
                    security_deposit: this.security_deposit,
                    lease_term: this.lease_term
                }



                api.post(this, api.APPLICATIONS + this.a.id + '/check-credit', data).then(r => {
                    this.loaded = true;
                });

            },

        },
        props:{
            application: {
                type: Object,
                required: true
            },
            contact: {
                type: Object,
                required: true
            }
        },
        watch:{
            application(){
                this.app = this.application
            }
        }
    }

</script>


<style scoped>
    .credit-check-holder{
        margin-top: 20px;
    }
    .loading-block{
        padding: 50px;
        text-align: center;
        background: #f5f7f8;
        width: 400px;
        margin: 20px auto;
    }
</style>

