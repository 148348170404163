export default [
    {
        name: "rentManagement",
        path: '/rent-management',
        component: () => import('../../components/revenue_management/rent_management/index.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
        }
    }
]
  