export default [
    {
      name: "AdvancedPurchaseWindow",
      path: '/advanced-purchase-window',
      component: () => import('../components/advanced_purchase_window/Index.vue'),
      meta: {
        requiresAuth: true,
        hasAccess: ['admin'],
        layout: 'master',
        re_fetch:true
      }
    }
  ]
  