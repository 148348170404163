<template>
    <v-row class="hb-timeline-item-action-wrapper" no-gutters>
        <v-col class="pa-0 ma-0" :cols="auditTrail ? 11 : 12">
            <v-row no-gutters>
                <v-col cols="auto" class="pa-0 hb-timeline-item-action-icon-wrapper">
                    <HbIcon
                        v-if="type === 'success'"
                        small
                        color="#02AD0F"
                        mdi-code="mdi-check"
                        class=""
                    />
                    <HbIcon
                        v-else-if="type === 'error'"
                        small
                        color="#FB4C4C"
                        mdi-code="mdi-minus"
                        class=""
                    />
                    <v-icon
                        v-else
                        size="20"
                        color="#101318"
                    >mdi-circle-small</v-icon>
                </v-col>
                <v-col class="pa-0 ma-0">
                    <span class="hb-timeline-item-action-item">
                        <span v-if="title" class="hb-font-caption-medium">{{ title }}<template v-if="description">:</template></span>
                        <span v-if="description" class="hb-font-caption hb-text-light ml-1">{{ description }}</span>
                    </span>
                    <hb-link
                        v-if="linkText"
                        @click="$emit('link-clicked', $event)"
                        :href="linkUrl"
                        target="_blank"
                        class="hb-font-caption px-1"
                    >{{ linkText }}</hb-link>
                </v-col>
            </v-row>
        </v-col>
        <v-col v-if="auditTrail" cols="1" align="right">
            <hb-tooltip>
                <template v-slot:item>
                    <HbIcon
                        small
                        mdi-code="mdi-account-clock-outline"
                        class="hb-timeline-item-action-audit-trail-icon-adjustment"
                    />
                </template>
                <template v-slot:header v-if="auditTrailTooltipHeader">
                    {{ auditTrailTooltipHeader }}
                </template>
                <template v-slot:body v-if="auditTrailTooltipName || auditTrailTooltipDate">
                    {{ auditTrailTooltipName }}<br />
                    {{ auditTrailTooltipTime }}<br />
                </template>
            </hb-tooltip>
        </v-col>
    </v-row>
</template>
<script>

export default {
    name: "HbTimelineItemAction",
    data() {
        return {
            dialog: false,
        }
    },
    props: {
        type: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        description: {
            type: String,
            default: undefined
        },
        linkText: {
            type: String,
            default: undefined
        },
        linkUrl: {
            type: String,
            default: undefined
        },
        auditTrail: {
            type: Boolean,
            default: false
        },
        auditTrailTooltipHeader: {
            type: String,
            default: 'Last Updated:'
        },
        auditTrailTooltipName: {
            type: String,
            default: undefined
        },
        auditTrailTooltipTime: {
            type: String,
            default: undefined
        }
    }
};
</script>

<style scoped>
.hb-timeline-item-action-icon-wrapper {
  position: relative;
  margin: 0 6px 0 0;
  min-width: 18px;
  max-width: 18px;
}

.hb-timeline-item-action-wrapper {
    margin-bottom: 0;
}

.hb-timeline-item-action-item {
  display: inline;
}

.hb-timeline-item-action-audit-trail-icon-adjustment {
    position: relative;
    top: -3px;
}

</style>