<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <v-card class="ma-0 pa-0 d-flex align-stretch" flat color="transparent">
        <div>
            <HbSpaceIcon v-if="type && type !== 'contact'" avatar :type="type" />

            <v-avatar
                v-else-if="contact || (type && type === 'contact')"
                height="48"
                color="#ffffff"
                class="hb-header-info-avatar-wrapper"
            >
                <HbIcon v-if="contactIsBusiness" mdi-code="mdi-domain" />
                <HbIcon v-else mdi-code="mdi-account" />
            </v-avatar>

            <HbSpaceIcon v-else-if="unit && (unit.type || unit.unit_type)" avatar :unit="unit" />

            <v-avatar
                v-else
                height="48"
                color="#ffffff"
                class="hb-header-info-avatar-wrapper"
            >
                <HbIcon mdi-code="mdi-help-circle" />
            </v-avatar>

        </div>
        <div
            class="pl-5 hb-header-info-wrapper hb-header-info-text-light hb-header-info-default-font-size"
        >
            <span v-if="contact">
                <v-row class="hb-header-info-first-row" :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''">
                    <span class="hb-header-info-title font-weight-medium text-capitalize mr-1">
                        {{ contact.salutation }} {{ contact.first }} {{ contact.middle }} {{ contact.last }} {{ contact.suffix }}
                    </span>
                    <span v-if="contact.Military && contact.Military.active" class="hb-header-info-military-wrapper">
                        <hb-tooltip>
                            <template v-slot:item>
                                <hb-icon small color="#101318">mdi-custom-active-military</hb-icon>
                            </template>
                            <template v-slot:body>
                                Active Military
                            </template>
                        </hb-tooltip>
                    </span>
                    <HbStatus
                        v-if="!statusOff && computedStatus"
                        :status="computedStatus"
                        class="ml-1"
                        @emitType="emitType"
                    />
                </v-row>
                <v-row :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''" class="hb-header-info-default-font-size">
                    <span v-if="contact.Phones && contact.Phones[0] && contact.Phones[0].phone_verified && contact.Phones[0].phone_verified === 1" class="hb-verified-icon-margin-fix"><hb-icon small color="success">mdi-account-check</hb-icon></span>
                    <span v-if="contact.Phones && contact.Phones[0] && smsClick"><hb-link color="secondary" @click="$emit('smsClicked')">{{ contact.Phones[0].phone | formatPhoneHeaderInfo }}</hb-link></span>
                    <span v-else-if="contact.Phones && contact.Phones[0]">{{ contact.Phones[0].phone | formatPhoneHeaderInfo }}</span>
                    <span v-if="(contact.Phones && contact.Phones[0]) && (contact.email && contact.email.length)" class="mx-1">|</span>
                    <span v-if="contact.email && contact.email.length && !disableEmail" class="d-flex align-center">
                        <span v-if="contact.email_verified && contact.email_verified === 1" class="hb-verified-icon-margin-fix"><hb-icon small color="success">mdi-account-check</hb-icon></span>
                        <hb-link color="secondary" @click="$emit('emailClicked')">{{ contact.email }}</hb-link>
                    </span>
                    <span v-if="((contact.Phones && contact.Phones[0]) || (contact.email && contact.email.length)) && ((contact.Addresses && contact.Addresses[0]) || (access && access.length))" class="mx-1">|</span>
                    <span v-if="contact.Addresses && contact.Addresses[0]">{{contact.Addresses[0].Address.address}}<span v-if="contact.Addresses[0].Address.address2" class="ml-1">{{contact.Addresses[0].Address.address2}}</span>, {{contact.Addresses[0].Address.city}} {{contact.Addresses[0].Address.zip}}</span>
                    <span v-if="((contact.Phones && contact.Phones[0]) || (contact.email && contact.email.length) || contact.Addresses && contact.Addresses[0]) && access && access.length > 0" class="mx-1">|</span>
                    <span v-if="access && access.length === 1 && !disableCode">Access Code: <hb-link :color="accessError ? 'destructive' : 'primary'" @click="$emit('pin')">{{access[0] && access[0].pin ? access[0].pin : 'Not Set'}}</hb-link></span>
                    <span v-else-if="access && access.length === 1">Access Code: {{access[0] && access[0].pin ? access[0].pin : 'Not Set'}}</span>
                    <span v-if="access && access.length > 1 && !disableCode"><hb-link :color="accessError ? 'destructive' : 'primary'" @click="$emit('pin')">Multiple Access Codes</hb-link></span>
                    <span v-else-if="access && access.length > 1">Multiple Access Codes</span>
                </v-row>
            </span>
            <span v-else-if="unit">
                <v-row class="hb-header-info-first-row" :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''">
                    <span class="hb-header-info-title font-weight-medium mr-1">Space {{unit.number}}</span>
                    <span v-if="insurance && insurance.id" class="hb-header-info-insurance-wrapper">
                        <hb-tooltip>
                            <template v-slot:item>
                                <hb-icon small color="#101318">mdi-security</hb-icon>
                            </template>
                            <template v-slot:body>
                                {{insurance.name}}<br />
                                {{insurance.description}}
                            </template>
                        </hb-tooltip>
                    </span>
                    <HbStatus
                        v-if="!statusOff && computedStatus"
                        :status="computedStatus"
                        class="ml-1"
                        @emitType="emitType"
                    />
                </v-row>
                
                <v-row :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''" class="hb-header-info-default-font-size">
                    <span v-if="unit.label" class="mr-1">{{unit.label}} |</span><span v-if="unit.Category && unit.Category.name">{{ unit.Category.name }}</span><span v-if="unit.Category && unit.Category.name && (unit.property_number || unit.Address.city)" class="mx-1">|</span>{{unit.property_number}}<span v-if="unit.property_number && unit.Address.city" class="mx-1">-</span><span v-if="unit.Address && unit.Address.city">{{unit.Address.city}}</span>
                    <span v-if="access && !disableCode" class="pl-1">| Access Code: <hb-link :color="accessError ? 'destructive' : 'primary'" @click="$emit('pin')">{{ access ? access : 'Not Set' }}</hb-link></span>
                    <span v-else-if="access" class="pl-1">| Access Code: {{ access ? access : 'Not Set' }}</span>
                </v-row>
            </span>
            <span v-else>
                <v-row class="hb-header-info-first-row" :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''">
                    <span class="hb-header-info-title font-weight-medium mr-1">{{ (type === 'residential' || type === 'storage' || type === 'parking' || type === 'unit') && title ? 'Space ' + title : title }}</span>
                    <span v-if="isMilitary" class="hb-header-info-military-wrapper">
                        <hb-tooltip>
                            <template v-slot:item>
                                <hb-icon
                                    small
                                    color="#101318"
                                >
                                    mdi-custom-active-military
                                </hb-icon>
                            </template>
                            <template v-slot:body>
                                Active Military
                            </template>
                        </hb-tooltip>
                    </span>
                    <span v-if="hasInsurance" class="hb-header-info-insurance-wrapper">
                        <hb-tooltip v-if="insuranceName || insuranceDescription">
                            <template v-slot:item>
                                <hb-icon
                                    small
                                    color="#101318"
                                >
                                    mdi-security
                                </hb-icon>
                            </template>
                            <template v-slot:body>
                                {{insuranceName}}<br />
                                {{insuranceDescription}}
                            </template>
                        </hb-tooltip>
                        <hb-icon
                            v-else
                            small
                            color="#101318"
                        >
                            mdi-security
                        </hb-icon>
                    </span>
                    <HbStatus
                        v-if="!statusOff && computedStatus"
                        :status="computedStatus"
                        class="ml-1"
                        @emitType="emitType"
                    />
                </v-row>
                
                <v-row
                    v-if="type === 'contact'"
                    :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''"
                    class="hb-header-info-default-font-size"
                >
                    <span v-if="representativeName && representativeName !== undefined" class="hb-header-info-item">
                        <span class="hb-verified-icon-margin-fix">
                            <hb-icon small>
                                mdi-account-tie
                            </hb-icon>
                        </span>
                        <span class="hb-header-info-item">
                            {{ representativeName }}
                        </span>
                    </span>
                    <span
                        v-if="phone && phoneIsVerified"
                        class="hb-verified-icon-margin-fix"
                    >
                        <hb-icon
                            small
                            color="success"
                        >
                            mdi-account-check
                        </hb-icon>
                    </span>
                    <span
                        v-if="phone"
                        class="hb-header-info-item"
                    >
                        <hb-link
                            v-if="smsClick"
                            color="secondary"
                            @click="$emit('smsClicked')"
                        >
                            {{ phone }}
                        </hb-link>
                        <span v-else>{{ phone }}</span>
                    </span>
                    <span
                        v-if="email"
                        class="d-flex align-center hb-header-info-item"
                    >
                        <span
                            v-if="emailIsVerified"
                            class="hb-verified-icon-margin-fix"
                        >
                            <hb-icon
                                small
                                color="success"
                            >
                                mdi-account-check
                            </hb-icon>
                        </span>
                        <hb-link
                            v-if="!disableEmail"
                            color="secondary"
                            @click="$emit('emailClicked')"
                        >
                            {{ email }}
                        </hb-link>
                        <span v-else>
                            {{ email }}
                        </span>
                    </span>
                    <span
                        v-if="address"
                        class="hb-header-info-item"
                    >
                        {{ address }}
                    </span>
                    <span
                        v-if="multipleAccessCodes && disableCode"
                        class="hb-header-info-item"
                    >
                        Multiple Access Codes
                    </span>
                    <span
                        v-else-if="multipleAccessCodes && !disableCode"
                        class="hb-header-info-item"
                    >
                        <hb-link :color="accessError ? 'destructive' : 'primary'" @click="$emit('pin')">Multiple Access Codes</hb-link>
                    </span>
                    <span
                        v-else-if="accessCode && disableCode && !multipleAccessCodes"
                        class="hb-header-info-item"
                    >
                        Access Code: {{ accessCode }}
                    </span>
                    <span
                        v-else-if="accessCode && !multipleAccessCodes"
                        class="hb-header-info-item"
                    >
                        Access Code: <hb-link :color="accessError ? 'destructive' : 'primary'" @click="$emit('pin')">{{ accessCode }}</hb-link>
                    </span>
                </v-row>

                <v-row
                    v-if="type === 'residential' || type === 'storage' || type === 'parking' || type === 'unit'"
                    :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''"
                    class="hb-header-info-default-font-size"
                >
                    <span v-if="categoryOne" class="hb-header-info-item">{{ categoryOne }}</span>
                    <span v-if="categoryTwo" class="hb-header-info-item">{{ categoryTwo }}</span>
                    <span v-if="featureOne" class="hb-header-info-item">{{ featureOne }}</span>           
                    <span v-if="featureTwo" class="hb-header-info-item">{{ featureTwo }}</span>
                    <span v-if="amenityOne" class="hb-header-info-item">{{ amenityOne }}</span>
                    <span v-if="amenityTwo" class="hb-header-info-item">{{ amenityTwo }}</span>
                </v-row>

                <v-row
                    v-if="(property || accessCode) && (type === 'residential' || type === 'storage' || type === 'parking' || type === 'unit')"
                    :style="maxWidthIsSet ? 'max-width:' + rowMaxWidth : ''"
                    class="hb-header-info-default-font-size"
                >
                    <span class="hb-header-info-item">{{ property }}</span>
                    <span
                        v-if="accessCode && disableCode"
                        class="hb-header-info-item"
                    >
                        Access Code: {{ accessCode }}
                    </span>
                    <span
                        v-else-if="accessCode"
                        class="hb-header-info-item"
                    >
                        Access Code: <hb-link :color="accessError ? 'destructive' : 'primary'" @click="$emit('pin')">{{ accessCode }}</hb-link>
                    </span>
                </v-row>
            </span>
        </div>
    </v-card>
</template>
<script type="text/babel">

    import HbSpaceIcon from './HbSpaceIcon.vue';
    import HbIcon from './HbIcon.vue';
    import HbLink from './HbLink.vue';
    import HbStatus from './HbStatus.vue';
    import HbTooltip from './HbTooltip.vue';

    import { parsePhoneNumber } from 'libphonenumber-js';

    export default {
        name: "HbHeaderInfo",
        components:{
            HbSpaceIcon,
            HbIcon,
            HbLink,
            HbStatus,
            HbTooltip
        },
        props: { 
            contact: {
                type: Object,
                default: null
            },
            unit: {
                type: Object,
                default: null
            },
            status: {
                type: String,
                default: undefined
            },
            statusIs: {
                type: String,
                default: undefined
            },
            statusOff: {
                type: Boolean,
                default: false
            },
            rowMaxWidth: {
                type: String,
                default: undefined
            },
            insurance: {
                type: Object,
                default: null
            },
            access: {
                type: [ Array, String ],
                default: null
            },
            accessError: {
                type: Boolean,
                default: false
            },
            disableEmail: {
                type: Boolean,
                default: false
            },
            disableCode: {
                type: Boolean,
                default: false
            },
            smsClick: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: undefined
            },
            title: {
                type: String,
                default: undefined
            },
            isMilitary: {
                type: Boolean,
                default: false
            },
            hasInsurance: {
                type: Boolean,
                default: false
            },
            insuranceName: {
                type: String,
                default: undefined
            },
            insuranceDescription: {
                type: String,
                default: undefined
            },
            phone: {
                type: String,
                default: undefined
            },
            phoneIsVerified: {
                type: Boolean,
                default: false
            },
            email: {
                type: String,
                default: undefined
            },
            emailIsVerified: {
                type: Boolean,
                default: false
            },
            address: {
                type: String,
                default: undefined
            },
            accessCode: {
                type: String,
                default: undefined
            },
            multipleAccessCodes: {
                type: Boolean,
                default: false
            },
            categoryOne: {
                type: String,
                default: undefined
            },
            categoryTwo: {
                type: String,
                default: undefined
            },
            featureOne: {
                type: String,
                default: undefined
            },
            featureTwo: {
                type: String,
                default: undefined
            },
            amenityOne: {
                type: String,
                default: undefined
            },
            amenityTwo: {
                type: String,
                default: undefined
            },
            property: {
                type: String,
                default: undefined
            },
            contactIsBusiness: {
                type: Boolean,
                default: false
            },
            representativeName: {
                type: String,
                default: undefined
            }
        },
        computed: {
            computedStatus(){
                if(this.status){
                    return this.status;
                }
                else if(this.statusIs){
                    return this.statusIs;
                }
                else if(this.contact && this.contact.status) {
                    return this.contact.status;
                }
                else if(this.unit && this.unit.status) {
                    return this.unit.state;
                }
                else {
                    return false;
                }
            },
            maxWidthIsSet(){
                if(this.rowMaxWidth){
                    return true;
                } else {
                    return false;
                }
            }
        },
        methods: {
            emitType(type){
                this.$emit('emitType', type);
            }
        },
        filters:{
            formatPhoneHeaderInfo(value){
                if (!value) return '';
                    try {
                        var parsedPhoneNumber = parsePhoneNumber('+' + value);

                        if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                            if(parsedPhoneNumber.country === 'US'){
                                return '+1 ' + parsedPhoneNumber.formatNational();
                            } else {
                                return parsedPhoneNumber.formatInternational();
                            }
                        } else {
                            value = value.toString();

                            var numbers = value.replace(/\D/g, ''), char = {0: '(', 3: ') ', 6: '-'};
                            value = '';
                            for (var i = 0; i < numbers.length; i++) {
                                value += (char[i] || '') + numbers[i];
                            }
                        }
                    }   catch(err){
                    }
                return value;
            }
        },
    }
</script>

<style scoped>

.hb-header-info-text-light {
    color: #637381;
}

.hb-header-info-default-font-size {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
}

.hb-header-info-first-row {
    margin-top: 1px;
    margin-bottom: 2px;
}

.hb-header-info-title {
    color: #101318;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    top: -1px;
}

.hb-verified-icon-margin-fix {
    margin-top: -2px;
    margin-right: 3px;
}

.hb-header-info-avatar-wrapper {
    border: 1px solid #DFE3E8 !important;
}

.hb-header-info-item:not(:last-of-type)::after {
    content: '|';
    padding-left: 5px;
    padding-right: 5px;
}

.hb-header-info-overflow-handler {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-header-info-military-wrapper {
    height: 20px;
    position: relative;
    top: -1px;
    padding: 2px;
}

.hb-header-info-insurance-wrapper {
    height: 20px;
    position: relative;
    top: -2px;
    padding: 2px;
}

</style>
