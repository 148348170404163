import { Country, State, City } from "country-state-city";
import missing_cities from '../utils/missing_cities_list.json'
import missing_states from '../utils/missing_states_list.json'
import { parseCountry } from '../utils/common'
// Doc for country-state-city module https://www.npmjs.com/package/country-state-city

export const countryStateCityMixin = {
	data() {
		return {
			countriesListMixinRaw: [],
			countriesListMixin: [],
			countriesStatesListMixin: [],
			stateListRawMixin: [],
			stateListMixin: [],
			cityListMixin: [],
			stateDLListMixin: [],
			relationStateListMixin: [],
			relationCityListMixin: [],
			militaryStateListMixin:[],
			militaryCityListMixin: [],
			paymentStateListMixin:[],
			paymentCityListMixin: [],
			businessStateListMixin:[],
			businessCityListMixin: [],
			vehicleStateListMixin: [],
			registeredVehicleStateListMixin: [],
			registeredVehicleCityListMixin: [],
			legalVehicleStateListMixin: [],
			legalVehicleCityListMixin: [],
			propertyStateListMixin: [],
			propertyCityListMixin:[],
			missingCitiesMixin: {...missing_cities},
			defaultCountry: 'United States',
		}
	},
	methods:{
		addMissingCity(payload){
			// add missing city manually by the user
			let addedCities = JSON.parse(JSON.stringify(this.missingCitiesMixin)) || {}
			// checking if we already have the data against country_code or not if not then initialize
			if(payload.country_code && !addedCities[payload.country_code]){
				addedCities[payload.country_code] = {}
			}

			// checking if we dont have state_code but have country_code then we are adding cities key and initializing with empty array
			if(payload.country_code && !payload.state_code){
			  if(!addedCities[payload.country_code].cities){
				addedCities[payload.country_code].cities = []
			  }
			  
			  // pushing the cities without state
			  addedCities[payload.country_code].cities.push({name:payload.city})

			} else{
				// checking if we already have data against country_code and state_code if not then initialize
				if(payload.state_code && !addedCities[payload.country_code][payload.state_code]){
					addedCities[payload.country_code][payload.state_code] = []
				}

				// pushing the data against country_code and state_code
				addedCities[payload.country_code][payload.state_code].push({name:payload.city})
			}

			this.missingCitiesMixin = JSON.parse(JSON.stringify(addedCities))
		},
		async resetMissingCityList(){
			this.missingCitiesMixin = {...missing_cities}
		},
		setCountriesListEvent(){
            const countries = Country.getAllCountries();
            this.countriesListMixinRaw = countries;
            this.countriesListMixin = countries.map(item => item.name);
		},
		setCountryCitiesListEvent(country_name, index){
			if(!country_name){
				this.cityListMixin[index] = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.cityListMixin[index] = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
        setStatesListEvent(country_name, index){
			if(!country_name){
				this.stateListMixin[index] = []
				return
			}
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const states = State.getStatesOfCountry(country.isoCode);
			// adding missing states against that country_code
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
            this.stateListRawMixin = [...this.stateListRawMixin, ...states];
			this.stateListMixin[index] = states.map(item =>{ 
				return {name:item.name, value:item.isoCode}
			})
			this.cityListMixin[index] = []
		},
		setCitiesListEvent(country_name, state_code, index){
			if(!country_name){
				this.cityListMixin[index] = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);	

			if(!state_code){
				this.setCountryCitiesListEvent(country_name, index)
				return
			}

			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			);

			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}
			
			this.cityListMixin[index] = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setDLStatesListEvent(country_name){
			if(!country_name){
				this.stateDLListMixin = []
				return
			}
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
            this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.stateDLListMixin = states.map(item => { 
				return {name: item.name, value:item.isoCode}
			})
		},
		setRelationCountryCitiesListEvent(country_name, index){
			if(!country_name){
				this.relationCityListMixin[index] = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.relationCityListMixin[index] = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setRelationStatesListEvent(country_name, index){
			if(!country_name){
				this.relationStateListMixin[index] = []
				return
			}
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
            this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.relationStateListMixin[index] = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.relationCityListMixin[index] = []
		},
		setRelationCitiesListEvent(country_name, state_code, index){
			if(!country_name){
				this.relationCityListMixin[index] = []
				return
			}
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if(!state_code){
				this.setRelationCountryCitiesListEvent(country_name, index)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			  );

			// adding missing cities against that country_code
			  if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}

			this.relationCityListMixin[index] = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setMilitaryCountryCitiesListEvent(country_name){
			if(!country_name){
				this.militaryCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.militaryCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setMilitaryStatesListEvent(country_name){
			if(!country_name){
				this.militaryStateListMixin = []
				return
			}
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
            this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.militaryStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.militaryCityListMixin = []
		},
		setMilitaryCitiesListEvent(country_name, state_code){
			if(!country_name){
				this.militaryCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if(!state_code){
				this.setMilitaryCountryCitiesListEvent(country_name)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			  );

			// adding missing cities against that country_code
			  if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}

			this.militaryCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setBusinessCountryCitiesListEvent(country_name){
			if(!country_name){
				this.businessCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.businessCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setBusinessStatesListEvent(country_name){
			if(!country_name){
				this.businessStateListMixin = []
				return
			}
			
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
            this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.businessStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.businessCityListMixin = []
		},
		setBusinessCitiesListEvent(country_name, state_code){
			if(!country_name){
				this.businessCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if(!state_code){
				this.setBusinessCountryCitiesListEvent(country_name)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			  );

			// adding missing cities against that country_code
			  if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}

			this.businessCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setPaymentCountryCitiesListEvent(country_name){
			if(!country_name){
				this.paymentCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.paymentCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setPaymentStatesListEvent(country_name){
			if(!country_name){
				this.paymentStateListMixin = []
				return
			}
            const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
            this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.paymentStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.paymentCityListMixin = []
		},
		setPaymentCitiesListEvent(country_name, state_code){
			if(!country_name ){
				this.paymentCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if(!state_code){
				this.setPaymentCountryCitiesListEvent(country_name)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
				);

				// adding missing cities against that country_code
				if(this.missingCitiesMixin[country.isoCode]){
					// adding missing cities against country_code if we don't have state_code
					if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
						cities.push(...this.missingCitiesMixin[country.isoCode].cities)
					}
					// adding missing cities against country_code if we have state_code
					if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
						cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
					}
					cities.sort((a, b) => a.name.localeCompare(b.name))
				}
				
				this.paymentCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setVehicleStatesListEvent(country_name){
			if(!country_name){
				this.vehicleStateListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
			this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.vehicleStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
		},
		setRegisteredVehicleCountryCitiesListEvent(country_name){
			if(!country_name){
				this.registeredVehicleCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.registeredVehicleCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setRegisteredVehicleStatesListEvent(country_name){
			if(!country_name){
				this.registeredVehicleStateListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
			this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.registeredVehicleStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.registeredVehicleCityListMixin = []
		},
		setRegisteredVehicleCitiesListEvent(country_name, state_code){
			if(!country_name){
				this.registeredVehicleCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if( !state_code){
				this.setRegisteredVehicleCountryCitiesListEvent(country_name)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			  );

			// adding missing cities against that country_code
			  if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}

			this.registeredVehicleCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setLegalVehicleCountryCitiesListEvent(country_name){
			if(!country_name){
				this.legalVehicleCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.legalVehicleCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setLegalVehicleStatesListEvent(country_name){
			if(!country_name){
				this.legalVehicleStateListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
			this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.legalVehicleStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.legalVehicleCityListMixin = []
		},
		setLegalVehicleCitiesListEvent(country_name, state_code){
			if(!country_name){	
				this.legalVehicleCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if(!state_code){
				this.setLegalVehicleCountryCitiesListEvent(country_name)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			  );

			// adding missing cities against that country_code
			  if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}

			this.legalVehicleCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setPropertyCountryCitiesListEvent(country_name){
			if(!country_name){
				this.propertyCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
            const cities = City.getCitiesOfCountry(country.isoCode);
			// adding missing cities against that country_code
			if(this.missingCitiesMixin[country.isoCode]){
				Object.entries(this.missingCitiesMixin[country.isoCode]).forEach(([key, value]) =>{
					cities.push(...value)
				})
			}
			this.propertyCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		setPropertyStatesListEvent(country_name){
			if(!country_name){
				this.propertyStateListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			const states = State.getStatesOfCountry(country.isoCode);
			if(missing_states[country.isoCode] && missing_states[country.isoCode].length){
				states.push(...missing_states[country.isoCode])
				states.sort((a, b) => a.name.localeCompare(b.name))
			}
			this.stateListRawMixin = [...this.stateListRawMixin, ...states]
			this.propertyStateListMixin = states.map(item => { 
				return {name:item.name, value:item.isoCode}
			})
			this.propertyCityListMixin = []
		},
		setPropertyCitiesListEvent(country_name, state_code){
			if(!country_name){
				this.propertyCityListMixin = []
				return
			}
			const country = this.countriesListMixinRaw.find(item => item.name === country_name);
			if(!state_code){
				this.setPropertyCountryCitiesListEvent(country_name)
				return
			}
			const cities = City.getCitiesOfState(
				country.isoCode,
				state_code,
			  );

			// adding missing cities against that country_code
			  if(this.missingCitiesMixin[country.isoCode]){
				// adding missing cities against country_code if we don't have state_code
				if(this.missingCitiesMixin[country.isoCode].cities && this.missingCitiesMixin[country.isoCode].cities.length){
					cities.push(...this.missingCitiesMixin[country.isoCode].cities)
				}
				// adding missing cities against country_code if we have state_code
				if(this.missingCitiesMixin[country.isoCode][state_code] && this.missingCitiesMixin[country.isoCode][state_code].length){
					cities.push(...this.missingCitiesMixin[country.isoCode][state_code])
				}
				cities.sort((a, b) => a.name.localeCompare(b.name))
			}

			this.propertyCityListMixin = cities.filter(item => item.name.length >= 3 && item).map(value => value.name)
		},
		validateCityName(value){
			const specialCharacter = value.toLowerCase().match(/[^a-zA-Z0-9 ]/g)
			return specialCharacter && specialCharacter.length ? true : false
		},
		setCountryNameEvent(countryName){
			return parseCountry(countryName, this.defaultCountry) 
		}
	}
};
