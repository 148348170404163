
<template>
  <div>
    <hb-modal
      size="large"
      :title="'Filter Refunds'"
      v-model="dialog"
      @close="onCloseDialog"
      :footerCancelOption="true"
      show-help-link
    >

    <template v-slot:content>
          <template>
            <div class="px-4 py-4 text-grey">
              Filter refunds by the selected fields.
            </div>
            <v-col cols="12" class="pa-0">
              <v-divider></v-divider>
            </v-col>
          </template>
          <div>
            <hb-form label="Property">
                <HbSelect
                    v-model="filters.property"
                    :items="propertyList"
                    item-value="propertyList.id"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="status"
                    data-vv-as="Status"
                    :error="errors.has('default.status')"
                    placeholder="Select Property"
                />

            </hb-form>
            <hb-form label="Status">
                <HbSelect
                    v-model="filters.status"
                    :items="['void','Processed','Approved',' Approval Pending']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="status"
                    data-vv-as="Status"
                    :error="errors.has('default.status')"
                    placeholder="Select Status"
                />

            </hb-form>
            <hb-form label="Date Range">
                <div>
                  <v-col>
                    <hb-date-picker
                        :solo="false"
                        single-line
                        hide-details
                        v-model="filters.startDate"
                        id="date"
                        name="date"
                        placeholder="Starte Date"
                        :error="errors.collect('date').length > 0"
                        v-validate="'required'"
                        data-vv-as="date"
                      
                        :removePaddingMargin="true"
                    ></hb-date-picker>
                  </v-col>
                </div>
                <div>
                  <v-col>
                
                    <hb-date-picker
                        :solo="false"
                        single-line
                        hide-details
                         v-model="filters.endDate"
                        id="date"
                        name="date"
                        placeholder="End Date"
                        :error="errors.collect('date').length > 0"
                        v-validate="'required'"
                        data-vv-as="date"
                       
                        :removePaddingMargin="true"
                    ></hb-date-picker>
                  </v-col>
                </div>

            </hb-form>
          </div>
          
        </template>
      <template v-slot:right-actions>
        <span>
          <hb-btn color="primary" type="submit" @click="applyFilters">{{
            "Filter"
          }}</hb-btn>
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import HbDatePicker from "../assets/HummingbirdDatepicker";
import api from "../../assets/api.js";

import { mapGetters } from "vuex";
import Vue from "vue";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "GetFilter",
  components: {
    HbDatePicker,
  },
  props: [],
  mixins: [notificationMixin],
  data() {
    return {
      dialog: false,
      properties:[],
      filters:{
        property: '',
        status: '',
        startDate:'',
        endDate:''
      }
     
    
    };
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered"
    }),

    propertyList(){
                return this.properties.map(p => {
                    return p.name
                });

            },
  },
  mounted(){

this.getPropertyList(true);
},

  async created() {
    this.dialog = true;    
  },

  methods: {
    onCloseDialog() {
      this.$emit("close");
    },

    getPropertyList(){
            api.get(this, api.PROPERTIES).then(r => {
                this.properties = r.properties;
            });

        },
    async applyFilters(){
     
      if(this.filters.property){
        this.filters.property = this.properties.find(property => property.name === this.filters.property).id
      }
      this.$emit('DRFilters',this.filters);
      

    }   
  }
};
</script>

<style lang="scss" scoped>
.text-grey {
  color: grey;
}
</style>
