<template>
  <hb-modal
    size="medium"
    title="Void Reason"
    v-model="showVoidReason"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <div class="pa-4">
        <span class="hb-text-night">You are required to provide a reason for voiding the direct debit application.</span>
      </div>

      <div class="pa-4">
        <v-text-field
          single-line
          dense
          flat
          class="input-font-size"
          label="Void Reason*"
          v-model="void_reason"
          data-vv-name="void_reason"
          data-vv-as="void reason"
          v-validate="'required'"
          :error-messages="errors.collect('void_reason')"
          hide-details
        ></v-text-field>
      </div>
    </template>

    <template v-slot:actions>
      <hb-btn color="primary" @click="submitVoidReason" :disabled="isSubmitting || !void_reason">
        Submit
      </hb-btn>
    </template>
  </hb-modal>
</template>

<script>
import { notificationMixin } from '../../mixins/notificationMixin.js';

export default {
  name: "DisplayVoid",
  mixins: [ notificationMixin ],
  props: {
    refund_id: {
      type: Number,
      required: true 
    },
    showVoidReason: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      void_reason: '',
      isSubmitting: false,
    };
  },
  methods: {
  
    async submitVoidReason() {
      this.isSubmitting = true;
      this.$emit('submit-void-reason', this.void_reason);
      this.isSubmitting = false;    }
  }
};
</script>
