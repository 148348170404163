
export default [
  {
    path: '/phone/:phone_number',
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master',
      re_fetch: true
    },
    component: () => import('../views/PhoneRoute.vue'),
    
  }
]