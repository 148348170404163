<template>
    <div>
        <!--
        <p class="interaction ma-0 pa-4 hb-text-light pl-6">This is a description for images.</p>
        -->
        <div class="slide-out-section">
            <div class="slide-out-section-heading">
                <div class="slide-out-edit-btn">
                    <hb-btn color="secondary" @click="showAdd = true">Upload Image</hb-btn>
                </div>

                <!-- <h2>Unit Images</h2> -->
                <p class="hb-text-light">Upload and manage your space images</p>
            </div>
        </div>

        <div class="slide-out-image-holder interaction" ref="imgContainer">
            <div class="image" v-for="img in images" :key="img.id" :data-id="img.id">
                <img :src="img.src" />


                <dropdown-menu
                        width="300"
                        align="left"
                        className="remove-link"
                        name="'image-delete-' + img.id"
                        :ref="'image-delete-' + img.id"
                >
                    <template slot="trigger" slot-scope="props">
                        <div class="remove-link">
                            <div class=" icon" ></div>
                        </div>
                    </template>
                    <template slot="dropdown" >
                        <div class="form-section">
                            <div class="error-block status-block">
                                Are you sure you want to remove this image?
                            </div>
                        </div>
                        <div class="modal-footer modal-footer-padding">
                            <a class="close-link" @click="props.away()">Cancel</a>
                            <button class="trash-btn w-button" @click="deleteImage(img)"> Delete Image</button>
                        </div>
                    </template>
                </dropdown-menu>
            </div>
        </div>




        <upload-file v-model="showAdd" v-if="showAdd" type="image" model="units" :foreign_id="unit_id" size="lg" slot="body" @refetch="fetchData">
            </upload-file>


    </div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import UploadFile from '../assets/UploadFile.vue';
    import Modal from '../assets/Modal.vue';
    import Sortable from "sortablejs";


    export default {
        name: "UnitImage",
        data() {
            return {
                showAdd: false,
                images: [],
                sortable: null,
            }
        },
        components:{
            Dropdown,
            DropdownMenu,
            Status,
            Loader,
            UploadFile,
            Modal
        },

        props: ['unit_id'],
        created(){
            this.fetchData();
        },
        mounted(){
            this.setUpDragging();
        },
        methods:{
            setUpDragging(){
                var _this = this;
                if (!this.sortable) {
                    this.sortable = Sortable.create(this.$refs.imgContainer, {
                        sort: true,
                        animation: 100,
                        onUpdate: function (evt) {
                            var save = [];
                            Array.from(evt.item.parentNode.children).forEach(function(c, i) {
                                if(c.dataset.id){
                                    save.push({
                                        id: c.dataset.id,
                                        sort: i
                                    })
                                }
                            });

                            api.put(_this, api.UNITS + _this.unit_id + '/upload/sort', {
                                uploads: save
                            }).then(() => {
                                EventBus.$emit('unit_image_saved', _this.unit_id);
                            })
                        },
                    });
                }
            },
            fetchData(){
                api.get(this, api.UNITS + this.unit_id + '/images').then(r => {
                    this.images = r.images
                });
            },
            save(){
                api.post(this, api.UNITS + this.unit_id + '/images', this.image).then(r => {
                    this.fetchData()
                });

            },
            deleteImage(image){
                api.delete(this, api.UPLOADS, image.id).then(r => {
                    this.fetchData();
                });
            },
            cancel(img){
                this.$refs['image-delete-' + img.id][0].away()
            },


        },
        watch:{
            unit_id(){
                this.images = [];
                this.fetchData();
            }
        }

    }


</script>


<style scoped>
    .slide-out-image-holder{
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
    }

    .image{
        flex-shrink:0;
        flex-grow:0;
        flex-basis: 33%;
        padding: 10px;
        position: relative;
    }

    .image img{
        max-width: 100%;
    }

    .remove-link{
        position: absolute;
        top: 5px;
        display:none;
        right: 5px;
        padding: 10px;
        z-index: 100;
        cursor: pointer;
        font-size: 14PX;
    }

    .remove-link .icon{
        -webkit-text-shadow: 1px 1px 1px #101318;
        -moz-text-shadow: 1px 1px 1px #101318;
        text-shadow: 1px 1px 1px #101318;
        color: white;
    }

    .image:hover .remove-link{
        display: block;
    }

    @media (max-width: 991px) {
        .image {
            flex-basis: 50%;
        }
    }

    @media (max-width: 767px) {
        .image {
            flex-basis: 100%;
        }
    }

</style>
