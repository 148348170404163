<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <v-row no-gutters :class="{ 'my-3 pa-3 hb-primary-lighter hb-settings-property-selector-wrapper' : wrapper }">
        <v-col cols="12" class="ma-0 pa-0">
            <hb-autocomplete
                :value="value"
                @input="$emit('input', value)"
                v-bind="$attrs"
                v-on="$listeners"
                rounded
                :placeholder="placeholder"
                :clearable="clearable"
                class="hb-autocomplete-property-selector-wrapper"
                :background-color="backgroundColor"
            >
                <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                    <slot :name="scopedSlot" v-bind="slotData"/>
                </template>
                <template v-for="(_, slot) in $slots" v-slot:[slot]>
                    <slot :name="slot"/>
                </template>
            </hb-autocomplete>
        </v-col>
    </v-row>
</template>

<script type="text/babel">

import HbAutocomplete from './HbAutocomplete.vue';

export default {
    name:'HbPropertySelector',
    data(){
        return {

        }
    },
    components:{
        HbAutocomplete
    },
    props: {
        value: {

        },
        placeholder: {
            type: String,
            default: 'Select Property'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: '#FFFFFF'
        },
        wrapper: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>

.hb-autocomplete-property-selector-wrapper .v-input--is-label-active.v-input--is-focused fieldset {
    border: 2px solid #00848e !important;
}

</style>