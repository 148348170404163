<template>
    <hb-btn
        icon
        tooltip="Help"
        @click="getHelpLink"
        target="_blank"
        active-state-off
        :hover-background-color="bladeHeader ? '#CAEAEA' : ''"
    >
        mdi-help-circle-outline
    </hb-btn>
</template>


<script>
 
import { mapActions } from 'vuex';

export default {
    name: "HelpJuice",
    props: {
        nav: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: ''
        },
        top: {
            type: String,
            default: ''
        },
        bladeHeader: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        ...mapActions({
            getHelpJuiceLink: 'utilsStore/getHelpJuiceLink'
        }),
        async getHelpLink() {
            try {
                let response = await this.getHelpJuiceLink();
                let newTab = window.open();
                newTab.location.href = response.help_url;
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>

