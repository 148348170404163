<template>
  <v-timeline
    v-bind="attrs"
    v-on="$listeners"
    class="hb-timeline-wrapper pa-0"
    :class="{ 'hb-timeline-top-icon': attrs['align-top'] }"
  >
    <slot />
  </v-timeline>
</template>

<script type="text/babel">
export default {
  name: "HbTimeline",
  data: () => ({}),

  computed: {
    attrs() {
      return {
        dense: true,
        "align-top": true,
        ...this.$attrs,
      };
    },
  },

  methods: {},
};
</script>

<style>

.hb-timeline-wrapper.v-timeline::before {
  background: unset;
}

</style>