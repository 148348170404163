export default Object.freeze({
	TYPES: [
    { name: 'Car', id: 'car'}, 
    { name: 'RV', id: 'rv' }, 
    { name: 'Motorcycle', id: 'motorcycle'}, 
    { name: 'Boat', id: 'boat'}, 
    { name: 'Commercial Vehicle', id: 'commercial_vehicle'}, 
    { name: 'Trailer', id: 'trailer'}, 
    { name: 'All-Terrain Vehicle', id: 'terrain_vehicle'}, 
    { name: 'Snowmobile', id: 'snowmobile'}, 
    { name: 'Semi-truck', id: 'semi_truck'}, 
    { name: 'Jet Ski', id: 'jet_ski'}, 
    { name: 'Camper', id: 'camper'}, 
    { name: 'Moped', id: 'moped'}, 
    { name: 'Other', id: 'other'}
  ]
});