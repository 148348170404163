<template>

    <div>
        <h4 class="notification">Open Payments Found</h4>
        <br />
        <p>This account has the following open payments/credits listed below. Choose the payment you want to apply, or click, "I want to enter a new payment" to enter a new payment:</p>

        <br />
        <div class="table">
            <div class="table-row">
                <div class="table-cell"><strong>Payment Date</strong></div>
                <div class="table-cell"><strong>Payment Amount</strong></div>
                <div class="table-cell"><strong>Balance</strong></div>
                <div class="table-cell text-right">
                   &nbsp;
                </div>
            </div>

            <div class="table-row" v-for="p in payments">
                <div class="table-cell">{{p.date | formatDate}}</div>
                <div class="table-cell ">{{p.amount | formatMoney}}</div>
                <div class="table-cell ">{{p.amount - p.total_applied | formatMoney}}</div>
                <div class="table-cell text-right">
                    <button class="w-button primary-btn btn-sm" @click.prevent="$emit('select', p)">Apply</button>
                </div>
            </div>
            <div class="table-row">
                <div class="table-cell blank text-right">
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <a class="close-link" @click.prevent="$emit('close')">I want to enter a new payment</a>
        </div>

    </div>
</template>
<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';

    export default {
        name: "ApplyPayment",
        data() {
            return {
                confirmed: false
            }
        },
        components:{
            Status,
            Loader
        },
        computed:{

        },
        filters:{

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
            }
        },
        methods:{
            choosePayment(){
                return true;
            }
        },
        props: {
            payments: {}
        },
    }
</script>
