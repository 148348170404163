

<template>
    <div>
      <hb-modal
        confirmation
        v-if="showConfirmDialog"
        v-model="showConfirmDialog"
        size="medium"
        title="Confirm"
        :footerCancelOption="false"
        @close="closeDialog"
        show-help-link
      >
        <template v-slot:content>
          <div class="pa-5">
            You have some record where income account is not configured.
          </div>
        </template>
        <template v-slot:right-actions>
          <hb-link class="hb-link ml-1" @click="closeDialog">
            Cancel
          </hb-link>
          <hb-btn @click="onClickConfirm" color="primary">
            Ok
          </hb-btn>
        </template>
      </hb-modal>
    </div>
</template>



<script>
import { mapActions,mapGetters } from "vuex";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import HbLink from '../../../aviary/HbLink.vue';
import HbBtn from '../../../aviary/HbBtn.vue';


export default {
  name: "IncomeAccountAlert",
  mixins: [notificationMixin],
  data() {
    return {
      showConfirmDialog: false
    };
  },
  components: {
    HbBtn,
    HbLink
  },
  created() {
    this.showConfirmDialog = true;
  },
  methods: {

    onClickConfirm() {
        this.$emit("confirm");
    },

    closeDialog() {
      this.$emit("close");
    },
  }
};

</script>