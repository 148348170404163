import oneTimePayment from '../views/OneTimePayment.vue'
import Invalid from '../views/Invalid.vue'
import ThankYouPage from '../views/ThankYouPage.vue'
import PaymentCompleted from '../views/PaymentCompleted.vue'

export default [
	{
		path: '/:id/pay/:token',
		component: oneTimePayment,
		name: 'onetimepayment',
		meta: {
			layout: 'unauthenticated'
		},
	},
	{
		path: '/invalid',
		name: 'invalid',
		component: Invalid,
		meta: {
			layout: 'unauthenticated'
		}
	},
	{
		path: '/thankyou',
		component: ThankYouPage,
		meta: {
			layout: 'unauthenticated'
		},
		name: 'thankyou',
		//props: (route) => ({ paymentId: route.params.paymentId, headers: route.params.headers })
	},
	{
		path: '/done',
		name: 'done',
		component: PaymentCompleted,
		meta: {
			layout: 'unauthenticated'
		}
	},
]
