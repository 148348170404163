export default [
    // {
    //     path: '/properties',
    //     component: () => import('../components/properties/Index.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         hasAccess: ['admin']
    //     }
    // },
    //
    // {
    //     path: '/properties/:property_id/facility-map',
    //     component: () => import('../components/properties/FacilityMap.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         hasAccess: ['admin']
    //     }
    // }
]
