<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <span>
        <v-row
            v-if="grid"
            class="pt-6 pl-6"
            no-gutters
        >
            <div
                v-if="uploader"
                class="hb-media-viewer-uploader hb-media-viewer-pointer mr-6 mb-6"
                :class="{ 'hb-media-viewer-uploader-error' : uploadError }"
                @click="$refs.hbMediaViewerUploader.$refs.input.click()"
            >
                <v-file-input
                    v-model="uploaderFiles"
                    :accept="accept"
                    multiple
                    ref="hbMediaViewerUploader"
                    @change="handleUploaderFiles"
                    style="display:none;"
                >
                </v-file-input>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <div style="display:block;">
                        <div align="center">
                            <HbIcon :color="uploadError ? '#FB4C4C' : ''" mdi-code="mdi-image" />
                        </div>
                        <div class="hb-media-viewer-add-images-pseudo-link mt-4">Add Images</div>
                    </div>
                </v-row>
            </div>
            <div
                v-for="(grid, i) in $slots.slides"
                :key="'grid_' + i"
                class="pr-6 pb-6"
            >
                <v-img
                    :src="grid.data.attrs.thumbnail ? grid.data.attrs.thumbnail : grid.data.attrs.src"
                    height="130"
                    width="130"
                    @click="!disableGridClick ? openGallery(grid.data, i) : ''"
                    :class="{ 'hb-media-viewer-pointer' : !disableGridClick, 'hb-media-viewer-no-preview-border' : !grid.data.attrs.thumbnail && !grid.data.attrs.src }"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                v-if="grid.data.attrs.thumbnail || grid.data.attrs.src"
                                indeterminate
                                color="grey lighten-2"
                            ></v-progress-circular>
                            <HbIcon v-else-if="grid.data.attrs.video" :custom-size="48" mdi-code="mdi-file-video" />
                            <HbIcon v-else :custom-size="48" mdi-code="mdi-file-image" />
                        </v-row>
                    </template>

                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        v-if="grid.data.attrs.video_src && grid.data.attrs.video && (grid.data.attrs.thumbnail || grid.data.attrs.src)"
                    >
                        <HbIcon large-close mdi-code="mdi-play-circle-outline" color="#ffffff" />
                    </v-row>
                </v-img>
            </div>
        </v-row>
        <v-dialog
            v-model="dialog"
            overlay-color="#454F5B"
            content-class="hb-media-viewer-wrapper"
        >
            <div class="hb-media-viewer-sheet">
                <div class="hb-media-viewer-close">
                    <hb-btn :large-close="$vuetify.breakpoint.mdAndUp" icon tooltip="Close" @click="close" active-state-off color="#ffffff" hover-color="#484848">mdi-close</hb-btn>
                </div>
                <div class="hb-media-viewer-gallery-area">
                    <v-carousel
                        v-model="activeSlide"
                        hide-delimiters
                        :show-arrows="false"
                        class="hb-media-viewer-carousel"
                        height="100%"
                        ref="hbMediaViewerCarouselRef"
                    >
                        <v-carousel-item
                            v-for="(slide, i) in $slots.slides"
                            :key="'slide_' + i"
                        >
                            <div v-if="slide.data.attrs.video_src && slide.data.attrs.video" class="hb-media-viewer-gallery-video-wrapper" align="center">
                                <video controls width="100%" height="100%" :src="slide.data.attrs.video_src" />
                            </div>
                            <v-img
                                v-else
                                :src="slide.data.attrs.src ? slide.data.attrs.src : slide.data.attrs.thumbnail"
                                contain
                                height="100%"
                            >
                            
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            v-if="slide.data.attrs.thumbnail || slide.data.attrs.src"
                                            indeterminate
                                            color="grey lighten-2"
                                        ></v-progress-circular>
                                        <HbIcon v-else-if="slide.data.attrs.video" color="#FFFFFF" :custom-size="96" mdi-code="mdi-video-off" />
                                        <HbIcon v-else color="#FFFFFF" :custom-size="96" mdi-code="mdi-image-off" />
                                    </v-row>
                                </template>
                            </v-img>
                            
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <div :class="$vuetify.breakpoint.xs ? 'hb-media-viewer-thumbnail-track-xs' : 'hb-media-viewer-thumbnail-track'">
                    <v-slide-group
                        v-model="activeSlide"
                        center-active
                        show-arrows
                        class="hb-media-viewer-slide-group-horizontal"
                        height="100%"
                    >
                        <template v-slot:prev>
                            <v-icon size="36" color="#ffffff">mdi-chevron-left</v-icon>
                        </template>
                        <template v-slot:next>
                            <v-icon size="36" color="#ffffff">mdi-chevron-right</v-icon>
                        </template>
                        <v-slide-item
                            v-for="(thumbnail, i) in $slots.slides"
                            :key="'thumbnail_' + i"
                            class="hb-media-viwer-slide-group-horizontal-slide"
                            v-slot="{ active, toggle }"
                        >
                            <v-card
                                class="hb-media-viewer-slide-group-horizontal-item"
                                :height="$vuetify.breakpoint.xs ? 60 : 80"
                                :width="$vuetify.breakpoint.xs ? 60 : 80"
                                @click="toggle"
                            >
                                <v-img
                                    :class="{ 'hb-media-viewer-slide-group-horizontal-item-active' : active }"
                                    :src="thumbnail.data.attrs.thumbnail ? thumbnail.data.attrs.thumbnail : thumbnail.data.attrs.src"
                                    :height="$vuetify.breakpoint.xs ? 60 : 80"
                                    :width="$vuetify.breakpoint.xs ? 60 : 80"
                                >

                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                v-if="thumbnail.data.attrs.thumbnail || thumbnail.data.attrs.src"
                                                indeterminate
                                                color="grey lighten-2"
                                            ></v-progress-circular>
                                            <HbIcon v-else-if="thumbnail.data.attrs.video" :custom-size="$vuetify.breakpoint.xs ? 24 : 32" mdi-code="mdi-file-video" />
                                            <HbIcon v-else :custom-size="$vuetify.breakpoint.xs ? 24 : 32" mdi-code="mdi-file-image" />
                                        </v-row>
                                    </template>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        v-if="thumbnail.data.attrs.video_src && thumbnail.data.attrs.video && (thumbnail.data.attrs.thumbnail || thumbnail.data.attrs.src)"
                                    >
                                        <HbIcon large-close mdi-code="mdi-play-circle-outline" color="#ffffff" />
                                    </v-row>
                                </v-img>
                            </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </div>
                <div v-if="moreOptions && !hideOptions" class="hb-media-viewer-more-options">
                    <hb-menu
                        options
                        options-color="#101318"
                        options-background-color="white"
                    >

                        <v-list>

                            <v-list-item
                                v-if="!hideDelete"
                                :ripple="false"
                                @click="emitDelete()"
                                :disabled="disableDelete"
                            >
                                <v-list-item-title>Delete Media</v-list-item-title>
                            </v-list-item>

                        </v-list>
                        
                    </hb-menu>
                </div>
            </div>
        </v-dialog>
    </span>
</template>
<script type="text/babel">

    import HbIcon from './HbIcon.vue';
    import HbLink from './HbLink.vue';
    import HbTooltip from './HbTooltip.vue';

    export default {
        name: "HbMediaViewer",
        data(){
            return {
                activeSlide: 0,
                moreOptions: true,
                uploaderFiles: [],
                theUploadedFiles: [],
                activeSlideData: {}
            }
        },
        components:{
            HbIcon,
            HbLink,
            HbTooltip
        },
        created() {
            if(this.$slots.slides && this.$slots.slides.lengh){
                this.activeSlideData = this.$slots.slides[this.activeSlide].data;
            }
        },
        computed: {
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        methods: {
            close(){
                this.$emit('close');
                setTimeout(() => {
                    this.dialog = false;
                }, 200);
            },
            openGallery(selection, index){
                this.$emit('grid-click', selection);
                this.activeSlide = index;
                this.dialog = true;
            },
            handleUploaderFiles(){
                this.$emit('upload', this.uploaderFiles);
            },
            emitDelete(){
                this.activeSlideData = this.$slots.slides[this.activeSlide].data;
                this.$emit('delete', this.activeSlideData);
            }
        },
        props: {
            value:{
                type: Boolean,
                default: false
            },
            goToSlide: {
                type: Number,
                default: undefined
            },
            goToId: {
                type: String,
                default: undefined
            },
            hideOptions: {
                type: Boolean,
                default: false
            },
            disableDelete: {
                type: Boolean,
                default: false
            },
            hideDelete: {
                type: Boolean,
                default: false
            },
            grid: {
                type: Boolean,
                default: false
            },
            disableGridClick: {
                type: Boolean,
                default: false
            },
            uploader: {
                type: Boolean,
                default: false
            },
            uploadError: {
                type: Boolean,
                default: false
            },
            accept: {
                type: String,
                default: 'image/png, image/jpeg'
            }
        },
        watch: {
            activeSlide(){
                this.activeSlideData = this.$slots.slides[this.activeSlide].data;

                var videos = document.getElementsByTagName("video");

                Array.prototype.forEach.call(videos, function (v) {
                    v.pause();
                });
            },
            dialog(){
                if(this.dialog){
                    this.activeSlideData = this.$slots.slides[this.activeSlide].data;

                    if(this.goToSlide){
                        this.activeSlide = this.goToSlide;
                    }
                    if(this.goToId){
                        const indexOfIdMatch = this.$slots.slides.findIndex(slide => slide.data.attrs.id === this.goToId);
                        if(indexOfIdMatch){
                            this.activeSlide = indexOfIdMatch;
                        }
                    }
                } else {
                    this.activeSlide = 0;
                    var videos = document.getElementsByTagName("video");

                    Array.prototype.forEach.call(videos, function (v) {
                        v.pause();
                        v.currentTime = 0;
                    });
                }
            }
        }
    }
</script>

<style scoped>
.hb-media-viewer-wrapper .hb-media-viewer-sheet {
    height: 100%;
    background-color: #484848;
}
.hb-media-viewer-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 999999;
}
.hb-media-viewer-gallery-video-wrapper {
    height:100%;
    width:100%;
}
.hb-media-viewer-slide-group-horizontal {
    margin:0 auto;
}
.hb-media-viwer-slide-group-horizontal-slide {
    margin:0 6px;
}
.hb-media-viewer-slide-group-horizontal-item {
    border-radius: 4px !important;
}
.hb-media-viewer-slide-group-horizontal-item-active {
    outline: 2px solid white;
    outline-offset: -2px;
}
.hb-media-viewer-gallery-area {
    border-radius: 4px !important;
    padding-top: 64px;
    height: calc(100% - 164px);
}
.hb-media-viewer-thumbnail-track {
    height: 80px;
    margin-top: 8px;
}
.hb-media-viewer-thumbnail-track-xs {
    height: 60px;
    margin-top: 28px;
}
.hb-media-viewer-pointer {
    cursor: pointer;
}
.hb-media-viewer-uploader {
    min-width: 130px;
    min-height: 130px;
    border-radius: 4px;
    border: 2px #DFE3E8 dashed;
}
.hb-media-viewer-add-images-pseudo-link {
    text-decoration: underline;
    color: #00848E;
}
.hb-media-viewer-uploader-error.hb-media-viewer-uploader {
    border-color: #FB4C4C !important;
}
.hb-media-viewer-no-preview-border {
    border: 1px #DFE3E8 solid;
}
.hb-media-viewer-more-options {
    position: absolute;
    bottom: 18px;
    right: 18px;
}
</style>

<style>
.hb-media-viewer-wrapper.v-dialog {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    max-height: 100%;
}
.hb-media-viewer-slide-group-horizontal .v-slide-group__content {
    justify-content: center;
}
</style>