export default Object.freeze({
  MONTHS_LIST: [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ],
  YEARS_LIST: [
    { name: "2021", value: "2021" },
    { name: "2022", value: "2022" },
    { name: "2023", value: "2023" },
    { name: "2024", value: "2024" },
    { name: "2025", value: "2025" },
    { name: "2026", value: "2026" },
    { name: "2027", value: "2027" },
    { name: "2028", value: "2028" },
    { name: "2029", value: "2029" },
    { name: "2030", value: "2030" },
  ],
  DAYS_LIST: [
    { name: "Monday", value: 2 },
    { name: "Tuesday", value: 3 },
    { name: "Wednesday", value: 4 },
    { name: "Thursday", value: 5},
    { name: "Friday", value: 6 },
    { name: "Saturday", value: 7 },
    { name: "Sunday", value: 1 }
  ]
});