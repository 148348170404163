<template>
  <div>
  <hb-form class="rent-tax-exempt-items-form-input-wrapper" @cancel="cancel" :divider="false" :label="formLabel"
    :active.sync="active" :editable="!is_closed" @save="openConfirmationModal">
    <template v-slot:tooltipBody>
      <div style="width: 250px">
        This is text that describes the Rent Tax Status and who/what qualifies for the exemptions.
      </div>
    </template>
    <template v-slot:display>
      <div class="mt-0 pt-0" :class="{ 'ml-4': is_closed }">
        <span class="hb-text-light">{{leaseDescription}}</span>
        <HbCheckbox class="mt-2" v-model="lease.tax_exempt" label="Mark rent as tax exempt" readonly />
        <span v-if="lease.tax_exempt" class="hb-text-light">{{ lease.tax_exempt_description }}
        </span>
        <div v-if="taxExemptActivity?.activity && !taxActivityError">
          <div v-if="taxExemptActivity" class="text-body-2 hb-text-light">
            Last {{ taxExemptActivity?.activity == "modify" ? "Modified" : "Modified" }} By: {{
              taxExemptActivity?.activity_by }} @ {{ taxExemptActivity?.activity_at | formatDateCustom('MMM DD, YYYY hh:mm A') }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:edit>
      <span class="hb-text-light">{{leaseDescription}}</span>
      <HbCheckbox class="mt-2" v-model="tax_exempt" label="Mark rent as tax exempt" />
      <HbTextarea v-if="tax_exempt" :rows=6 row-height="15" v-model="tax_exempt_description"
        v-validate="'required|max:255'" :maxlength="255" data-vv-scope="lead" data-vv-name="tax_exempt"
        data-vv-as="TaxExempt" :error="errors.has('tax_exempt_description')" placeholder="Reason for status change"
        placeholder-fix />
      <div v-if="taxExemptActivity?.activity && !taxActivityError">
        <div v-if="taxExemptActivity" class="text-body-2 hb-text-light">
          Last {{ taxExemptActivity?.activity == "modify" ? "Modified" : "Modified" }} By: {{
            taxExemptActivity?.activity_by }} @ {{ taxExemptActivity?.activity_at | formatDateCustom('MMM DD, YYYY hh:mm A') }}
        </div>
      </div>
    </template>
  </hb-form>
  <hb-modal v-if="showConfirmation" v-model="showConfirmation" size="medium" title="Update Tax Exempt" @close="cancel" confirmation>
    <template v-slot:content>
      <div class="py-4 px-6">
        Are you sure you want to update the selected lease tax exempt?
        <br /><br />
      </div>
    </template>
    <template v-slot:actions>
      <hb-btn @click="saveLeaseRentTaxExemptItems">Update Tax Exempt</hb-btn>
    </template>
  </hb-modal>
  </div>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "RentTaxExemptItems",
  mixins: [notificationMixin],
  data() {
    return {
      active: "",
      tax_exempt: 0,
      tax_exempt_description: "",
      taxExemptActivity: {},
      taxActivityError: false,
      showConfirmation: false,
      leaseDescription: "Checking this box means that the tenant will not be charged for tax on Rent for invoices generated moving forward."
    };
  },

  props: {
    lease: {
      type: Object,
      required: true,
    },
    formLabel: {
      type: String,
      default: "Rent Tax",
    },
    is_closed: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    try {
      this.tax_exempt = this.lease.tax_exempt;
      this.tax_exempt_description = this.lease.tax_exempt_description;
      await this.fetchLeaseTaxExemptActivity();
    } catch (error) {
      console.error('Error fetching fetchLeaseTaxExemptActivity:', error);
    }
  },

  methods: {
    cancel() {
      this.$emit('cancel')
      this.tax_exempt = this.lease.tax_exempt;
      this.tax_exempt_description = this.lease.tax_exempt_description;
    },
    validateTaxDescription() {
      return ((this.tax_exempt && !this.tax_exempt_description) ? false : true)
    },
    async fetchLeaseTaxExemptActivity() {
      try {
        let r = await api.get(
          this,
          api.LEASES + this.lease.id + "/tax-exempt-activity"
        );
        this.taxExemptActivity = r;
      }
      catch (error) {
        this.taxActivityError = true;
      }
    },
    openConfirmationModal(){
      if (!this.validateTaxDescription()) {
        this.showMessageNotification({ description: 'Please enter reason for status change' });
        return;
      }
      this.showConfirmation = true;
    },
    async saveLeaseRentTaxExemptItems() {
      const isUpdated = await this.$store.dispatch('onBoardingStore/updateLeaseTaxExemptHistory', {
        lease_id: this.lease.id,
        tax_exempt: this.tax_exempt,
        tax_exempt_description: this.tax_exempt_description
      });
      this.showConfirmation = false;
      if (isUpdated.lease_id) {
        this.showMessageNotification({ type: 'success', description: 'You have successfully updated the Lease Rent Tax Status.' });
        await this.fetchLeaseTaxExemptActivity();
        EventBus.$emit('reload_tenant_profile_leases');
        this.active = '';
      }
    }
  },
  watch: {
    lease() {
      this.tax_exempt = this.lease.tax_exempt;
      this.tax_exempt_description = this.lease.tax_exempt_description;
    },
  }
};

</script>

<style>
.protected-item .v-label {
  margin-top: 0px !important;
}

.rent-tax-exempt-items-form-input-wrapper .v-input--selection-controls:not(.v-input--is-label-active) .v-input--selection-controls__input>i.v-icon,
.hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-label-active) .v-input--selection-controls__input>i.v-icon {
  color: #DFE3E8 !important;
}
</style>
