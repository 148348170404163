<template>
  <hb-modal
    v-model="showManualRentChange"
    :title="title"
    @close="clearManualRentModal"
    show-help-link
  >
    <template v-slot:subheader>
      <div>
       Modify the current rent amount for the user. Adjusting the rent amount may result in changes to the scheduled timeframe for deployment.
      </div>
    </template>
    <template v-slot:content>
      <RentChangeForm
        :tenantProperty="tenantProperty"
        :currentRent="currentRent"
        :rentData="rentData"
        :ids="ids"
        :is-edit="edit"
        ref="rentChangeFormRef"
        @closeRentChangeModal="$emit('closeModal', $event)"
        @valueChanged="(value) =>enableManualRentChange = value"
        @loading="loading = $event"
      />
    </template>
    <template v-slot:right-actions>
      <hb-btn
        :loading="loading"
        :disabled="!enableManualRentChange"
        @click="applyManualRentChange"
        >{{ `${edit ? "Edit Rent Change" : "Save"}` }}</hb-btn
      >
    </template>
  </hb-modal>
</template>

<script>
import { EventBus } from "../../../EventBus";
import RentChangeForm from "./RentChangeForm";

export default {
  name: "RentChangeModal",
  components: {
    RentChangeForm,
  },
  props: {
    manualRentChangeModal: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    currentRent: {
      type: Number,
      default: 0,
    },
    rentData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "Manual Rent Change",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    tenantProperty: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showManualRentChange: this.manualRentChangeModal,
      enableManualRentChange: true,
      loading: false,
    };
  },
  computed: {},
  methods: {

    clearManualRentModal() {
      this.$refs.rentChangeFormRef.clearManualRentModal();
    },

    applyManualRentChange() {
      this.$refs.rentChangeFormRef.applyManualRentChange(true);
    },
  },
  watch: {
    manualRentChangeModal: {
      handler(value) {
        this.showManualRentChange = value;
      },
      immediate: true,
    },
  },
};
</script>
