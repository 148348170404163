<template>
  <hb-modal
    size="medium"
    :title="action === 'Apply' ? 'Apply Credit' : action === 'Remove' ? 'Remove Credit' : 'Adjust Credit'"
    v-model="dialog"
    @close="close"
    show-help-link
    >
      <template v-slot:content>
        
      <!--
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
      -->
      <v-card-text class="pa-0 ma-0">
        <v-container class="pa-0 ma-0">

          <!--
          <v-row class="hb-table-row ma-0 pa-0">
            <v-col class="hb-table-label py-4 pl-4" cols="5">
              {{ credits.length > 0 ? 'Apply / Remove' : 'Apply' }}
            </v-col>
            <v-col class="ma-0 pa-4" cols="7">
              <v-select
                :items="credits.length > 0 ? ['Apply', 'Remove'] : ['Apply']"
                v-model="action"
                name="action"
                label="Select"
                :hide-details="!errors.collect('action').length"
                :error-messages="errors.collect('action')"
                v-validate="'required'"
                data-vv-as="action"
              ></v-select>
            </v-col>
          </v-row>
          -->

          <hb-form v-if="action === 'Apply'" label="Reason" full>
            <HbTextarea
              v-model="payment.notes"
              placeholder="Enter Reason"
              v-validate.disable="'required|max:999'"
              data-vv-name="reason"
              data-vv-as="Reason"
              name="reason"
              id="reason"
              :rows="3"
              :error="errors.collect('reason').length > 0"
            />
          </hb-form>

          <hb-form v-if="action === 'Apply'" label="Amount" last>
            <HbTextField
              v-model="payment.amount"
              prepend-inner-icon="mdi-currency-usd"
              placeholder="Enter Amount"
              v-validate.disable="'required|min_value:.01|max_value:9999999|max:45'"
              data-vv-name="amount"
              data-vv-as="Amount"
              name="amount"
              :error="errors.collect('amount').length > 0"
            />
          </hb-form>
          <!--
          <span v-if="action === 'Apply'">
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-4" cols="5">
                Reason
              </v-col>
              <v-col class="ma-0 pa-4" cols="7">
                <v-text-field
                  v-model="payment.notes"
                  label="Provide a Reason"
                  v-validate="'required|max:999'"
                  data-vv-name="reason"
                  name="reason"
                  id="reason"
                  :hide-details="!errors.collect('reason').length"
                  :error-messages="errors.collect('reason')"
                  data-vv-as="reason"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-4" cols="5">
                Amount
              </v-col>
              <v-col class="ma-0 pa-4" cols="7">
                <v-text-field
                  v-model="payment.amount"
                  label="Credit Amount"
                  v-validate="'required|min_value:.01|max_value:9999999'"
                  data-vv-name="amount"
                  name="amount"
                  prefix="$"
                  id="amount"
                  :hide-details="!errors.collect('amount') || !errors.collect('amount').length"
                  :error-messages="errors.collect('amount')"
                  data-vv-as="amount"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </span>
          -->
          <span v-if="action === 'Remove'">
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-4" cols="5">
                Select
              </v-col>
              <v-col class="ma-0 pa-4" cols="7">
                <v-select
                  :items="credits"
                  v-model="selection"
                  return-object
                  name="selection"
                  label="Select a Credit to Remove"
                  :hide-details="!errors.collect('selection').length"
                  :error-messages="errors.collect('selection')"
                  v-validate="'required'"
                  data-vv-as="selection"
                >
                  <template v-slot:selection="{ item, index }">
                    {{ item.amount | formatMoney }} - {{item.date | formatDateServices}}
                  </template>
                  <template v-slot:item="{ item, index }">
                    {{ item.amount | formatMoney }} - {{item.date | formatDateServices}}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </span>

        </v-container>

        </v-card-text>
          
      </template>
      <template v-slot:actions>
        <hb-btn
          color="primary"
          @click="confirm(selection)"
          :loading="isLoading($options.name)"
          :disabled="loading || !action || (action === 'Apply' && (!payment.notes || !payment.notes.trim() || !payment.amount)) || (action === 'Remove' && selection.length === 0)"
        >
          Confirm
        </hb-btn>
      </template>
  </hb-modal>

</template>
<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import { EventBus } from '../../EventBus.js';
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    export default {
        name: "Credit",
        mixins: [notificationMixin],
        data() {
            return {
                payment: {
                    credit_type: 'credit',
                    amount: '',
                    lease_id: '',
                    property_id: '',
                    notes: '',
                },
                action: '',
                selection: [],
                saved: {
                  type: 'success',
                  message: 'Credit Saved.'
                },
                removed: {
                  type: 'success',
                  message: 'Credit Removed.'
                },
                loading: false,
            }
        },
        components:{
            Status,
            Loader
        },
        computed: {
          dialog: {
              get () {
                  return this.value
              },
              set (value) {
                  this.$emit('input', value)
              }
          },
        },
        created(){

            //this.payment.property_id = this.property_id;

            this.action = 'Apply';

            if(this.credits.length === 0){
              this.action = 'Apply';
            }
        },
        props: ['lease_id', 'contact_id', 'value', 'credits'],
        methods:{
          async save(){

              let status = await this.validate(this);
              if(!status) return;
              this.loading = true;
              try {
                await api.post(this, api.LEASES + this.lease_id + '/credit', this.payment);
                // EventBus.$emit('payment_saved');
                this.resetForm();
                this.$emit('notification', this.saved);
                EventBus.$emit('refetch_credit_payments', {lease_id: this.lease_id});
                EventBus.$emit('reload_tenant_profile_leases');
                this.showMessageNotification({ type: 'success', description: 'Credit has been successfully applied.' });
                this.close();
              } catch (err) {
                this.showMessageNotification({ description: err });
              }
              this.loading = false;
          },
          async refund(selection){
              let status = await this.validate(this);
              if(!status) return;
              this.loading = true;
              // need to add refund logic, stuff below is not working

              /*
              let data = {
                date: moment().startOf('day').format('YYYY-MM-DD'),
                id: this.selection.id,
                credit: this.selection,
                handle_credit: 2,
                contact_id: this.contact_id
              }

              if(this.refundObj.Payments){
                  data.refunds = this.refundObj.Payments.map(p => {
                      return {
                          id: this.selection.id,
                          payment_id: this.selection.id,
                          credit: this.selection.amount,
                          payment_methods_id: this.selection.payment_methods_id,
                          ref_num: ''
                      }
                  })
              }

              api.post(this, api.LEASES + this.lease_id + '/process-overpayment', data).then(r => {
                  EventBus.$emit('update_charges_widget');
                EventBus.$emit('payment_saved');
                this.resetForm();
                this.$emit('notification', this.removed);
                this.close();
              });
              

              var data = {
                  amount: selection.amount,
                  reason: 'credit',
              };
              api.post(this, api.PAYMENTS + selection.id + '/refund', data).then(r => {
                EventBus.$emit('update_charges_widget');
                this.resetForm();
                this.$emit('notification', this.removed);
                this.close();
              });
              */
          },
          confirm(selection){
            if(this.action === 'Apply'){
              this.save();
            } else if(this.action === 'Remove'){
              this.refund(selection);
            } else {
              '';
            }
          },
          resetForm(){
              this.payment.amount = '';
              this.payment.notes = '';
          },
          close(){
            /*
            this.errorClear(this.$options.name);
            this.successClear(this.$options.name);
            */
            this.$emit('close');
          }
        }
    }

</script>
