<template>
    <div>
        <div class="form-section">

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name_on_card">Name On Card</label>

                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('name_on_card') }">
                        <input
                                name="name_on_card"
                                id="name_on_card"
                                v-model.lazy="creditCard.name_on_card"
                                class="w-input input"
                                data-vv-as="name on card"
                                v-validate="'required|max:45'"
                        />
                    </div>

                    <span v-show="errors.has('name_on_card')"   class="status-block error-block field-error">{{ errors.first('name_on_card') }}</span>
                </div>
            </div>
            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name_on_card">Card Number*</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('name_on_card') }">
                        <input
                                id="card_number"
                                name="card_number"
                                v-model.lazy="creditCard.card_number"
                                v-validate="'required|credit_card'"
                                placeholder="xxxx-xxxx-xxxx-xxxx"
                                data-vv-as="card number"
                                class="w-input input"
                        />
                    </div>
                    <span v-show="errors.has('card_number')"  class="status-block error-block field-error">{{ errors.first('card_number') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="exp_mo">Expiration</label>
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 100px;" :class="{'has-error': errors.has('exp_mo') }">
                        <input
                                id="exp_mo"
                                name="exp_mo"
                                v-model.lazy="creditCard.exp_mo"
                                placeholder="MM"

                                class="w-input input"
                                v-validate="'required|between:1,12'"
                        />
                    </div>
                    <div class="horizontal-form-input">/</div>
                    <div class="horizontal-form-input" style="width: 120px;" :class="{'has-error': errors.has('exp_yr') }">
                        <input
                                name="exp_yr"
                                id="exp_yr"
                                v-model.lazy="creditCard.exp_yr"
                                placeholder="YY"
                                class="w-input input"
                                v-validate="'required|between:18,40'"
                        />
                    </div>
                    <span v-show="errors.has('exp_mo') || errors.has('exp_yr')" class="status-block error-block field-error">You have entered an invalid expiration date.</span>
                </div>
            </div>

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name_on_card">CVV2</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 75px;" :class="{'has-error': errors.has('cvv2') }">
                        <input
                                id="cvv2"
                                name="cvv2"
                                placeholder="123"
                                v-model="creditCard.cvv2"
                                class="w-input input"
                                v-validate="'required|numeric|min:3|max:4'"
                        />
                    </div>
                    <span v-show="errors.has('cvv2')" class="status-block error-block field-error">{{ errors.first('cvv2') }}</span>
                </div>
            </div>

        </div>
        <div class="form-section">
            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="credit_first">Billing Info</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 198px;" :class="{'has-error': errors.has('first') }">
                        <input
                                id="credit_first"
                                name="first"
                                v-model.lazy="creditCard.first"
                                v-validate="'required|alpha_spaces'"
                                placeholder="First"
                                class="w-input input"
                        />
                    </div>
                    <div class="horizontal-form-input"
                         style="width: 198px;"
                         :class="{'has-error': errors.has('last') }">
                        <input
                                id="credit_last"
                                name="last"
                                v-model.lazy="creditCard.last"
                                placeholder="Last"
                                v-validate="'required|alpha_spaces|max:45'"
                                class="w-input input"
                        />
                    </div>
                    <span v-show=" errors.has('first') || errors.has('last')" class="status-block error-block field-error">Please enter the first and last name of the person making the payment. Only letters are allowed</span>
                </div>
            </div>
            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="credit_first">Company</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input"  style="width: 400px;" :class="{'has-error': errors.has('company') }">
                        <input
                                id="credit_company"
                                name="company"
                                v-validate="'max:45'"
                                v-model.lazy="creditCard.company"
                                class="w-input input"
                        />
                    </div>
                    <span v-show=" errors.has('company')" class="status-block error-block field-error">{{ errors.first('company') }}</span>
                </div>
            </div>
            <div class="horizontal-form-row">
                <label class="horizontal-form-label"  for="credit_address">Address</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width:276px;" :class="{'has-error': errors.has('address') }">
                        <input
                                name="address"
                                id="credit_address"
                                v-validate="'required|max:45'"
                                placeholder="Address"
                                v-model="creditCard.address"
                                class="w-input input"
                        />
                    </div>
                    <div class="horizontal-form-input" style="width:120px;" :class="{'has-error': errors.has('address2') }">
                        <input
                                id="credit_address2"
                                name="address2"
                                v-validate="'max:45'"
                                placeholder="Apt. Ste. etc."
                                v-model="creditCard.address2"
                                class="w-input input"
                        />
                    </div>
                </div>
            </div>
            <div class="horizontal-form-row">
                <span class="horizontal-form-label" >&nbsp;</span>
                <div class="form-input-container">

                    <div class="horizontal-form-input" :class="{'has-error': errors.has('credit_city') }">

                        <input
                                name="credit_city"
                                id="credit_city"
                                data-vv-as="city"
                                v-validate="'required|max:45'"
                                placeholder="City"
                                v-model="creditCard.city"
                                class="w-input input"
                        />
                    </div>


                    <div class="horizontal-form-input" style="width: 130px;" :class="{'has-error': errors.has('credit_state') }">

                        <vuetify-statepicker
                          name="credit_state"
                          id="credit_state"
                          data-vv-as="state"
                          v-validate="'required'"
                          v-model="creditCard.state"
                        ></vuetify-statepicker>
                    </div>


                    <div class="horizontal-form-input" style="width: 119px;" :class="{'has-error': errors.has('credit_zip') }">

                        <input
                                name="credit_zip"
                                id="credit_zip"
                                placeholder="Zip"
                                data-vv-as="zip"
                                v-validate="'required|max:45'"
                                v-model="creditCard.zip"
                                class="w-input input"
                        />
                    </div>
                    <span v-show="errors.has('address')" class="status-block error-block field-error">{{ errors.first('address') }}</span>
                    <span v-show="errors.has('address2')" class="status-block error-block field-error">{{ errors.first('address2') }}</span>
                    <span v-show="errors.has('credit_city')" class="status-block error-block field-error">{{ errors.first('credit_city') }}</span>
                    <span v-show="errors.has('credit_state')" class="status-block error-block field-error">{{ errors.first('credit_state') }}</span>
                    <span v-show="errors.has('credit_zip')" class="status-block error-block field-error">{{ errors.first('credit_zip') }}</span>
                </div>
            </div>
        </div>

        <div style="text-align: right;">
            <a class="close-link" @click="$emit('close')">Cancel</a>
            <button :disabled="is_loading"
                    class="w-button primary-btn"
                    @click="savePaymentMethod"
            >Save Payment Method</button>
            <span v-show="is_loading" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </div>


    </div>
</template>

<script type="text/babel">
	import Dropdown from '../../assets/Dropdown.vue';
	import Status from '../../includes/Messages.vue';
	import Loader from '../../assets/CircleSpinner.vue';
	import api from '../../../assets/api.js';
    import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
    import { mapGetters } from "vuex";

	export default {
		name: "AddAuthnetCard",
		data() {
			return {
				creditCard:{
					first: '',
					last:'',
					company: '',
					exp_mo:'',
					exp_yr: '',
					name_on_card: '',
					card_number:'',
					address: '',
					address2: '',
					city: '',
					state: '',
					zip: ''
				}
			}
		},

		filters:{},
		computed:{
            ...mapGetters({
              paymentConfig: 'paymentsStore/getPaymentSourceConfig'
            }),
        },
		components:{
			Dropdown,
			Status,
			Loader,
            VuetifyStatepicker
		},
		created(){},

		methods: {
			async savePaymentMethod(){

				try {
                    let status = await this.$validator.validateAll();
                    if(!status) return;

                    let data = this.creditCard;
                    data.card_number = this.creditCard.card_number.replace(/\D/g,'');
                    data.type = 'card';

                    this.$emit('save', data);

				} catch(err){

					console.log(err);
					this.errorSet(this.$options.name, err);

				}
			},

		},
		props:[ "is_loading" ]
	}
</script>
