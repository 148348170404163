<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div class="hb-global-notification-wrapper">
        <v-snackbar
            v-model="snackbar"
            light
            right
            top
            :color="backgroundColor"
            transition="slide-x-reverse-transition"
            :timeout="setTimeout"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <div class="pb-1 ml-n4 mr-n6" style="margin-top:-2px;margin-bottom:-2px;">
                <v-row no-gutters class="mt-n1 pb-2">
                    <v-col cols="10" class="pl-4 d-flex align-center">
                        <hb-icon :color="iconColor" :mdi-code="setIcon"></hb-icon>
                        <span class="hb-global-notification-title font-weight-medium pl-2" style="margin-top:-3px;">{{ setTitle }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="my-n1 pr-2" align="right">
                        <hb-btn icon tooltip="Close" @click="close" active-state-off :hover-background-color="closeHoverColor">mdi-close</hb-btn>
                    </v-col>
                </v-row>
                <v-divider v-if="(type !== 'success' && type !== 'caution' && type !== 'error' && !loading && !progress) || type === 'success' || type === 'caution' || type === 'error'" :style="'border:1px solid' + dividerColor"></v-divider>
                <v-progress-linear
                    v-if="type !== 'success' && type !== 'caution' && type !== 'error' && (loading || progress)"
                    background-color="#E0F5F5"
                    color="#00848E"
                    :indeterminate="!progress"
                    :value="!loading && progressPercentage ? progressPercentage : undefined"
                    height="2"
                >
                </v-progress-linear>
                <div class="px-4 mt-3 mb-0 hb-global-notification-content">
                    <div class="hb-global-notification-description">
                        {{ description }}
                    </div>
                    <ul v-if="list && list.length" class="hb-global-notification-list-wrapper mt-3">
                        <li v-for="(e, i) in list" class="mt-1 mb-0" :key="'error' + i">{{ e.msg ? e.msg : e.message ? e.message : e }}</li>
                    </ul>
                    <div v-if="$slots.default" class="mb-0" :class="$slots.default && $slots.default[0] && $slots.default[0].text && $slots.default[0].text.trim().length && ((description && description.length) || (list && list.length)) ? 'mt-1' : 'mt-0'">
                        <slot></slot>
                    </div>
                </div>
            </div>
            <div v-if="link" align="right" class="mt-2 hb-global-notification-font-body">
                <hb-link :color="linkType" @click="$emit('link-click')" :to="linkTo" :href="linkHref">{{ link }}</hb-link>
            </div>
            <div v-if="button" class="mt-3" style="margin-bottom:2px;">
                <hb-btn block :color="buttonType" @click="$emit('button-click')" :to="buttonTo" :href="buttonHref">{{ button }}</hb-btn>
            </div>
            <div v-if="$slots.footer" class="hb-global-notification-footer ml-n4 mr-n6 px-4" :class="$slots.footer && $slots.footer[0] && $slots.footer[0].text && $slots.footer[0].text.trim().length ? 'mt-1' : 'mt-0'" align="right">
                <slot name="footer"></slot>
            </div>
        </v-snackbar>
    </div>
</template>
<script type="text/babel">

    import HbIcon from './HbIcon.vue';
    import HbBtn from './HbBtn.vue';
    
    export default {
        name: "HbGlobalNotification",
        data: function() {
            return {
                editing: false,
                hovering: false,
            };
        },
        components: {
            HbIcon,
            HbBtn
        },
        computed: {
            snackbar: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            backgroundColor(){
                switch(this.type){
                    case 'success':
                        return '#E1FAE3'
                        break;
                    case 'caution':
                        return '#FFF9DA'
                        break;
                    case 'error':
                        return '#F8E5E1'
                        break;
                }
            },
            dividerColor(){
                switch(this.type){
                    case 'success':
                        return '#02AD0F'
                        break;
                    case 'caution':
                        return '#FFD600'
                        break;
                    case 'error':
                        return '#FB4C4C'
                        break;
                    default:
                        return '#00848E'
                }
            },
            iconColor(){
                switch(this.type){
                    case 'success':
                        return '#02AD0F'
                        break;
                    case 'caution':
                        return '#FFD600'
                        break;
                    case 'error':
                        return '#FB4C4C'
                        break;
                    default:
                        return '#101318'
                }
            },
            closeHoverColor(){
                switch(this.type){
                    case 'success':
                        return '#C5EDC9'
                        break;
                    case 'caution':
                        return '#F5EBB7'
                        break;
                    case 'error':
                        return '#EFCFCC'
                        break;
                    default:
                        return '#DFE3E8'
                }
            },
            setTimeout(){
                if(this.persistent){
                    return '-1';
                } else if(this.$attrs.timeout){
                    return this.$attrs.timeout;
                } else {
                    switch(this.type){
                        case 'success':
                            return '6000'
                            break;
                        default:
                            return '-1'
                    }
                }
            },
            setIcon(){
                if(this.type === 'success'){
                    return 'mdi-check'
                } else if (this.type === 'caution'){
                    return 'mdi-alert'
                } else if (this.type === 'error'){
                    return 'mdi-alert-circle'
                } else if(this.icon && this.icon.length){
                    return this.icon;
                } else if(this.type === 'generate'){
                    return 'mdi-file'
                } else {
                    return 'mdi-bell-outline'
                }
            },
            setTitle(){
                if(this.title && this.title.length){
                    return this.title;
                } else {
                    switch(this.type){
                        case 'success':
                            return 'Success'
                            break;
                        case 'caution':
                            return 'Warning'
                            break;
                        case 'error':
                            return 'Error'
                            break;
                        case 'generate':
                            return 'Generating Document'
                            break;
                        default:
                            return 'Notification'
                    }
                }
            }
        },
        methods: {
            close(){
                this.snackbar = false;
                this.$emit('close');
            },
        },
        props: {
            value:{
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'default'
            },
            description: {
                type: String,
                default: undefined
            },
            list: {
                type: [Array, Object],
            },
            title: {
                type: String,
                default: undefined
            },
            icon: {
                type: String,
                default: undefined
            },
            persistent: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            progress: {
                type: Boolean,
                default: false
            },
            progressPercentage: {
                type: Number,
                default: 0
            },
            link: {
                type: String,
                default: undefined
            },
            linkType: {
                type: String,
                default: undefined
            },
            linkTo: {
                type: [String, Object],
                default: undefined
            },
            linkHref: {
                type: String,
                default: undefined
            },
            button: {
                type: String,
                default: undefined
            },
            buttonType: {
                type: String,
                default: 'secondary'
            },
            buttonTo: {
                type: [String, Object],
                default: undefined
            },
            buttonHref: {
                type: String,
                default: undefined
            }
        },
    }
</script>

<style scoped>

.hb-global-notification-wrapper >>> .v-snack__wrapper {
    box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-global-notification-wrapper .v-snack__wrapper * {
    max-width: 328px !important;
    letter-spacing: normal !important;
}

.hb-global-notification-wrapper >>> .v-snack__wrapper {
    margin: 22px !important;
}

.hb-global-notification-title {
    font-size: 14px;
    padding-top: 2px;
    color: #101318;
    line-height: 24px;
}

.hb-global-notification-description {
    font-size: 12px;
    line-height: 16px;
    color: #101318;
}

.hb-global-notification-content {
    font-size: 12px;
    line-height: 16px;
    color: #101318;
    width: 328px !important;
}

.hb-global-notification-footer {
    margin-top: 13px;
}

.hb-global-notification-list-wrapper {
    list-style-type:disc;
}

.hb-global-notification-font-body {
    font-size: 14px;
}
    
</style>

<style>

.hb-global-notification-wrapper .v-snack__wrapper {
    min-width: 328px !important;
    max-width: 328px !important;
}

.hb-global-notification-wrapper .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    top: 0 !important;
}

</style>