export default [{
    name: "businessIntelligence",
    path: '/business-intelligence',
    component: () => import('../components/power_bi/index.vue'),
    meta: {
        requiresAuth: true,
        hasAccess: ['admin'],
        layout: 'master'
    }
}]
