<!-- Modal for skip/cancel rent change -->
<template>
  <div>
    <hb-modal
      v-model="showSkipCancelRent"
      :title="title"
      @close="closeSkipCancel"
      class="hb-text-night"
      show-help-link
    >
      <template v-slot:subheader>
        <div>Skip or cancel a rent change within a deployment month</div>
      </template>
      <template v-slot:content>
        <hb-form :full="true" label="Next Rent Change " class="px-0 py-0">
          <div class="hb-text-night-light mb-2 pb-2">
            Select whether to Skip or Cancel upcoming rent changes for this
            tenant. Then provide a reason for the change
          </div>
          <hb-radio-group class="pt-0" v-model="skipCancelRadio">
            <hb-radio value="skip" label="Skip Rent Change" class="pt-0" dense>
              <template v-slot:description>
                <span class="hb-font-caption hb-text-night-light mt-0">
                  Skipping a rent change postpones it by one month or by the
                  next notification period.
                </span>
              </template>
            </hb-radio>
            <hb-radio
              value="cancel"
              label="Cancel Rent Change"
              class="pt-0"
              dense
            >
              <template v-slot:description>
                <span class="hb-font-caption hb-text-night-light mt-0">
                  Canceling a rent change removes it entirely from the
                  designated stage, preventing it from occurring again. This is
                  a permanent removal of the rent adjustment.
                </span>
              </template>
            </hb-radio>
          </hb-radio-group>
          <div class="pt-2 mb-2">
            <div class="pb-0 mb-0 hb-font-caption hb-text-night-light">
              Notes:
            </div>
            <v-col cols="10 ma-0 pa-0">
              <HbTextarea
                v-model="rentChangeReason"
                label="Reason for rent change:"
                placeholder="Enter Reason"
                maxlength="250"
              />
            </v-col>
          </div>
        </hb-form>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          :loading="loading"
          :disabled="!isDirty"
          @click="showConfirmationPopup"
          >Save</hb-btn
        >
        <ConfirmationPopup v-once ref="confirmationPopup" />
      </template>
    </hb-modal>
  </div>
</template>

<script>
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import api from "../../../assets/api.js";
import { EventBus } from "../../../EventBus";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "SkipCancelRent",
  components: {
    ConfirmationPopup,
  },

  mixins: [notificationMixin],

  props: {
    showSkipCancel: {
      type: Boolean,
      default: false,
    },
    skipCancelData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "Skip/Cancel Rent Change",
    },
    property: {
      type: String,
      default: "",
    },
    rentChangeId: {
      type: String,
      default: "",
    },
    report: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rentChangeReason: "",
      skipCancelRadio: "",
      showSkipCancelRent: this.showSkipCancel,
      loading: false,
    };
  },
  computed: {
    successDescription() {
      return `You have ${
        this.isSkipped ? "skipped" : "cancelled"
      } the upcoming rent change.`;
    },

    isSkipped() {
      return this.skipCancelRadio === "skip";
    },

    validateMonth() {
      return this.isSkipped ? "required" : "";
    },
    isDirty() {
      return !!(this.skipCancelRadio && this.rentChangeReason);
    },
  },
  methods: {
    closeSkipCancel(status = false) {
      this.rentChangeReason = "";
      setTimeout(() => {
        this.skipCancelRadio = "";
      }, 2000);
      this.$emit("closeSkip", {
        status: status,
        leaseId: this.skipCancelData?.lease_id,
        rentChangeId: this.skipCancelData?.rentchange_id,
        statusType:
          this.skipCancelRadio.charAt(0).toUpperCase() +
          this.skipCancelRadio.slice(1) +
          (this.skipCancelRadio === "skip" ? "ped" : "led"),
      });
    },

    /**
     * Update Rent change
     */
    async updateTenantRentPlan(data) {
      this.loading = true;
      let body = {
        rent_change_ids: [this.rentChangeId],
        note: this.rentChangeReason ?? "",
      };
      let url =
        api.getPropertyRentManagementUrl(this.property) +
        `rent-changes/${this.skipCancelRadio}`;
      await api
        .put(this, url, body, "", false)
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: this.successDescription,
            timeout: 10000,
          });
          if (!this.report) EventBus.$emit("contact_updated");
          this.closeSkipCancel(true);
        })
        .catch((error) => {
          if (error?.body?.data?.failed)
            this.showMessageNotification({
              type: "error",
              description: error?.body?.data?.failed[0]?.error,
              timeout: 20000,
            });
          else
            this.showMessageNotification({
              type: "error",
            });
        });
      this.loading = false;
    },

    /**
     *	confirmation pop up
     */
    async showConfirmationPopup(type = "active") {
      this.confirmationType = type;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `Confirm ${this.isSkipped ? "Skip" : "Cancel"} Rent Change `,
          message: `Are you sure you want to ${
            this.isSkipped ? "Skip" : "Cancel"
          } Rent Change?`,
          buttonType: type == "delete" ? "destructive" : "primary",
          resolver: `Continue`,
        })
        .catch(() => false);
      if (confirmed) this.updateTenantRentPlan(this.skipCancelData);
    },
  },
  watch: {
    showSkipCancel: {
      handler(value) {
        this.showSkipCancelRent = value;
      },
      immediate: true,
    },
  },
};
</script>
