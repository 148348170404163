<template>
    <hb-modal v-model="dialog" size="large" :title="isEdit ? 'Edit Vehicle Information' : 'Add Vehicle Information'" show-help-link>
        <template v-slot:subheader>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error" class="mx-6 mt-3"></status>
        </template>
        <template v-slot:content>
            <v-container class="hb-table">
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Type
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    :items="vehicle_types"
                                    item-text="name"
                                    item-value="id"
                                    v-validate="'required'"
                                    v-model="form.vehicle.type"
                                    label="Vehicle Type"
                                    id="type"
                                    name="type"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    :hide-details="!errors.collect('vehicle.type').length"
                                    :error-messages="errors.collect('vehicle.type')"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Vehicle Information
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    id="make"
                                    name="make"
                                    label="Make"
                                    v-model="form.vehicle.make"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    id="model"
                                    name="model"
                                    label="Model"
                                    v-model="form.vehicle.model"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    id="year"
                                    name="year"
                                    label="Year"
                                    v-model="form.vehicle.year"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    id="color"
                                    name="color"
                                    label="Color"
                                    v-model="form.vehicle.color"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    License Plate Information
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    id="license_plate_number"
                                    name="license_plate_number"
                                    label="License Plate Number"
                                    v-model="form.vehicle.license_plate_number"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <HbAutocomplete
                                    :items="countriesListMixin"
                                    v-model="form.vehicle.license_plate_country"
                                    label="Country"
                                    id="license_plate_country"
                                    name="license_plate_country"
                                    data-vv-scope="vehicle"
                                    single-line
                                    @change="setVehicleCountry"
                                    dense
                                    flat
                                >
                                </HbAutocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <HbAutocomplete
                                    :items="vehicleStateListMixin"
                                    item-text='name'
                                    item-value='value'
                                    id="license_plate_state"
                                    name="license_plate_state"
                                    label="State"
                                    single_line
                                    v-model="form.vehicle.license_plate_state"
                                    data-vv-scope="vehicle"   
                                    dense
                                    flat                            
                                />
                                <!-- <vuetify-statepicker
                                    id="license_plate_state"
                                    name="license_plate_state"
                                    v-model="form.vehicle.license_plate_state"
                                    data-vv-scope="vehicle"
                                    :solo="false"
                                    :dense="true"
                                ></vuetify-statepicker> -->
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Registration
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <hb-date-picker
                                        @click:clear="form.vehicle.registration_expiry_date = ''"
                                        label="Expiration Date"
                                        :clearable="true"
                                        :dense="true"
                                        :solo="false"
                                        :min="new Date().toISOString()"
                                        name="registration_expiry_date"
                                        id="registration_expiry_date"
                                        v-model="form.vehicle.registration_expiry_date"
                                    ></hb-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-file-input
                                    id="registration_upload_id"
                                    name="registration_upload_id"
                                    label="Upload Registration"
                                    v-model="upload_registartion_file"
                                    prepend-icon=""
                                    dense
                                    flat
                                    hide-details
                                >
                                <template v-slot:prepend-inner>
                                    <v-icon class="mdi-rotate-270 pb-1">mdi-attachment</v-icon>
                                </template>
                                </v-file-input>
                                <!-- <v-text-field
                                    id="registration_upload_id"
                                    name="registration_upload_id"
                                    label="Upload Registration"
                                    v-model="form.vehicle.registration_upload_id"
                                    dense
                                    flat
                                    hide-details
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="mdi-rotate-270 pb-1">mdi-attachment</v-icon>
                                    </template>
                                </v-text-field> -->
                            </v-col>
                            <!-- <v-col cols="6">
                                <hb-link>Send Link to Snap or Upload</hb-link>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Insurance
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    id="insurance_provider_name"
                                    name="insurance_provider_name"
                                    label="Provider Name"
                                    v-model="form.vehicle.insurance_provider_name"
                                    data-vv-scope="vehicle"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-file-input
                                    id="insurance_policy_upload_id"
                                    name="insurance_policy_upload_id"
                                    label="Upload Policy"
                                    v-model="insurance_upload_policy_file"
                                    prepend-icon=""
                                    dense
                                    flat
                                    hide-details
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="mdi-rotate-270 pb-1">mdi-attachment</v-icon>
                                    </template>
                                </v-file-input>
                            </v-col>
                            <!-- <v-col cols="6">
                                <hb-link>Send Link to Snap or Upload</hb-link>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Identification Number (VIN)
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    id="vechile_identification_number"
                                    name="vechile_identification_number"
                                    label="Vechile Identification Number"
                                    v-model="form.vehicle.vechile_identification_number"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Hull Identification Number (HIN)
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    id="hull_identification_number"
                                    name="hull_identification_number"
                                    label="Hull Identification Number"
                                    v-model="form.vehicle.hull_identification_number"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Serial Number
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    id="serial_number"
                                    name="serial_number"
                                    label="Serial Number"
                                    v-model="form.vehicle.serial_number"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row">
                    <v-col class="hb-table-value pl-4" cols="12">
                        <hb-link @click="show_vehicle_additional_fields = !show_vehicle_additional_fields"><span v-if="show_vehicle_additional_fields">Hide</span><span v-else>Show</span> additional fields <v-icon class="mdi-18px" :class="show_vehicle_additional_fields ? 'mdi-rotate-90' : 'mdi-rotate-270'"  color="#00848e">mdi-play</v-icon> </hb-link>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row" v-if="show_vehicle_additional_fields">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Approximate Value
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    prepend-inner-icon="mdi-currency-usd"
                                    id="approximation_value"
                                    name="approximation_value"
                                    label="Approximate Value"
                                    v-model="form.vehicle.approximation_value"
                                    dense
                                    flat
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row" v-if="show_vehicle_additional_fields">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Registered Owner
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="12" class="pa-0">
                                <v-checkbox
                                    dense
                                    class="hb-table-checkbox"
                                    hide-details
                                    v-model="form.vehicle.registered_owner.is_tenant"
                                    label="Registered owner is different than tenant.">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <div v-if="form.vehicle.registered_owner.is_tenant">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        id="registered_owner_first_name"
                                        name="registered_owner_first_name"
                                        label="First Name"
                                        v-model="form.vehicle.registered_owner.first_name"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        id="registered_owner_last_name"
                                        name="registered_owner_last_name"
                                        label="Last Name"
                                        v-model="form.vehicle.registered_owner.last_name"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        id="registered_owner_address"
                                        name="registered_owner_address"
                                        label="Address 1"
                                        v-model="form.vehicle.registered_owner.address"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        id="registered_owner_address2"
                                        name="registered_owner_address2"
                                        label="Address 2"
                                        v-model="form.vehicle.registered_owner.address2"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <HbAutocomplete
                                        :items="countriesListMixin"
                                        v-model="form.vehicle.registered_owner.country"
                                        label="Country"
                                        id="license_plate_country"
                                        name="license_plate_country"
                                        data-vv-scope="vehicle"
                                        single-line
                                        @change="setRegisteredVehicleCountry"
                                        dense
                                        flat
                                        >
                                    </HbAutocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <v-text-field
                                        id="registered_owner_zip"
                                        name="registered_owner_zip"
                                        label="Postal Code"
                                        v-model="form.vehicle.registered_owner.zip"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3" class="margin-top-3">
                                    <HbAutocomplete
                                        :items="registeredVehicleStateListMixin"
                                        item-text='name'
                                        item-value='value'
                                        id="registered_owner_state"
                                        name="registered_owner_state"
                                        label="State"
                                        single_line
                                        v-model="form.vehicle.registered_owner.state"
                                        data-vv-scope="vehicle"     
                                        @change="setRegisteredVehicleState" 
                                        dense
                                        flat                         
                                    />
                                    <!-- <vuetify-statepicker
                                        id="registered_owner_state"
                                        name="registered_owner_state"
                                        v-model="form.vehicle.registered_owner.state"
                                        data-vv-scope="vehicle"
                                        :solo="false"
                                        :dense="true"
                                    ></vuetify-statepicker> -->
                                </v-col>
                                <v-col cols="6" class="margin-top-3">
                                    <HbAutocomplete
                                        :items="registeredVehicleCityListMixin"
                                        v-model="form.vehicle.registered_owner.city"
                                        label="City"
                                        id="registered_owner_city"
                                        name="registered_owner_city"
                                        data-vv-scope="vehicle"
                                        data-vv-name="registered_owner_city"
                                        single-line
                                        dense
                                        flat
                                        :error="!!errors.collect('vehicle.registered_owner_city').length"
                                        @keydown.enter="setCityList($event, form.vehicle.registered_owner.country, form.vehicle.registered_owner.state, registeredVehicleCityListMixin, true)"
                                        >
                                    </HbAutocomplete>
                                    <!-- <v-text-field
                                        id="registered_owner_city"
                                        name="registered_owner_city"
                                        label="City"
                                        v-model="form.vehicle.registered_owner.city"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field> -->
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row" v-if="show_vehicle_additional_fields">
                    <v-col class="hb-table-label pt-4 pb-2 pl-4" cols="3">
                    Legal Owner
                    </v-col>
                    <v-col class="hb-table-value py-2 pl-4" cols="9">
                        <v-row>
                            <v-col cols="12" class="pa-0">
                                <v-checkbox
                                    dense
                                    class="hb-table-checkbox"
                                    hide-details
                                    v-model="form.vehicle.legal_owner.is_tenant"
                                    label="Registered owner is different than tenant.">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <div v-if="form.vehicle.legal_owner.is_tenant">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        id="legal_owner_first_name"
                                        name="legal_owner_owner_first_name"
                                        label="First Name"
                                        v-model="form.vehicle.legal_owner.first_name"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        id="legal_owner_last_name"
                                        name="legal_owner_last_name"
                                        label="Last Name"
                                        v-model="form.vehicle.legal_owner.last_name"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        id="legal_owner_address"
                                        name="legal_owner_address"
                                        label="Address 1"
                                        v-model="form.vehicle.legal_owner.address"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        id="legal_owner_address2"
                                        name="legal_owner_address2"
                                        label="Address 2"
                                        v-model="form.vehicle.legal_owner.address2"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <HbAutocomplete
                                        :items="countriesListMixin"
                                        v-model="form.vehicle.legal_owner.country"
                                        label="Country"
                                        id="legal_owner_country"
                                        name="legal_owner_country"
                                        data-vv-scope="vehicle"
                                        single-line
                                        @change="setLegalVehicleCountry"
                                        dense
                                        flat
                                    >
                                    </HbAutocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <v-text-field
                                        id="legal_owner_zip"
                                        name="legal_owner_zip"
                                        label="Postal Code"
                                        v-model="form.vehicle.legal_owner.zip"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3" class="margin-top-3">
                                    <HbAutocomplete
                                        :items="legalVehicleStateListMixin"
                                        item-text='name'
                                        item-value='value'
                                        id="legal_owner_state"
                                        name="legal_owner_state"
                                        label="State"
                                        single_line
                                        v-model="form.vehicle.legal_owner.state"
                                        data-vv-scope="vehicle"     
                                        @change="setLegalVehicleState"
                                        dense
                                        flat                          
                                    />
                                    <!-- <vuetify-statepicker
                                        id="legal_owner_state"
                                        name="legal_owner_state"
                                        v-model="form.vehicle.legal_owner.state"
                                        data-vv-scope="vehicle"
                                        :solo="false"
                                        :dense="true"
                                    ></vuetify-statepicker> -->
                                </v-col>
                                <v-col cols="6" class="margin-top-3">
                                    <HbAutocomplete
                                        :items="legalVehicleCityListMixin"
                                        v-model="form.vehicle.legal_owner.city"
                                        label="City"
                                        id="legal_owner_city"
                                        name="legal_owner_city"
                                        data-vv-scope="vehicle"
                                        data-vv-name="legal_owner_city"
                                        single-line
                                        dense
                                        flat
                                        :error="!!errors.collect('vehicle.legal_owner_city').length"
                                        @keydown.enter="setCityList($event, form.vehicle.legal_owner.country, form.vehicle.legal_owner.state, legalVehicleCityListMixin, true)"
                                        >
                                    </HbAutocomplete>
                                    <!-- <v-text-field
                                        id="legal_owner_city"
                                        name="legal_owner_city"
                                        label="City"
                                        v-model="form.vehicle.legal_owner.city"
                                        dense
                                        flat
                                        hide-details
                                    ></v-text-field> -->
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save"><span v-if="isEdit">Save</span><span v-else>Add</span></hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import Status from '../includes/Messages.vue';
import Loader from '../assets/CircleSpinner.vue';
import { mapActions } from 'vuex';
import HbDatePicker from '../assets/HummingbirdDatepicker.vue';
import api from '../../assets/api.js';
import VuetifyStatepicker from '../includes/VuetifyStatepicker.vue';
import VEHICLE from '@/constants/vehicle.js'; 
import { countryStateCityMixin } from '../../mixins/countryStateCityMixin';

export default {
    name: 'AddEditVehicle',
    mixins: [countryStateCityMixin],
    data() {
        return {
            form: {
                vehicle: {}
            },
            vehicle_types: VEHICLE.TYPES,
            show_vehicle_additional_fields: false,
            isEdit: false,
            upload_registartion_file: null,
            insurance_upload_policy_file: null,
        }
    },
    components: {
        Loader,
        Status,
        VuetifyStatepicker,
        HbDatePicker
    },
    props: ['selected', 'value', 'lease_id', 'contact_id'],
    created() {
        this.setCountriesListEvent()
        const scope = 'vehicle'
        if(this.selected && this.selected.id) {
            this.isEdit = true;
            this.form.vehicle = JSON.parse(JSON.stringify(this.selected)) || {};

            this.form.vehicle.license_plate_country = this.setCountryNameEvent(this.form.vehicle.license_plate_country);
            
            this.form.vehicle.registered_owner.country = this.setCountryNameEvent(this.form.vehicle.registered_owner.country);
            
            this.form.vehicle.legal_owner.country = this.setCountryNameEvent(this.form.vehicle.legal_owner.country);

            this.form.vehicle.license_plate_state = this.form.vehicle.license_plate_state || ''
            this.form.vehicle.registered_owner.state = this.form.vehicle.registered_owner.state || ''
            this.form.vehicle.legal_owner.state = this.form.vehicle.legal_owner.state || ''

            if(this.form.vehicle.license_plate_country){
                this.setVehicleStatesListEvent(this.form.vehicle.license_plate_country)
            }
            if(this.form.vehicle.registered_owner.country){
                this.setRegisteredVehicleStatesListEvent(this.form.vehicle.registered_owner.country)
                if(!this.form.vehicle.registered_owner.state){
                    this.setRegisteredVehicleCountryCitiesListEvent(this.form.vehicle.registered_owner.country)
                }else{
                    this.setRegisteredVehicleCitiesListEvent(this.form.vehicle.registered_owner.country, this.form.vehicle.registered_owner.state)
                }
            }
            const registeredField = 'registered_owner_city'
            const registeredTargetValue = {target:{value: this.form.vehicle.registered_owner.city, dataset:{vvScope: scope, vvName: registeredField }}} 
            
            this.setCityList(registeredTargetValue, this.form.vehicle.registered_owner.country, this.form.vehicle.registered_owner.state, this.registeredVehicleCityListMixin)

            if(this.form.vehicle.legal_owner.country){
                this.setLegalVehicleStatesListEvent(this.form.vehicle.legal_owner.country)
                if(!this.form.vehicle.legal_owner.state){
                    this.setLegalVehicleCountryCitiesListEvent(this.form.vehicle.legal_owner.country)
                }else{
                    this.setLegalVehicleCitiesListEvent(this.form.vehicle.legal_owner.country, this.form.vehicle.legal_owner.state)
                }
            }

            const legalField = 'legal_owner_city'
            const legalTargetValue = {target:{value: this.form.vehicle.legal_owner.city, dataset:{vvScope: scope, vvName: legalField }}} 
            this.setCityList(legalTargetValue, this.form.vehicle.legal_owner.country, this.form.vehicle.legal_owner.state, this.legalVehicleCityListMixin)

        } else {
            this.resetVehicle();
        }
    },
    destroyed(){
        this.resetMissingCityList()
    },
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        setVehicleCountry(){
            this.form.vehicle.license_plate_state = ''
            this.setVehicleStatesListEvent(this.form.vehicle.license_plate_country)
        },
        setRegisteredVehicleCountry(){
            this.form.vehicle.registered_owner.state = ''
            this.form.vehicle.registered_owner.city = ''
            this.setRegisteredVehicleStatesListEvent(this.form.vehicle.registered_owner.country)
            this.setRegisteredVehicleCountryCitiesListEvent(this.form.vehicle.registered_owner.country)
        },
        setRegisteredVehicleState(){
            this.form.vehicle.registered_owner.city = ''
            this.setRegisteredVehicleCitiesListEvent(this.form.vehicle.registered_owner.country, this.form.vehicle.registered_owner.state)
        },
        setCityList(event, country, state, cityList, toValidate = false){
            const {value, dataset} = event.target
            if(!value) return

            if(toValidate){
                const name= `${dataset.vvScope}.${dataset.vvName}`
                const isSpecialCharacter = this.validateCityName(value)
                const cityValidation = this.errors.items.indexOf(name)
                this.errors.items.splice(cityValidation);
                if(isSpecialCharacter){
                  return this.errors.add({
                            field: name,
                            msg: 'Please add a valid city name.'
                        }) 
                }
            }
            const isCityExists = cityList.some((city) => city.toLowerCase() === value.toLowerCase())
            if(isCityExists) return

            cityList.push(value)
            const countryData = this.countriesListMixinRaw.find(item => item.name === country);
            this.addMissingCity({
                country_code: countryData.isoCode,
                state_code: state,
                city: value
            })
        },
        setLegalVehicleCountry(){
            this.form.vehicle.legal_owner.state = ''
            this.form.vehicle.legal_owner.city = ''
            this.setLegalVehicleStatesListEvent(this.form.vehicle.legal_owner.country)
            this.setLegalVehicleCountryCitiesListEvent(this.form.vehicle.legal_owner.country)
        },
        setLegalVehicleState(){
            this.form.vehicle.legal_owner.city = ''
            this.setLegalVehicleCitiesListEvent(this.form.vehicle.legal_owner.country, this.form.vehicle.legal_owner.state)
        },
        resetVehicle(){
            this.$set(this.form, 'vehicle', {
                id: null,
                contact_id: this.contact_id ? this.contact_id : null,
                type: null,
                make: '',
                year: '',
                model: '',
                color: '',
                license_plate_number: '',
                license_plate_state: '',
                license_plate_country: this.defaultCountry,
                registration_expiry_date: '',
                registration_upload_id: '', 
                insurance_provider_name: '',
                insurance_policy_upload_id: '',
                vechile_identification_number: '',
                hull_identification_number: '',
                serial_number: '',
                approximation_value: 0,
                registered_owner: {
                    country: this.defaultCountry
                },
                legal_owner: {
                    country: this.defaultCountry
                }
            })
            this.setVehicleStatesListEvent(this.form.vehicle.license_plate_country)
            this.setRegisteredVehicleStatesListEvent(this.form.vehicle.registered_owner.country)
            this.setRegisteredVehicleCountryCitiesListEvent(this.form.vehicle.registered_owner.country)
            this.setLegalVehicleStatesListEvent(this.form.vehicle.legal_owner.country)
            this.setLegalVehicleCountryCitiesListEvent(this.form.vehicle.legal_owner.country)
        },
        async save() {
            let vehicle_status = await this.$validator.validateAll('vehicle');
            if(!vehicle_status) {
                this.errorSet(this.$options.name, "There are errors on your form. Please correct them before continuing.");
                return;
            }

            if(this.upload_registartion_file) {
                const upload_registartion_file_content = await this.upload(this.upload_registartion_file);

                const { id } = upload_registartion_file_content;
                if(id) {
                    this.form.vehicle.registration_upload_id = id;   
                }
            }
            
            if(this.insurance_upload_policy_file) {
                const insurance_upload_policy_file_content = await this.upload(this.insurance_upload_policy_file);
                
                const { id } = insurance_upload_policy_file_content;
                if(id) {
                    this.form.vehicle.insurance_policy_upload_id = id;   
                }
            }

            let vehicleData =  this.form.vehicle;

            if(this.selected && this.selected.id) {
                await api.put(this, api.LEASES + this.lease_id + '/vehicles', vehicleData);
            } else {
                await api.post(this, api.LEASES + this.lease_id + '/vehicles', vehicleData);
            }
            this.$emit('close');
        },
        async upload(file){
            let body = {
                document_type: 'file',
                document_type_id: null
            }

            let uploadedFile = await api.postFile(this, api.LEASES + this.lease_id + '/upload', body, [file]);
            if(uploadedFile && uploadedFile.uploads && uploadedFile.uploads.length) return uploadedFile.uploads[0];
        },

    }
}
</script>
<style scoped>
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .margin-top-3{
        margin-top: 3px !important
    }
</style>