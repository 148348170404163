export default [

    {
        name: "Spaces",
        path: '/spaces',
        component: () => import('../components/units/Index.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
        }
    }
]
