var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.spacesWithCoordinates.length === 0)?_c('div',{staticStyle:{"width":"100%"}},[_c('hb-empty-state',[(!_vm.mapInitialLoad)?_c('div',[_vm._v("Please select a Property to continue.")]):_c('div',[_vm._v(" The Property Map for this property has not been set up. "),_c('br'),_vm._v(" Please contact Customer Success (success@tenantinc.com) to configure your property map. ")])])],1):_c('div',{staticClass:"map-wrap"},[_c('div',{staticClass:"map-layer",style:(`transform: translate(${_vm.backgroundX}px, ${_vm.backgroundY}px) scale(${_vm.mapScaleFactor});`)},[_vm._l((_vm.spacesWithCoordinates),function(space,i){return _c('div',{key:i,class:[
        `space-entity`,
        `state-${space.state ? _vm.colorCodes[space.state.toLowerCase()] : ''}`,
        space.floor == _vm.currentFloor ? 'active-floor' : '',
        space.floor == 1 ? 'base-floor' : '',
        space.filterMatch ? 'filter-match' : 'filter-out',
        'floor-layer',
      ],style:(`width: ${space.width * 10}px;
         height: ${space.length * 10}px;
         transform: rotate(${space.rotate}deg);
         top: ${space.y * 10}px;
         left: ${space.x * 10}px;
         color: ${space.state == 'available' ? '#fff' : '#000'};
         background-color: ${space.filterMatch ? space.color : '#fff'};`),attrs:{"data-id":space.id,"data-floor":space.floor},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSpaceModal(space.id)}}},[_c('div',{staticClass:"map-space-label",style:(`transform: rotate(${-space.rotate}deg)`)},[_vm._v(" "+_vm._s(space.number)+" "),(space.filterMatch)?_c('div',{staticClass:"space-quick-card",style:(`transform: scale(${1 / _vm.mapScaleFactor})`)},[_c('div',{staticClass:"space-chip"},[_c('hb-status',{on:{"emitColor":function($event){return _vm.addStateColor($event, i)}}},[_vm._v(_vm._s(space.state))])],1),_c('p',{staticClass:"space-id"},[_vm._v(_vm._s(space.number))]),_c('p',[_vm._v(_vm._s(space.width)+"` x "+_vm._s(space.length)+"`")]),_c('p',[_vm._v("Set Rate: $"+_vm._s(space.price ? space.set_rate.toFixed(2) : ""))]),_c('p',[_vm._v(_vm._s(space.topAmenity))])]):_vm._e()])])}),_vm._l((_vm.assets),function(asset){return _c('div',{key:asset.id,class:[
        `space-entity asset-entity`,
        asset.floor == _vm.currentFloor ? 'active-floor' : '',
        asset.floor == 1 ? 'base-floor' : '',
        'floor-layer',
      ],style:(`width: ${asset.width}px;
         height: ${asset.length}px;
         transform: rotate(${asset.rotate}deg);
         top: ${asset.y * 10}px;
         left: ${asset.x * 10}px;`),attrs:{"data-id":asset.id}},[_c('div',{staticClass:"map-asset-label",style:(`transform: rotate(${-asset.rotate}deg)`)},[(asset.asset === 'office')?_c('span',[_vm._v(_vm._s(asset.asset))]):_c('span',[_c('hb-icon',[_vm._v("mdi-"+_vm._s(asset.asset))])],1)])])})],2),_c('div',{staticClass:"background-layer",style:(`
       background-position: ${_vm.backgroundX}px ${_vm.backgroundY}px;
       background-size: ${50 * _vm.mapScaleFactor}px ${50 * _vm.mapScaleFactor}px;`)},[_c('vue-draggable-resizable',{style:(`opacity:0;transform: translate(${_vm.backgroundElementOffsetX}px, ${_vm.backgroundElementOffsetY}px);`),attrs:{"grid":[10, 10],"w":50,"h":50,"x":_vm.backgroundX,"y":_vm.backgroundY,"resizable":false},on:{"dragging":_vm.backgroundMove}},[_c('div',{style:(`
      width: 100vw;
      height: 100vh;
    `)})])],1),_c('div',{staticClass:"float-nav"},[_c('div',{staticClass:"zoom-controls"},[_c('button',{on:{"click":function($event){return _vm.zoomIn()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('button',{on:{"click":function($event){return _vm.zoomOut()}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('button',{on:{"click":function($event){return _vm.zoomReset()}}},[_c('v-icon',[_vm._v("mdi-image-filter-center-focus")])],1)])]),_c('div',{staticClass:"bottom-bar"},[_vm._v("Spaces: "+_vm._s(_vm.spaceCount))])])
}
var staticRenderFns = []

export { render, staticRenderFns }