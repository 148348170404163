var moment = require('moment');
class Charm {
    constructor (contact) {
     this.contactObj = contact;
    }
    transformData (dataObject={}) {
        const address = this.contactObj.Addresses ? this.contactObj.Addresses.find((a) => a.primary === 1) || null: {};
        const phone = this.contactObj.Phones ? this.contactObj.Phones.find((p) => p.primary === 1) || null: {}; 
        let reservation_in_store_price = this.reservation.Lease?.Unit?.default_price ? this.reservation.Lease.Unit.default_price : null;
        let reservation_space_information = null;
        let reservation_move_in_date = null;
        let reservation_web_only_price = null;
    
        if (dataObject.status !== "call out") {
          reservation_web_only_price = this.reservation.Lease?.Unit?.price ? this.reservation.Lease.Unit.price : null;
          reservation_space_information = `${this.LeadDetails.Unit?.label ? this.LeadDetails.Unit.label : ''}${this.LeadDetails.Unit?.label && this.LeadDetails.Category?.name ? ', ' : ''}${this.LeadDetails.Category?.name ? this.LeadDetails.Category.name : ''}` || null,
          reservation_move_in_date = this.reservation.Lease && this.reservation.Lease.start_date || null;
        }
       
        return {
            id: phone && phone.phone || null,
            status: dataObject.status || null,
            contact_unknown: false,
            contact_id: this.contactObj.id || null,
            contact_name: `${this.contactObj.first ? this.contactObj.first: ''}${this.contactObj.middle ? ` ${this.contactObj.middle}`: ''}${this.contactObj.last ? ` ${this.contactObj.last}`: ''}`,
            contact_status: this.contactObj.status || null,
            contact_email: this.contactObj.email || null,
            contact_phone: phone && phone.phone || null,
            phone_number: phone && phone.phone || null,
            phone_number_added_to_selected_contact: dataObject.phone_number_added_to_selected_contact || null,
            contact_added_to_selected_contact: dataObject.contact_added_to_selected_contact || null,
            selected_contact_id: dataObject.selected_contact_id ||null,
            selected_contact_name: dataObject.selected_contact_name ||null,
            selected_contact_status: dataObject.selected_contact_status ||null,
            selected_contact_phone: dataObject.selected_contact_phone ||null,
            selected_contact_email: dataObject.selected_contact_email ||null,
            selected_contact_address: dataObject.selected_contact_address ||null,
            selected_contact_access_codes: dataObject.selected_contact_access_codes ||null,
            selected_contact_units: dataObject.selected_contact_units ||null,
            playback_url: dataObject.playback_url || null,
            voicemail_url: dataObject.voicemail_url || null,
            communication_time: dataObject.communication_time || new Date(),
            time: dataObject.time || new Date(),
            call_back_time: dataObject.call_back_time || "",
            call_duration_time: dataObject.call_duration_time || "0:00",
            hold_duration_time: dataObject.hold_duration_time || "0:00",
            contact_address: address && address.Address && this.formatAddress(address.Address) || null,
            communication_type: dataObject.status || null,
            communication_source: this.contactObj.Lead?.source || null,
            contact_units: this.contactUnits,
            property_id: dataObject.property_id || null,
            communication_property: dataObject.communication_property || null,
            contact_access_codes: dataObject.contact_access_codes || null,
            reservation_id: this.reservationId || null,
            reservation_in_store_price: reservation_in_store_price ? `$ ${parseFloat(reservation_in_store_price).toFixed(2)}` : null,
            reservation_web_only_price: reservation_web_only_price ? `$ ${parseFloat(reservation_web_only_price).toFixed(2)}` : null,
            reservation_space_information,
            reservation_move_in_date,
            reservation_code: dataObject.reservation_code || null,
        }
    }
    formatAddress (address) {
        if (!address) {
            return "";
        }
        const formatted_address = [];
        if (address.address) formatted_address.push(address.address);
        if (address.address2) formatted_address.push(address.address2);
        if (address.city) formatted_address.push(address.city);
        if (address.state) formatted_address.push(address.state);
        if (address.country) formatted_address.push(address.country);
        if (address.zip) formatted_address.push(address.zip);
        return formatted_address.join(", ");
    }
    setLeadContactUnits(propertyId = null, activeReservations = null){
      let units = [];
      units = this.setLeasedUnits(propertyId);
      if(activeReservations && activeReservations.length){
        activeReservations.forEach((reservation) => {
          if (moment.utc(reservation?.Reservation?.expires).local() >= moment()) {
            const unit = reservation?.Lease?.Unit;
            if (unit?.id && (!propertyId || unit?.Property?.id == propertyId)) {
              units.push({
                id: unit.id,
                type: unit.type,
                number: unit.number,
                address: this.formatAddress(reservation.Address),
                status: "reserved"
              });
            }
          }
        });
      }
      this.contactUnits =  units;
    }
    setContactUnits(propertyId = null) {
      let units = [];
      units = this.setLeasedUnits(propertyId);
      if (this.contactObj.Reservations && this.contactObj.Reservations.length) {
          this.contactObj.Reservations.forEach((reservation) => {
            if (moment.utc(reservation.expires).local() >= moment()) {
              const unit = reservation?.Lease?.Unit;
              if (unit?.id && (!propertyId || unit?.property_id == propertyId)) {
                units.push({
                  id: unit.id,
                  type: unit.type,
                  number: unit.number,
                  address: this.formatAddress(unit.Address),
                  status: "reserved"
                });
              }
            }
          });
      }
      this.contactUnits =  units;
    }
    setLeasedUnits(propertyId){
      const leasedUnits = [];
      if (this.contactObj.Leases && this.contactObj.Leases.length) {
        this.contactObj.Leases.forEach((e) => {
          if(!propertyId || e.Unit?.property_id == propertyId){
            leasedUnits.push({
              id: e.Unit.id,
              type: e.Unit.type,
              number: e.Unit.number,
              address: this.formatAddress(e.Unit.Address),
              status: "leased"
            });
          }
        });
      }
      return leasedUnits;
    }
    setUpLeadReservation(activeReservations = null){
      let reservation = {}; 
      let reservationId = null;
      if(activeReservations && activeReservations.length){
        reservationId = activeReservations[0].Reservation?.id;
        reservation = activeReservations[0];
        reservation.Lease.Unit.default_price = reservation.Unit?.default_price || null;
        reservation.Lease.Unit.price = reservation.Unit?.price || null;
      }
      this.reservation = reservation;
      this.reservationId = reservationId;
      this.LeadDetails = this.contactObj.Lead || {};
    }
    setUpReservation(){
      let reservation = {}; 
      let reservationId = null;
      if(this.contactObj.Reservations && this.contactObj.Reservations.length){
        reservationId = this.contactObj.Reservations[0].id;
        reservation = this.contactObj.Reservations[0];
      }
      this.reservation = reservation;
      this.reservationId = reservationId;
      this.LeadDetails = this.contactObj.Lead || {};
    }
}


module.exports = Charm;
