export default [
  {
    name: "NoticeManager",
    path: '/notice-manager',
    component: () => import('../components/notice_manager/Index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master',
      re_fetch:true
    }
  },
  {
    name: "noticeManagerBatchViewDirectBatchIdOnly",
    path: '/notice-manager/:document_batch_id',
    component: () => import('../components/notice_manager/Index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master'
    }
  },
  {
    name: "noticeManagerBatchViewDirect",
    path: '/notice-manager/:document_batch_id/:document_delivery_id',
    component: () => import('../components/notice_manager/Index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master'
    }
  }
]
  