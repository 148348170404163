<template>
    <hb-modal v-model="dialog" show-help-link size="large" :title="service.product_id ? 'Payment' : 'Coverage Options'" @close="$emit('close')">
        <template v-slot:subheader>
            <span v-if="insurance_set && invoice.total_due">
                <v-row class="text-right px-9 pt-0 pb-0">
                    <v-col class="row-title align-end pa-0 ma-0">
                    Total Payment Due:<span class="ml-12">{{invoice.total_due| formatMoney }}</span>
                    </v-col>
                </v-row>
            </span>
            <span v-else-if="insurance_set && !invoice.total_due">
                <v-row class="text-right px-9 pt-0 pb-0">
                    <v-col class="row-title align-end pa-0 ma-0">
                    Total Payment Due:<span class="ml-12">$0.00</span>
                    </v-col>
                </v-row>
            </span>
            <span v-else>
                Select a new option to update coverage.
            </span>
        </template>
        <template v-slot:content>
            <span v-if="insurance_set && invoice.total_due">
                <payment-process
                    class="ml-4 mr-4 md-4"
                    :contactID="contact_id"
                    :propertyID="property_id"
                    :invoice="invoice"
                    paymentSource="INSURANCE">
                </payment-process>
            </span>
            <span v-else-if="insurance_set && !invoice.total_due">
                <div class="pa-6">
                    There is no payment due at this time.
                </div>
            </span>
            <span v-else>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Current Coverage
                    </v-col>
                    <v-col v-if="insurance.id" cols="9" class="col-cursor-pointer px-7 pb-4">
                        <v-row class="ml-0 d-inline">
                            {{insurance.name}} (Current)<br />
                            <span class="gray-color">{{insurance.description}},</span> <span>{{insurance.price | formatMoney}} Premium</span>
                        </v-row>
                    </v-col>
                    <v-col v-else cols="9" class="col-cursor-pointer px-7 pb-4">
                        <v-row class="ml-0 d-inline">
                            Declined
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Coverage
                    </v-col>
                    <v-col cols="9" class="col-cursor-pointer pl-5 pr-12 py-0">
                        <v-radio-group v-if="options && options.length" class="py-0 my-0 pt-2" v-model="optionRadios" :mandatory="false">
                            <v-radio class="pl-2 pt-2 align-start" :class="{'border-bottom' : i < options.length - 1, 'pb-4' : i < options.length - 1}" v-for="(o, i) in options" :key="'option' + i" :value="i">
                                <template slot="label">
                                    <v-row class="pl-4">
                                        <v-col cols="12" class="activeSelected pa-0 ma-0">{{o.coverage | formatMoney}}</v-col>
                                        <v-col cols="12" class="pa-0 ma-0">{{o.deductible | formatMoney}} Deductible, <span style="color:#101318;">{{o.premium | formatMoney}} Premium</span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        <v-row class="pt-3 pl-5" v-if="!options || !options.length">No coverage options available</v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Decline Coverage
                    </v-col>
                    <v-col cols="5" class="col-cursor-pointer pa-0 ma-0 pl-7">
                        <v-checkbox
                        v-model="optionDecline"
                        label="Tenant has own coverage"
                        class="pb-0 mb-0"
                        ></v-checkbox>
                    </v-col>
                    <!-- <v-col cols="4" class="col-cursor-pointer pt-4">
                        <v-btn v-if="!policyUploaded" @click="showAdd = true" text color="primary" style="font-weight:normal;">Snap or upload policy</v-btn>
                        <v-row class="pt-2 pl-6" v-if="policyUploaded">
                            <v-icon medium color="primary" class="mr-1">mdi-check</v-icon>
                            <span style="color:#00848e;">Policy Uploaded</span>
                        </v-row>
                    </v-col> -->
                </v-row>
                <v-row class="ma-0 pa-0" v-if="optionDecline">
                    <v-col cols="3" class="row-title pl-6 lease-column-1"></v-col>
                    <v-col cols="8" class="col-cursor-pointer pa-0 ma-0 pl-7">
                        <div class="mt-1">
                          <span style="color: #637381;">Please select the expiration date of the coverage policy</span>
                        </div>

                        <v-row class="mt-n1 padding-bottom">
                          <v-col cols="5" class="py-0">
                             <v-select
                                :items="monthList"
                                :hide-details="!errors.collect('insurance_exp_month').length"
                                v-model="insurance_exp_month"
                                clearable
                                data-vv-name="insurance_exp_month"
                                data-vv-as="Month"
                                :error-messages="errors.collect('insurance_exp_month')"
                                label="Select Month"

                              ></v-select>
                          </v-col>

                          <v-col cols="5" class="py-0">
                            <v-select
                              :items="yearList"
                              :hide-details="!errors.collect('insurance_exp_year').length"
                              v-model="insurance_exp_year"
                              clearable
                              data-vv-name="insurance_exp_year"
                              data-vv-as="Year"
                              :error-messages="errors.collect('insurance_exp_year')"
                              label="Select Year"

                            ></v-select>
                          </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </span>
            <upload-file
                v-model="showAdd"
                v-if="showAdd"
                type="file"
                model="leases"
                :foreign_id="$route.params.lease_id"
                size="lg"
                @save="policyUploaded = true"
            >
            </upload-file>
        </template>
        <template v-slot:actions>
            <hb-btn class="mr-2" @click="resetForm" color="secondary" v-if="insurance_set">
                Back
            </hb-btn>
            <span v-if="insurance_set && invoice.total_due">
                <hb-btn color="primary" :disabled="isLoading($options.name)" @click="checkPaymentsErrors">Process Payment</hb-btn>
            </span>
            <span v-else-if="insurance_set && !invoice.total_due">
                <hb-btn color="primary" :disabled="isLoading($options.name)" @click="processPayment(true)">Save Insurance</hb-btn>
            </span>
            <span v-else>
                <hb-btn color="primary" @click="processPayment">Save</hb-btn>
            </span>
        </template>
    </hb-modal>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';

    import { EventBus } from '../../EventBus.js';

    import UploadFile from '../assets/UploadFile.vue';

    import moment from 'moment';

    import PaymentProcess from '../includes/PaymentProcess/Index.vue';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';

    export default {
        name: "InsuranceDialog",
        mixins: [ notificationMixin ],
        data() {
            return {
                insurance_set : false,
                service: {},
                invoice: {},
                formErrors: '',
                payments: [],
                billed_months: '',
                payment_methods: [],
                collect_payments: 0,
                payment_type: 'reader',
                alt_payment_type: '',
                valid: false,
                leases: [],
                payment_amount: 0,
                partial_payment: false,
                connections: [],
                current_charges: {},
                services:[],
                form: {
                    insurance:{},
                    start_date: ''
                },
                selected:{},
                taxRate: 0,
                showAddService:false,
                showDeleteService:false,
                showAddProduct:false,
                selectedService: null,
                offset: 0,
                service_updated: false,
                insurance:[],
                insurance_service: {},
                options: [],
                showAddInsuranceModal: false,
                showEditInsuranceModal:false,
                errorInsurance: false,
                optionRadios: false,
                optionDecline: false,
                showAdd: false,
                policyUploaded: false,
                takeInsurancePayment: false,
                start_date: '',
                todaysDate: '',
                tomorrowsDate: '',
                disable_payment: false,
                monthList:[
                    { text:'January', value:1 },
                    { text:'February', value:2 },
                    { text:'March', value:3 },
                    { text:'April', value:4 },
                    { text:'May', value:5 },
                    { text:'June', value:6 },
                    { text:'July', value:7 },
                    { text:'August', value:8 },
                    { text:'September', value:9 },
                    { text:'October', value:10 },
                    { text:'November', value:11 },
                    { text:'December', value:12 },
                ],
                yearList: [
                    { text: '2020', value: '2020'},
                    { text: '2021', value: '2021'},
                    { text: '2022', value: '2022'},
                    { text: '2023', value: '2023'},
                    { text: '2024', value: '2024'},
                    { text: '2025', value: '2025'},
                    { text: '2026', value: '2026'},
                    { text: '2027', value: '2027'},
                    { text: '2028', value: '2028'},
                    { text: '2029', value: '2029'},
                    { text: '2030', value: '2030'},
                ],
            }
        },
        components:{
            UploadFile,
            PaymentProcess
        },
        async created (){
            // this.fetchInsurance();
            this.fetchInsuranceAndOptions();
            this.payment.property_id = this.property_id;
            this.payment.contact_id = this.contact_id;


            this.todaysDate = moment().startOf('day').format('YYYY-MM-DD');
            this.tomorrowsDate = moment().add(1, "days").startOf('day').format('YYYY-MM-DD');
        },
        destroyed(){
            this.closeMessageNotification();
        },
        computed: {
            ...mapGetters({
                getErrors: 'paymentsStore/getErrors'
            }),
            invoices(){
                let invoices = [];
                for(let i = 0; i < this.leases.length; i++){
                    if(!this.leases[i].pay) continue;
                    for(let j = 0; j < this.leases[i].OpenInvoices.length; j++){
                        let invoice = this.leases[i].OpenInvoices[j];
                        invoices.push(invoice);
                    }
                }
                return invoices;
            },
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },

        },
        methods: {
            ...mapActions({
                getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject'
            }),
            ...mapMutations({
                setCheckErrors: 'paymentsStore/setCheckErrors',
                checkOnlyCredit: 'paymentsStore/checkOnlyCredit'
            }),

            enablePayment(response) {
                this.disable_payment = false;
                if(response && response.error) {
                    this.showMessageNotification({ list: [response.error] });
                } else if(response && response.data && response.data.msg) {
                    this.showMessageNotification({ type: 'success', description: response.data.msg.text });
                    this.$emit('close');
                    //EventBus.$emit('insurance_updated');
                }
            },
            checkPaymentsErrors() {
                //this.setCheckErrors({ value: true });
                //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
                this.checkOnlyCredit();
            },
            async processPayment(executePayment) {
                if(!this.optionDecline && !this.options[this.optionRadios]){
                    this.showMessageNotification({ list: ['You have not selected a new coverage option.  Please select one before continuing.'] });
                    return;
                }

                if(this.optionDecline && !this.insurance_set){
                    this.insurance_set = true;
                    return;
                }

                let dryrun = true;
                let paymentInfo = {};

                if(this.insurance_set) {
                    dryrun = false;
                    if(this.invoice.total_due > 0){

                        paymentInfo = await this.getTransformedPaymentObject({
                            id: this.$options.name,
                            formErrors: this.formErrors
                            // remainingTotalAmount: remainingTotalAmount
                        });

                        if(paymentInfo.errors && paymentInfo.errors.length) {
                            this.showMessageNotification({ list: paymentInfo.errors });
                            return;
                        }

                    }
                }

                let { payment, paymentMethod, use_credits , Invoices} = paymentInfo;
                let response = {};
                try {
                    this.disable_payment = true;
                    let responseData = await api.put(this, api.LEASES + this.lease_id + '/insurance', {
                        decline: this.optionDecline,
                        insurance_exp_month: this.insurance_exp_month,
                        insurance_exp_year: this.insurance_exp_year,
                        dryrun: dryrun,
                        payment,
                        contact_id: this.contact_id,
                        property_id: this.property_id,
                        paymentMethod: paymentMethod,
                        insurance_id: this.options[this.optionRadios] ? this.options[this.optionRadios].id : null,
                        use_credits,
                        Invoices,
                    });

                    if(dryrun) {
                        this.insurance_set = true;
                        this.service = responseData.Service || {};
                        this.invoice = responseData.Invoice || {};
                        this.payment_amount = this.invoice.total_due;
                    }

                    if(executePayment && (!paymentMethod || !paymentMethod.device_id)) {
                        response = {
                            data: {
                                msg: {
                                    id: this.$options.name,
                                    text: responseData.msg,
                                }
                            }
                        };

                        this.enablePayment(response);
                    }
                } catch(err) {
                    response = {
                    error: err
                  };
                  this.enablePayment(response);
                }

            },
            async fetchInsuranceAndOptions(){
                let result =  await api.get(this, api.LEASES + this.lease_id + '/insurance');
                this.options = result.data.options;
                this.insurance = result.data.insurance_service;
            },

            deleteInsurance(){
                api.delete(this, api.SERVICES, this.insurance.id)
            },

            setFormErrors(err){
                this.formErrors = err
            },
            resetPaymentInfo(){
                this.payment_type = null;
                this.payment_amount = 0;
                this.alt_payment_type = null;
            },
            resetForm(){
                this.insurance_set = false;
                this.payment_type = null;
                this.payment_amount = 0;
                this.alt_payment_type = null;
                this.invoice = {};
                this.service = {};
            },
            async getPaymentMethodOptions(){
                let r = await api.get(this, api.PROPERTIES + this.property_id +  '/connections');
                this.connections = r.connections;
                if(!this.connections.length){
                    this.errorSet(this.$options.name, "This account is not set up to accept online payments.");
                }
            },

            endCurrentInsurance(){
                var data = {
                    start_date: this.insurance.start_date,
                    price: this.insurance.price,
                    name: this.insurance.name,
                    prorate: this.insurance.prorate,
                    prorate_out: this.insurance.prorate_out,
                    description: this.insurance.description,
                    recurring: this.insurance.recurring,
                    qty: this.insurance.qty,
                    end_date: moment(this.todaysDate).subtract(1, "days").format('YYYY-MM-DD')
                }
                api.put(this, api.LEASES + this.lease_id + '/services/' + this.insurance.id, data).then(r => {
                    EventBus.$emit('insurance_updated');
                });
            },

        },
        props:['lease', 'lease_id', 'decline_insurance', 'contact_id', 'property_id','value'],
        watch: {
            lease_id: {
                handler: function (val) {
                    this.fetchData();
                },
                deep: true
            },
            'optionDecline': function(){
                if(this.optionDecline){
                    this.optionRadios = false;
                }
                //
            },
            'optionRadios': function(val){
                if(this.optionRadios){
                    this.optionDecline = false;
                }
            },
            getErrors(value) {
                if(value === false) {
                    this.processPayment(true);
                  this.setCheckErrors({ value: null });
                }
            }
        }
    }
</script>



<style scoped>

  .row-title {
    font-weight: 500;
  }

  .lease-column-1{
    background:#F4F6F8;
  }

  .gray-color{
    color:#637381;
  }

  .border-bottom {
    border-bottom: 1px #e0e0e0 solid;
  }

  .secondary-button{
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border:1px solid #C4CDD5;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  }

  .v-item--active .activeSelected {
    color: #101318;
    font-weight: 500;
  }

   .padding-bottom {
     margin-bottom: 15px !important;
   }
</style>

