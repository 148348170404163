<template>
    <div >
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        {{r}}
        <div v-if="!payment.id">
            <span v-if="overPayment">

                <div class="info-section" >
                    There will be an overpayment of {{ overPayment | formatMoney}} remaining on this account.
                </div>
                <br />
                <br />
            </span>
            <div class="form-section payment-summary skinny-row" >
                <h4 class="notification">Payment Totals</h4>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Date:</span>
                    <span class="horizontal-form-text">{{payment.date | formatDate }}</span>

                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Amount:</span>
                    <span class="horizontal-form-text">{{payment.amount | formatMoney }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Method:</span>
                    <span class="horizontal-form-text" >

                        <!--<img class="cc-icon" v-if="payment.method.type == 'Apple Pay'"  :src="payment.method | getCCIcon" />-->
                         <span class="card-type">{{payment.method.type}}</span>
                    </span>
                </div>

            </div>


            <div class="form-section" v-if="isAdmin" v-show="totalApplied > 0" >


                <h4 class="notification">This payment will be applied to the following invoices:</h4>
                <br />
                <br />
                <div class="table">
                    <div class="table-row" v-for="i in invoices" v-if="i.payment_amount > 0">
                        <div class="table-cell">Invoice #{{i.number}}</div>
                        <div class="table-cell">{{i.payment_amount | formatMoney}}</div>
                    </div>
                </div>


            </div>

            <div class="modal-footer">


                <a class="close-link" @click.prevent="$emit('close')">Cancel</a>
                <span v-if="!payment.method.id">
                    <div v-show="canMakePayments" id="apple-pay-btn" @click="createPayment" class="apple-pay-button apple-pay-button-black"></div>

                    <!--<button :disabled="isLoading($options.name)" class="w-button primary-btn" @click.prevent="saveApplePayment">Pay {{payment.amount | formatMoney}} Now</button>-->
                </span>

                <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click.prevent="savePayment" v-if="payment.method.type == 'ach' || payment.method.type == 'card' || payment.method.id">Pay {{payment.amount | formatMoney}} Now</button>

                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>

                <br />
                <br />
                <span v-if="payment.method.type == 'ach' || payment.method.type == 'card'" class="subdued">
                    <span class="icon"></span>&nbsp; Your account will be charged</span>
            </div>

        </div>


        <div class=" payment-summary text-center skinny-row" v-if="payment.id">

            <div class="text-center form-section" >
                <h3 class="success" v-if="paymentType == 'open'">Payment Applied Successfully</h3>
                <h3 class="success" v-if="paymentType == 'new'">Payment Successful</h3>
                <span class="icon confirmation-check success" >
                    
                </span>

                <div v-if="paymentType == 'new'">
                    <p>Your payment of {{payment.amount | formatMoney}} as been processed.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

                <div v-if="paymentType == 'open'">
                    <p>Your payment has been applied to the selected open invoices.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

            </div>
            <button class="w-button secondary-btn float-right" @click.prevent="$emit('reload')"> Enter Another Payment</button>
            <button class="w-button primary-btn float-left" @click="$emit('finish')">Close Window</button>

        </div>


    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "ConfirmApplyPay",
        data() {
            return {
                confirmed: false,
                merchant_name: 'LeaseCaptain',
                merchant_id: 'merchant.com.leasecaptain.app',
                session: {},
                canMakePayments : false,
                applePayConfigured: true
            }
        },
        components:{
            Status,
            Loader
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            }),
            totalApplied(){
                var total =  this.invoices.reduce(function(a,b){
                    return a += Math.round(b.payment_amount * 100);
                }, 0);
                return total / 100;
            },
            overPayment(){
                return  ((this.payment.amount * 100)/100) -  this.totalApplied;

            }

        },
        created(){

            this.getMerchantInfo()


        },
        filters:{

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
            },
        },
        mounted(){

            if (window.ApplePaySession) {
                var promise = window.ApplePaySession.canMakePaymentsWithActiveCard(this.merchant_id);
                promise.then(canMakePayments => {
                    this.canMakePayments = canMakePayments;
                    if(!this.canMakePayments){
                        this.errorSet(this.$options.name, 'ApplePay is possible on this browser, but not currently activated.');
                    }
                });
            } else {
               this.applePayConfigured = false;
            }

        },
        methods:{
            getMerchantInfo(){


            },

            createPayment(){

                var paymentRequest = {
                    currencyCode: 'USD',
                    countryCode: 'US',
                    //requiredShippingContactFields: ['postalAddress'],
                    //requiredShippingContactFields: ['postalAddress','email', 'name', 'phone'],
                    //requiredBillingContactFields: ['postalAddress','email', 'name', 'phone'],
                    lineItems: [
                        {
                            label: "Goods",
                            amount: this.payment.amount
                        }
                    ],
                    total: {
                        label: 'Total Due',
                        amount: this.payment.amount
                    },
                    supportedNetworks: ['amex', 'masterCard', 'visa' ],
                    merchantCapabilities: [ 'supports3DS', 'supportsEMV', 'supportsCredit', 'supportsDebit' ]
                };

                this.session = new ApplePaySession(1, paymentRequest);
                // Merchant Validation
                this.session.onvalidatemerchant = this.onValidateMerchant;
                this.session.onpaymentmethodselected = this.onPaymentMethodSelected;
                this.session.onpaymentauthorized = this.onPaymentAuthorized;
                this.session.oncancel = this.onCancel;

                this.session.begin();
            //    this.session.onshippingcontactselected = this.onshippingcontactselected;
            },

            onValidateMerchant(event) {
                var promise = this.performValidation(event.validationURL);
                promise.then(merchantSession => {
                    console.log(merchantSession);
                    this.session.completeMerchantValidation(merchantSession);
                });
            },

            onPaymentMethodSelected(event) {

                console.log('starting session.onpaymentmethodselected');
                console.log(event);

                var newTotal = {
                    type: 'final',
                    label: 'Total Due',
                    amount: this.payment.amount
                };
                var newLineItems =[
                    {
                        type: 'final',
                        label: "Goods",
                        amount: this.payment.amount
                    }];

                this.session.completePaymentMethodSelection( newTotal, newLineItems );


            },

            onPaymentAuthorized(){

                this.savePayment(event.payment.token).then(r => {

                    var status = ApplePaySession.STATUS_SUCCESS;

                    this.session.completePayment(status);

                    this.confimed = true;
                    this.payment.id = r.payment_id;
                    document.getElementById("applePay").style.display = "none";
                    document.getElementById("success").style.display = "block";
                    EventBus.$emit('lease_payment_saved');

                }).catch(err => {
                    var status = ApplePaySession.STATUS_FAILURE;
                    this.session.completePayment(status);
                })
            },

            onCancel(){
                console.log('Cancel');
            },

            performValidation(valURL) {
               	console.log("valURL", valURL);
               return api.post(this, api.PAYMENTS + 'apple-pay/validate', {url: valURL}).then(r => {
                    this.r = r
               	console.log("RRR", r);

                    return r;
                });
            },

            savePayment(paymentToken){
                var params = {
                    payment: this.payment,
                    invoices: this.invoices,
                    lease_id: this.lease_id,
                    paymentToken: paymentToken
                };

                return api.post(this, api.PAYMENTS, params)

            }
        },
        props: {
            invoices: {
                type: Array
            },
            payment: {
                type: Object
            },
            paymentType:{
                type: String
            },
            lease_id:{
                type: String
            },
            onboard: {
                type: Boolean,
                default: false
            },
            global: {
                type: Boolean,
                default: false
            },
            environment: {
                type: String,
                required: true
            }
        },
    }
</script>

<style scoped>
    label{
        margin: 0;
        padding: 0;
    }
    img.cc-icon {
        border: 1px solid #e2e2e2;
        border-radius: 2px;
        width: 32px;
        margin-right:5px;;
    }

    .confirmation-check{
        font-size: 115px;
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 115px;
    }


    /* CSS */
    @supports (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            -webkit-appearance: -apple-pay-button;
        }
        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }
        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }
        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    }

    @supports not (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            background-size: 100% 60%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border-radius: 5px;
            padding: 0px;
            box-sizing: border-box;
            min-width: 200px;
            min-height: 32px;
            max-height: 64px;
        }
        .apple-pay-button-black {
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-color: #101318;
        }
        .apple-pay-button-white {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button-white-with-line {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
            border: .5px solid black;
        }
    }

</style>
