<template>
    <create @cancel="$emit('cancel')" :invoice_id="$route.params.invoice_id" ></create>
</template>
<script type="text/babel">
    import Create from './CreateInvoice.vue';

    export default {
        name: "EditInvoice",
        data() {
            return {
                invoice:{},
                payments:[],
                showAddProduct:false
            }
        },
        components:{
            Create
        },
        created (){
        },
        filters:{
        },
        computed:{
        },
        methods: {
        }
    }

</script>
