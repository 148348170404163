<template>
  <div>
    <hb-form :label="label" class="text-capitalize">
      <span class="pa-0">
        <all-discounts @removeDiscount="setRemoveDiscount" :label="label" :nationalAccount="nationalAccount" :displayRemoveOption="shouldDisplayRemoveOption" :discounts="promotions ? promotions.future : []" />
        <all-discounts @removeDiscount="setRemoveDiscount" :label="label" :nationalAccount="nationalAccount" :displayRemoveOption="shouldDisplayRemoveOption" :discounts="promotions ? promotions.present : []" endDateText="Present" />
        <div class="hb-text-light text-body-2 pb-2" style="padding-top: 1px;">
          <hb-tooltip>
            <template slot="item">
              <span style="border-bottom: thin dashed; cursor: pointer">
                Past {{ label }}s ({{ promotions && promotions.past && promotions.past.length }})
              </span>
            </template>
            <template slot="body">
              <all-discounts
                :discounts="promotions ? promotions.past : []"
                :label="label"
                durationPeriodColor="#fff"
                displayNoDiscountMessage
              />
            </template>
          </hb-tooltip>
        </div>
        <div class="pt-1" v-if="!(is_closed || isAuctioned) && !nationalAccount">
          <hb-link v-if="isLabelPromotion" @click="promotionDialog = true">+ Add Promotion</hb-link>
          <hb-link v-else-if="promotions && !promotions.present.length && !promotions.future.length" @click="promotionDialog = true">+ Add Discount</hb-link>
          <hb-link v-else @click="promotionDialog = true">Change Discount</hb-link>
        </div>
      </span>
    </hb-form>
    <hb-modal size="medium" v-model="confirm_remove" title="Remove Discount" confirmation show-help-link>
      <template v-slot:content>
        <div class="px-6 py-4">You are about to remove the applied discount. This change will affect upcoming invoices on this account.</div>
        <div class="px-6 pb-4">Are you sure you want to remove the discount?</div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="savePromotion">Remove Discount</hb-btn>
      </template>
    </hb-modal>
    <PromotionDialog
        v-model="promotionDialog"
        v-if="promotionDialog"
        :selected="selected"
        :promotions.sync="promotions"
        :label="label"
        :lease="lease"
        :contactId="contactId"
        @save="savePromotion"
      >
    </PromotionDialog>
  </div>
</template>
<script type="text/babel">
import PromotionDialog from './PromotionDialog.vue';
import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import AllDiscounts from "./AllDiscounts.vue";
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "PromotionsTenantProfile",
  mixins: [notificationMixin],
  data() {
    return {
      promotionDialog: false,
      selected: {},
      confirm_remove: false,
      selectedRemoveDiscount: null
    };
  },
  props: ['label','promotions', 'lease', 'contactId','nationalAccount'],
  components: {
    PromotionDialog,
    AllDiscounts
  },
  created() {
    EventBus.$on('promotionAdded', this.closePromotionsDialog);
    this.selected.isFuture = false;
    if(this.promotions && this.promotions.future.length) {
      this.selected = this.promotions.future[0];
      this.selected.isFuture = true;
    }
    else if(this.promotions && this.promotions.present.length){
      this.selected = this.promotions.present[0];
    }
  },
  destroyed(){
    EventBus.$off('promotionAdded', this.closePromotionsDialog);
  },
  computed: {
    isEndDateSet() {
      return this.promotions && this.promotions.present[0].end ? true : false;
    },
    isLabelPromotion() {
      return this.label == 'promotion' ? true : false;
    },
    is_closed(){
      return moment(this.lease.end_date).startOf('day') <= moment().startOf('day');
    },
    shouldDisplayRemoveOption() {
      if(this.is_closed || this.isAuctioned || this.isLabelPromotion) return false;
      return true;
    },
    isAuctioned(){
      return ( ['auction_payment','move_out'].includes(this.lease.auction_status))
    }
  },
  filters: {},
  methods: {
    
    setRemoveDiscount(data){
      this.confirm_remove = true;
      this.selectedRemoveDiscount = data;
    },

    closePromotionsDialog() {
      this.confirm_remove = false;
      this.promotionDialog = false;
      EventBus.$emit('promotion_updated');
    },
    async savePromotion(data = {}) {
      try {
        if(this.isLabelPromotion) {
          await api.post(this, api.LEASES + this.lease.id + '/discount', { id: data.id, label: this.label });
          this.showMessageNotification({ type: 'success', description: 'A promotion has been added to this account.' });
        } else {
          if (this.selectedRemoveDiscount && this.selectedRemoveDiscount.id && this.confirm_remove) {
            await api.put(this, api.LEASES + this.lease.id + '/discount', { old_id : this.selectedRemoveDiscount.id });
            this.confirm_remove = false;
            this.selectedRemoveDiscount = null;
            this.showMessageNotification({ type: 'success', description: 'The discount has been removed from this account.' });
          } else if (!this.selectedRemoveDiscount && data && data.id) {
              await api.put(this, api.LEASES + this.lease.id + '/discount', {new_id: data.id});
              this.showMessageNotification({ type: 'success', description: 'The discount has been set to change.' });
          } else {
            await api.post(this, api.LEASES + this.lease.id + '/discount', { id: data.id, label: this.label });
            this.showMessageNotification({ type: 'success', description: 'The discount has been added to this account.' });
          }
        }
        this.closePromotionsDialog();
      } catch(err) {
        this.showMessageNotification({ list: [{ msg: err }] });
      }
    }
      
  },
  watch: {
    promotions() {
      this.selected.isFuture = false;
      if(this.promotions && this.promotions.future.length) {
        this.selected = this.promotions.future[0];
        this.selected.isFuture = true;
      }
      else if(this.promotions && this.promotions.present.length){
        this.selected = this.promotions.present[0];
      }
    }
  }
};
</script>

<style scoped>
.field-label {
  background:#F4F6F8;
}
</style>
