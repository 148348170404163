<template>
    <hb-modal size="medium" title="Attention" v-model="dialog" confirmation @close="$emit('close')">
        <template v-slot:content>
            <div class="py-4 px-6">
                Given that the Business Representative and Alternate Contact share the same information, any modifications made to the details of either contact will be automatically updated in the other.
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('ok')">OK</hb-btn>
        </template>
    </hb-modal>
</template>
  
<script type="text/babel">
export default {
    name: 'AlternateContactUpdateModal',
    data: () => ({
    }),
    async created() {
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    props: ['value'],
}
</script>
  