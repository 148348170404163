<template>
    <div class="table-row">
        <div class="table-cell">
            <div class="horizontal-form-input" style="width: 250px;">

                <span v-if="line.id">{{line.Product.name}}</span>
                <auto-complete
                    v-else

                    :id="'product' + rando"
                    :name="'product' + rando"
                    addText="Add Product"
                    :options="products"
                    showAll
                    labelField="name"
                    @add="$emit('addProduct')"
                    v-model="line.Product"
                    @search="searchProducts"
                    @input="setProduct">
                </auto-complete>
            </div>
        </div>
        <div class="table-cell text-right">
            <div class="horizontal-form-input" style="width: 75px;" :class="{'has-error': errors.has('quantity') }">
                <input type="text" name="quantity" v-model="line.qty" v-validate="'max_value:9999'" class="w-input light" />
            </div>
            <span v-show="errors.has('quantity')" class="status-block error-block field-error">{{ errors.first('quantity') }}</span>
        </div>

        <div class="table-cell text-right">

            <div class="horizontal-form-input" style="width: 75px;">
                <div class="form-group">
                    <div class="form-group-icon icon">$</div>
                    <input
                            class="w-input input"
                            type="text"
                            v-model="line.cost"
                            v-validate="'required|decimal:2|min_value:.01'"
                            name="cost"
                            id="cost"
                            :class="{'has-error': errors.has('cost') }"
                    />
                </div>
            </div>

        </div>

        <div class="table-cell text-right total-row">
            <span>{{line | lineTotal | formatMoney}}</span>


        </div>
        <div class="table-cell text-right action-col">
            <hb-btn icon tooltip="Delete" active-state-off @click="$emit('remove', index)">mdi-delete</hb-btn>
        </div>
    </div>
</template>
<script type="text/babel">

    import AutoComplete from '../assets/Autocomplete.vue';
    import Modal from '../assets/Modal.vue';

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';


    export default {
        data() {
            return {
                invoice:{},
                taxRate: 0,
                products:[],
                discountLine:[],
                charge:{
                    Product:{
                        name: null,
                        id: null
                    },
                    product_id: null,
                    service_id: null,
                    cost: null,
                    qty: null
                },
                product:{},
                showAddProduct:false
            }
        },
        components:{
            AutoComplete

        },
        created (){

        },
        filters:{

            lineTotal(c){
                return c.qty * c.cost;
            },

        },
        computed:{
            rando (){
                return Math.random().toString(36).replace(/[^a-z]+/g, '');
            },
            taxSum(){
                var _this = this;
                if(!this.invoice.InvoiceLines) return 0;
                return this.invoice.InvoiceLines.reduce(function(a, b){

                    return a +=  (b.Product.taxable)? ((b.cost * b.qty) * (_this.taxRate / 100)) : 0;
                }, 0)
            },
            subTotal(){
                if(!this.invoice.InvoiceLines) return 0;
                return  this.invoice.InvoiceLines.reduce(function(a, b){
                    return a +=  (b.cost * b.qty)
                }, 0);
            },
            invoiceTotal(){
                return this.taxSum + this.subTotal;
            }
        },
        methods: {

            searchProducts(search){
                search = search || '';
                this.charge.Product.name = search;

                var params = {
                    type: 'product',
                    all: true,
                    search: search,
                    lease_id: this.lease_id

                };



                api.get(this, api.SEARCH_PRODUCTS, params).then(r => {
                    this.products = r.products;
                });

            },
            setProduct(data){

                if(!data){
                    this.line.product_id = null;
                    this.line.Product = {};
                    return;
                }
                this.line.product_id = data.id;
                this.line.cost = data.price;
                this.line.Product = data;
            },
            addNewline(selectedProduct){
                var product = this.productList.filter(function(p){
                    console.log(p);
                    return p.data.id == selectedProduct.value;
                })[0].data;

                this.productList = [];
                this.invoice.InvoiceLines.push({
                    Product: product,
                    product_id: product.id,
                    cost: this.charge.cost ||  product.price || 0,
                    qty: this.charge.qty || 1
                });

                this.charge = {
                    Product:{
                        name: null,
                        id: null
                    },
                    product_id: null,
                    service_id: null,
                    cost: null,
                    qty: null
                };

            },
            away(){
                return this.openSuggestion = false;
            }
        },

        props:['line', 'index', 'lease_id']
    }
</script>

<style scoped>

    .total-row{
        flex: 0 0 125px;
    }

    .action-col{
        flex: 0 0 50px;
    }

</style>
