<template>
  <div>
  <hb-modal
      :title="'New Invoice' + invoiceTitle"
      v-model="dialog"
      @close="close"
      show-help-link
    >     
        <template :v-slot="show_invoice ? '':'subheader'">
          <span>Please select the invoice period and also the due date for this new invoice.</span>
        </template>
        <template v-slot:content>
          <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0" v-if="!show_invoice">
              <v-col cols="3" class="field-label font-weight-medium px-4 text-capitalize">
                Invoice Period
              </v-col>
              <v-col cols="9" class="px-4" style="padding-top:6px;">
                <v-row class="ma-0 pa-0">
                  <v-col cols="9" class="pa-0 ma-0">
                    <v-select
                      :class="{'custom-field-error' : errors.first('period')}"
                      :items="allowedBillingPeriods"
                      item-text="start"
                      return-object
                      hide-details
                      single-line
                      id="period"
                      label="Select a Period"
                      data-vv-name="period"
                      data-vv-as="period"
                      :error-message="errors.collect('period')"
                      class="pa-0 ma-0 text-capitalize"
                      v-validate="'required'"
                      v-model="invoice.period"
                    >
                      <template v-slot:selection="data">{{data.item.start | formatLocalShortDate}} - {{data.item.end | formatLocalShortDate}}</template>
                      <template v-slot:item="data">{{data.item.start | formatLocalShortDate}} - {{data.item.end | formatLocalShortDate}}</template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider v-if="!show_invoice"></v-divider>
            <v-row class="ma-0 pa-0" v-if="!show_invoice">
              <v-col cols="3" class="field-label font-weight-medium px-4 text-capitalize" style="min-height: 28em;">
                Due Date
              </v-col>
              <v-col cols="9" class="px-4 pt-0 mt-n2">
                <v-row class="ma-0 pa-0">
                  <v-col cols="9" class="pa-0 ma-0">
                    <hb-date-picker
                      v-model="invoice.due"
                      id="date"
                      name="due_date"
                      placeholder="Select Date"
                      :solo="false"
                      :min="currentBillingPeriod.start"
                      :max="currentBillingPeriod.end"
                      single-line
                    ></hb-date-picker>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <create-new-invoice :selected="invoice" :isAdjusted="true" ref="viewInvoice" :property_id="property_id" v-if="show_invoice"></create-new-invoice>
          </v-container>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" @click="saveInvoice" :disabled="!invoice.period || !invoice.due || isInvoiceLineProductAdded"><template v-if="show_invoice">Save</template><template v-else>Next</template></hb-btn>
            <loader v-show="isLoading($options.name)" color="#00b2ce" size="20px" class="inline-loader"></loader>
        </template>
    </hb-modal>
    <hb-modal
      size="small"
      v-model="showEmptyInvoiceLineWarning"
      title="Warning"
      confirmation
      show-help-link
    >
      <template v-slot:content
          >
          <div class="px-6 py-4">
           You are removing all items on the invoice. Please void the invoice to remove the invoice. 
          </div>
      </template>
      <template v-slot:actions>
          <hb-btn color="primary" @click="closeShowEmptyInvoiceLineWarning">Ok</hb-btn>
      </template>

    </hb-modal>
  </div>

</template>
<script type="text/babel">
    import { EventBus } from '../../EventBus.js';
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import HbDatePicker from "../assets/HummingbirdDatepicker";
    import CreateNewInvoice from './CreateNewInvoice.vue';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "CreateInvoiceModal",
        mixins: [notificationMixin],
        data() {
          return {
            show_invoice: false,
            showEmptyInvoiceLineWarning: false,
            invoice: {
              id: 1,
              number: 1,
              period: null,
              date: null,
              due: null,
              Contact: {},
              Property: {},
              Lease: {},
              lease_id: null,
              InvoiceLines: []
            },
            allowedBillingPeriods: [],
            currentBillingPeriod: {}
          }
        },
        components:{
          CreateNewInvoice,
          HbDatePicker,
          Loader
        },
        computed: {
          ...mapGetters({
            leasedProperties: 'propertiesStore/leasedProperties'
          }),
          dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
          },
          invoiceTitle() {
            if(this.leasedProperty.id) {
              return ` (${this.leasedProperty.number} - ${this.leasedProperty.name})`
            }
          },
          leasedProperty() {
            if(this.property_id) {
              return this.leasedProperties.filter(lp => lp.id == this.property_id)[0];
            }
          },
          isInvoiceLineProductAdded() {
            return (this.show_invoice && (!this.invoice.InvoiceLines.length || this.invoice.InvoiceLines.filter(il => il.product_id == null).length)) ? true : false;
          }
        },
        async created(){
          this.invoice.date = moment().format('YYYY-MM-DD');
          if(this.contact && this.contact.id) {
            this.invoice.Contact = this.contact;
          }
          if(this.lease && this.lease.id) {
            this.invoice.Lease = this.lease;
            this.invoice.lease_id = this.lease.id;
          }
          this.allowedBillingPeriods = await this.fetchBillingPeriods();
        },
        props: ['contact_id', 'property_id', 'value', 'payments', 'unallocated', 'contact', 'lease', 'property_id'],
        methods:{
          async fetchBillingPeriods() {
            let r = await api.get(this, api.LEASES + this.lease.id + '/billing_periods?past_months=12');
            const {current, past} = r.billing_months;
            this.currentBillingPeriod = current;
            past.unshift(current);
            return past;
          },
          async closeShowEmptyInvoiceLineWarning() {
            this.showEmptyInvoiceLineWarning =  false
          },
          async saveInvoice() {
            
            if(this.show_invoice) {
              if(this.invoice.InvoiceLines.length && (this.invoice.InvoiceLines.reduce((inital, line) => inital + line.cost, 0) > 0)){
                try {
                  let data = {
                    dryrun: false,
                    lease_id: this.lease.id,
                    contact_id: this.contact.id,
                    date: this.invoice.date,
                    due: this.invoice.due,
                    period_start: this.invoice.period.start,
                    period_end: this.invoice.period.end,
                    InvoiceLines: this.invoice.InvoiceLines,
                    Contact: this.invoice.Contact,
                    Property: this.leasedProperty
                  }
                  let r = await api.post(this, api.LEASES + this.lease.id + '/create-invoice', data);
                  this.showMessageNotification({ type: 'success', description: 'A new invoice has been created.' });
                  EventBus.$emit('payment_saved');
                  await EventBus.$emit('refetch_overview_payments');
                  this.$emit('close');
                  return;
                } catch(err) {
                  this.showMessageNotification({ list: [{ msg: err }] });
                }
              }else{
                  this.showEmptyInvoiceLineWarning =  true
              }
            }
            this.show_invoice = true;
          }
        }
    }

</script>
