<template>
    <div >
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <div v-if="!payment.id">
            <span v-if="overPayment">

                <div class="info-section" >
                    There will be an overpayment of {{ overPayment | formatMoney}} remaining on this account.
                </div>
                <br />
                <br />
            </span>
            <div class="form-section payment-summary skinny-row" >
                <h4 class="notification">Payment Totals</h4>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Date:</span>
                    <span class="horizontal-form-text">{{payment.date | formatDate }}</span>

                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Amount:</span>
                    <span class="horizontal-form-text">{{payment.amount | formatMoney }}</span>
                </div>

                <div class="horizontal-form-row" v-if="isAdmin">
                  <span class="horizontal-form-label">Payment Source:</span>
                  <span class="horizontal-form-text">{{payment.source }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Method:</span>
                    <span class="horizontal-form-text" >

                        <img class="cc-icon" v-if="payment.method.type === 'check'"  src="/img/cc_icons/64/cheque.png" />
                        <img class="cc-icon" v-if="payment.method.type === 'cash'"  src="/img/cc_icons/64/dollar.png" />
                        <img class="cc-icon" v-if="payment.method.type === 'ach'"  src="/img/cc_icons/64/ach.png" />
                        <img class="cc-icon" v-if="payment.method.type === 'card'"  :src="payment.method | getCCIcon" />

                          <span class="card-type" v-if="payment.method.type === 'check'" >Check
                              <span v-if="payment.ref_name || payment.number"> ( {{payment.ref_name}}
                              <span v-if="payment.number">#{{payment.number}}</span> )</span>
                          </span>

                         <span class="card-type" v-if="payment.method.type === 'cash'" >Cash</span>
                         <span class="card-type" v-if="payment.method.type === 'ach' || payment.method.type == 'card'" >
                             *{{payment.method.card_end}} ({{payment.method.first}} {{payment.method.last}})
                         </span>
                    </span>


                </div>

                <div class="horizontal-form-row" v-if="isAdmin">
                    <span class="horizontal-form-label">Notes:</span>
                    <span class="horizontal-form-text">{{payment.notes }}</span>
                </div>
            </div>


            <div class="form-section" v-if="isAdmin" v-show="totalApplied > 0" >


                <h4 class="notification">This payment will be applied to the following invoices:</h4>
                <br />
                <br />
                <div class="table">
                    <div class="table-row" v-for="i in invoices" v-if="i.payment_amount > 0">
                        <div class="table-cell">Invoice #{{i.number}}</div>
                        <div class="table-cell">{{i.payment_amount | formatMoney}}</div>
                    </div>
                </div>


            </div>

            <div class="modal-footer">


                <a class="close-link" @click.prevent="$emit('close')">Cancel</a>
                <button
                    :disabled="isLoading($options.name)"
                    class="w-button primary-btn"
                    @click.prevent="savePayment"
                    v-if="payment.method.type.toLowerCase() === 'cash' || payment.method.type.toLowerCase() === 'check'">Record Payment</button>


                <div v-if="payment.method.type.toLowerCase() === 'paypal' && !payment.method.id" id="paypal-button"></div>


                <div v-if="payment.method.type.toLowerCase() === 'google payments' && !payment.method.id" id="google-button">

                </div>

                <div v-if="payment.method.type.toLowerCase() === 'amazon payments' && !payment.method.id">
                    <div style="text-align: center; border: 1px solid #bbb;border-radius: 3px;padding:5px;margin:5px;">
                        <div id="AmazonPayButton"></div>
                    </div>
                    <button type="button" name="button" id="Logout">Logout</button>

                    <div id="walletWidgetDiv" style="height:250px"></div>
                    <br />
                    <div id="consentWidgetDiv" style="height:250px"></div>
                    <br />

                    <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click.prevent="saveAmazonPayment">Pay {{payment.amount | formatMoney}} Now</button>
                </div>

                <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click.prevent="savePayment" v-if="payment.method.type == 'ach' || payment.method.type == 'card' || payment.method.id">Pay {{payment.amount | formatMoney}} Now</button>

                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>

                <br />
                <br />
                <span v-if="payment.method.type === 'ach' || payment.method.type === 'card'" class="subdued">
                    <span class="icon"></span>&nbsp; Your account will be charged</span>
            </div>

        </div>


        <div class=" payment-summary text-center skinny-row" v-if="payment.id">

            <div class="text-center form-section" >
                <h3 v-if="payment_type === 'record'">Payment Applied Successfully</h3>
                <h3 v-if="payment_type === 'charge'">Payment Successful</h3>
                <span class="icon confirmation-check" ></span>

                <div v-if="payment_type === 'charge'">
                    <p>Your payment of {{payment.amount | formatMoney}} as been processed.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

                <div v-if="payment_type === 'record'">
                    <p>Your payment has been applied to the selected open invoices.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

            </div>
            <button class="w-button secondary-btn float-right" @click.prevent="$emit('reload')"> Enter Another Payment</button>
            <button class="w-button primary-btn float-left" @click="$emit('finish')">Close Window</button>

        </div>



    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import moment from 'moment';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    
    export default {
        name: "ConfirmPayment",
        data() {
            return {
                confirmed: false,
                orderReferenceId: ''
            }
        },
        components:{
            Status,
            Loader
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            }),
            totalApplied(){
                var total =  this.invoices.reduce(function(a,b){
                    return a += Math.round(b.payment_amount * 100);
                }, 0);
                return total / 100;
            },
            overPayment(){
                return  ((this.payment.amount * 100)/100) -  this.totalApplied;

            }

        },
        created(){
            this.setApiEndpoint();
        },
        filters:{

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                if(method.card_type) {
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
                  return '/img/cc_icons/64/credit_card.png';
                }
            },
        },
        mounted(){
            if(this.payment.method.type.toLowerCase() === 'paypal'){
                this.setupPayPal();
            }

            if(this.payment.method.type.toLowerCase() === "google payments"){
                this.setupGoogle();
            }

            if(this.payment.method.type.toLowerCase() === "amazon payments"){
                this.setupAmazon();
            }


        },
        methods:{

            setApiEndpoint(){
                var paths = location.hostname.split('.');
                var subdomain = paths[0];
                if(paths[1] + '.' + paths[2] === 'leasecaptain.xyz'){
                    this.endpoint = 'https://api.leasecaptain.xyz/v1/';
                } else if(paths[1] + '.' + paths[2] === 'leasecaptain.com'){
                    this.endpoint = 'https://api.leasecaptain.com/v1/'
                }
            },
            setupAmazon(){


                var accessToken = this.getURLParameter("access_token", location.hash);
                if (typeof accessToken === 'string' && accessToken.match(/^Atza/)) {
                    document.cookie = "amazon_Login_accessToken=" + accessToken + ";path=/;secure";
                }


                window.onAmazonLoginReady = () => {
                    amazon.Login.setClientId("amzn1.application-oa2-client.8de25e10fe4c47339f0fb040bb248052");

                };


                window.onAmazonPaymentsReady = () => {
                    this.showLoginButton();
                    this.showWalletWidget(null);
                };

                document.getElementById('Logout').onclick = () => {
                    amazon.Login.logout();
                    document.cookie = "amazon_Login_accessToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

                };


                (d => {
                    var a = d.createElement('script'); a.type = 'text/javascript';
                    a.async = true; a.id = 'amazon-login-sdk';
                    a.src = 'https:/-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js';
                    d.getElementById('AmazonPayButton').appendChild(a);

                })(document);

            },

            SetOrderReferenceDetails(){



            },
            saveAmazonPayment(){

                var params = {
                    payment: this.payment,
                    invoices: this.invoices,
                    lease_id: this.lease_id,
                    access_token: this.access_token,
                    expires_in: this.expires_in,
                    token_type:   this.token_type,
                    billingAgreementId:   this.billingAgreementId,
                    OrderReferenceId:   this.OrderReferenceId
                };

                console.log(params);
                return api.post(this,  api.PAYMENTS , params).then(r => {
                    this.confimed = true;
                    this.payment.id = r.id;
                });



            },
            showLoginButton() {
                var authRequest;
                OffAmazonPayments.Button("AmazonPayButton", "A1YFRFGSMJQLAR", {
                    type:  "PwA",
                    color: "Gold",
                    size:  "medium",

                    authorization: () => {
                        var loginOptions = {
                            scope: "profile payments:widget",
                            popup: true
                        };
                        authRequest = amazon.Login.authorize (loginOptions, t => {
                            console.log(t);
                            this.access_token = t.access_token;
                            this.expires_in = t.expires_in;
                            this.token_type = t.token_type;

                            this.showWalletWidget(null);
                        });
                    }
                });
            },
            showWalletWidget(billingAgreementId) {
                // Wallet
                this.billingAgreementID = billingAgreementId;
                new OffAmazonPayments.Widgets.Wallet({
                    sellerId: 'A1YFRFGSMJQLAR',
                    agreementType: 'BillingAgreement',
                    amazonBillingAgreementId: billingAgreementId,
                    onReady: function(billingAgreement) {
                        console.log(billingAgreement.getAmazonBillingAgreementId());
                    },
                    onPaymentSelect: () => {
                        console.log("paymentSelectArguments", arguments);
                    },
                    onOrderReferenceCreate: orderReference => {
                        // Use the following cod to get the generated Order Reference ID.

                        this.OrderReferenceId = orderReference.getAmazonOrderReferenceId();
                        console.log(this.OrderReferenceId );
                    },
                    onReady: billingAgreement =>  {
                        this.showConsentWidget(billingAgreement);
                    },
                    design: {
                        designMode: 'responsive'
                    },
                    onError: error => {
                        // Error handling code
                        // We also recommend that you implement an onError handler in your code.
                        // @see https://payments.amazon.com/documentation/lpwa/201954960
                        console.log('OffAmazonPayments.Widgets.Wallet', error.getErrorCode(), error.getErrorMessage());
                    }
                }).bind("walletWidgetDiv");
            },
            showConsentWidget(billingAgreement) {
                // Consent
                new OffAmazonPayments.Widgets.Consent({
                    sellerId: 'A1YFRFGSMJQLAR',
                    amazonBillingAgreementId: billingAgreement.getAmazonBillingAgreementId(),
                    onReady: billingAgreementConsentStatus =>{
                        console.log(billingAgreementConsentStatus.getConsentStatus());
                    },
                    onConsent: billingAgreementConsentStatus =>{
                        console.log(billingAgreementConsentStatus.getConsentStatus());
                    },
                    design: {
                        designMode: 'responsive'
                    },
                    onError: error => {
                        // Error handling code
                        // We also recommend that you implement an onError handler in your code.
                        // @see https://payments.amazon.com/documentation/lpwa/201954960
                        console.log('OffAmazonPayments.Widgets.Consent', error.getErrorCode(), error.getErrorMessage());
                    }
                }).bind("consentWidgetDiv");
            },

            setupGoogle(){

                var paymentToken = '';
                const baseRequest = {
                    apiVersion: 2,
                    apiVersionMinor: 0
                };
                const tokenizationSpecification = {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        'gateway': 'example',
                        'gatewayMerchantId': 'exampleGatewayMerchantId'
                    }
                };
                const allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"];
                const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

                const baseCardPaymentMethod = {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: allowedCardAuthMethods,
                        allowedCardNetworks: allowedCardNetworks
                    }
                };

                const cardPaymentMethod = Object.assign(
                        {
                            tokenizationSpecification: tokenizationSpecification
                        },
                        baseCardPaymentMethod
                );

                const paymentsClient =
                        new google.payments.api.PaymentsClient({
                            environment: 'TEST'
                        });

                const isReadyToPayRequest = Object.assign({}, baseRequest);
                isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];

                paymentsClient.isReadyToPay(isReadyToPayRequest)
                        .then(function(response) {
                            console.log(response);
                            if (response.result) {
                                // add a Google Pay payment button
                            }
                        })
                        .catch(function(err) {
                            // show error in developer console for debugging
                            console.error(err);
                        });

                const button =
                        paymentsClient.createButton({
                            onClick: () => {
                                var params = {
                                    payment: this.payment,
                                    invoices: this.invoices,
                                    lease_id: this.lease_id,
                                    paymentToken: paymentToken
                                };

                                api.post(this, api.PAYMENTS, params).then(r => {
                                    this.confimed = true;
                                    this.payment.id = r.id;
                                    EventBus.$emit('lease_payment_saved');
                                });
                            }
                        });
                document.getElementById('google-button').appendChild(button);
                const paymentDataRequest = Object.assign({}, baseRequest);

                paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];

                paymentDataRequest.transactionInfo = {
                    totalPriceStatus: 'FINAL',
                    totalPrice: this.payment.amount,
                    currencyCode: 'USD'
                };

                paymentDataRequest.merchantInfo = {
                    merchantName: 'Example Merchant'
                };

                paymentsClient.loadPaymentData(paymentDataRequest).then(function(paymentData){
                    // if using gateway tokenization, pass this token without modification
                    paymentToken = paymentData.paymentMethodData.tokenizationData.token;
                }).catch(function(err){
                    // show error in developer console for debugging
                    console.error(err);
                });
                paymentsClient.prefetchPaymentData(paymentDataRequest);



            },

            setupPayPal(){

                paypal.Button.render({
                    env: 'sandbox',
                    payment: (data, actions) => {

                        return api.post(this,  api.LEASES + this.lease_id + '/paypal-payment-create', this.payment).then(res => {
                            console.log(res.id);

                            return res.id;
                        });
                    },
                    onAuthorize:(data, actions) => {
                        var params = {
                            payment: this.payment,
                            invoices: this.invoices,
                            lease_id: this.lease_id,
                            paymentID: data.paymentID,
                            payerID:   data.payerID
                        };

                        console.log(params);
                        return api.post(this,  api.PAYMENTS , params).then(r => {
                            console.log(r);
                            this.confimed = true;
                            this.payment.id = r.id;
                        });
                    },
                    onError: (err) => {
                        console.log(err)
                    }
                }, '#paypal-button');
            },

            savePayment(){

                if(!this.isLoading(this.$options.name) && !this.confimed){
                    var params = {
                        payment: {
                            lease_id: this.lease_id,
                            amount: this.payment.amount,
                            source: this.isAdmin ? this.payment.source: 'e-commerce',
                            date: moment(this.payment.date).format('YYYY-MM-DD'),
                            notes: this.payment.notes,
                            ref_name: this.payment.ref_name,
                            number: this.payment.number
                        },
                        invoices: this.invoices,
                    };

                    if(this.payment.method.id){
                        params.payment.payment_method_id = this.payment.method.id;
                        params.payment.nonce = this.payment.method.nonce;
                    } else if (this.payment.method.type === 'cash' || this.payment.method.type === 'check'){
                        params.payment.type = this.payment.method.type;
                        params.payment.ref_name = this.payment.ref_name;
                    }

                    if(this.payment.method.type === 'check'){
                        params.payment.number = this.payment.number
                    }


                    params.invoices = this.invoices.filter(i => i.payment_amount > 0).map(i => {
                        return {
                            id: i.id,
                            amount: i.payment_amount
                        }
                    });
                    let endpoint = '';
                    if(!this.payment_type || this.payment_type === 'record'){
                        endpoint = 'record'
                    }

                    api.post(this, api.PAYMENTS + endpoint, params).then(r => {
                        this.confirmed = true;
                        this.payment.id = r.payment_id;
                        //this.payment.amount = r.payment.amount;
                        //this.payment.status_desc = r.payment.status_desc;
                        EventBus.$emit('lease_payment_saved');
                    });

                }
            },
            // getURLParameter(name, source) {
        //     return decodeURIComponent((new RegExp('[?|&amp;|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(source) || [ , ""])[1].replace(/\+/g, '%20')) || null;
        // }
        },
        props: {
            invoices: {
                type: Array
            },
            payment: {
                type: Object
            },
            payment_type:{
                type: String
            },
            lease_id:{
                type: String
            },
            onboard: {
                type: Boolean,
                default: false
            },
            global: {
                type: Boolean,
                default: false
            }
        },
    }
</script>

<style scoped>
    label{
        margin: 0;
        padding: 0;
    }
    img.cc-icon {
        border: 1px solid #e2e2e2;
        border-radius: 2px;
        width: 32px;
        margin-right:5px;;
    }

    .confirmation-check{
        font-size: 115px;
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 115px;
    }

</style>
