export default [
  {
    name: "promotionManagement",
    path: '/promotion-management',
    component: () => import('../../components/revenue_management/promotion_management/index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master'
    }
  }
]
