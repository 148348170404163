<template>
  <div>
    <div v-if="displayNoDiscountMessage && !discounts.length">
      No {{ label }} found
    </div>
    <div v-for="(discount, i) in discounts" :key="i">
      <v-row class="ma-0 pa-0">
        <v-col :cols="displayRemoveOption && (label == 'discount' && !discount.end) ? 11 : 12" class="ma-0 pa-0">
          <div>{{ discount.Promotion.name }}
            <hb-tooltip v-if="nationalAccount">
              <template v-slot:body>
              This setting is not editable. Please contact the National Account Manager for more information.
            </template>
          </hb-tooltip></div>
          <div :class="durationPeriodColor" class="text-body-2 pb-2">
            {{ discount.start | formatLocalShortDate }}
            <span v-if="discount.end">- {{ discount.end | formatLocalShortDate }}</span>
            <span v-else-if="endDateText">- {{ endDateText }}</span>
          </div>
        </v-col>

        <v-col cols="1" class="ma-0 pa-0" v-if="displayRemoveOption && (label == 'discount' && !discount.end) && !nationalAccount">
          <div class="mt-n2">
            <hb-menu options>
              <v-list>
                <v-list-item @click="removeDiscount(discount)">
                  <v-list-item-title>Remove</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script type="text/babel">
import { EventBus } from '../../EventBus';

export default {
  name: "AllDiscounts",
  data() {
    return {};
  },
  props: {
    discounts: {
      type: Array,
      required: true,
    },
    displayNoDiscountMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    durationPeriodColor: {
      type: String,
      required: false,
      default: "hb-text-light",
    },
    displayRemoveOption: {
      type: Boolean,
      default: false
    },
		label: {
      type: String,
      default: 'discount'
    },
    endDateText: {
      type: String,
      required: false,
      default: ''
    },
    nationalAccount:{
      type: Boolean
    }
  },

  methods: {
		removeDiscount(discount) {
      this.$emit('removeDiscount', discount);
		}
	},
};
</script>

<style>
</style>