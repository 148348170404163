// import MaintenanceIndex from '../components/maintenance/Index.vue';
// import MaintenanceRequests from '../components/maintenance/Maintenance.vue';
// import MaintenanceRequestView from '../components/maintenance/MaintenanceRequestView.vue';



export default [
	{
		path: '/maintenance-requests',
		component: () => import('../components/maintenance/Index.vue'),
		meta: {
			requiresAuth: true,
      layout: 'master'
		},
		children:[
			{
				path: '/',
				component: () => import('../components/maintenance/Maintenance.vue'),
				meta: {
					requiresAuth: true,
					hasAccess: ['admin'],
          layout: 'master'
				}
			},
		]
	},
	{
		path: '/maintenance-requests/:maintenance_id',
		component: () => import('../components/maintenance/MaintenanceRequestView.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      layout: 'master'
		}
	}
]
