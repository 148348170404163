export const calendarMixin = {
  name: 'calendarMixin',
  computed: {
    daysOfMonth() {
      let days = [];
      for (var i = 1; i <= 31; i++) {
        days.push(i);
      }
      return days;
    },
  },
  methods: {
    dateDifferenceInDays(start, end) {
      return end.diff(start, 'days');
    }
  }
};
