<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div
        class="hb-toast-item-div-wrapper"
        :class="[addingInProgress ? 'hb-toast-item-slide-in' : '', switchingInProgress ? 'hb-toast-item-slide-out' : '']"
    >
        <div
            class="hb-toast-item-wrapper d-flex align-center font-weight-medium"
            :class="[backgroundColorClass, textColorClass, pointer ? 'hb-toast-item-pointer' : '', miniCharm ? 'hb-toast-item-mini-charm hb-toast-item-top-border-radius' : '']"
            @click="!switchingInProgress ? $emit('click', id) : ''"
        >
            <div class="hb-toast-item-column-one">

                <hb-icon
                    :color="status == 'hold' ? '#101318' : '#ffffff'"
                    :mdi-code="setIcon"
                >
                </hb-icon>
                
            </div>

            <div class="hb-toast-item-column-two" :style="'width:' + columnTwoWidth + '%;'">
                <hb-icon
                    small
                    :color="status == 'hold' ? '#101318' : '#ffffff'"
                    style="position:relative;top:-1px;margin-left:-2px;"
                >
                    mdi-account
                </hb-icon>
                {{ contactUnknown ? 'Unknown' : contactName ? contactName : '' }} {{ contactUnknown ? phoneNumber : '' }}
            </div>

            <div v-if="!contactUnknown" :class="columnThreeClass" align="right">
                <HbStatus
                    v-if="contactStatus"
                    :status="contactStatus"
                    :pointer="pointer ? true : false"
                />
            </div>

            <div class="hb-toast-item-column-four" align="right">
                <slot name="timeSlot" v-if="$slots.timeSlot"></slot>
                <span v-else>{{ time }}</span>
            </div>
        </div>

        <div v-if="miniCharm" :class="backgroundColorClass" class="hb-toast-item-cascade-shifter">

        </div>

    </div>
</template>

<script type="text/babel">

import HbIcon from './HbIcon.vue';

export default {
    name:'HbToastItem',
    data(){
        return {

        }
    },
    components: {
        HbIcon
    },
    computed: {
        backgroundColorClass() {
            if(this.status && (this.status.toLowerCase() == 'call in' || this.status.toLowerCase() == 'call out')){
                return 'hb-toast-item-incoming-background';
            } else if(this.status && this.status.toLowerCase() == 'hold'){
                return 'hb-toast-item-hold-background';
            } else if(this.status && (this.status.toLowerCase() == 'active' || this.status.toLowerCase() == 'ended')){
                return 'hb-toast-item-active-background';
            } else {
                return 'hb-toast-item-default-background';
            }
        },
        textColorClass() {
            if(this.status && this.status.toLowerCase() == 'hold'){
                return 'hb-toast-item-text-night';
            } else {
                return 'hb-toast-item-text-white';
            }
        },
        columnTwoWidth() {
            var firstColumnWidth = 14;
            var thirdColumnWidth = '';
            var fourthColumnWidth = 16;

            if(this.contactUnknown){
                thirdColumnWidth = 0;
            } else if(this.contactStatus && (this.contactStatus.toLowerCase() == 'lease closed' || this.contactStatus.toLowerCase() == 'balance due')){
                thirdColumnWidth = 35;
            } else if(this.contactStatus && (this.contactStatus.toLowerCase() == 'delinquent' || this.contactStatus.toLowerCase() == 'suspended' || this.contactStatus.toLowerCase() == 'active lien' || this.contactStatus.toLowerCase() == 'active lead' || this.contactStatus.toLowerCase() == 'bankruptcy' || this.contactStatus.toLowerCase() == 'converted')) {
                thirdColumnWidth = 32;
            } else {
                thirdColumnWidth = 27;
            }

            return 100 - (firstColumnWidth + thirdColumnWidth + fourthColumnWidth);

        },
        columnThreeClass() {
            if(this.contactStatus && (this.contactStatus.toLowerCase() == 'lease closed' || this.contactStatus.toLowerCase() == 'balance due')){
                return 'hb-toast-item-column-three-large-status';
            } else if(this.contactStatus && (this.contactStatus.toLowerCase() == 'delinquent' || this.contactStatus.toLowerCase() == 'suspended' || this.contactStatus.toLowerCase() == 'active lien' || this.contactStatus.toLowerCase() == 'active lead' || this.contactStatus.toLowerCase() == 'bankruptcy' || this.contactStatus.toLowerCase() == 'converted')) {
                return 'hb-toast-item-column-three-medium-status';
            } else {
                return 'hb-toast-item-column-three';
            }
        },
        setIcon() {
            if(this.icon){
                return this.icon;
            } else if(this.status && (this.status.toLowerCase() == 'call in' || this.status.toLowerCase() == 'call out')){
                return 'mdi-phone-in-talk';
            } else if(this.status && this.status.toLowerCase() == 'voicemail'){
                return 'mdi-voicemail';
            } else if(this.status && this.status.toLowerCase() == 'missed'){
                return 'mdi-phone-missed';
            } else if(this.status && this.status.toLowerCase() == 'active'){
                return 'mdi-phone';
            } else if(this.status && this.status.toLowerCase() == 'hold'){
                return 'mdi-pause';
            } else if(this.status && this.status.toLowerCase() == 'reservation'){
                return 'mdi-calendar-clock';
            } else if(this.status && this.status.toLowerCase() == 'ended'){
                return 'mdi-phone-hangup';
            } else {
                return '';
            }
        }
    },
    props: {
        id: {
            type: String,
            default: undefined
        },
        status: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        },
        contactId: {
            type: String,
            default: undefined
        },
        contactName: {
            type: String,
            default: undefined
        },
        contactStatus: {
            type: String,
            default: undefined
        },
        time: {
            type: String,
            default: undefined
        },
        pointer: {
            type: Boolean,
            default: true
        },
        miniCharm: {
            type: Boolean,
            default: false
        },
        addingInProgress: {
            type: Boolean,
            default: false
        },
        switchingInProgress: {
            type: Boolean,
            default: false
        },
        contactUnknown: {
            type: Boolean,
            default: false
        },
        phoneNumber: {
            type: String,
            default: undefined
        }
    }
}
</script>

<style scoped>

.hb-toast-item-wrapper {
    min-height: 36px;
    max-height: 36px;
    font-size: 14px;
}

.hb-toast-item-top-border-radius {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.hb-toast-item-pointer {
    cursor: pointer;
}

.hb-toast-item-incoming-background {
    background-color: #02AD0F;
}

.hb-toast-item-hold-background {
    background: repeating-linear-gradient(135deg, #f8d748, #f8d748 5px, #dabe40 0px, #dabe40 11px);
}

.hb-toast-item-active-background {
    background-color: #003135;
}

.hb-toast-item-default-background {
    background-color: #F26500;
}

.hb-toast-item-text-night {
    color: #101318;
}

.hb-toast-item-text-white {
    color: #ffffff;
}

.hb-toast-item-column-one {
    width: 14%;
    padding-left: 12px;
}

.hb-toast-item-column-two {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
}

.hb-toast-item-column-three {
    width: 27%;
    padding-right: 4px;
}

.hb-toast-item-column-three-medium-status {
    width: 32%;
    padding-right: 4px;
}

.hb-toast-item-column-three-large-status {
    width: 35%;
    padding-right: 4px;
}

.hb-toast-item-column-four {
    width: 16%;
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-toast-item-mini-charm {
    -moz-box-shadow: inset 0 -12px 12px -12px #202225;
    -webkit-box-shadow: inset 0 -12px 12px -12px #202225;
    box-shadow: inset 0  -12px 12px -12px #202225;
}

.hb-toast-item-div-wrapper {
    width:100%;
    position: relative;
}

.hb-toast-item-cascade-shifter {
    height: 2px;
    width: 100%;
    position: absolute;
    top: 36;
    z-index: -1;
    -moz-box-shadow: inset 0 12px 12px -12px #202225;
    -webkit-box-shadow: inset 0 12px 12px -12px #202225;
    box-shadow: inset 0 12px 12px -12px #202225;
}

.hb-toast-item-column-one >>> .mdi-phone-in-talk {
    animation: ring 2.5s .7s ease-in-out infinite;
}

.hb-toast-list-items-wrapper .hb-toast-item-default-background:hover {
    background-color: #de4c00;
}

@-webkit-keyframes ring {
  0% { transform: rotate(0); }
  5% { transform: rotate(0); }
  10% { transform: rotate(0); }
  15% { transform: rotate(10deg); }
  20% { transform: rotate(-10deg); }
  25% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  35% { transform: rotate(10deg); }
  40% { transform: rotate(0); }
  45% { transform: rotate(0); }
  50% { transform: rotate(0); }
  55% { transform: rotate(0); }
  60% { transform: rotate(0); }
  65% { transform: rotate(10deg); }
  70% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  80% { transform: rotate(-10deg); }
  85% { transform: rotate(10deg); }
  90% { transform: rotate(0); }
  95% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  5% { transform: rotate(0); }
  10% { transform: rotate(0); }
  15% { transform: rotate(10deg); }
  20% { transform: rotate(-10deg); }
  25% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  35% { transform: rotate(10deg); }
  40% { transform: rotate(0); }
  45% { transform: rotate(0); }
  50% { transform: rotate(0); }
  55% { transform: rotate(0); }
  60% { transform: rotate(0); }
  65% { transform: rotate(10deg); }
  70% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  80% { transform: rotate(-10deg); }
  85% { transform: rotate(10deg); }
  90% { transform: rotate(0); }
  95% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

.hb-toast-item-slide-in {
    position: relative;
    opacity: .25;
    right: -371px;
    -webkit-animation: slide-in 0.2s linear;
    animation: slide-in 0.2s linear;
}

.hb-toast-item-slide-out {
    position: relative;
    top: 0px;
    -webkit-animation: slide-out 0.35s linear;
    animation: slide-out 0.35s linear;
}

@-webkit-keyframes slide-in {
    100% { right: 0; opacity: 1 }
}

@keyframes slide-in {
    100% { right: 0; opacity: 1 }
}

@-webkit-keyframes slide-out {
    100% { top: 72px; opacity: 0; }
}

@keyframes slide-out {
    100% { top: 72px; opacity: 0; }
}

</style>