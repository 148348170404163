<template>
  <div class="d-flex align-center">
    <HbUnitIcon
      v-for="(item, index) in planIcons"
      :key="index + item"
      class="descriptive-icons"
      color="#101318"
      v-bind="$attrs"
      v-on="$listeners"
      :class="`${conf.class} ${size}`"
      :style="conf.style"
      :type="getUnitTypeLabel({unit_type_id:item})"
    />
  </div>
</template>

<script type="text/babel">
export default {
  name: 'DescriptiveTags',
  inheritAttrs: false,
  props: {
    plan: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (value) => {
        let keys = Object.keys(value ?? {})
        return keys.length && ['tags', 'price_delta_type'].every((needed) => keys.includes(needed))
      }
    },
    conf: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    /**
     * Function for get icons according plan tags.
     */
    planIcons() {
      let { tags } = this.plan || {}
      return tags;
    },

    priceDeltaTypeIcon(){
      let { price_delta_type } = this.plan || {}
      return price_delta_type == 'dollar' ? 'mdi-currency-usd' : 'mdi-percent'
    },

    size() {
      return Boolean(this.$attrs.hasOwnProperty("small")) ? "small" : "medium"
    }
  }
}
</script>

<style lang="scss">
.descriptive-icons {
  &.medium {
    svg {
      // margin-bottom: 3px;
    }
    i.v-icon.mdi.mdi-currency-usd {
      margin-bottom: 1px;
      margin-left: -5px !important;
    }
    i.v-icon.mdi.mdi-rv-truck {
      margin-bottom: 2px;
      font-size: 25px !important;
    }
    i.v-icon.mdi.mdi-percent {
      margin-left: -2px !important;
      margin-bottom: 3px;
    }
  }
  &.small {
    i.v-icon.mdi.mdi-currency-usd {
      margin-bottom: 3px !important;
      margin-left: -3px !important;
    }
    svg {
      margin-top: 2px !important;
    }
    i.v-icon.mdi.mdi-rv-truck {
      margin-bottom: 2px !important;
      font-size: 17px !important;
    }
    i.v-icon.mdi.mdi-percent {
      margin-left: -3px !important;
      margin-bottom: 3px !important;
    }
  }
}

</style>
