<template>
    <div>
        <div class="form-section">
            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="ach_first">Name on Account</label>

                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 200px;" :class="{'has-error': errors.has('first') }">
                        <input
                            name="first"
                            id="ach_first"
                            placeholder="First"
                            v-model.lazy="ach.first"
                            class="w-input input"
                            v-validate="'required'"
                        />
                    </div>

                    <div class="horizontal-form-input" style="width: 200px;" :class="{'has-error': errors.has('last') }">
                        <input
                            name="last"
                            id="ach_last"
                            placeholder="Last"
                            v-model.lazy="ach.last"
                            class="w-input input"
                            v-validate="'required'"
                        />
                    </div>

                    <span v-show="errors.has('first')"   class="status-block error-block field-error">{{ errors.first('first') }}</span>
                    <span v-show="errors.has('last')"   class="status-block error-block field-error">{{ errors.first('last') }}</span>
                </div>
            </div>

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="account_number">Account Number</label>

                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('account_number') }">
                        <input

                                name="account_number"
                                id="account_number"
                                v-model.lazy="ach.account_number"
                                class="w-input input"
                                v-validate="'required|numeric|max:45'"
                        />
                    </div>

                    <span v-show="errors.has('account_number')"   class="status-block error-block field-error">{{ errors.first('account_number') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="routing_number">Routing Number</label>

                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('account_number') }">
                        <input

                                name="routing_number"
                                id="routing_number"
                                v-model.lazy="ach.routing_number"
                                class="w-input input"
                                v-validate="'required|numeric|max:45'"
                        />
                    </div>

                    <span v-show="errors.has('routing_number')"   class="status-block error-block field-error">Routing number is required and can only contain numbers</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="account_type">Account Type</label>

                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('account_type') }">
                        <dropdown
                                name="account_type"
                                id="account_type"
                                :options="accountTypes"
                                v-model="ach.account_type"
                                v-validate="'required'"
                        >
                        </dropdown>
                    </div>
                </div>
            </div>

        </div>
        <div style="text-align: right;">
            <a class="close-link" @click="$emit('close')">Cancel</a>
            <button :disabled="is_loading"
                    class="w-button primary-btn"
                    @click="savePaymentMethod"
            >Save Payment Method</button>
            <span v-show="is_loading" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </div>

    </div>
</template>



<script type="text/babel">
	import Dropdown from '../../assets/Dropdown.vue';
	import Status from '../../includes/Messages.vue';
	import Loader from '../../assets/CircleSpinner.vue';
	import api from '../../../assets/api.js';

	export default {
		name: "ForteAch",
		data() {
			return {
				ach: {
					first:'',
					last:'',
					account_type: '',
					account_number:'',
					routing_number:''

				},
				accountTypes:['Checking', 'Savings']
			}
		},

		filters:{
		},
		computed:{
		},
		components:{
			Dropdown,
			Status,
			Loader
		},
		created(){

		},

		methods: {

			async savePaymentMethod(){


				try {
					let status = await this.$validator.validateAll();
					if(!status) return;

					let data = this.ach;
					data.type = 'ach';
					this.$emit('save', data);
				} catch(err){
					console.log(err);
					this.errorSet(this.$options.name, err);

				}
			},

		},
		props:['is_loading']
	}
</script>
