<template>
    <span>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <div class="form-section" style="text-align: center;">

            <div class="horizontal-form-row" >
                 <label class="horizontal-form-label">Payment Type</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" :class="{'has-error': errors.has('product_id') }">
                        <span class="payment-type" :class="{active: active.type == 'ach'}" @click="setActive('ach')" v-if="connections.find(c => c.type == 'ach') ">
                            <div class="payment-type-img">
                                <img src="/img/ach_icon.svg"/>
                            </div>
                                ACH Bank Account
                        </span>
                    </div>
                    <div class="horizontal-form-input" :class="{'has-error': errors.has('product_id') }">
                         <span class="payment-type" :class="{active: active.type == 'card' }" @click="setActive('card')" v-if="connections.find(c => c.type == 'card')">
                                 <div class="payment-type-img">
                                    <img src="/img/cc_icon.svg"/>
                                 </div>
                                Credit Card
                            </span>

                    </div>
                </div>
                <span v-show="errors.has('product_id')" class="status-block error-block field-error">{{ errors.first('product_id') }}</span>
            </div>
        </div>

        <tsys-credit-card v-if="active.type == 'card' && active.name == 'tsys'" :lease_id="lease_id" :active="active" @save="savePaymentMethod" :is_loading="isLoading(this.$options.name)" ></tsys-credit-card>
        <authnet-credit-card v-if="active.type == 'card' && active.name == 'authorizenet'" :active="active" @save="savePaymentMethod" :is_loading="isLoading(this.$options.name)"></authnet-credit-card>
        <tenant-payments-credit-card v-if="active.type == 'card' && active.name == 'tenant_payments'" :lease_id="lease_id" :active="active" @save="savePaymentMethod" :is_loading="isLoading(this.$options.name)" ></tenant-payments-credit-card>

        <tsys-ach v-if="active.type == 'ach' && active.name == 'tsys'" :lease_id="lease_id" :active="active" @save="savePaymentMethod" :is_loading="isLoading(this.$options.name)"></tsys-ach>
        <forte-ach v-if="active.type == 'ach' && active.name == 'forte'" :active="active" @save="savePaymentMethod" :is_loading="isLoading(this.$options.name)"></forte-ach>
        <tenant-payments-ach v-if="active.type == 'ach' && active.name == 'tenant_payments'" :lease_id="lease_id" :active="active" @save="savePaymentMethod" :is_loading="isLoading(this.$options.name)"></tenant-payments-ach>


    </span>

</template>

<script type="text/babel">
    import Dropdown from '../assets/Dropdown.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';

    import TsysCreditCard from './paymentMethods/TsysCreditCard.vue';
    import AuthnetCreditCard from './paymentMethods/AuthnetCreditCard.vue';
    import TenantPaymentsCreditCard from './paymentMethods/TenantPaymentsCreditCard.vue';
    import TsysAch from './paymentMethods/TsysAch.vue';
    import ForteAch from './paymentMethods/ForteAch.vue';
    import TenantPaymentsAch from './paymentMethods/TenantPaymentsAch.vue';


    export default {
        name: "AddPaymentMethod",
        data() {
            return {
                connections:[],
                creditCard:{
                    first: '',
                    last:'',
                    company: '',
                    exp_mo:'',
                    exp_yr: '',
                    name_on_card: '',
                    card_number:'',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                ach: {
                    first:'',
                    last:'',
                    account_type: '',
                    account_number:'',
                    routing_number:''
                },
                active: {},
                accountTypes:['Checking', 'Savings']
            }
        },

        filters:{

            lineTotal(c){
                return c.qty * c.cost;
            }
        },
        computed:{
        },
        components:{
            Dropdown,
            Status,
            TsysCreditCard,
            AuthnetCreditCard,
            TenantPaymentsCreditCard,
            TsysAch,
            ForteAch,
            TenantPaymentsAch,
            Loader
        },
        created(){
            this.getPaymentMethodOptions();
        },

        methods: {
            getPaymentMethodOptions(){

                api.get(this, api.LEASES + this.lease_id + '/payment-method-options').then(r => {
                    this.connections = r.connections;
                    if(this.connections.length){
                        this.active =  (this.connections[0] == 'card') ? 'credit-card' : 'ach';
                    } else {
                        this.errorSet(this, "This account is not set up to accept online payments.");
                    }
                });

            },
            setAccountType(type){
                this.ach.account_type = type;
            },
            setActive(type){
                this.active = this.connections.find(c => c.type == type);
            },
            isActive(value){
                return this.active == value;
            },

            async savePaymentMethod(data){

                if(this.isLoading(this.$options.name)) return;
                this.errorClear(this.$options.name);
                this.startLoading(this.$options.name);

                let r = await api.post(this, api.LEASES + this.lease_id +  '/payment-methods', data);
                this.$emit('close');
                this.$emit('refetch');
            },

        },
        props:[ "lease_id" ]
    }
</script>
<style>

  form#addPaymentMethod {
    margin-top: 0;
  }

  ul.payment-type li a {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    background-color: white;
  }
  ul.payment-type li {
    width: 30%;
    cursor: pointer;
    text-align: center;
    margin: 0;
  }
  ul.payment-type li:first-child{
    margin-left: 20%
  }
  .form-col{
    margin-top: 40px;

  }


  .payment-type{
    background-color: #f5f7f8;
    padding: 10px 10px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 150px;
    height: 100px;

    border: 1px solid #f5f7f8;
  }

  .payment-type.active{
    border: 1px solid #00b2ce;
  }

  .payment-type .payment-type-img{
    height: 50px;
    width: 100%;
    position: relative;
    margin-bottom: 5px;
  }


  .payment-type .payment-type-img img {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    top: 50%;
    left: 50%;
    margin-left: -25px;
  }

  @media (max-width: 600px) {
    ul.payment-type li {
      width: 50%;
    }
    ul.payment-type li:first-child{
      margin-left: 0%
    }
    .payment-type{
      padding: 10px 5px;
      width: 115px;
    }
  }




</style>
