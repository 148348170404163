export default Object.freeze({
    icons: {
        contact: [
            { token: 'icon-contact', type: 'contact', icon: 'mdi-account' },
            { token: 'icon-lead', type: 'lead', icon: 'mdi-account' },
            { token: 'icon-business', type: 'business', icon: 'mdi-domain' },
            { token: 'icon-account', type: 'account', icon: 'mdi-web' },
            { token: 'icon-contact-default', type: 'default', icon: 'mdi-account' },
        ],
        spaces: [
            { token: 'icon-storage', type: 'storage', icon: 'mdi-facility-custom-3' },
            { token: 'icon-parking', type: 'parking', icon: 'mdi-rv-truck' },
            { token: 'icon-car-wash', type: 'car_wash', icon: 'mdi-car-wash' },
            { token: 'icon-cell-tower', type: 'cell_tower', icon: 'mdi-radio-tower' },
            { token: 'icon-billboard', type: 'billboard', icon: 'mdi-billboard' },
            { token: 'icon-apartment', type: 'apartment', icon: 'mdi-home-city-outline' },
            { token: 'icon-office', type: 'office', icon: 'mdi-desk' },
            { token: 'icon-wine', type: 'wine', icon: 'mdi-bottle-wine' },
            { token: 'icon-mailbox', type: 'mailbox', icon: 'mdi-mailbox-up-outline' },
            { token: 'icon-locker', type: 'locker', icon: 'mdi-locker' },
            { token: 'icon-warehouse', type: 'warehouse', icon: 'mdi-warehouse' },
            { token: 'icon-firearm', type: 'firearm', icon: 'mdi-pistol' },
            { token: 'icon-portable', type: 'portable', icon: 'mdi-custom-space-portable' },
            { token: 'icon-commercial', type: 'commercial', icon: 'mdi-custom-space-commercial' },
            { token: 'icon-space-default', type: 'default', icon: 'mdi-facility-custom-3' },
        ],
    }
});