<!-- BCT: Created Separate component for Refund Modal from UnitView.vue -->
<template>
  <div>
    <hb-modal
      size="medium"
      title="Refund Cleaning Deposit"
      v-model="refundAuctionFlag"
      v-if="refundAuctionFlag"
      show-help-link
    >
      <template v-slot:content>
        <hb-form label="Refund From">
            <hb-select
              v-model="refund.paymentFrom"
              :items="paymentTypes"
              item-value="value"
              item-text="text"
              name="payment_type"
              label="Select a payment"
              hide-details
            ></hb-select>
        </hb-form>

        <hb-form label="Amount">
           <hb-text-field
              :disabled="true"
              :error-messages="errors.collect('refund_amount')"
              :hide-details="!errors.collect('refund_amount').length"
              label="Refund Amount"
              flat
              id="refund_amount"
              name="refund_amount"
              data-vv-as="refund amount"
              v-model="refund.amount"
              v-validate="'required|integer'"
            >
            </hb-text-field>
            <span class="caption" style="color: #637381;" >(Total Available: {{ scheduled_auction.cleaning_deposit | formatMoney }})</span >
        </hb-form>

        <hb-form label="Refund To">
          <span class="text-capitalize">{{ refund_option.text }}</span>
        </hb-form>

      </template>
      <template v-slot:right-actions>
        <hb-btn
          color="primary"
          :disabled="isRefundAmount || disableRefund"
          @click="processRefund"
          >Refund</hb-btn
        >
      </template>
    </hb-modal>
  </div>
</template>

<script>
export default {
    name: 'RefundModal',
    components: {},
    props: ['scheduled_auction', 'refund', 'refund_option', 'disableRefund'],
    data() {
        return {
          refundAuctionFlag: false,
          paymentTypes: [ { text: "Cleaning Deposit", value: "cleaning_deposit" } ],
        }
    },
    computed: {
      isRefundAmount() {
        return this.refund.amount ? false : true;
      },
    },
    created() {
      this.refundAuctionFlag = true;
    },
    methods: {
        processRefund() {
          this.$emit('processRefundEvt');
        }
    },

};
</script>

<style></style>
