<!-- Note: label of hb-form is not used and is hidden because hb-form label width does not align with widths
of other labels where this component is used, should be changed after integerating hb-form at other places.
Some other props of hb-form are not used due to same reason. -->

<template>
  <hb-form
    :class="{'protected-property-item' : !showFormLabel}"
    class="protected-property-items-form-input-wrapper"
    :label="formLabel"
    :editable="!is_closed"
    :active.sync="active"
    :start-open="onlyEditable"
    :hide-actions="hideActions"
    :divider="false"
    @save="saveLeaseProtectedPropertyItems"
  >
    <template v-slot:tooltipBody v-if="showFormLabelTooltip">
      <div style="width: 250px">
        There are a number of items that are considered protected property, such as alcoholic beverages, certain kinds of documents, firearms, and pharmaceuticals. Tenants in Arizona and Nevada must disclose if they plan to store any of these items in their storage facility.
      </div>
    </template>
    <template v-slot:display>
      <div class="mt-0 pt-0" :class="{'ml-4': is_closed}" >
        <div v-if="!selected_protected_property_items.length" >
          <span class="hb-link">Click to Add</span>
        </div>
        <div
          v-else
          class="mb-2"
          v-for="(item) in protected_property_items"
          :key="item.protected_property_item_id"
        >
          <span v-if="item.id">{{ item.name }}
              <hb-tooltip v-if="item.note">
                <template v-slot:body>
                  <div style="width: 200px">
                    {{ item.note }}
                  </div>
                </template>
              </hb-tooltip>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:edit>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-0 ma-0 mt-0">
          <div class="mb-3 hb-form-description-text-night-light" v-if="showDescription">
            Select all property types that will be stored in the space.
          </div>
          <div
            v-for="item in protected_property_items"
            :key="item.protected_property_item_id"
          >
            <v-checkbox
              class="ml-n1 my-0 protected-item"
              data-name="selected_protected_property_items"
              flat
              hide-details
              id="selected_protected_property_items"
              name="selected_protected_property_items"
              single-line
              solo
              v-model="selected_protected_property_items"
              :value="item"
            >
              <template v-slot:label>
                <span>{{ item.name }}</span>
                <div v-if="item.note" class="pl-1">
                  <hb-tooltip>
                    <template v-slot:body>
                      <div style="width: 200px">
                        {{ item.note }}
                      </div>
                    </template>
                  </hb-tooltip>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
    </template>
  </hb-form>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "ProtectedPropertyItems",
  mixins: [notificationMixin],
  data() {
    return {
      active: "",
      protected_property_items: [],
      selected_protected_property_items: []
    };
  },

  props: {
    leaseId: {
      type: String,
      required: true,
    },
    onlyEditable: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    formLabel: {
      type: String,
      default: "Items Stored",
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    showSuccessMessage: {
      type: Boolean,
      default: true,
    },
    refetchOnSave: {
      type: Boolean,
      default: true,
    },
    showFormLabelTooltip: {
      type: Boolean,
      default: true,
    },
    showFormLabel: {
      type: Boolean,
      default: false,
    },
    is_closed: {
      type: Boolean,
      default: false
    }
  },

  async created() {
    await this.fetchProtectedPropertyItems();
    EventBus.$on("saveProtectedPropertyItems", this.saveLeaseProtectedPropertyItems);
  },
  

  async destroyed() {
    EventBus.$off("saveProtectedPropertyItems", this.saveLeaseProtectedPropertyItems);
  },

  methods: {
    async fetchProtectedPropertyItems() {
      const res = await api.get(this, `${api.LEASES}${this.leaseId}/protected-property-items`);
      this.protected_property_items = res;
      this.selected_protected_property_items = this.protected_property_items.filter((item) => item.id != null);    
    },

    async saveLeaseProtectedPropertyItems() {
      try {
        await api.post(this, `${api.LEASES}${this.leaseId}/protected-property-items`, {
          active_protected_items: this.selected_protected_property_items,
        });

        if (this.showSuccessMessage) {
          this.showMessageNotification({ type: 'success', description: 'Protected Property Items have been updated successfully.' });
        }

        this.active = '';

        if (this.refetchOnSave) {
          await this.fetchProtectedPropertyItems();
        }
      } catch (err) {
        this.showMessageNotification({ description: err });
      }
    }
  }
};

</script>

<style>
.protected-property-item .hb-aviary-form-label-150 {
  display: none !important;
}

.protected-item .v-label {
  margin-top: 0px !important;
}

.protected-property-items-form-input-wrapper .v-input--selection-controls:not(.v-input--is-label-active) .v-input--selection-controls__input > i.v-icon, .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-label-active) .v-input--selection-controls__input > i.v-icon {
  color: #DFE3E8 !important;
}
</style>
