<template>
    <hb-modal v-model="dialog" show-help-link :size="canEdit ? 'large' : 'small'" title="Edit Service" @close="$emit('close')">
        <template v-slot:subheader v-if="canEdit">
            <span v-if="service.last_billed && service.recurring">
                Billed Through: {{service.last_billed | formatDateServices}}<br />
                Any changes will affect billing as of this date.
            </span>
            <span v-else>
                This service has not been billed yet.
            </span>
        </template>
        <template v-slot:content>
            <v-container class="ma-0 pa-0" v-if="canEdit">
               <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Product Name
                    </v-col>
                    <v-col cols="9" class="gray-color col-cursor-pointer px-7 py-3">
                        {{product.name}}
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Description
                    </v-col>
                    <v-col cols="9" class="col-cursor-pointer px-4 py-1">
                        <v-text-field
                            v-model="service.description"
                            label="Enter in a description. It will be visible on the invoice."
                            v-validate="'max:1000'"
                            data-vv-name="description"
                            single-line
                            dense
                            solo
                            flat
                            :hide-details="!errors.collect('description').length"
                            :error-messages="errors.collect('description')"
                            >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Price
                    </v-col>
                    <v-col cols="9" class="col-cursor-pointer px-2 py-1">
                        <v-text-field
                            v-model="service.price"
                            label="Enter Price Amount"
                            v-validate="'required|decimal:2|max_value:999099|min_value:0'"
                            data-vv-name="price"
                            single-line
                            prepend-inner-icon="mdi-currency-usd"
                            dense
                            solo
                            flat
                            :hide-details="!errors.collect('price').length"
                            :error-messages="errors.collect('price')"
                            >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pl-6 lease-column-1">
                        Quantity
                    </v-col>
                    <v-col cols="9" class="col-cursor-pointer px-4 py-1">
                        <v-text-field
                            label="Enter Quantity"
                            v-model="service.qty"
                            v-validate="'required|integer|min_value:1|max_value:9999'"
                            data-vv-name="quantity"
                            single-line
                            dense
                            solo
                            flat
                            :hide-details="!errors.collect('quantity').length"
                            :error-messages="errors.collect('quantity')"
                            >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col cols="3" class="row-title pt-4 pl-6 lease-column-1">
                        Start Date
                    </v-col>
                    <v-col cols="9" class="col-cursor-pointer px-4 py-1">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" sm="5" class="ma-0 mt-1 pa-0" v-if="!service.last_billed">
                                <hb-date-picker
                                label="MM/DD/YYYY"
                                v-validate="'required|min:1'"
                                :dense="true"
                                :min="todaysDate"
                                data-vv-as="start_date"
                                data-vv-name="Start Date"
                                v-model="service.start_date"
                                name="start_date"
                                :hide-details="!errors.collect('start_date').length"
                                :error-messages="errors.collect('start_date')"
                                ></hb-date-picker>
                                <v-divider class="mx-3 ml-sm-10 mr-sm-4"></v-divider>
                            </v-col>
                            <v-col v-else cols="12" sm="5" class="ma-0 mt-3 pa-0 px-3">
                                {{service.start_date | formatDateServices}}
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 px-3 mb-3">
                                <v-checkbox
                                    v-model="service.recurring"
                                    label="Is this a recurring charge?"
                                    hide-details
                                    class="py-0 mt-4 mb-0"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider v-if="service.recurring"></v-divider>
                <v-row v-if="service.recurring" class="ma-0 pa-0">
                    <v-col cols="3" class="pt-4 pl-6 lease-column-1">
                        <div class="row-title">End Date</div>
                        <div class="gray-color">Optional, leave blank to bill indefinitely</div>
                    </v-col>
                    <v-col cols="9" class="col-cursor-pointer px-4 py-1 my-0">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" sm="5" class="ma-0 mt-1 pa-0">
                                <hb-date-picker
                                label="MM/DD/YYYY"
                                :dense="true"
                                :min="dayAfterStartDate"
                                data-vv-as="end_date"
                                data-vv-name="End Date"
                                v-model="service.end_date"
                                name="end_date"
                                :hide-details="!errors.collect('end_date').length"
                                :error-messages="errors.collect('end_date')"
                                ></hb-date-picker>
                                <v-divider class="mx-3 ml-sm-10 mr-sm-4"></v-divider>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 mt-4 px-3 mb-3">
                                <v-checkbox
                                    v-model="service.prorate"
                                    label="Prorate this charge"
                                    hide-details
                                    class="py-0 mt-0"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
            <hb-modal size="medium" show-help-link v-model="confirm_delete" title="Delete Service">
                <template v-slot:content>

                    <div class="pa-6">Are you sure you want to delete this service?</div>

                </template>
                <template v-slot:actions>

                    <hb-btn color="destructive" @click="deleteServiceConfirm">Delete Service</hb-btn>

                </template>
            </hb-modal>
        </template>
        <template v-slot:actions>
            <span v-if="canEdit">
                <hb-btn class="mr-2" color="destructive" @click="confirm_delete = true">Delete Service</hb-btn>
                <hb-btn color="primary" @click="save">Update</hb-btn>
            </span>
        </template>
    </hb-modal>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import { EventBus } from '../../EventBus.js';
    import Loader from '../assets/CircleSpinner.vue';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import moment from 'moment';

    export default {
        name: "EditServiceDialog",
        data() {
            return {
                errorName: false,
                productsLoading: false,
                service:{
                    id: null,
                    product_id: '',
                    price: '',
                    qty: 1,
                    lease_id: '',
                    recurring: false,
                    prorate: false,
                    prorate_out: false,
                    description: '',
                    start_date: '',
                    end_date: '',
                },
                products:[],
                product:{
                    name: '',
                    id: ''
                },
                dayAfterStartDate: '',
                todaysDate: '',
                tryingToSaveWithoutProduct: false,
                confirm_delete: false,
            }
        },
        components:{
            HbDatePicker,
            Loader,
            Status,
        },
        created(){
            if(this.selected && this.selected.id){
                this.service = JSON.parse(JSON.stringify(this.selected));
                this.service.start_date = moment(this.service.start_date).format('YYYY-MM-DD');
                this.dayAfterStartDate = moment(this.service.start_date).startOf('day').add(1, 'day').format('YYYY-MM-DD');
                this.service.end_date = this.service.end_date ? moment(this.service.end_date).format('YYYY-MM-DD') : 'null';
                this.product = JSON.parse(JSON.stringify(this.selected.Product));
            } else {
                this.service.start_date = moment().startOf('day').format('YYYY-MM-DD');
            }

            this.searchProducts();

            this.todaysDate = moment().startOf('day').format('YYYY-MM-DD');
        },
        computed:{
            canEdit(){
                return  this.service.recurring || !this.service.last_billed;
            },
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            productNames(){
                return this.products.map(function(p){ return p.name });
            },
            hasDate(){
                return (this.date !== 'undefined');
            }
        },
        filters:{

        },
        methods: {

            save(){
                var _this = this;

                this.$validator.validateAll().then(status =>{
                    if(!status) throw "error";

                        var service = {
                            name: this.product.name,
                            price: this.service.price,
                            qty: this.service.qty,
                            recurring: this.service.recurring,
                            prorate: this.service.recurring ? this.service.prorate : false,
                            prorate_out: this.service.prorate_out ? this.service.prorate_out : false,
                            description: this.service.description,
                            start_date: this.service.start_date,
                            end_date: this.service.recurring && this.service.end_date ? this.service.end_date : null,
                        }
			// Old Code: end_date: this.service.recurring && this.service.end_date ? moment(this.service.end_date).clone().startOf('day').format('YYYY-MM-DD'): null;
                        api.put(_this, api.LEASES + _this.lease_id + '/services/' + _this.service.id, service).then(r => {
                            EventBus.$emit('service_updated');
                            this.dialog = false;
                        });
                }).catch(function(err){
                    _this.errorSet(_this.$options.name, "You have errors on your form.  Please fix them before continuing");
                });

            },

            searchProducts(){

                this.productsLoading = true;
                var params = {
                    type: 'product',
                    all: true,
                    lease_id: this.lease_id
                };

                api.get(this, api.PRODUCTS + 'list', params).then(r => {
                    this.productsLoading = false;
                    this.products = r.products;
                });



            },

	        setPrice(product){
                this.tryingToSaveWithoutProduct = false;
                this.service.price = this.product.price;
                this.service.description = this.product.description;


            },

            deleteServiceConfirm(){
                api.delete(this, api.SERVICES, this.service.id).then(r => {
                    EventBus.$emit('service_updated');
                    this.dialog = false;
                })
            }

        },
        watch:{
            product(p){

                this.service.product_id = p.id;
                if(!this.service.id){
                    this.service.price = p.price;
                }
            },
            'service.start_date'(){
                this.dayAfterStartDate = moment(this.service.start_date).startOf('day').add(1, 'day').format('YYYY-MM-DD');
            },
            'service.recurring'(){
                if(this.service.recurring === 1 && !this.service.end_date){
                    this.service.end_date = moment(this.service.start_date).startOf('day').add(1, 'day').format('YYYY-MM-DD');
                } else if(this.service.recurring === 0){
                    this.service.end_date = null;
                } else {
                    
                }
            }
        },
        props:[
            'selected',
            'lease_id',
            'property_id',
            'value'
        ]
    }
</script>



<style scoped>

.row-title {
    font-weight: 500;
}

.lease-column-1{
    background:#F4F6F8;
}

.gray-color{
    color:#637381;
}

.border-bottom {
    border-bottom: 1px #e0e0e0 solid;
}

.secondary-button{
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border:1px solid #C4CDD5;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}

.v-item--active .activeSelected {
    color: #101318;
    font-weight: 500;
}

</style>

