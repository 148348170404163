<template>
    <v-text-field
        v-model="cvv"
        v-validate="`required|numeric|length:${maxlength}`"
        :data-vv-name="label"
        :data-vv-as="label"
        :error-messages="errors.collect(`${label}`)"
        :label="`${label}*`"
        id="card-cvv"
        name="card-cvv"
        autocomplete="cc-card-cvv2"
        :maxlength="maxlength"
        counter
        outlined
        :rules="cvv2Rules"
        @input="updateCVV($event)"
    >
    </v-text-field>
</template>

<script type="text/babel">
import creditCardType from 'credit-card-type';
import { mapGetters, mapMutations } from "vuex";

    export default {
        name: "NewCardCVV",
        data: () => (
        {
            cvv: "",
            maxlength: 3,
            label: 'CVV',
        },
        {
            cid: "",
            maxlength: 4,
            label: 'CID',
        }
        ),
        destroyed() {
        },
        methods: {
             ...mapMutations({
                setCheckErrors: 'paymentsStore/setCheckErrors',
                setPaymentMethod: 'paymentsStore/setPaymentMethod',
            }),
            updateCVV : _.debounce(async function(value) {
                let cvv = this.cvv.replace(/\D/g,'');
                this.setPaymentMethod({property: 'cvv2', propertyValue: this.cvv});
                this.$emit('card-cvv-updated', cvv);
            }, 1000),
            validateCardCVV(){
                return this.$validator.validateAll(); 
            },
            cvv2Validator(cvv){
            return new RegExp(/^[0-9]+$/).test(cvv)
        },
        },
        computed: {
            ...mapGetters({
              getErrors: 'paymentsStore/getErrors',
              payment_method: 'paymentsStore/getPaymentMethod',
            }),
            card_number(){
                return this.payment_method.card_number || '';
            },
            cvv2Rules() {
                return [
                    (v) => this.cvv2Validator(v) || 'The CVV field may only contain numeric characters',
                    (v) => v.length === 3 || v.length === 4 || 'The CVV length must be 3',
                ]
            }
        },
        watch: {
            card_number() {
                if(this.card_number) {
                    let card_results = creditCardType(this.card_number);
                    if(card_results && card_results.length){
                        let card = card_results[0];
                        if(card.code){
                            this.label = `${card.code.name}`;
                            this.maxlength = card.code.size;
                        }
                    }
                }
            },
            method(val){
                if(val !== 'new'){
                    this.$validator.reset();
                }
            }
        },
        props: ['method'],
    };
</script>

<style>
</style>
