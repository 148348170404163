// import SettingsIndex from '../components/settings/Index.vue'
// import SettingsBilling from '../components/settings/BillingSettings.vue'
// import SettingsApiKeys from '../components/settings/ApiKeysSettings.vue'
// import SettingsQuickbooks from '../components/settings/QuickbooksSettings.vue'
// import SettingsLinkQB from '../components/settings/LinkQB.vue'
// import SettingsQBCallback from '../components/settings/QBCallback.vue'
// import SettingsCategories from '../components/settings/CategoriesSettings.vue'
// import SettingsSpaceTypes from '../components/settings/SpaceTypes.vue'
// import SettingsProducts from '../components/settings/Products.vue'
// import SettingsTriggers from '../components/settings/Triggers.vue'
// import SettingsBrivo from '../components/settings/BrivoSettings.vue'
// import SettingsLinkBrivo from '../components/settings/LinkBrivo.vue'
// import SettingsNotifications from '../components/settings/NotificationSettings.vue'
// import SettingsPromotions from '../components/settings/PromotionSettings.vue'
// import SettingsInsurance from '../components/settings/InsuranceSettings.vue'
// import SettingsTemplates from '../components/settings/Templates.vue'
// import SettingsAccounting from '../components/settings/Accounting.vue'
// import SettingsRoles from '../components/settings/Roles.vue'


export default [
    // {
    //     path: '/settings/automation',
    //     name : 'automation',
    //     component: () => import('../components/settings/Automation/Index.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         hasAccess: ['admin']
    //     },
    //     children: [{
    //         path: 'groups',
    //         name : 'automation-groups',
    //         component: () => import('../components/settings/Automation/Groups.vue'),
    //         meta: {
    //             requiresAuth: true,
    //             hasAccess: ['admin']
    //         }
    //     },{
    //         path: 'groups/group/:id',
    //         name : 'automation-group-properties',
    //         component: () => import('../components/settings/Automation/GroupProperties.vue'),
    //         meta: {
    //             requiresAuth: true,
    //             hasAccess: ['admin']
    //         }
    //     }]
    // },
    {
        path: '/settings',
        name : 'settings',
        component: () => import('../components/settings/Index.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
          layout: 'master'
        },
        children: [
            {
              path: "app/:gds_id",
              name: "setting-gds",
              component: () => import('../components/settings/Apps.vue'),
              meta: {
                requiresAuth: true,
                hasAccess: ['admin'],
                layout: 'master'
              }
            },
            {
                path: 'automation',
                name : 'automation',
                component: () => import('../components/settings/Automations/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                },
                children: [{
                    path: 'index',
                    name : 'settings-automations',
                    component: () => import('../components/settings/Automations/Index.vue'),
                    meta: {
                        requiresAuth: true,
                        hasAccess: ['admin'],
                      layout: 'master'
                    }
                },{
                    path: 'groups/group/:id',
                    name : 'automation-group-properties',
                    component: () => import('../components/settings/Automation/GroupProperties.vue'),
                    meta: {
                        requiresAuth: true,
                        hasAccess: ['admin'],
                      layout: 'master'
                    }
                }]
            },
            {
                path: 'billing',
                name : 'settings-billing',
                component:  () => import('../components/settings/BillingSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'api-keys',
                name : 'settings-api-keys',
                component: () => import('../components/settings/ApiKeysSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'quickbooks',
                name : 'settings-quickbooks',
                component: () => import('../components/settings/QuickbooksSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            // {
            //     path: 'link-qb',
            //     name : 'settings-link-qb',
            //     component: SettingsLinkQB,
            //     meta: {
            //         requiresAuth: true,
            //         hasAccess: ['admin'],
            //         layout: 'unauthenticated'
            //     }

            // },
            // {
            //     path: 'qb-callback',
            //     name : "settings-qb-callback",
            //     component: SettingsQBCallback,
            //     meta: {
            //         requiresAuth: true,
            //         layout: 'unauthenticated',
            //         hasAccess: ['admin']
            //     }

            // },
            {
                path: 'brivo',
                name : 'settings-brivo',
                component: () => import('../components/settings/BrivoSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },

            {
                path: 'link-brivo',
                name : 'settings-link-brivo',
                component: () =>  import('../components/settings/LinkBrivo.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'categories',
                name : 'settings-categories',
                component: () => import('../components/settings/CategoriesSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'space-types',
                name : 'settings-space-types',
                component: () => import('../components/settings/SpaceTypes.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'products',
                name : 'settings-products',
                component: () => import('../components/settings/Products.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'notifications',
                name : 'settings-notifications',
                component: () => import('../components/settings/NotificationSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'triggers',
                name : 'settings-triggers',
                component: () => import('../components/settings/Triggers.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }
            },
            // {
            //     path: 'promotions',
            //     name : 'settings-promotions',
            //     component: () => import('../components/settings/PromotionSettings.vue'),
            //     meta: {
            //         requiresAuth: true,
            //         hasAccess: ['admin']
            //     }

            // },
            {
                path: 'insurance',
                name : 'settings-insurance',
                component: () => import('../components/settings/InsuranceSettings.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'templates',
                name : 'settings-templates',
                component:  () => import('../components/settings/Templates.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'templates/:template_id',
                name : 'settings-templates-single',
                component:  () => import('../components/settings/configureLease/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            },
            {
                path: 'roles',
                name : 'settings-roles',
                component:  () => import('../components/settings/Roles.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }
            },
            {
                path: 'access-control',
                name : 'settings-access-control',
                component:  () => import('../components/settings/AccessControl/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }
            },
            {
                path: 'payment-configuration',
                name : 'settings-payment-configuration',
                component: () => import('../components/settings/PaymentConfiguration/index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }
            },
            {
                path: 'sso-configuration',
                name : 'settings-sso',
                component: () => import('../components/settings/SSO/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }
            },
            {
              path: 'national-accounts',
              name : 'settings-national-account',
              component: () => import('../components/settings/NationalAccounts/Index.vue'),
              meta: {
                  requiresAuth: true,
                  hasAccess: ['admin'],
                layout: 'master'
              }
            },
            {
                path: 'lease-configuration',
                name : 'settings-lease-configuration',
                component: () => import('../components/settings/PropertyTemplates/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }
            },
            {
                path: 'properties',
                name : 'settings-properties',
                component: () => import('../components/settings/Properties/Index.vue'),
                meta: {
                    requiresAuth: true,
                    hasAccess: ['admin'],
                  layout: 'master'
                }

            }
        ]
    }
]
