<template>
  <div>
      <v-row>
        <v-col class="pb-0">
          <strong class="mr-1">Payment Property</strong>
           <hb-tooltip>
              <template v-slot:item>
                <hb-icon small mdi-code="mdi-information"></hb-icon>
              </template>
              <template v-slot:body>
                Location where payment is received.
              </template>
            </hb-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <HbSelect
              :value="selectedPropertyId"
              :items="properties"
              placeholder="Select Property"
              id="source_property"
              name="source_property"
              item-value="id"
              :clearable="false"
              @change="selectedPropertyUpdated($event)"
              :backgroundColor="'#FFF9DA'"
              item-color="#000000"
              color="#000000"
              class="font-weight-medium"
          >
            <template v-slot:selection="data">
                <div class="hb-input-overflow pl-4">
                    {{data.item.number}} -  {{data.item.Address.city}}
                </div>
            </template>
            <template v-slot:item="data">
              <div>
                {{data.item.number}} - {{data.item.Address.city}}
              </div>
            </template>
          </HbSelect>
        </v-col>
      </v-row>
  </div>
</template>


<script type="text/babel">
  import { mapGetters, mapMutations } from "vuex";
import payment from '../../../assets/api/payment';
  import { EventBus } from '../../../EventBus';

  export default {
    data() {
      return {
      }
    },
    computed:{
      ...mapGetters({
        properties: 'propertiesStore/filtered',
        payment: 'paymentsStore/getPayment',
      }),

      selectedPropertyId(){
        return this.payment.property_id;
      },
    },
    props: [],
    created () {
    },
    methods: {
      ...mapMutations({
        setPayment: 'paymentsStore/setPayment',
      }),
      async getErrors() {
        return await this.$validator.validateAll();
      },
      selectedPropertyUpdated(selectedProperty){
        EventBus.$emit('handleSourcePropertyUpdate', selectedProperty,null,null,true);
      }
    }
  }
</script>

<style scoped>
  .hb-select-width-370 {
    max-width: 100% !important;
  }
</style>
