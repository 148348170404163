<template>
    <div >
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>


        <div v-if="!payment.id">
            <span v-if="overPayment">

                <div class="info-section" >
                    There will be an overpayment of {{ overPayment | formatMoney}} remaining on this account.
                </div>
                <br />
                <br />
            </span>
            <div class="form-section payment-summary skinny-row" >
                <h4 class="notification">Payment Totals</h4>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Date:</span>
                    <span class="horizontal-form-text">{{payment.date | formatDate }}</span>

                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Amount:</span>
                    <span class="horizontal-form-text">{{payment.amount | formatMoney }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Method:</span>
                    <span class="horizontal-form-text" >

                        <img class="cc-icon" v-if="payment.method.type == 'check'"  src="/img/cc_icons/64/cheque.png" />
                        <img class="cc-icon" v-if="payment.method.type == 'cash'"  src="/img/cc_icons/64/dollar.png" />
                        <img class="cc-icon" v-if="payment.method.type == 'ach'"  src="/img/cc_icons/64/ach.png" />
                        <img class="cc-icon" v-if="payment.method.type == 'card'"  :src="payment.method | getCCIcon" />

                          <span class="card-type" v-if="payment.method.type == 'check'" >Check
                              <span v-if="payment.ref_name || payment.number"> ( {{payment.ref_name}}
                              <span v-if="payment.number">#{{payment.number}}</span> )</span>
                          </span>

                         <span class="card-type" v-if="payment.method.type == 'cash'" >Cash</span>
                         <span class="card-type" v-if="payment.method.type == 'ach' || payment.method.type == 'card'" >
                             *{{payment.method.card_end}} ({{payment.method.first}} {{payment.method.last}})
                         </span>
                    </span>


                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Notes:</span>
                    <span class="horizontal-form-text">{{payment.notes }}</span>
                </div>
            </div>


            <div class="form-section"v-if="isAdmin" v-show="totalApplied > 0" >


                <h4 class="notification">This payment will be applied to the following invoices:</h4>
                <br />
                <br />
                <div class="table">
                    <div class="table-row" v-for="i in invoices" v-if="i.payment_amount > 0">
                        <div class="table-cell">Invoice #{{i.number}}</div>
                        <div class="table-cell">{{i.payment_amount | formatMoney}}</div>
                    </div>
                </div>


            </div>

            <div class="modal-footer">


                <button class="w-button tertiary-btn" @click.prevent="$emit('close')">Cancel</button> &nbsp;&nbsp;

                <div v-show="showPaymentButton" style="display: inline-block; vertical-align: middle" v-if="payment.method.type.toLowerCase() == 'google' && !payment.method.id" id="google-button">
                </div>

                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
                <br />
                <br />
                <span class="subdued"><span class="icon"></span>&nbsp; Your account will be charged</span>&nbsp;&nbsp;&nbsp;&nbsp;
            </div>

        </div>


        <div class=" payment-summary text-center skinny-row" v-if="payment.id">

            <div class="text-center form-section" >
                <h3 class="success" v-if="paymentType == 'open'">Payment Applied Successfully</h3>
                <h3 class="success" v-if="paymentType == 'new'">Payment Successful</h3>
                <span class="icon confirmation-check success" >
                    
                </span>

                <div v-if="paymentType == 'new'">
                    <p>Your payment of {{payment.amount | formatMoney}} as been processed.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

                <div v-if="paymentType == 'open'">
                    <p>Your payment has been applied to the selected open invoices.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

            </div>
            <button class="w-button secondary-btn float-right" @click.prevent="$emit('reload')"> Enter Another Payment</button>
            <button class="w-button primary-btn float-left" @click="$emit('finish')">Close Window</button>

        </div>


    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "ConfirmPayment",
        data() {
            return {
                confirmed: false,
                showPaymentButton: '',
                paymentToken: '',
            }
        },
        components:{
            Status,
            Loader
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            }),
            totalApplied(){
                var total =  this.invoices.reduce(function(a,b){
                    return a += Math.round(b.payment_amount * 100);
                }, 0);
                return total / 100;
            },
            overPayment(){
                return ((this.payment.amount * 100) / 100) - this.totalApplied;

            }
        },
        filters:{

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
            },
        },
        mounted(){
            this.setupGoogle();
        },
        methods:{


            setupGoogle(){

                var paymentToken = '';
                const baseRequest = {
                    apiVersion: 2,
                    apiVersionMinor: 0
                };
                const tokenizationSpecification = {
                    type: 'PAYMENT_GATEWAY', // Verify This
                    parameters: {
                        'gateway': 'example', // TODO Need to change this to gateway name
                        'gatewayMerchantId': this.googleGatewayMerchantId
                    }
                };
                const allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"];
                const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

                const baseCardPaymentMethod = {
                    type: 'CARD',  // TODO Verify this is correct
                    parameters: {
                        allowedAuthMethods: allowedCardAuthMethods,
                        allowedCardNetworks: allowedCardNetworks
                    }
                };

                const cardPaymentMethod = Object.assign(
                        {
                            tokenizationSpecification: tokenizationSpecification
                        },
                        baseCardPaymentMethod
                );

                const paymentsClient =
                        new google.payments.api.PaymentsClient({
                            environment: this.environment.toUpperCase()
                        });

                const isReadyToPayRequest = Object.assign({}, baseRequest);
                isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];

                paymentsClient.isReadyToPay(isReadyToPayRequest)
                        .then(response => {
                            if (response.result) {
                                this.showPaymentButton = true;
                            }
                        })
                        .catch(function(err) {
                            // show error in developer console for debugging
                            console.error(err);
                        });

                const button =
                        paymentsClient.createButton({
                            onClick: () => {
                                this.savePayment(paymentToken)
                            }
                        });
                document.getElementById('google-button').appendChild(button);
                const paymentDataRequest = Object.assign({}, baseRequest);

                paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];

                paymentDataRequest.transactionInfo = {
                    totalPriceStatus: 'FINAL',
                    totalPrice: this.payment.amount,
                    currencyCode: 'USD'
                };

                paymentDataRequest.merchantInfo = {
                    merchantName: this.googleMerchantName
                };

                paymentsClient.loadPaymentData(paymentDataRequest).then(function(paymentData){
                    // if using gateway tokenization, pass this token without modification
                    this.paymentToken = paymentData.paymentMethodData.tokenizationData.token;
                }).catch(function(err){
                    // show error in developer console for debugging
                    console.error(err);
                });
                paymentsClient.prefetchPaymentData(paymentDataRequest);



            },

            savePayment(paymentToken){

                if(!this.isLoading(this.$options.name) && !this.confimed){

                    var params = {
                        payment: this.payment,
                        invoices: this.invoices,
                        lease_id: this.lease_id,
                        paymentToken: paymentToken
                    };

                    api.post(this, api.PAYMENTS, params).then(r => {
                        this.confimed = true;
                        this.payment.id = r.payment_id;
                        EventBus.$emit('lease_payment_saved');
                    });

                }
            }
        },
        props: {
            invoices: {
                type: Array
            },
            payment: {
                type: Object
            },
            paymentType:{
                type: String
            },
            lease_id:{
                type: String
            },
            onboard: {
                type: Boolean,
                default: false
            },
            global: {
                type: Boolean,
                default: false
            },
            googleGatewayMerchantId:{
                type: String,
                required: true
            },
            googleMerchantName:{
                type: String,
                required: true
            },
            environment: {
                type: String,
                required: true
            }
        },
    }
</script>

<style scoped>
    label{
        margin: 0;
        padding: 0;
    }
    img.cc-icon {
        border: 1px solid #e2e2e2;
        border-radius: 2px;
        width: 32px;
        margin-right:5px;;
    }

    .confirmation-check{
        font-size: 115px;
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 115px;
    }

</style>
