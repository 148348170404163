<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div class="d-flex align-top hb-mini-profile-overflow-handler" @click.capture="!disableClick ? $emit('click', $event) : ''">
        <div
            @click="!disableAvatarClick ? $emit('avatar-click', $event) : ''"
            class="hb-mini-profile-left-side"
            :class="{ 'hb-mini-profile-pointer' : !disableAvatarClick }"
        >
            <hb-tooltip :disabled="(!type && !spaceType) || type === 'space'">
                <template v-slot:item>
                    <v-avatar
                        v-if="thumbnail && !disableThumbnail"
                        height="48"
                        color="#ffffff"
                        class="hb-mini-profile-avatar-wrapper"
                        :class="{ 'hb-mini-profile-avatar-wrapper-thumbnail-verified' : (type === 'contact' || type === 'lead') && idVerified  }"
                    >
                        <img
                            alt="thumbnail"
                            :src="thumbnail"
                        >
                    </v-avatar>

                    <HbSpaceIcon v-else-if="type === 'space'" avatar :type="spaceType" enable-tooltip />

                    <v-avatar
                        v-else-if="type && (type === 'contact' || type ==='lead')"
                        height="48"
                        color="#ffffff"
                        class="hb-mini-profile-avatar-wrapper"
                        :class="{ 'hb-mini-profile-avatar-wrapper-thumbnail-verified' : idVerified }"
                    >
                        <HbIcon mdi-code="mdi-account" />
                    </v-avatar>

                    <v-avatar
                        v-else-if="type && type === 'business'"
                        height="48"
                        color="#ffffff"
                        class="hb-mini-profile-avatar-wrapper"
                    >
                        <HbIcon mdi-code="mdi-domain" />
                    </v-avatar>

                    <v-avatar
                        v-else-if="type && type === 'account'"
                        height="48"
                        color="#ffffff"
                        class="hb-mini-profile-avatar-wrapper"
                    >
                        <HbIcon mdi-code="mdi-web" />
                    </v-avatar>

                    <v-avatar
                        v-else
                        height="48"
                        color="#ffffff"
                        class="hb-mini-profile-avatar-wrapper"
                        :class="{ 'hb-mini-profile-avatar-wrapper-thumbnail-verified' : idVerified }"
                    >
                        <HbIcon mdi-code="mdi-help-circle" />
                    </v-avatar>
                </template>
                <template v-slot:body>
                    <span class="text-capitalize">{{ type }}</span>
                </template>
            </hb-tooltip>

            <v-badge
                v-if="(type === 'contact' || type === 'lead') && idVerified"
                offset-y="24"
                offset-x="51"
                color="#3D8FFF"
                class="hb-mini-profile-verified-badge"
            >
                <template v-slot:badge>
                    <hb-tooltip>
                        <template v-slot:item>
                            <HbIcon
                                small
                                color="white"
                                mdi-code="mdi-account-check"
                                pointer
                                style="position:relative;top:-2px;"
                            />
                        </template>
                        <template v-slot:body>
                            ID Verified
                        </template>
                    </hb-tooltip>
                </template>
            </v-badge>

        </div>
        <div
            class="px-2 hb-mini-profile-wrapper hb-mini-profile-text-light hb-mini-profile-default-font-size"
        >
            <div class="hb-mini-profile-first-row d-flex align-center">
                <span class="hb-mini-profile-title font-weight-medium" :style="doNotRent || (type !== 'space' && (bankrupt || military)) ? 'margin-right: 5px;' : 'margin-right: 7px;'">{{ type === 'space' && title ? 'Space #' + title : title }}</span>
                <span v-if="doNotRent && $vuetify.breakpoint.smAndUp" class="hb-mini-profile-badge hb-mini-profile-do-not-rent-wrapper" :class="{ 'mr-1' : type === 'space' }">
                    <hb-tooltip>
                        <template v-slot:item>
                            <hb-icon
                                small
                                color="#101318"
                            >
                                mdi-account-cancel
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            Do Not Rent
                        </template>
                    </hb-tooltip>
                </span>
                <span v-if="type !== 'space' && bankrupt && $vuetify.breakpoint.smAndUp" class="hb-mini-profile-badge hb-mini-profile-is-bankruptcy-wrapper">
                    <hb-tooltip>
                        <template v-slot:item>
                            <hb-icon
                                small
                                color="#101318"
                            >
                                mdi-custom-bankrupt
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            Bankruptcy
                        </template>
                    </hb-tooltip>
                </span>
                <span v-if="type !== 'space' && military && $vuetify.breakpoint.smAndUp" class="hb-mini-profile-badge hb-mini-profile-military-wrapper">
                    <hb-tooltip>
                        <template v-slot:item>
                            <hb-icon
                                small
                                color="#101318"
                            >
                                mdi-custom-active-military
                            </hb-icon>
                        </template>
                        <template v-slot:body>
                            Military
                        </template>
                    </hb-tooltip>
                </span>

                <HbStatus
                    v-if="!disableStatus && status"
                    :status="status"
                    class="hb-mini-profile-status"
                    :style="type !== 'space' && (doNotRent || bankrupt || military) ? 'padding-left: 5px;' : ''"
                    @emitType="emitType"
                />
                <span v-if="type !== 'space' && !disableReport && ($slots.reportTooltipHeader || $slots.reportTooltipBody || pastDueAmount)" class="hb-mini-profile-report pl-1">
                    <hb-tooltip :disabled="!$slots.reportTooltipHeader && !$slots.reportTooltipBody">
                        <template v-slot:item>
                            <HbIcon
                                small
                                class="ml-1"
                                mdi-code="mdi-poll-box-outline"
                            />
                            <span v-if="pastDueAmount" class="ml-1 hb-mini-profile-dashed">Past Due: {{ pastDueAmount }}</span>
                        </template>
                        <template v-slot:header>
                            <slot name="reportTooltipHeader"></slot>
                        </template>
                        <template v-slot:body>
                            <slot name="reportTooltipBody"></slot>
                        </template>
                    </hb-tooltip>
                </span>
            </div>
            
            <div class="hb-mini-profile-info">
                <div
                    v-if="(type === 'space' && spaceIsOccupied) || type !== 'space'"
                    class="hb-mini-profile-row hb-mini-profile-default-font-size"
                    :class="!phone || canText === null ? 'hb-mini-profile-row-contact-first-row' : ''"
                    :style="!phone || (canText !== null && canText) ? 'position:relative;top:3px;' : ''"
                >
                    <span
                        v-if="businessName"
                        class="hb-mini-profile-item"
                    >
                        <hb-tooltip>
                            <template v-slot:item>
                                <HbIcon
                                    small
                                    class="mr-1"
                                    mdi-code="mdi-domain"
                                />
                            </template>
                            <template v-slot:body>
                                Business
                            </template>
                        </hb-tooltip>{{ businessName }}</span>
                    <span
                        v-if="representativeName"
                        class="hb-mini-profile-item"
                    >
                        <hb-tooltip>
                            <template v-slot:item>
                                <HbIcon
                                    small
                                    class="mr-1"
                                    mdi-code="mdi-account-tie"
                                />
                            </template>
                            <template v-slot:body>
                                {{ type === 'account' ? 'Account Representative' : 'Representative' }}
                            </template>
                        </hb-tooltip>{{ representativeName }}</span>
                    <span
                        v-if="tenantName && type === 'space'"
                        class="hb-mini-profile-item"
                    >
                        <hb-tooltip>
                            <template v-slot:item>
                                <HbIcon
                                    small
                                    class="mr-1"
                                    mdi-code="mdi-account"
                                />
                            </template>
                            <template v-slot:body>
                                Tenant
                            </template>
                        </hb-tooltip>{{ tenantName }}</span>
                    <span
                        v-if="phone"
                        class="hb-mini-profile-item"
                    >
                        <hb-menu v-if="allowPhoneClick" disable-d-flex>
                            <template v-slot:menu-activator>
                                <span class="font-weight-medium hb-mini-profile-link-secondary">{{ phone }}</span>
                            </template>
                            <v-list>

                                <v-list-item :ripple="false" @click="$emit('phone-call-click')">
                                    <v-list-item-title>Call {{ phone }}</v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" @click="$emit('phone-text-click')" :disabled="!canText">
                                    <v-list-item-title v-if="!canText">Texting is not authorized for this number</v-list-item-title>
                                    <v-list-item-title v-else>Text {{ phone }}</v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </hb-menu>
                        <span v-else>{{ phone }}</span><hb-tooltip v-if="canText !== null">
                            <template v-slot:item>
                                <HbIcon
                                    small
                                    class="ml-1"
                                    :style="canText ? 'position:relative;top:-.5px;' : 'position:relative;top:4px;'"
                                    :mdi-code="canText ? 'mdi-message-outline' : 'mdi-custom-message-off-outline'"
                                />
                            </template>
                            <template v-slot:body>
                                {{ canText ? 'Texting Enabled' : 'Texting is not authorized for this number' }}
                            </template>
                        </hb-tooltip>
                    </span>
                    <span
                        v-if="email"
                        class="hb-mini-profile-item"
                    ><hb-link v-if="allowEmailClick" @click="$emit('email-click')" color="secondary"><span class="font-weight-medium">{{ email }}</span></hb-link><span v-else>{{ email }}</span></span>
                    <span
                        v-if="address"
                        class="hb-mini-profile-item"
                    >{{ address }}</span>
                </div>

                <div
                    v-if="type === 'space'"
                    class="hb-mini-profile-row hb-mini-profile-default-font-size"
                    :class="{ 'hb-mini-profile-row-additional-padding-top' : !thumbnail }"
                    :style="spaceType && spaceTypeMatchNotFound ? '' : spaceType && ((thumbnail && spaceType !== 'storage' && spaceType !== 'default' && spaceType !== 'portable' && spaceType !== 'commercial') || disableThumbnail) ? 'position:relative;top:3px;' : ''"
                >
                    <span
                        v-if="spaceType || spaceCategory"
                        class="hb-mini-profile-item"
                    >
                        <HbSpaceIcon
                            v-if="spaceType && thumbnail && !disableThumbnail"
                            enable-tooltip
                            small
                            :type="spaceType"
                            @match-not-found="matchNotFound('space')"
                        />
                        <span
                            v-if="spaceCategory"
                            :class="{ 'pl-1' : spaceType && thumbnail }"
                        >{{ spaceCategory }}</span>
                    </span>
                    <span
                        v-if="spaceFeaturedAmenity"
                        class="hb-mini-profile-item"
                    >{{ spaceFeaturedAmenity }}</span>
                    <span
                        v-if="spacePropertyInfo"
                        class="hb-mini-profile-item"
                    >{{ spacePropertyInfo }}</span>
                </div>

                <div
                    v-if="(type === 'contact' || type === 'business') && !disableContactSpaceInfo && !useMenu && !isLead"
                    class="hb-mini-profile-row hb-mini-profile-default-font-size"
                    :class="{ 'hb-mini-profile-row-additional-padding-top' : !contactSpaceType, 'hb-mini-profile-item-storage-type-fix' : contactSpaceType === 'storage' }"
                >
                    <span
                        v-if="contactSpaceType || contactSpaceNumber"
                        class="hb-mini-profile-item"
                    >
                        <hb-link
                            v-if="!disableContactSpaceLink"
                            color="secondary"
                            @click="$emit('contact-space-click', $event)"
                        >
                            <HbSpaceIcon
                                v-if="contactSpaceType"
                                enable-tooltip
                                small
                                :type="contactSpaceType"
                                color="#306FB6"
                                style="position:relative;top:1px;"
                            />
                            <span
                                v-if="contactSpaceNumber"
                                class="font-weight-medium"
                                :class="{ 'pl-1' : contactSpaceType }"
                            >#{{ contactSpaceNumber }}</span>
                        </hb-link>
                        <span v-else>
                            <HbSpaceIcon
                                v-if="contactSpaceType"
                                enable-tooltip
                                small
                                :type="contactSpaceType"
                                style="position:relative;top:1px;"
                            />
                            <span
                                v-if="contactSpaceNumber"
                                :class="{ 'pl-1' : contactSpaceType }"
                            >#{{ contactSpaceNumber }}</span>
                        </span>
                    </span>
                    <span
                        v-if="contactSpaceCategory"
                        class="hb-mini-profile-item"
                    >{{ contactSpaceCategory }}</span>
                    </span>
                    <span
                        v-if="contactSpaceFeaturedAmenity"
                        class="hb-mini-profile-item"
                    >{{ contactSpaceFeaturedAmenity }}</span>
                    <span
                        v-if="contactSpacePropertyInfo"
                        class="hb-mini-profile-item"
                    >{{ contactSpacePropertyInfo }}</span>
                    <span
                        v-if="contactSpaceAccessCode"
                        class="hb-mini-profile-item"
                        :class="{ 'hb-mini-profile-pointer' : !disableContactSpaceAccessClick }"
                        @click="!disableContactSpaceAccessClick ? $emit('access-click', $event) : ''"
                    >
                        <hb-tooltip v-if="contactSpaceAccessCode">
                            <template v-slot:item>
                                <HbIcon
                                    small
                                    :mdi-code="contactSpaceLockout ? 'mdi-custom-access-code-off' : 'mdi-custom-access-code'"
                                    :color="contactSpaceLockout ? '#FB4C4C' : '#02AD0F'"
                                    style="padding-left:1px;padding-right:5px;"
                                />
                            </template>
                            <template v-slot:body>
                                {{ contactSpaceLockout ? 'Lockout' : 'Access' }}
                            </template>
                        </hb-tooltip><span :style="contactSpaceLockout ? 'color:#FB4C4C' : 'color:#02AD0F'">{{ contactSpaceAccessCode }}</span>
                    </span>
                </div>

                <div
                    v-if="type === 'account' && !disableAccountInfo"
                    class="hb-mini-profile-row hb-mini-profile-default-font-size"
                    :class="{ 'hb-mini-profile-row-additional-padding-top' : !accountSpaceType, 'hb-mini-profile-item-storage-type-fix' : accountSpaceType === 'storage' }"
                    :style="accountSpaceType && accountSpaceType !== 'storage' && accountSpaceType !== 'default' && accountSpaceType !== 'portable' && accountSpaceType !== 'commercial' ? 'position:relative;top:3px;' : ''"
                >
                    <span
                        v-if="accountSpaceType || accountSpaceCount"
                        class="hb-mini-profile-item"
                    >
                        <HbSpaceIcon
                            v-if="accountSpaceType"
                            enable-tooltip
                            small
                            :type="accountSpaceType"
                            style="position:relative;top:1px;"
                        />
                        <span
                            v-if="accountSpaceCount"
                            class="font-weight-medium"
                            :class="{ 'pl-1' : accountSpaceType }"
                        >{{ accountSpaceCount }} Space<span v-if="accountSpaceCount > 1">s</span></span>
                    </span>
                    <span
                        v-if="accountSize"
                        class="hb-mini-profile-item"
                    >{{ accountSize }}</span>
                    <span
                        v-if="accountTotalMonthlySpent"
                        class="hb-mini-profile-item"
                    >Total Monthly Spent: {{ accountTotalMonthlySpent }}</span>
                </div>

                <div
                    v-if="(type === 'lead' || ((type === 'contact' || type === 'business') && isLead)) && !disableLeadInfo"
                    class="hb-mini-profile-row hb-mini-profile-default-font-size"
                    :class="{ 'hb-mini-profile-row-additional-padding-top' : !leadSpaceType }"
                    :style="leadSpaceType && leadSpaceTypeMatchNotFound ? '' : leadSpaceType && leadSpaceType !== 'storage' && leadSpaceType !== 'default' && leadSpaceType !== 'portable' && leadSpaceType !== 'commercial' ? 'position:relative;top:3px;' : ''"
                >
                    <span
                        v-if="leadType"
                        class="hb-mini-profile-item"
                    >
                        <hb-link
                            v-if="!disableLeadLink"
                            color="secondary"
                            @click="$emit('lead-click', $event)"
                        >
                            <span class="font-weight-medium">{{ leadType }}</span>
                        </hb-link>
                        <span v-else>{{ leadType }}</span>
                    </span>
                    <span
                        v-if="leadSpaceType || leadSpaceNumber"
                        class="hb-mini-profile-item"
                    >
                        <HbSpaceIcon
                            v-if="leadSpaceType"
                            enable-tooltip
                            small
                            :type="leadSpaceType"
                            @match-not-found="matchNotFound('lead')"
                        />
                        <span
                            v-if="leadSpaceNumber"
                            :class="{ 'pl-1' : leadSpaceType }"
                        >#{{ leadSpaceNumber }}</span>
                    </span>
                    <span
                        v-if="leadSpaceCategory"
                        class="hb-mini-profile-item"
                    >{{ leadSpaceCategory }}</span>
                    </span>
                    <span
                        v-if="leadFeaturedAmenity"
                        class="hb-mini-profile-item"
                    >{{ leadFeaturedAmenity }}</span>
                    <span
                        v-if="leadPropertyInfo"
                        class="hb-mini-profile-item"
                    >{{ leadPropertyInfo }}</span>
                </div>

                <div
                    v-if="(type === 'contact' || type === 'business') && useMenu && !disableContactSpaceInfo && !isLead"
                    class="hb-mini-profile-menu-row"
                >
                    <hb-menu :close-on-content-click="false" open-on-hover>
                        <template v-slot:menu-activator>
                            
                            <HbSpaceIcon
                                v-if="menuIconType"
                                small
                                :type="menuIconType"
                                class="mr-1"
                                disable-small-size-position-fix
                                style="height:17px;"
                            />
                            <span
                                class="hb-mini-profile-dashed"
                                :style="menuIconType ? 'position:relative;top:-1px;' : ''"
                            >
                                <span
                                    v-if="contactSpaceCount"
                                    class="hb-mini-profile-item"
                                >{{ contactSpaceCount }}<span class="ml-1">Space<span v-if="contactSpaceCount > 1">s</span></span></span>
                                <span
                                    v-if="contactSpaceCountOtherProperties"
                                    class="hb-mini-profile-item"
                                >{{ contactSpaceCountOtherProperties }}<span class="mx-1">Space<span v-if="contactSpaceCountOtherProperties > 1">s</span></span>OP</span>
                                <span
                                    v-if="contactActiveLeadCount"
                                    class="hb-mini-profile-item"
                                >{{ contactActiveLeadCount }}<span class="ml-1">Active Lead<span v-if="contactActiveLeadCount > 1">s</span></span></span>
                            </span>
                        </template>
                        <v-list>
                            <div class="pt-1 pb-2 px-5 hb-mini-profile-last-row-menu-wrapper">
                                <div
                                    v-if="$slots.menuSpaces"
                                    class="hb-text-light hb-mini-profile-menu-title hb-mini-profile-default-font-size"
                                >
                                    Spaces
                                </div>

                                <div
                                    v-if="$slots.menuSpaces"
                                >
                                    <slot name="menuSpaces"></slot>
                                </div>

                                <div
                                    v-if="$slots.menuSpacesOtherProperties"
                                    class="hb-text-light hb-mini-profile-menu-title hb-mini-profile-default-font-size"
                                >
                                    Spaces Other Properties (OP)
                                </div>

                                <div v-if="$slots.menuSpacesOtherProperties">
                                    <slot name="menuSpacesOtherProperties"></slot>
                                </div>

                                <div
                                    v-if="$slots.menuActiveLeads"
                                    class="hb-text-light hb-mini-profile-menu-title hb-mini-profile-default-font-size"
                                >
                                    Active Leads
                                </div>

                                <div v-if="$slots.menuActiveLeads">
                                    <slot name="menuActiveLeads"></slot>
                                </div>

                                <div
                                    v-if="$slots.menuClosedLeases"
                                    class="hb-text-light hb-mini-profile-menu-title hb-mini-profile-default-font-size"
                                >
                                    Closed Leases
                                </div>

                                <div v-if="$slots.menuClosedLeases">
                                    <slot name="menuClosedLeases"></slot>
                                </div>
                            </div>
                        </v-list>
                    </hb-menu>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/babel">

    import HbSpaceIcon from './HbSpaceIcon.vue';
    import HbIcon from './HbIcon.vue';
    import HbLink from './HbLink.vue';
    import HbStatus from './HbStatus.vue';
    import HbTooltip from './HbTooltip.vue';

    import { parsePhoneNumber } from 'libphonenumber-js';

    export default {
        name: "HbMiniProfile",
        data() {
            return {
                leadSpaceTypeMatchNotFound: false,
                spaceTypeMatchNotFound: false,
            };
        },
        components:{
            HbSpaceIcon,
            HbIcon,
            HbLink,
            HbStatus,
            HbTooltip
        },
        props: {
            type: {
                type: String,
                default: undefined
            },
            disableClick : {
                type: Boolean,
                default: false
            },
            thumbnail: {
                type: String,
                default: undefined
            },
            disableThumbnail: {
                type: Boolean,
                default: false
            },
            idVerified: {
                type: Boolean,
                default: false
            },
            disableAvatarClick: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: undefined
            },
            doNotRent: {
                type: Boolean,
                default: false
            },
            bankrupt: {
                type: Boolean,
                default: false
            },
            military: {
                type: Boolean,
                default: false
            },
            status: {
                type: String,
                default: undefined
            },
            disableStatus: {
                type: Boolean,
                default: false
            },
            pastDueAmount: {
                type: String,
                default: undefined
            },
            disableReport : {
                type: Boolean,
                default: false
            },
            businessName: {
                type: String,
                default: undefined
            },
            representativeName: {
                type: String,
                default: undefined
            },
            phone: {
                type: String,
                default: undefined
            },
            canText: {
                type: Boolean,
                default: null
            },
            email: {
                type: String,
                default: undefined
            },
            address: {
                type: String,
                default: undefined
            },
            spaceIsOccupied: {
                type: Boolean,
                default: false
            },
            tenantName: {
                type: String,
                default: undefined
            },
            spaceType: {
                type: String,
                default: undefined
            },
            spaceCategory: {
                type: String,
                default: undefined
            },
            spaceFeaturedAmenity: {
                type: String,
                default: undefined
            },
            spacePropertyInfo: {
                type: String,
                default: undefined
            },
            contactSpaceType: {
                type: String,
                default: undefined
            },
            contactSpaceNumber: {
                type: String,
                default: undefined
            },
            disableContactSpaceLink: {
                type: Boolean,
                default: false
            },
            contactSpaceCategory: {
                type: String,
                default: undefined
            },
            contactSpaceFeaturedAmenity: {
                type: String,
                default: undefined
            },
            contactSpacePropertyInfo: {
                type: String,
                default: undefined
            },
            contactSpaceAccessCode: {
                type: String,
                default: undefined
            },
            contactSpaceLockout: {
                type: Boolean,
                default: false
            },
            disableContactSpaceAccessClick: {
                type: Boolean,
                default: false
            },
            disableContactSpaceInfo: {
                type: Boolean,
                default: false
            },
            useMenu: {
                type: Boolean,
                default: false
            },
            menuIconType: {
                type: String,
                default: 'storage'
            },
            contactSpaceCount: {
                type: Number,
                default: 0
            },
            contactSpaceCountOtherProperties: {
                type: Number,
                default: 0
            },
            contactActiveLeadCount: {
                type: Number,
                default: 0
            },
            isLead: {
                type: Boolean,
                default: false
            },
            leadType: {
                type: String,
                default: undefined
            },
            disableLeadLink: {
                type: Boolean,
                default: false
            },
            leadSpaceType: {
                type: String,
                default: undefined
            },
            leadSpaceNumber: {
                type: String,
                default: undefined
            },
            leadSpaceCategory: {
                type: String,
                default: undefined
            },
            leadFeaturedAmenity: {
                type: String,
                default: undefined
            },
            leadPropertyInfo: {
                type: String,
                default: undefined
            },
            disableLeadInfo: {
                type: Boolean,
                default: false
            },
            accountSpaceType: {
                type: String,
                default: undefined
            },
            accountSpaceCount: {
                type: Number,
                default: 0
            },
            accountSize: {
                type: String,
                default: undefined
            },
            accountTotalMonthlySpent: {
                type: String,
                default: undefined
            },
            disableAccountInfo: {
                type: Boolean,
                default: false
            },
            allowPhoneClick: {
                type: Boolean,
                default: false
            },
            allowEmailClick: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            emitType(type){
                this.$emit('emitType', type);
            },
            matchNotFound(type){
                if(type === 'lead'){
                    this.leadSpaceTypeMatchNotFound = true;
                } else if(this.type === 'space'){
                    this.spaceTypeMatchNotFound = true;
                }
            }
        },
    }
</script>

<style scoped>

.hb-mini-profile-text-light {
    color: #637381;
}

.hb-mini-profile-default-font-size {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
}

.hb-mini-profile-title {
    color: #101318;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    top: -1px;
}

.hb-mini-profile-verified-icon-margin-fix {
    margin-top: -2px;
    margin-right: 3px;
}

.hb-mini-profile-avatar-wrapper {
    border: 1px solid #DFE3E8 !important;
}

.hb-mini-profile-item:not(:last-of-type)::after {
    content: '|';
    padding-left: 5px;
    padding-right: 5px;
}

.hb-mini-profile-overflow-handler *:not(.hb-mini-profile-status, .hb-mini-profile-badge, .hb-mini-profile-left-side, .hb-mini-profile-left-side, .hb-mini-profile-title, .hb-mini-profile-verified-badge) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-mini-profile-military-wrapper {
    height: 20px;
    position: relative;
    top: -1px;
    padding: 2px;
}

.hb-mini-profile-insurance-wrapper {
    height: 20px;
    position: relative;
    top: -2px;
    padding: 2px;
}


/* new */

.hb-mini-profile-left-side {
    min-width: 48px;
}

.hb-mini-profile-avatar-wrapper-thumbnail-verified {
    border: 3px solid #3D8FFF !important;
}

.hb-mini-profile-avatar-verified-icon {
    position: absolute;
    z-index: 99;
    bottom: 10px;
    left: 10px;
    height: 18px;
    width: 18px;
}

.hb-mini-profile-pointer {
    cursor: pointer;
}

.hb-mini-profile-verified-badge >>> .v-badge__badge {
    border-radius: 12px !important;
    height: 24px !important;
    padding: 6px !important;
}

.hb-mini-profile-first-row {
    min-height: 22px;
}

.hb-mini-profile-dashed {
    border-bottom: thin dashed;
}

.hb-mini-profile-badge:not(:first-of-type) {
    padding-left: 2px;
}

.hb-mini-profile-do-not-rent-wrapper {
    padding-right: 2px;
}

.hb-mini-profile-is-bankruptcy-wrapper {
    transform: scale(135%);
    position: relative;
    top: .5px;
    padding-right: 2px;
}

.hb-mini-profile-military-wrapper {
    padding-right: 2px;
}

.hb-mini-profile-row {
    height: 18px;
}

.hb-mini-profile-menu-row {
    margin-top: 3px;
    height: 19px;
}

.hb-mini-profile-row-contact-first-row {
    position: relative;
    top: 3px;
}

.hb-mini-profile-row-additional-padding-top {
    padding-top: 3px;
}

.hb-mini-profile-last-row-menu-wrapper .hb-mini-profile-menu-title {
    margin-bottom: 3px;
}

.hb-mini-profile-last-row-menu-wrapper .hb-mini-profile-menu-title:not(:first-of-type) {
    margin-top: 9px;
}

.hb-mini-profile-info {
    position: relative;
    top: -2px;
}

.hb-mini-profile-link-secondary {
    color: #306FB6;
}

</style>