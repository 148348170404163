<template>
  <hb-modal
    size="medium"
    :title="
      rentExempted ? 'Remove Rent Change Exemption' : 'Exempt from Rent Change'
    "
    v-model="dialog"
    @close="$emit('close')"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="popupDescription"></div>
        <div>
          Provide an explanation for {{ rentExempted ? "removing" : "" }} the
          exemption.
        </div>
        <div>
          <span class="reason-text hb-text-night-light hb-font-caption"
            >Notes:</span
          >
          <HbTextarea
            v-model.trim="reason"
            placeholder="Enter Reason"
            v-validate="'max:255'"
            data-vv-name="reason"
            data-vv-as="Reason"
            :error="errors.collect('name').length > 0"
          />
        </div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="isLoadingData" @click="exemptRentChange">Save</hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";

import api from "../../../assets/api.js";

export default {
  name: "ExemptRentChange",
  components: {
    ConfirmationPopup,
  },
  props: {
    property: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      rentPlan: "",
      rentExempted: false,
      tenantData: {},
      leaseId: "",
      rentChangeId: "",
      reason: "",
      popupDescription: "",
      isLoadingData: false,
    };
  },
  methods: {
    show(data, leaseId = "", rentChangeId = "", rentPlanStatus = "") {
      this.dialog = true;
      this.rentExempted = rentPlanStatus === "exempt";
      this.tenantData = data;
      this.leaseId = leaseId;
      this.rentPlan = data?.tenant_selected_rent_plan;
      this.reason = "";
      this.rentChangeId = rentChangeId;
      this.popupDescription =
        rentPlanStatus === "exempt"
          ? `You are about to remove <strong>${this.tenantData?.tenant_name}</strong> from being exempt all future rent changes.`
          : `You are about to make <strong>${this.tenantData?.tenant_name}</strong> exempt from all future rent changes.`;
    },

    async exemptRentChange() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        this.isLoadingData = true;
        await this.updateExemptStatus({
          exempt: this.rentExempted,
          exemptReason: this.reason,
          leaseId: this.leaseId,
        });
      }
    },

    /**
     * Function to exempt rent change plan for tenant (API call)
     */

    async updateExemptStatus(data) {
      let status = data.exempt ? "active" : "exempt";
      await api
        .put(
          this,
          api.getPropertyRentManagementUrl(this.property) +
            `leases/${data.leaseId}`,
          { status: status, note: data?.exemptReason }
        )
        .then(() => {
          this.dialog = false;
          let message = !data.exempt
            ? "You have exempted the tenant from the next rent change."
            : "You have reinstated the rent changes for this tenant.";

          this.$emit("updateExemptStatus", {
            type: "success",
            description: message,
            statusType: status,
            rentPlan: this.rentPlan,
            leaseId: data.leaseId,
            rentChangeId: this.rentChangeId,
            rentchange_id: this.tenantData?.rentchange_id,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$emit("updateExemptStatus", {
            type: "error",
            description: "Rent change exemption failed",
            statusType: "",
            leaseId: data.leaseId,
            rentChangeId: this.rentChangeId,
            rentchange_id: this.tenantData?.rentchange_id,
          });
        });
      this.isLoadingData = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  gap: 16px;
}
</style>
