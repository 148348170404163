<template>
  <div>
    <div v-if="displayNoInsuranceMessage && !insurances.length">
      No insurances found
    </div>
    <div v-for="(insurance, i) in insurances" :key="i">
      <v-row class="ma-0 pa-0">
        <v-col
          cols="12"
          class="ma-0 pa-0"
        >
          <div>{{ insurance.name }}</div>
          <div :class="durationPeriodColor" class="hb-font-caption pb-2">
            {{ insurance.start_date | formatLocalShortDate }}
            <span v-if="insurance.end_date">- {{ insurance.end_date | formatLocalShortDate }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script type="text/babel">

export default {
  name: "AllInsurances",
  data() {
    return {};
  },

  props: {
    insurances: {
      type: Array,
      required: true,
    },
    displayNoInsuranceMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    durationPeriodColor: {
      type: String,
      required: false,
      default: "hb-text-light",
    }
  },

  methods: {},
};
</script>

<style>
</style>