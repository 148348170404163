export default [
	{
		name: 'Leads',
		path: '/leads',
		component: () => import('../components/leads/Index.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
			layout: 'master',
			re_fetch:true
		}
	}
]
