export default Object.freeze({
	SUB_METHOD: {
        auction: 'Auction',
        cleaning_deposit: 'Cleaning deposit',
        security_deposit: 'Security deposit',
        transfer: 'Transfer',
        move_out: 'Move out',
        retained_revenue: 'Retained revenue',
        future_invoice: 'Future Invoice'
    }
});