<!-- BCT: HB-1337 - Override content from 'src\components\includes\ReportFunctions\UnitView.vue'
          to this component to create unique Space Profile Component -->
<template>
  <div class="flex-panel-container">
    <div class="d-flex align-center justify-center px-10 py-10"  v-if="featuresLoading" style="height:100%; min-height:400px">
        <v-progress-circular
            color="primary"
            indeterminate
        ></v-progress-circular>
    </div>
    <template v-else>
        
    <hb-header
        style="display: block;"
        class="flex-shrink"
        one-action
        align-top
        :padding="false"
        :divider="false"
    >
        <template v-slot:left>
            <HbHeaderInfo
                v-if="unit"
                :type="getUnitTypeLabel(unit)"
                :title="unit.number ? unit.number : undefined"
                :status="unit.state ? unit.state : undefined"
                :categoryOne="unit.label ? unit.label : undefined"
                :property="computedPropertyInfo"
                class="px-4 pt-4 pb-3"
                :unit="unit"
            />
        </template>
        <template v-slot:right>
            <hb-btn class="pa-2" icon tooltip="Close" @click="$emit('close')" active-state-off>mdi-close</hb-btn>
        </template>
    </hb-header>



      <div class="flex-shrink">
        <!-- SUB NAV -->
        <v-sheet>
          <hb-tabs
            class="hb-text-light"
            v-model="unitTab"
            show-arrows
            background-color="#ffffff"
          >
            <v-tab
              v-for="menuOption in menu"
              :key="menuOption.key"
              :href="'#tab-' + menuOption.key"
              @click="view = menuOption.key"
                :ripple="false"
            >
              {{ menuOption.label }}
            </v-tab>
          </hb-tabs>
          <v-divider class="ma-0 pa-0"></v-divider>
        </v-sheet>
        <!-- END SUBNAV -->
      </div>

    <v-card flat class="edit-panel flex-grow">
      <v-sheet>
        <!-- BCT: Tenant details HB-909-->
        <overview-lease-widget
            v-if="unit.id && (view === 'tenant-profile') && activeLease"
            :parent="'space-profile'"
            :payment_methods="paymentMethods"
            :lease="contactLease[0]"
            :contact="tenantContact"
            class="mb-6"
            style="height:auto;"
            :elevation="false"
            :unitData="unit"
            @auctionSale="showAuctionModal"
            @processPayment="setAuctionPayment"
            @refundAuction="refundAuction"
        >
        </overview-lease-widget>

        <unit-history v-if="unit.id && view === 'history'" :unit_id="unit.id" :leases="leases"></unit-history>
        <!-- BCT - Hb-72 Space Profile -->
        <unit-details
            v-if="unit.id && view === 'details'" :unit_id="unit.id" 
            :report-type="reportType"
            :unit_number="unit.number"
        >
        </unit-details>
        <unit-amenities 
            v-if="unit.id && view === 'amenities'"
            :unit_id="unit.id"
            :property_id="unit.property_id"
            :unit_number="unit.number"
            :unit_type_id="unit.unit_type_id"
        >
        </unit-amenities>
        <!-- BCT - Hb-72 Space Profile -->

        <unit-images v-if="unit.id && view === 'images'" :unit_id="unit.id"></unit-images>
      </v-sheet>

    </v-card>

    <hb-bottom-action-bar :class="{ 'mb-n3' : marginFix }" v-if="unit && unit.state && (unit.state.toLowerCase() == 'available' || activeLease)" cancel-off>
        <template v-slot:right-actions>
            <span v-if="unit.id && activeLease" class="d-flex">
                <hb-btn color="secondary" @click="setMoveOut" :disabled="isLoading('tenant_leases')">Move-Out</hb-btn>
                <span>
                    <hb-btn color="secondary" :disabled="isLoading('tenant_leases')" v-if="leases && leases.length && leases[0].balance <= 0" @click="setTransfer">Transfer</hb-btn>
                    <hb-tooltip v-else>
                        <template v-slot:item >
                            <hb-btn color="secondary" disabled>Transfer</hb-btn>
                        </template>
                        <template v-slot:body> Clear out balance to transfer unit </template>
                    </hb-tooltip>
                </span>
                <hb-btn color="primary" @click="takePayment" :disabled="isLoading('tenant_leases')">Take a Payment</hb-btn>
            </span>
            <hb-btn color="primary" @click="moveIn" v-if="unit.id && unit.state.toLowerCase() === 'available'">Reserve or Move-In</hb-btn>
        </template>
    </hb-bottom-action-bar>

    <!-- BCT: HB-909 Tenant details in Space profile - Start -->
    <auction-dialog
        v-if="show_auction_model"
        v-model="show_auction_model"
        :contact_id="tenantContact.id"
        :auctionLease.sync="auctionLease"
        :auction_refund_id="auction_refund_id"
        :property_id="propertyView ? propertyView : ''"
        @notification="setNotification"
        @processPayment="setAuctionPayment"
        @moveOut="setMoveOut"
        @refundAuction="refundAuction"
        @close="show_auction_model=false"
    >
    </auction-dialog>

    <!-- Auction Payment Modal -->
    <v-dialog
        v-model="showAuctionPaymentModal"
        :width="720"
        :persistent="true"
        :scrollable="true"
        slot="body"
        v-if="showAuctionPaymentModal"
    >
        <edit-payment
            :payment_id="auction_payment_id"
            :auctionPayment="true"
            @close="showAuctionPaymentModal=false"
        >
        </edit-payment>
    </v-dialog>
    <!-- Auction Payment Modal -->

    <!-- Refund Modal -->
    <RefundModal
        v-if="refundAuctionFlag"
        :refund="refund"
        :refund_option="refund_option"
        :scheduled_auction="scheduled_auction"
        :disableRefund="disableRefund"
        @processRefundEvt="processRefund"
    >
    </RefundModal>
        
    <!-- BCT: HB-909 Tenant details in Space profile - End -->

<!--    <v-row align="center" justify="end" class="mr-6 mt-3 hidden-sm-and-down" style="z-index:99999;">-->
<!--      &lt;!&ndash;-->
<!--      <router-link v-if="unit.id" :to="'/units/' + unit.id + '/create-lease'">-->
<!--          <v-icon color="#637381" class="mr-5" title="Create Lease">mdi-file-document-box-plus</v-icon>-->
<!--      </router-link>-->

<!--      <v-icon v-if="unit.id" @click.prevent="showMakeReservation = true;" color="#637381" title="Reserve Unit">mdi-stamper</v-icon>-->
<!--      &ndash;&gt;-->
<!--      <v-btn elevation="0" icon class="mr-4">-->
<!--        <v-icon>mdi-launch</v-icon>-->
<!--      </v-btn>-->
<!--      <v-btn class="text-capitalize btn-style secondary-button mr-2" @click="moveIn" v-if="unit.id">Add Maintenace Ticket</v-btn>-->
<!--      <v-btn class="text-capitalize btn-style primary-button" @click="moveIn" v-if="unit.id">Reserve or Move-In</v-btn>-->
<!--    </v-row>-->
    </template>
  </div>


</template>

<script type="text/babel">
    import OverviewLeaseWidget from '../leases/OverviewLeaseWidget.vue';
    import AuctionDialog from '../leases/AuctionDialog.vue';
    import EditPayment from '../payments/EditPayment.vue';
    import UnitHistory from './UnitHistory.vue'
    import UnitDetails from './UnitDetails.vue'
    import UnitAmenities from './UnitAmenities.vue'
    import UnitImages from './UnitImages.vue'
    import RefundModal from './RefundModal.vue'

    import { notificationMixin } from  '@/mixins/notificationMixin.js';
    // import Modal from '../assets/Modal.vue';
    // import MakeReservation from '../reservations/MakeReservation.vue';
    import { EventBus } from '../../EventBus.js';
    import api from '../../assets/api.js';
    import { mapActions, mapGetters, mapMutations} from 'vuex';
    import moment from 'moment';

    export default{
        name: "UnitView",
        data(){
            return {
                featuresLoading: false,
                contactLeases: [],
                refund: {
                    paymentFrom: 'cleaning_deposit',
                    amount: 0,
                    paymentTo: ''
                },
                refund_option: {
                    text: '',
                    value: 0
                },
                auctionPayment: {},
                auction_payment_id: '',
                scheduled_auction: {},
                showAuctionPaymentModal: false,
                refundAuctionFlag: false,
                show_auction_model: false,
                auctionLease: {},
                propertyView: '',
                paymentMethods: [],
                tenantContact: {},
                leases: [],
                view: 'details',
                unit: {},
                description: '',
                unitTab: 'tab-details',
                showMakeReservation: false,
                showEditDescription: false,
                disableRefund: false,
                // BCT: Added Tenant Profile, Remove Floorplan, Utilities
                menuOptions: [
                    {
                        key: 'tenant-profile',
                        label: 'Tenant Profile'
                    },
                    {
                        key: 'details',
                        label: 'Space Info'
                    },
                    {
                        key: 'amenities',
                        label: 'Features and Amenities'
                    },
                    {
                        key: 'history',
                        label: 'Lease History'
                    },
                    {

                        key: 'images',
                        label: 'Images'
                    }
                ]
            }
        },
        props: {
            unit_id: {
                type: String
            },
            show: {
                type: String
            },
            reportType: {
                type: String,
                required: false,
                default: 'spaces'
            },
            marginFix: {
                type: Boolean,
                default: true
            }
        },
        mixins: [ notificationMixin],
        components:{
            UnitHistory,
            UnitDetails,
            UnitAmenities,
            UnitImages,
            OverviewLeaseWidget,
            AuctionDialog,
            EditPayment,
            // MakeReservation,
            RefundModal,
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                leasedProperties: 'propertiesStore/leasedProperties',
            }),

            // Active lease: unit status is 1 and end date is either null or future date
            activeLease() {
                if (this.unit.Lease) {
                    let today = moment().startOf('day').format('x');
                    let endDate = this.unit.Lease.end_date ? moment(this.unit.Lease.end_date, 'YYYY-MM-DD').startOf('day').format('x'): null;
                    return this.unit.Lease && this.unit.Lease.status === 1 && (!endDate || endDate > today);
                }
                return false;
            },

            contactLease(){
                if (this.contactLeases && this.contactLeases.length && this.unit && this.unit.Lease) {
                    // filters the leases that are available at the set property
                    return this.contactLeases.filter(cl => cl.id == this.unit.Lease.id);
                }
                return [];
            },

            menu() {
                // BCT: 'Tenant Profile' tab is visible only if Space is active lease type
                return this.activeLease ? this.menuOptions : this.menuOptions.slice(1);
            },

            computedPropertyInfo() {
              if(this.unit && this.unit.Address){
                return this.$options.filters.formatAddress(this.unit.Address, '-', true);
              } else {
                return undefined;
              }
            }
        },
        async created(){
            this.view = this.show || 'details';
            this.unitTab = 'tab-' + this.view;
            this.featuresLoading = true
            await this.fetchData();
            EventBus.$on('unit_edited', this.fetchData);
            EventBus.$on('priority_action', this.closeWindow);
            EventBus.$on('unit_edited_single', this.updateUnit);

            // BCT: Added by BCT
            await this.fetchLeases();
            EventBus.$on('reload_tenant_profile_leases', this.fetchTenantContact);
            this.featuresLoading = false

        },
        destroyed(){
            EventBus.$off('unit_edited', this.fetchData);
            EventBus.$off('priority_action', this.closeWindow);
            EventBus.$off('reload_tenant_profile_leases', this.fetchTenantContact);
            EventBus.$off('unit_edited_single', this.updateUnit);
        },
        methods:{
            ...mapActions({
                setLeasedProperties: 'propertiesStore/setLeasedProperties'
            }),
            ...mapMutations({
                setLeases: 'tenantProfile/setLeases'
            }),

            closeWindow(){
                this.$emit('close');
            },
            fetchData(){
                return api.get(this, api.UNITS + this.unit_id).then(r => {
                    this.unit = r.unit;
                });
            },
            maintenance(){

            },
            async moveIn(){
                await this.$store.dispatch('onBoardingStore/getUnitInfo', {unit_id: this.unit_id});
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.unit.property_id));
                EventBus.$emit('new_lead');
                EventBus.$emit("FromUnitViewSetUnit");
                this.$emit('close');
            },
            async moveInPending(){
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.unit.property_id));
                await this.$store.dispatch('onBoardingStore/findPending', {unit_id: this.unit_id});
                EventBus.$emit('new_lead');
                this.$emit('close');
            },

            updateSaved(){
                this.fetchData();
                this.showMakeReservation = false;
            },
            resetUnit(){
                this.unit = {
                    id: null
                }
            },

            // BCT: Added by BCT
            fetchLeases(){
                this.startLoading('tenant_leases');
                this.featuresLoading = true
                api.get(this, api.UNITS + this.unit_id + '/leases').then((r) => {
                    this.leases = r.leases;
                    this.fetchTenantContact();
                }).finally(err => {
                    this.stopLoading('tenant_leases');
                    this.featuresLoading = false
                })
            },

            async fetchTenantContact() {
                let curLease = this.getCurrentLease();
                if (curLease?.Tenants[0].Contact.id) {
                    await this.setLeasedProperties(curLease.Tenants[0].Contact.id);
                    this.propertyView = this.leasedProperties[0].id;
                    this.fetchPaymentMethods();

                    api.get(this, api.CONTACTS + curLease.Tenants[0].Contact.id).then((r) => {
                        this.tenantContact = r.contact;
                        this.contactLeases = r.contact.Leases;
                        let foundLease = this.contactLeases.find(lease => lease.id === curLease.id);
                        curLease.Unit = foundLease.Unit;
                        curLease.balance = foundLease.balance;
                        curLease.Tenants[0].contact_id = this.tenantContact.id;
                        // setting the "lease" in the store
                        this.setLeases({
                            leases: [foundLease],
                            leases_status: 'current'
                        });
                        this.featuresLoading = false
                        this.stopLoading('tenant_leases');
                    }).finally(err => {
                        this.featuresLoading = false
                        this.stopLoading('tenant_leases');
                    })
                }
            },

            setMoveOut(){
                let curLease = this.getCurrentLease();
                if (curLease && curLease.Unit) {
                    this.$emit('close');
                    EventBus.$emit('move_out', {
                        property_id: curLease.Unit.property_id,
                        contact: this.tenantContact,
                        lease_id: curLease.id,
                        unit_id: curLease.Unit.id
                    });
                }
            },

            setTransfer() {
                let curLease = this.getCurrentLease();
                if (curLease && curLease.Unit) {
                    this.$emit('close');
                    EventBus.$emit('transfer', {
                        property_id: curLease.Unit.property_id,
                        contact: this.tenantContact,
                        lease_id: curLease.id,
                        unit_id: curLease.Unit.id
                    });
                }
            },

            takePayment() {
                let curLease = this.getCurrentLease();
                if (curLease && curLease.Unit) {
                    this.$emit('close');
                    EventBus.$emit('make_payment', {
                        property_id: curLease.Unit.property_id,
                        contact_id: this.tenantContact.id
                    });
                }
            },
        
            async fetchPaymentMethods(){
                let curLease = this.getCurrentLease();
                if(!(this.propertyView && curLease && curLease.Tenants[0].Contact.id)) return;
                await api.get(this, api.CONTACTS + curLease.Tenants[0].Contact.id + '/payment-methods?property_id=' + this.propertyView).then(r => {
                    this.paymentMethods = r.payment_methods.map(pm => {
                        this.$set(pm,'editable', false);
                        return pm;
                    });
                }).catch(e => {
                    console.log(e);
                })
            },

            showAuctionModal(lease) {
                this.auctionLease = lease;
                this.show_auction_model = true;
            },

            setAuctionPayment(payment_id) {
                this.showAuctionPaymentModal = true;
                this.auction_payment_id = payment_id;
            },

            setNotification(payload) {
                this.showMessageNotification({type: payload.type, description: payload.message});
            },

            async processRefund(){
                this.disableRefund = true;
                const status = await this.$validator.validateAll();
                if(!status) return
                // refund
                var data = {
                    amount: parseFloat(this.refund.amount)
                }
                await api.post(this, api.LEASE_AUCTION + this.scheduled_auction.id + '/refund', data).then(r => {
                    this.auction_refund_id = r.lease_auction_id;
                    EventBus.$emit('contact_updated',  this.tenantContact.id);
                    EventBus.$emit('reservation_made');
                    this.refundAuctionFlag = false;
                }).catch(err => {
                    this.showMessageNotification({type: 'error', description: err});
                });
                this.disableRefund = false;
            },

            refundAuction(scheduledAuction) {
                this.refundAuctionFlag = true;
                this.scheduled_auction = scheduledAuction;
                this.refund.amount = scheduledAuction.cleaning_deposit;
                this.fetchPayment();
            },

            async fetchPayment(){
                await api.get(this, api.GET_PAYMENT + this.scheduled_auction.payment_id).then(r => {
                    this.auctionPayment = r.payment;
                    this.getRefundOptions();
                }).catch(err => {
                    this.showMessageNotification({type: 'error', description: err});
                });
            },

            getRefundOptions() {
                // Handling Credit payment refund option
                if(this.auctionPayment.method && this.auctionPayment.method === 'credit') {
                    this.refund_option = {
                    text: 'Credit Balance',
                    value: 0
                    }
                    return;
                }
                let text = ''
                if (['card', 'ach'].indexOf(this.auctionPayment.method) >= 0) {
                    text = this.auctionPayment.PaymentMethod.card_type + '****' +  this.auctionPayment.PaymentMethod.card_end
                } else {
                    text = this.auctionPayment.method
                }
                this.refund_option = {
                    text,
                    value: this.auctionPayment.PaymentMethod.id  || 1
                }
            },

            updateUnit(unit) {
                // this.unit.Category.name = unit.unit_category;
                // this.unit.label = unit.unit_size;
                this.fetchData();
            },

            getCurrentLease(){
                return this.leases && this.leases.length && this.unit && this.leases.find(l => l.id == this.unit?.Lease?.id);
            }
        
        },
        watch:{
            async unit_id(){
                // this.featuresLoading = true
                this.view = this.show || 'details';
                this.unitTab = 'tab-' + this.view;
                this.featuresLoading = true
                this.resetUnit();
                await this.fetchData();
                this.fetchLeases();
                this.featuresLoading = false
            },
            '$route': function(){
                this.$emit('close');
            }
        }

    }


</script>

<style lang="scss" scoped>
  .editor-container{
    top: 0;
  }
  .property-slideout-container{
    z-index:9999;
    height: 100%;
    overflow-y:auto;
    overflow-x:hidden;
    /* background-color:#F9FAFB; */
  }

  .property-light-black{
    color:#677380;
  }

  .flex-panel-container{
    display: flex;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    position: relative;
    max-height: 80vh;
  }

  .flex-grow{
    flex: 1;
  }
  .flex-shrink{
    flex: 0 1;
  }
  .panel-footer {
    position: relative !important;
  }

</style>
