<template>
    <div >
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <div v-if="!payment.id">
            <span v-if="overPayment">

                <div class="info-section" >
                    There will be an overpayment of {{ overPayment | formatMoney}} remaining on this account.
                </div>
                <br />
                <br />
            </span>
            <div class="form-section payment-summary skinny-row" >
                <h4 class="notification">Payment Totals</h4>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Date:</span>
                    <span class="horizontal-form-text">{{payment.date | formatDate }}</span>

                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Amount:</span>
                    <span class="horizontal-form-text">{{payment.amount | formatMoney }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Payment Method:</span>
                    <span class="horizontal-form-text" >PayPal</span>
                </div>
            </div>


            <div class="form-section"v-if="isAdmin" v-show="totalApplied > 0" >


                <h4 class="notification">This payment will be applied to the following invoices:</h4>
                <br />
                <br />
                <div class="table">
                    <div class="table-row" v-for="i in invoices" v-if="i.payment_amount > 0">
                        <div class="table-cell">Invoice #{{i.number}}</div>
                        <div class="table-cell">{{i.payment_amount | formatMoney}}</div>
                    </div>
                </div>


            </div>

            <div class="modal-footer">


                <button class="w-button tertiary-btn" @click.prevent="$emit('close')">Cancel</button> &nbsp;&nbsp;

                <div style="display: inline-block; vertical-align: middle" v-if="!payment.method.id" id="paypal-button"></div>

                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </div>
        </div>


        <div class=" payment-summary text-center skinny-row" v-if="payment.id">

            <div class="text-center form-section" >
                <h3 class="success" v-if="paymentType == 'open'">Payment Applied Successfully</h3>
                <h3 class="success" v-if="paymentType == 'new'">Payment Successful</h3>
                <span class="icon confirmation-check success" >
                    
                </span>

                <div v-if="paymentType == 'new'">
                    <p>Your payment of {{payment.amount | formatMoney}} as been processed.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

                <div v-if="paymentType == 'open'">
                    <p>Your payment has been applied to the selected open invoices.</p>
                    <label>Please note transaction #{{payment.id}} for your records</label>
                </div>

            </div>
            <button class="w-button secondary-btn float-right" @click.prevent="$emit('reload')"> Enter Another Payment</button>
            <button class="w-button primary-btn float-left" @click="$emit('finish')">Close Window</button>

        </div>


    </div>
</template>

<script type="text/babel">
    import moment from 'moment';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "ConfirmPayment",
        data() {
            return {
                confirmed: false,
                orderReferenceId: ''
            }
        },
        components:{
            Status,
            Loader
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            }),
            totalApplied(){
                var total =  this.invoices.reduce(function(a,b){
                    return a += Math.round(b.payment_amount * 100);
                }, 0);
                return total / 100;
            },
            overPayment(){
                return  ((this.payment.amount * 100)/100) -  this.totalApplied;

            }

        },
        created(){

        },
        filters:{

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
            },
        },
        mounted(){
            this.setupPayPal();
        },
        methods:{
            setupPayPal(){

	            var params = {
                    payment: {
                        lease_id: this.lease_id,
                        date: moment(this.payment.date).format('YYYY-MM-DD'),
                        type: 'paypal',
                        amount: this.payment.amount,
                        ref_name: this.payment.ref_name,
                        number: this.payment.number,
                        notes: this.payment.notes,

                    },
                    invoices: this.invoices.map(i => {
                        return {
                            id: i.id,
                            amount: i.balance
                        }
                    })
                };


	            paypal.Buttons({
                    createOrder: (data, actions) => {
                        // Set up the transaction
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: this.payment.amount,
                                }
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
	                    params.payment.params = {
		                    orderID: data.orderID,
		                    payerID: data.payerID
	                    }
	                    return api.post(this,  api.PAYMENTS , params).then(r => {
		                    this.confimed = true;
		                    this.payment.id = r.payment_id;
		                    EventBus.$emit('lease_payment_saved');
	                    });
                    }
                })
                .render('#paypal-button');

            },

            getURLParameter(name, source) {
                return decodeURIComponent((new RegExp('[?|&amp;|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(source) || [, ""])[1].replace(/\+/g, '%20')) || null;
            }
        },
        props: {
            invoices: {
                type: Array
            },
            payment: {
                type: Object
            },
            paymentType:{
                type: String
            },
            lease_id:{
                type: String
            },
            onboard: {
                type: Boolean,
                default: false
            },
            global: {
                type: Boolean,
                default: false
            },
            environment: {
                type: String,
                required: true
            }
        },
    }
</script>

<style scoped>
    label{
        margin: 0;
        padding: 0;
    }
    img.cc-icon {
        border: 1px solid #e2e2e2;
        border-radius: 2px;
        width: 32px;
        margin-right:5px;;
    }

    .confirmation-check{
        font-size: 115px;
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 115px;
    }

</style>
