var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.full || (!_vm.hasLeftSlot && !_vm.hasRightSlot))?_c('v-row',{staticClass:"pa-0 ma-0 d-flex",class:_vm.verticalAlign},[_c('v-col',{staticClass:"ma-0 pa-0 mr-auto",class:{
        'pb-1 pt-2': _vm.padding,
        'pb-3': _vm.$vuetify.breakpoint.mdAndUp && _vm.padding,
        'pt-0 pb-0': !_vm.padding,
      },attrs:{"cols":"12"}},[_vm._t("default")],2)],1):_c('v-row',{staticClass:"pa-0 ma-0 d-flex",class:_vm.verticalAlign},[_c('v-col',{staticClass:"ma-0 pa-0 mr-auto",class:{
        'pb-1 pt-2': _vm.padding,
        'pb-3': _vm.$vuetify.breakpoint.mdAndUp && _vm.padding,
        'pt-0 pb-0': !_vm.padding,
      },attrs:{"cols":_vm.defaultCols,"md":_vm.mdCols,"lg":_vm.lgCols}},[_vm._t("left")],2),_c('v-col',{staticClass:"ma-0 px-3",class:[
        {
          'pb-4 pt-1': _vm.padding,
          'pt-3': _vm.$vuetify.breakpoint.mdAndUp && _vm.padding,
          'pt-0 pb-0': !_vm.padding,
        },
        _vm.verticalAlign,
      ],attrs:{"cols":_vm.defaultCols === 12 ? 12 : 12 - _vm.defaultCols,"md":12 - _vm.mdCols,"lg":12 - _vm.lgCols}},[_c('v-row',{class:{
          'd-flex align-center justify-end':
            _vm.$vuetify.breakpoint.mdAndUp || _vm.$vuetify.breakpoint.smAndDown,
          'mt-1': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"justify":"end"}},[_vm._t("right")],2)],1)],1),(_vm.divider)?_c('v-divider'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }