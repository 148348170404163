<!-- BCT: Component separated from OverviewLeaseWidget -->
<template>
  <div>
    <v-row v-if="unit.gate_status != 'Unlocked'" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Door Status
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <hb-status-general :status="unit.gate_status"></hb-status-general>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Move-In Date
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        {{leaseWithTenants.start_date | formatDateServices}}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Paid Through
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="lease.Metrics && lease.Metrics.paid_through_date">{{lease.Metrics.paid_through_date| formatDateServices}}</span>
        <span v-else>--</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row class="pa-0 mt-3 mx-4 d-flex justify-center align-center" :class="{'hb-text-destroy-status' : leaseWithTenants.balance > 0}">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Total Balance Due
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" :class="{'font-weight-medium' : leaseWithTenants.balance > 0}">
        {{leaseWithTenants.open_balance | formatMoney}}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row v-if="!is_closed" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Upcoming Charges
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="!showCharges">{{invoice.balance | formatMoney }}</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0" :class="showCharges ? 'pl-5' : 'pl-7'">
        <hb-link @click="showCharges = !showCharges" class="float-right">{{showCharges ? 'Cancel' : 'View'}}</hb-link>
      </v-col>
    </v-row>
    <span v-if="showCharges" class="text-body-2">
        <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
            <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
                Bill Date
            </v-col>
            <v-col cols="4" class="pa-0 ma-0 d-flex align-center justify-end">
                <v-icon size="20" :disabled="offset <= 0" color="#637381" @click="prevPeriod" style="padding-top:2px;">
                    mdi-menu-left
                </v-icon>
                {{invoice.due | formatDate}}
            </v-col>
            <v-col cols="2" class="pa-0 ma-0 d-flex align-center">
                <v-icon size="20" color="#637381" @click="nextPeriod" style="padding-top:2px;">
                    mdi-menu-right
                </v-icon>
            </v-col>
        </v-row>

        <span v-if="invoice && invoice.InvoiceLines && invoice.InvoiceLines.length !== 0">
            <v-row v-for="(lines, i) in invoice.InvoiceLines" :key="'invoiceline' + i" class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    {{lines.Service.name}} <span v-if="lines.Product.default_type === 'rent'">({{lines.start_date | formatDate}} - {{lines.end_date | formatDate}})</span> <span v-if="lines.qty > 1"> x {{lines.qty}}</span>
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    {{lines.cost * lines.qty | formatMoney}}
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">
                    <v-icon size="16" color="#637381" v-if="lines.Product.default_type !== 'rent'" @click="lines.Product.default_type === 'insurance' ? insuranceDialog = true : editService(lines.Service, 'filter')">
                        mdi-pencil
                    </v-icon>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    Taxes<!-- ({{taxRate}}%) -->
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    {{invoice.total_tax | formatMoney}}
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">

                </v-col>
            </v-row>
            <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    Discounts
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    ({{invoice.discounts | formatMoney}})
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">

                </v-col>
            </v-row>
            <v-row class="font-weight-medium hb-default-font-size pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    Total Charges
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    {{invoice.balance | formatMoney}}
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">

                </v-col>
            </v-row>
        </span>
        <span v-show="!invoice.InvoiceLines || !invoice.InvoiceLines.length" class="px-6 py-6">
            <em>No charges found for this period.</em>
        </span>
    </span>

    <v-row v-if="isAuctionPaid" class="pa-0 mt-2 mb-1 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-regular hb-link" style="cursor: pointer;" @click="auctionSale">
        Auction Sale
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        {{ auctionAmount | formatMoney }}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 pl-2">
        <hb-btn small color="secondary" @click="$emit('processPayment', lease.LeaseAuction.payment_id)" :disabled="auctionAmount <= 0 ? true : false">View</hb-btn>
      </v-col>
    </v-row>

    <v-row v-if="isAuctionPaid" class="pa-0 mt-2 mb-1 mx-4 d-flex justify-center align-center" :class="is_closed ? 'pb-2' : ''">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Cleaning Deposit
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        {{ auctionCleaningDeposit | formatMoney }}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 pl-2">
        <hb-btn small color="secondary" @click="$emit('refundAuction', lease.LeaseAuction)" :disabled="disableRefundButton">Refund</hb-btn>
      </v-col>
    </v-row>

    <v-row v-if="!is_closed" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Prepaid Balance
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="lease.Metrics && lease.Metrics.prepaid_balance">{{lease.Metrics.prepaid_balance| formatMoney}}</span>
        <span v-else>$0.00</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 pl-2">
        <!-- turn back on later after refund logic has been addressed -->
        <!--
        <hb-btn small color="secondary" @click="goRefund()" :disabled="prepayBalance <= 0 ? true : false">Refund</hb-btn>
        -->
      </v-col>
    </v-row>
    
    <v-row v-if="!nationalAccount" class="pa-0 mt-2 mb-3 mx-4 d-flex justify-center align-center">
      <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
          Credit Balance
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 d-flex justify-end" v-if="totalCredits">
          {{totalCredits | formatMoney}}
      </v-col>  
      <v-col cols="2" class="pa-0 ma-0 d-flex justify-end" v-else>
          $0.00
      </v-col>
        <v-col cols="2" class="pa-0 ma-0 pl-2">
            <!--
          <hb-btn small color="secondary" @click="goCredit()" v-if="!credits || totalCredits <= 0">Apply</hb-btn>
          <hb-btn small color="secondary" @click="goCredit()" v-else>Adjust</hb-btn>
          -->
          <hb-btn v-if="!is_closed && !isAuctionPaymentOrMovedOut && !nationalAccount" small color="secondary" @click="goCredit()" class="float-right">Apply</hb-btn>
      </v-col>
    </v-row>
    <!-- If it is National Account then don't show Credit Balance -->
    <v-row v-else class="pa-0 mt-2 mb-3 mx-4 d-flex justify-center align-center">
    </v-row>

    <insurance-dialog
      v-model="insuranceDialog"
      v-if="insuranceDialog"
      :lease_id="lease.id"
      :lease="lease"
      :contact_id="contact.id"
      :property_id="lease.Unit.property_id"
      @close="insuranceDialogClose"
      :decline_insurance="decline_insurance"
    >
    </insurance-dialog>

    <edit-service-dialog
      v-model="editServiceDialog"
      v-if="editServiceDialog"
      :selected="selectedService"
      :property_id="lease.Unit.property_id"
      :lease_id="lease.id"
      @close="editServiceDialog = false"
    >
    </edit-service-dialog>

    <credit
      v-if="show_credit_model"
      v-model="show_credit_model"
      :lease_id="lease.id"
      :contact_id="contact.id"
      :credits="credits"
      @close="show_credit_model = false;"
    >
    </credit>

  </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import moment from 'moment';

    import EditServiceDialog from './EditServiceDialog.vue';
    import InsuranceDialog from './InsuranceDialog.vue';
    import Credit from '../leases/Credit.vue';

export default {
  name: 'OverviewLeaseDetails',
  components: {
    InsuranceDialog,
    EditServiceDialog,
    Credit,
  },

  props: ['unit', 'leaseWithTenants', 'lease', 'invoice', 'offset', 'services', 'credits', 'contact', 'nationalAccount'],
  data() {
    return {
      showCharges: false,
      insuranceDialog: false,
      decline_insurance: false,
      editServiceDialog: false,
      selectedService: {},
      show_credit_model: false,
    }
  },

  created() {
    if(this.lease && this.lease.decline_insurance == 1){
      this.decline_insurance = true;
    }
  },

  computed: {
    ...mapGetters({
        isAdmin: 'authenticationStore/isAdmin',
        isSuperAdmin: 'authenticationStore/isSuperAdmin',
        hasPermission: "authenticationStore/rolePermission"
    }),

    is_closed(){
        return moment(this.lease.end_date).startOf('day') <= moment().startOf('day');
    },

    isAuctionPaymentOrMovedOut() {
      return this.lease && this.lease.auction_status === 'move_out';
    },

    isAuctionPaid() {
        return (this.lease && (this.lease.auction_status == 'move_out' || this.lease.auction_status == 'complete')) ? true : false;
    },
    
    auctionAmount() {
        return (this.lease.LeaseAuction && this.lease.LeaseAuction.amount) ? this.lease.LeaseAuction.amount : 0;
    },

    auctionCleaningDeposit() {
        return (this.lease.LeaseAuction && this.lease.LeaseAuction.cleaning_deposit && !this.lease.LeaseAuction.refund_id) ? this.lease.LeaseAuction.cleaning_deposit : 0;
    },

    totalCredits(){
        return this.credits.reduce((a, p) => a + Math.round(p.amt_remaining*1e2)/1e2, 0);
    },
    disableRefundButton(){
      if (this.lease.LeaseAuction.refund_id || this.auctionCleaningDeposit <= 0){
        return true;
      }
      if (this.lease.LeaseAuction.has_clean_out_period_passed){
        return !this.hasPermission('refund_after_clean_out_period');
      }
      return false;
    }
  },

  methods: {
    nextPeriod() {
      this.$emit('nextPeriod');
    },

    prevPeriod() {
      this.$emit('prevPeriod');
    },

    insuranceDialogClose(){
        this.insuranceDialog = false;
        this.$emit('insuranceDialogClose');
    },

    editService(s, t){
      if(!this.isAdmin) return;

      if(t === 'filter'){
          var getSelected = this.services.filter(service => service.id === s.id);
          this.selectedService = getSelected[0];
      } else {
          this.selectedService = s;
      }

      //Checks whether user can edit service
      this.editServiceDialog = this.checkIfServiceEditable(s);
      },

      checkIfServiceEditable(s){
          if (s.last_billed && !s.recurring) return false
          return true
      },

      auctionSale() {
        this.$emit('auctionSale', this.lease);
      },
      
      goCredit(){
        this.show_credit_model = true;
      },

  },
}
</script>