<template>
  <div>
    <v-container class="payment-method-container pb-4">
      <v-row>
        <v-col><strong class="text-capitalize">{{PAYMENTS.PAYMENT_METHODS.GIFTCARD.TEXT}} Payment</strong></v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="ma-0 pt-0 pl-0 pr-0"><strong>Transaction Details</strong></v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="pt-2 pl-0">
          <HbTextField
            :class="{'custom-field-error' : errors.first('ref_name')}"
            hide-details
            :value= "payment.ref_name"
            v-validate="'required'"
            data-vv-name="ref_name"
            data-vv-as="Reference Name"
            :error-messages="errors.collect('ref_name')"
            label="Reference Name*"
            id="gift-card-name"
            name="gift-card-name"
            autocomplete="gift-card-ref-name"
            full
            @input="handleInputUpdate('payment', 'ref_name', $event)"
            >
          </HbTextField>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" v-if="payment_type && payment_type.toLowerCase() === PAYMENTS.PAYMENT_METHODS.GIFTCARD.VALUE">
        <v-col class="pt-2 pl-0">
          <HbTextField
            :class="{'custom-field-error' : errors.first('number')}"
            hide-details
            :value="payment.number"
            v-validate="payment_type === PAYMENTS.PAYMENT_METHODS.GIFTCARD.VALUE ? 'required' : ''"
            data-vv-name="number"
            data-vv-as="Reference Number"
            :error-messages="errors.collect('number')"
            label="Reference Number*"
            id="reference-number"
            name="reference-number"
            autocomplete="reference-number"
            full
            @input="handleInputUpdate('payment', 'number', $event)"
            >
          </HbTextField>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col class="pt-2 pl-0">
          <HbTextarea
            :class="{'custom-field-error' : errors.first('notes')}"
            rows="2"
            hide-details
            :value="payment.notes"
            data-vv-name="notes"
            :error-messages="errors.collect('notes')"
            label="Notes"
            id="gift-notes"
            name="gift-notes"
            autocomplete="cc-gift-notes"
            full
            @input="handleInputUpdate('payment', 'notes', $event)"
          >
          </HbTextarea>
        </v-col>
      </v-row>

    </v-container>


  </div>
</template>


<script type="text/babel">

    import PaymentMethodBase from './PaymentMethodBase.vue';
    import { EventBus } from '../../../EventBus';
    import { mapGetters, mapMutations } from "vuex";
    import {PAYMENTS} from '@/constants/payments.js';

    export default {
        extends: PaymentMethodBase,
        data() {
            return {
            }
        },
        components:{},
        computed:{
          ...mapGetters({
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            getErrors: 'paymentsStore/getErrors',
            paymentData: 'paymentsStore/getPaymentInformation',
            payment_method: 'paymentsStore/getPaymentMethod',
            contact: 'paymentsStore/getContact',
            payment: 'paymentsStore/getPayment',
        })
        },
        created(){
            this.PAYMENTS = PAYMENTS;
        },
        destroyed() {
        },
        props: ['payment_type'],
        methods:{
          ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPayment: 'paymentsStore/setPayment',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
          }),
          handleInputUpdate : _.debounce(async function(obj ,property_name, property_value) {
            if(obj === 'payment_information'){
              this.setPaymentInformation({property: property_name, propertyValue: property_value});
            }
            if(obj === 'payment'){
              this.setPayment({property : property_name, propertyValue: property_value});
            }
          }, 200),

          validateFields(){
            return this.$validator.validateAll();
          }
        },
        watch:{
            
        }
    }
</script>