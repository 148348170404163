<template>
  <v-dialog
    v-model="dialog"
    width="1366"
    max-width="80%"
    scrollable
    slot="body"
    :content-class="'hb-modal-wrapper'"
  >
    <v-card>
      <v-card-title class="hb-modal-title d-flex align-center ma-0 pa-0">
        <v-col :cols="10" class="ma-0 py-0 px-4 d-flex align-center">
          <span class="hb-modal-title-font-size">
            {{ getModalData.heading }}
            <template v-if="itemsLength">
              ({{ itemsLength }} of Tenants)
            </template>
          </span>
        </v-col>
        <v-col
          :cols="2"
          class="ma-0 pa-0 hb-modal-header-close-padding-right d-flex justify-end"
        >
          <hb-btn icon tooltip="Close" @click="_cancel()" active-state-off>
            mdi-close
          </hb-btn>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pa-0 ma-0"
        style="height: 700px"
        id="assign_rentplan_modal_content"
      >
        <div>
          <div class="header-section">
            <span>
              <div class="hb-modal-sub-header px-4 py-4">
                {{ getModalData.description }}
              </div>
              <v-divider />
            </span>
            <div class="size-detail px-4 py-2" v-if="isValueTiered">
              <v-row v-if="rowData">
                <div class="rent-space-initials px-4 py-2">
                  <div
                    v-if="Object.keys(rowData.tier).length"
                    class="storage-tier"
                  >
                    <strong v-if="rowData.tier.name">
                      Value Tier: {{ rowData.tier.name }}
                    </strong>
                  </div>
                  <div
                    v-if="rowData.category || rowData.size"
                    class="storage-info"
                  >
                    <span v-if="rowData.category">
                      {{ rowData.category }}:
                    </span>
                    {{ rowData.size }}
                  </div>
                  <div v-if="rowData.amenity" class="amenity-info">
                    {{ rowData.amenity }}
                  </div>
                </div>
              </v-row>
            </div>
            <v-divider v-if="isValueTiered" />
          </div>
          <span class="hb-modal-content">
            <div
              class="rent-card-wrap pa-4"
              id="rentplan_cards"
              ref="rentplan_cards"
            >
              <!-- Display the details of each rent plan -->
              <RentCard
                v-for="(rent, index) in rentPlans"
                :key="index"
                :rent-plan="rent"
                :type="'select'"
                :isSelected="rent.id == config.selected_profile"
                :editPermission="false"
                @click="selectRent(rent.id)"
                class="rent-plan-card"
              />
            </div>
          </span>
        </div>
      </v-card-text>
      <hb-bottom-action-bar @close="_cancel()">
        <template v-slot:left-actions>
          <help-juice />
        </template>
        <template v-slot:right-actions>
          <hb-btn :disabled="!isRentplanChanged" @click="_save">
            {{ isValueTiered ? "Select" : "Save" }}
          </hb-btn>
        </template>
      </hb-bottom-action-bar>
    </v-card>
  </v-dialog>
</template>

<script>
import RentCard from "./RentCard.vue";

import { cloneDeep } from "lodash";

export default {
  name: "AssignRentPlan",
  props: {
    isValueTiered: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      config: {
        active: false,
        selected_profile: "",
      },
      rentPlans: [],
      initialSelectedPlan: null,
      rowData: null,
    };
  },
  components: { RentCard },
  watch: {
    dialog: {
      handler(value) {
        if (value)
          this.$nextTick(() => {
            document.getElementById("assign_rentplan_modal_content").scrollTo({
              top: 0,
            });
            if (this.config?.selected_profile)
              this.$refs?.rentplan_cards
                .getElementsByClassName("selected")[0]
                .scrollIntoView(false);
            else this.$refs?.rentplan_cards.scrollTo({
                    top: 0,
                  });
          });
      },
      immediate: true,
    },
  },
  updated() {
    if (this.dialog && this.config.selected_profile)
      document.getElementsByClassName("selected")[0].scrollIntoView(true);
  },
  computed: {
    isRentplanChanged() {
      return this.initialSelectedPlan !== this.config?.selected_profile;
    },

    getModalData() {
      return {
        heading: this.isValueTiered
          ? "Select Rent Plan for Value Tier"
          : "Assign Rent Plan",
        description: `Select the Rent Plan that you would like to apply for the selected ${
          this.isValueTiered ? "Space Group" : "Tenant"
        }.`,
      };
    },
    itemsLength() {
      return this.items.length;
    },
  },
  methods: {
    show(data) {
      this.dialog = true;
      this.rowData = data?.rowData ?? {};
      this.rentPlans = data?.rentPlans ?? [];
      this.initialSelectedPlan = cloneDeep(data?.currentPlanId);
      this.config.selected_profile = data?.currentPlanId;
      return new Promise((resolve, reject) => {
        resolve;
        reject;
      });
    },
    _save() {
      this.dialog = false;
      this.$emit("selected", {
        selectedPlanId: this.config.selected_profile,
        selectedPlanLabel:
          this.rentPlans.find(
            (plan) => plan.id === this.config.selected_profile
          )?.name ?? "",
        tierType: this.rowData?.tier?.type,
        leaseId: this.rowData?.lease_id,
        rowData: this.rowData,
      });
      this.config.selected_profile = "";
    },
    _cancel() {
      this.dialog = false;
      this.config.selected_profile = "";
      this.$emit("close", this.rowData?.tenant_id ?? "");
      console.log("Operation cancelled by user");
    },
    selectRent(index) {
      this.config.selected_profile = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.size-detail {
  background-color: #f4f6f8;
  .space-category {
    text-transform: capitalize;
  }
}
.rent-space-initials {
  font-weight: 400;
  color: black;
  .storage-tier {
    font-size: 16px;
    line-height: 24px;
  }
  .storage-info {
    font-size: 14px;
    line-height: 170%;
  }
  .amenity-info {
    font-size: 12px;
    line-height: 130%;
    color: #637381;
  }
}
.hb-modal-sub-header {
  min-height: 44px;
  // padding-top: 11px;
  // padding-bottom: 14px;
  font-size: 14px;
  color: #637381;
}

.hb-modal-content {
  font-size: 14px;
  color: #101318;
}

.header-section {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.hb-modal-header-close-padding-right {
  padding-right: 8px !important;
}

.hb-modal-content .v-input .v-label {
  line-height: 20px;
}

.hb-modal-title-font-size {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.hb-modal-title {
  min-height: 52px;
}

.v-card__actions.hb-modal-footer-wrapper {
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  height: 60px;
  padding-left: 8px;
}
.rent-card-wrap {
  display: grid;
  // height: auto;
  overflow: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  @media (max-width: 1970px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1470px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}
.rent-plan-card {
  border-top: outset 1px #dfdfdf;
}
</style>
