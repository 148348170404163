export default [
  {
    name: "onBoarding",
    path: '/onboarding',
    component: () => import('../components/onboarding/Index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'OnBoading'
    },
    children: [
      {
        name: "corporate",
        path: '/onboarding/corporate',
        component: () => import('../components/onboarding/corporateSettings/Index.vue'),
        meta: {
          requiresAuth: true,
          hasAccess: ['admin'],
          layout: 'OnBoading'
        }
      },
      {
        name: "property",
        path: '/onboarding/property',
        component: () => import('../components/onboarding/propertySettings/Index.vue'),
        meta: {
          requiresAuth: true,
          hasAccess: ['admin'],
          layout: 'OnBoading'
        }
      }
    ]
  }
]