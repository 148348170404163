<template>
    <div class="overview-panel">

        <div class="slide-out-section">
            <div class="slide-out-section-heading">
                <h2>Leases</h2>
            </div>
            <div class="table">
                <div class="table-row">
                    <div class="table-head">
                        <div>Address</div>
                    </div>
                    <div class="table-head">
                        <div>Rent</div>
                    </div>
                    <div class="table-head">
                        <div>Status</div>
                    </div>
                    <div class="table-head">
                        <div>Balance</div>
                    </div>
                    <div class="table-head actions text-right">
                        <div>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <router-link tag="div" :to="'/leases/' + l.id" class="table-row" v-for="l in contact.Leases" :key="l.id" >
                    <div class="table-cell">
                        <div>
                            SPACE #{{l.Unit.number}}<br />
                            <span class="subdued">
                            {{l.Unit.Address.address}}
                            </span>
                        </div>
                    </div>
                    <div class="table-cell">
                        <div>
                            {{l.rent | formatMoney }}/mo
                        </div>
                    </div>
                    <div class="table-cell">
                        <div>
                            {{l.status}}
                        </div>
                    </div>
                    <div class="table-cell">
                        <div>
                            {{l.balance | formatMoney}}
                        </div>
                    </div>
                    <div class="table-cell actions">
                        <div class="icon">
                            
                        </div>
                    </div>

                </router-link>
            </div>
        </div>
    </div>

</template>
<script type="text/babel">
    export default{
        name: "ContactsLeases",
        data(){
            return {

            }
        },
        props: {
            contact: {
                type:Object
            }
        },
        filters:{
            canSMS(sms){
                return sms ? 'Yes': 'No';
            }
        },
        components:{

        },
        computed:{
            isActiveMilitary(){
                return this.contact.active_military ? 'Yes': 'No';
            }
        },
        created(){

        }

    }
</script>

<style scoped>
    .table-head.light{
        background: white;
        border-bottom: 2px solid #dce8ef;
        padding:8px 10px;
        text-transform: uppercase;
        font-size: 11px;
    }
    .overview-phone-label{
        color: #798f9a;
        font-size: 11px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }

    .contact-holder{
        border-bottom:1px dashed #e2e2e2;
        padding: 10px 0;

    }
    .contact-holder:last-child{
        border-bottom: none;
        padding-bottom: 0;
    }
    .contact-holder:first-child{
        padding-top: 0;
    }


</style>
