export default [
    {
        path: '/invoices/edit/:invoice_id',
        component: () => import('../components/invoices/EditInvoice.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master'
        }
    },
    {
        path: '/invoices/create',
        component: () =>  import('../components/invoices/CreateInvoice.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master'
        }
    },
    {
        path: '/invoices/view/:invoice_id',
        component: () => import('../components/invoices/ViewInvoice.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin', 'tenant'],
            layout: 'unauthenticated'
        }
    },
    {
        path: '/invoices/pay/:id',
        component: () => import('../components/invoices/Pay.vue'),
        meta: {
          requiresAuth: true,
          hasAccess: ['admin',  'tenant'],
          layout: 'unauthenticated'

        }
    }
]
