<template>
    <div>
        <div v-if="!canSave">You cannot edit this lease</div>

        <div v-if="canSave">

            <div class="form-section invoice" v-if="invoice.Lease">
                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>


                <div class="text-h4 hb-text-lighter font-weight-medium">INVOICE</div>

                <div class="text-h6 hb-link mb-3">
                    {{invoice.Lease.Unit.Address.address}} #{{invoice.Lease.Unit.number}}<br />
                    {{ invoice.Lease.Unit.Address | formatAddress }}
                </div>

                <div class="horizontal-form-row">
                    <div class="horizontal-form-label">
                        Date
                    </div>
                    <div class="form-input-container">
                        <div class="horizontal-form-input">
                            <datepicker
                                name="date"
                                id="date"
                                ref="date"
                                data-vv-as="date"
                                v-model="invoice.date"
                                format="MM/dd/yyyy"
                                style="width: 150px;"
                                wrapper-class="date-wrapper"
                                input-class="input w-input date-input light"
                            ></datepicker>
                        </div>
                    </div>

                </div>


                <div class="horizontal-form-row">
                    <div class="horizontal-form-label">
                        Due Date
                    </div>
                    <div class="form-input-container">
                        <div class="horizontal-form-input">
                            <datepicker
                                    name="due"
                                    id="due"
                                    ref="due"
                                    data-vv-as="due date"
                                    v-model="invoice.due"
                                    format="MM/dd/yyyy"
                                    style="width: 150px;"
                                    wrapper-class="date-wrapper"
                                    input-class="input w-input date-input light"
                            ></datepicker>
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row">
                    <div class="horizontal-form-label">
                        Invoice Number
                    </div>
                    <div class="form-input-container">
                        <div class="horizontal-form-input">
                            <input
                                type="text"
                                class="w-input light"
                                name="invoice_number"
                                v-model="invoice.number"
                                v-validate="'max:45'"
                            />
                        </div>
                        <span v-show="errors.has('invoice_number')" class="status-block error-block">{{ errors.first('invoice_number') }}</span>
                    </div>
                </div>
                <div class="horizontal-form-row">
                    <div class="horizontal-form-label">
                       Invoice Period
                    </div>
                    <div class="form-input-container">
                        <div class="horizontal-form-input">
                            <datepicker
                                    name="period_start"
                                    id="period_start"
                                    ref="period_start"
                                    data-vv-as="period_start"
                                    v-model="invoice.period_start"
                                    format="MM/dd/yyyy"
                                    style="width: 150px;"
                                    wrapper-class="date-wrapper"
                                    input-class="input w-input date-input light"
                            ></datepicker>
                        </div>
                        <div class="horizontal-form-input"> - </div>
                        <div class="horizontal-form-input">
                            <datepicker
                                    name="period_end"
                                    id="period_end"
                                    ref="period_end"
                                    data-vv-as="period_end"
                                    v-model="invoice.period_end"
                                    format="MM/dd/yyyy"
                                    style="width: 150px;"
                                    wrapper-class="date-wrapper"
                                    input-class="input w-input date-input light"
                            ></datepicker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section invoice">
                <div class="table">
                    <div class="table-row">
                        <div class="table-head">
                            <div style="width: 250px;">
                                Product
                            </div>
                        </div>
                        <div class="table-head text-right">
                            <div style="padding-right:15px">
                                Qty
                            </div>
                        </div>
                        <div class="table-head text-right">
                            <div style="padding-right:15px">
                                Cost
                            </div>
                        </div>
                        <div class="table-head text-right total-row">
                            Total
                        </div>
                        <div class="table-head text-right action-col">&nbsp;</div>

                    </div>

                    <invoice-line v-for="line, i in invoice.InvoiceLines"
                      :line="line"
                      :index="i"
                      :key="line.id"
                      :lease_id="invoice.lease_id"
                      @addProduct="addProductToLine(i)"
                      @remove="removeLine"></invoice-line>


                    <div class="table-row">
                        <div class="table-cell">
                            <div class="horizontal-form-input" style="width: 250px;">

                                <auto-complete
                                    id="new-product"
                                    name="new-product"
                                    addText="Add Product"
                                    :options="products"
                                    showAll
                                    labelField="name"
                                    @add="showAddProduct = true;"
                                    v-model="product"
                                    @search="searchProducts"
                                    @input="addNewline"
                                >
                                </auto-complete>
                            </div>
                        </div>
                        <div class="table-cell text-right">
                            <div class="horizontal-form-input" style="width: 75px;" :class="{'has-error': errors.has('qty') }">
                                <input type="text" class="w-input light"
                                   v-model="charge.qty"
                                   name="qty"
                                   v-validate="'integer|min_value:0|max_value:9999'"
                                />
                            </div>
                            <span v-show="errors.has('qty')" class="status-block error-block">{{ errors.first('qty') }}</span>

                        </div>
                        <div class="table-cell text-right">
                            <div class="horizontal-form-input" style="width: 75px;" :class="{'has-error': errors.has('cost') }">
                                <div class="form-group">
                                    <div class="form-group-icon icon">$</div>
                                    <input
                                        class="w-input input"
                                        type="text"
                                        v-model="charge.cost"
                                        v-validate="'decimal:2|max_value:99999|min_value:0'"
                                        name="cost"
                                        id="cost"
                                        :class="{'has-error': errors.has('cost') }"
                                    />
                                </div>
                                <span v-show="errors.has('cost')" class="status-block error-block">{{ errors.first('cost') }}</span>
                            </div>
                        </div>


                        <div class="table-cell text-right total-row">
                        </div>
                        <div class="table-cell text-right action-col">
                            &nbsp;
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell blank text-right">Subtotal</div>
                        <div class="table-cell blank text-right  total-row"> {{subTotal | formatMoney }}</div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell blank text-right">Tax</div>
                        <div class="table-cell blank text-right  total-row">{{taxSum | formatMoney }}</div>
                    </div>
                    <!--<div class="table-row" v-show="promotionsTotal > 0">-->
                        <!--<div class="table-cell blank text-right">Promotions</div>-->
                        <!--<div class="table-cell blank text-right  total-row"> {{promotionTotal | formatMoney}}</div>-->
                    <!--</div>-->
                    <div class="table-row">
                        <div class="table-cell blank text-right"><strong>TOTAL</strong></div>
                        <div class="table-cell blank text-right  total-row">
                            <strong> {{invoiceTotal | formatMoney }} </strong>
                        </div>
                    </div>

                </div>

            </div>
            <div class="modal-footer mr-7 mb-4 d-flex align-center justify-end">
            <hb-link class="mr-2" @click="$emit('cancel')">Cancel</hb-link>
            <!-- <hb-link v-if="invoice.id" class="mr-2" @click="$emit('cancel')">Cancel</hb-link> -->
            <hb-btn :disabled="isLoading($options.name)" color="primary" @click="save">Save Invoice</hb-btn>
            <span v-show="isLoading($options.name)">
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </div>

        </div>
        <!--<modal v-if="showAddProduct" size="lg" allow-click-away="false" @close="showAddProduct = false">-->
            <!--<h3 slot="header">Add Product</h3>-->
            <!--<add-product size="lg" slot="body" @close="showAddProduct = false">-->
            <!--</add-product>-->
        <!--</modal>-->

        <modal v-if="showAddProduct" size="lg" allow-click-away="false" @close="showAddProduct = false">
            <h3 slot="header">Add Product</h3>
            <add-product size="lg" slot="body" @close="showAddProduct = false" @save="setNewProduct" >
            </add-product>
        </modal>

        <modal v-if="showApplyPromotion" size="lg" allow-click-away="false" @close="showApplyPromotion = false">
            <h3 slot="header">Apply Promotion</h3>
            <apply-promotion
                size="lg"
                slot="body"
                :promotions="unusedDiscounts"
                @close="showApplyPromotion = false"
                @input="applyPromotions"
            >
            </apply-promotion>
        </modal>
    </div>

</template>

<script type="text/babel">

    import AutoComplete from '../assets/Autocomplete.vue';
    import PulseLoader from '../assets/DotLoader.vue';
    import Modal from '../assets/Modal.vue';
    import InvoiceLine from './InvoiceLine.vue';
    import ApplyPromotion from './ApplyPromotion.vue';
    import Datepicker from 'vuejs-datepicker';
    import AddProduct from '../settings/EditProduct.vue';

    import { EventBus } from '../../EventBus.js';

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';


    export default {
        name: "CreateInvoice",
        data() {
            return {
                invoice:{
                    id: '',
                    lease_id: '',
                    date: '',
                    due: '',
                    period_start: '',
                    period_end: '',
                    paid: 0,
                    number: '',
                    Payments: [],
                    InvoiceLines: [],
                    lease:{
                        Address:{},
                        id: {},
                    },
                },
                taxRate: 0,
                products:[],
                canSave: false,
                charge:{
                    product_id: null,
                    service_id: null,
                    cost: null,
                    qty: null
                },
                product:{},
                showAddProduct:false,
                discounts:[],
                showApplyPromotion: false,
                selectedLineIndex: null,
            }
        },
        components:{
            Modal,
            Datepicker,
            AutoComplete,
            InvoiceLine,
            ApplyPromotion,
            Loader,
            Status,
            PulseLoader,
            AddProduct
        },
        created (){

            if(this.selected && this.selected.id && !this.isAdjusted){
                this.invoice = JSON.parse(JSON.stringify(this.selected));
                this.verifyEdit();
            } else if (this.isAdjusted) {
                this.invoice = JSON.parse(JSON.stringify(this.selected));
                this.fetchLeaseInfo();
                this.getNextInvoiceNumber();
                this.canSave = true;
                this.invoice.id= null
            }
            else {
                this.fetchLeaseInfo();
                this.getNextInvoiceNumber();
                this.canSave = true;
            }
           // this.fetchDiscounts();
            this.getTaxRate();
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            }
        },
        computed:{
            promotionTotal(){
                var _this = this;
                if(!this.invoice.InvoiceLines) return 0;
                var discountTotal = 0;
                this.invoice.InvoiceLines.forEach(function(line){
                    var lineDiscount = 0;
                    if(line.Product.default_type == 'rent'){
                        var lineTotal = line.qty * line.cost;
                        var discAmt = 0;
                        _this.discounts.forEach(function(d){
                            switch(d.type){
                                case 'percent':
                                    discAmt = (lineTotal * parseFloat(d.value / 100));
                                    break;
                                case 'dollar':
                                    discAmt = (lineTotal - parseFloat(d.value));
                                    break;
                            }
                            lineDiscount += discAmt;
                        });
                        discountTotal += (lineDiscount < lineTotal) ? lineDiscount: lineTotal;
                    }
                });
                return discountTotal;
            },
            taxSum(){
                var _this = this;
                if(!this.invoice.InvoiceLines) return 0;
                return this.invoice.InvoiceLines.reduce(function(a, b){
                    var taxableAmount = _this.getTaxableAmount(b);
                    return a +=  (taxableAmount)? ( taxableAmount * (_this.taxRate / 100)) : 0;
                }, 0)
            },
            subTotal(){
                if(!this.invoice.InvoiceLines) return 0;
                return  this.invoice.InvoiceLines.reduce(function(a, b){
                    return a +=  (b.cost * b.qty)
                }, 0);
            },
            invoiceTotal(){
                return this.taxSum + this.subTotal - this.promotionTotal;
            }
        },
        methods: {

            addProductToLine(i){
                this.selectedLineIndex = i;
                this.showAddProduct = true;
            },

            updateDate(date){
                this.invoice.date = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
            },

            updateDue(due){
                this.invoice.due = moment(due, 'MM/DD/YYYY').format('YYYY-MM-DD');
            },

            getTaxableAmount(line){
                if(!line.Product.taxable) return 0;
                var lineTotal = line.cost * line.qty;
                if(line.Product.default_type != 'rent') return lineTotal;
                this.discounts.forEach(function(d){
                    var discAmt = 0;
                    if(d.pretax){
                        switch(d.type){
                            case 'percent':
                                discAmt = (lineTotal * parseFloat(d.value / 100));
                                break;
                            case 'dollar':
                                discAmt = (lineTotal - parseFloat(d.value));
                                break;
                        }
                        lineTotal = lineTotal - discAmt || 0;
                    }
                });
                return lineTotal;
            },

            getTaxRate(){
                api.get(this, api.SETTINGS_BILLING).then(r => {
                    this.taxRate = r.settings.taxRate
                });
            },

            getNextInvoiceNumber(){
                api.get(this, api.MAX_INVOICE_NUMBER).then(r => {
                    this.invoice.number = parseInt(r.invoice_number) + 1
                });
            },

            searchProducts(search){
                search = search || '';
                this.product.name = search;

                var params = {
                    type: 'product',
                    all: true,
                    search: search,
                    lease_id: this.invoice.lease_id
                };

                api.get(this, api.PRODUCTS + 'list', params).then(r => {
                    this.products = r.products;
                });

            },

            save(){
                if(!this.canSave) return;
                var data = JSON.parse(JSON.stringify(this.invoice));
                data.date = moment(data.date).format('YYYY-MM-DD');
                data.due = moment(data.due).format('YYYY-MM-DD');
                data.period_start = moment(data.period_start).format('YYYY-MM-DD');
                data.period_end = moment(data.period_end).format('YYYY-MM-DD');


                this.$validator.validateAll().then(() => {
                    if(!this.isLoading(this.$options.name)){
                        this.errorClear(this.$options.name);
                        api.post(this, api.SAVE_INVOICE, data).then(r => {

                            this.successSet(this.$options.name, "Invoice Updated");
                            EventBus.$emit('invoice_saved');
                        })
                    }
                }).catch(err => {
                    this.errorSet(_this.$options.name, "You have errors on your invoice.  Please fix them before continuing");
                });


            },

            setNewProduct(p){
                if(this.selectedLineIndex != null){
                   this.invoice.InvoiceLines[this.selectedLineIndex].Product = p;
                   this.invoice.InvoiceLines[this.selectedLineIndex].product_id = p.id;
                   this.invoice.InvoiceLines[this.selectedLineIndex].cost = p.price;
                } else {
                    this.addNewline(p);
                }
            },

            removeLine(index){
                this.invoice.InvoiceLines.splice(index, 1);
            },

            addNewline(product){

                if(!product || !product.id) {
                    this.product = {};
                    return;
                }
                this.products = [];

                var newLine = {
                    Product: product,
                    product_id: product.id,
                    cost: product.price || 0,
                    qty: this.charge.qty || 1,
                    DiscountLines:[]
                };

                this.invoice.InvoiceLines.push(newLine);

                this.charge = {
                    product_id: null,
                    cost: null,
                    qty: null
                };
                this.product = {};

            },

            verifyEdit(){

                if(this.invoice.Payments && this.invoice.Payments.length) {
                    this.canSave = false;
                    this.errorSet(this.$options.name, "This invoice cannot be edited because it has payments applied to it. Please unapply the payments and then try again");
                    return;
                }
                this.canSave = true;

            },

            fetchLeaseInfo(){

                api.get(this, api.LEASES + this.$route.params.lease_id).then(r => {

                    this.canEdit = true;
                    this.invoice.lease_id = r.lease.id;

                    this.$set(this.invoice, 'Lease', r.lease);
                    this.invoice.date = moment().format('YYYY-MM-DD');
                    this.invoice.due = moment().format('YYYY-MM-DD');
                    this.invoice.period_start = moment().toDate('YYYY-MM-DD');
                    this.invoice.period_end = moment().toDate('YYYY-MM-DD');

                    if(this.invoice.lease_id){
                        this.fetchDiscounts();
                    }
                });
            },
            fetchDiscounts(){
                let params = {
                    lease_id:  this.invoice.lease_id,
                    date: this.invoice.due
                };
                api.get(this, api.LEASES + this.invoice.lease_id + '/discounts', params).then(r => {
                    this.discounts = r.discounts;
                });
            },

            away(){
                return this.openSuggestion = false;
            }

        },


        props:{
            selected: {
                type: Object,
                default: () => {}
            },

            onboard:{
                type: Boolean,
                default: false
            },
            isAdjusted: {
                type: Boolean,
                default: false
            }
        },
        watch:{
            'invoice.due': function(){
             //   this.fetchDiscounts();
            },
            selected:function(newInvoice){
                this.invoice = newInvoice;
                this.verifyEdit();
            },

        }
    }
</script>
<style scoped>
    .address-box h2{
        border-bottom: none;
        text-align: left;
    }
    h1{
        color: #cccccc;
    }

    .invoice h2 {
        font-weight: 500;
        color: #0277BD;
        font-size: 18px;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        line-height: 24px;
    }


    .invoice h4 {
        font-weight: 400;
        color: #0277BD;
        font-size: 14px;
        padding-bottom: 0px;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        line-height: 24px;
    }

    .invoice-section-row{
        display: flex;
        align-items: flex-start;
    }
    .invoice-section{
        flex-grow:1;
    }
    .total-row{
        flex: 0 0 125px;
    }

    .action-col{
        flex: 0 0 50px;
    }

</style>

