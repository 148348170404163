<template>
    <div class="bg-themed">
        <!--
        <p class="interaction ma-0 custom-description">This is a description for Lease History!</p>
        -->
        <!-- <div class="slide-out-section">
            <div class="slide-out-section-heading">
                <h2>Lease History</h2>
            </div>
        </div> -->
        <div class="pa-6">
            <hb-data-table
                :headers="headers"
                :items="leases"
                @click:row="goToLease"
            >
                <template v-slot:item.dates="{ item }">
                    {{item.start_date | formatLocalShortDate}}
                    <span v-if="item.end_date"> - {{item.end_date | formatLocalShortDate}}</span>
                </template>
                <template v-slot:item.rent="{ item }">
                    {{item.rent | formatMoney}}
                </template>
                <template v-slot:item.tenant="{ item }">
                    <span v-for="(t, index) in item.Tenants" :key="index">
                        {{t.Contact.salutation}} {{t.Contact.first}} {{t.Contact.middle}} {{t.Contact.last}} {{t.Contact.suffix}}<br />
                    </span>
                </template>
            </hb-data-table>
        </div>

        <!-- <div class="ma-4 v-card">
            <div class="lease-holder" :class="{'': isCurrent(l)}" v-for="l in leases">

                <div class="horizontal-form-row pa-0">
                    <div class="horizontal-form-label pa-0">
                        <strong>Dates</strong>
                    </div>
                    <div class="horizontal-form-text pa-0">
                        {{l.start_date | formatDate}} <span v-if="l.end_date"> - {{l.end_date | formatDate}} </span>
                    </div>
                </div>

                <div class="horizontal-form-row pa-0">
                    <div class="horizontal-form-label pa-0">
                        <strong>Rent</strong>
                    </div>
                    <div class="horizontal-form-text pa-0">
                        {{l.rent | formatMoney}}
                    </div>
                </div>

                <div class="horizontal-form-row pa-0">
                    <div class="horizontal-form-label pa-0">
                        <strong>Tenants</strong>
                    </div>
                    <div class="horizontal-form-text pa-0">
                        <span v-for="t in  l.Tenants">
                            {{t.Contact.salutation}} {{t.Contact.first}} {{t.Contact.last}} {{t.Contact.suffix}}<br />
                        </span>
                    </div>
                </div>
                <div class="horizontal-form-row pa-0">
                    <div class="horizontal-form-label pa-0">

                    </div>
                    <div class="align-right col horizontal-form-text pa-0">
                        <v-btn class="text-capitalize" color="primary" @click="goToLease(l.id)">View Lease</v-btn>
                    </div>
                </div>

            </div>

            <div class="slide-out-section" v-if="!leases.length">
                <strong>This space has never been leased.</strong>
            </div>

        </div> -->
    </div>
</template>


<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    export default{
        name: "UnitDetails",
        data(){
            return {
                unit: {},
                headers: [
                    { text: 'Dates', value: 'dates' },
                    { text: 'Rent', value: 'rent' },
                    { text: 'Tenant', value: 'tenant' }
                ],
            }
        },
        props: ['unit_id', 'leases'],
        created(){
            // BCT: Moved fetchData() to parent component.
            // this.fetchReservations();
            // this.fetchPending();
        },

        methods:{
            isCurrent(l){
                var start = moment(l.start_date, 'YYYY-MM-DD').startOf('day').format('x');
                var end = l.end_date ? moment(l.end_date, 'YYYY-MM-DD').startOf('day').format('x'): null;
                var today = moment().startOf('day').format('x');
               return start <= today && (!end || end > today);

            },
            goToLease(item){
                api.get(this, api.LEASES + item.id).then(r => {
                    let path = `/contacts/${r.lease.Tenants[0].contact_id}`;
                    if(r.lease.Unit?.property_id) path += `?property_id=${r.lease.Unit?.property_id}`;
                    this.$router.push({path});
                });
            },


            fetchReservations(){
                api.get(this, api.UNITS + this.unit_id + '/reservations', {all: true} ).then(r=> {
                    this.reservations = r.reservations;
                });
            },

            fetchPending(){
                api.get(this, api.UNITS + this.unit_id + '/pending' ).then(r=> {
                    this.pending = r.pending;
                });
            }

        },
        watch:{
            unit_id(){
                this.fetchData();
            }
        }
    }

</script>
<style scoped>
    .lease-holder{
        padding: 15px 25px;
        border-bottom: 2px solid #dce8ef;
    }
    .bg-white{
        background-color: #fff;
    }
    .bg-themed{
        background-color: #FAF9FB;
    }
    .unit-hist-table {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
    }
    .unit-hist-table .table-head{
        background: #FFFFFF;
        /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
        border-bottom: 1px solid rgba(0, 0, 0, 0.22);
    }
    .unit-hist-table .table-head strong{
        color: #474F5A;
    }
    /* .unit-hist-table .table-row:hover:not(:first-child){
        background: #F9FAFB;
    } */
    .unit-hist-table .table-row {
        cursor: inherit;
    }
    .unit-hist-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
    .unit-hist-table .table-row:not(:first-child){
        cursor: pointer;
    }
    .unit-hist-table .table-row:not(:first-child):hover{
        background-color: #FAF9FB;
    }
    .role-modal-header{
        font-size: 16px;
        color: #101318;
    }
    .no-border-all{
        border: none;
    }
    .light-text{
        color: #637381;
    }
    .key-heading{
        font-size: 15px;
        color: #101318;
    }
</style>
