import ViewPaymentContentVue from '../components/payments/ViewPaymentContent.vue';
export default [
    {
        path: '/reports',
        component: () => import('../components/reports/Index.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master'
        }
    },
    // Test section for BI tool
    {
      path: '/test-reports',
      component: () => import('../components/BI/index.vue'),
      meta: {
          requiresAuth: true,
          hasAccess: ['admin'],
          layout: 'master'
      }
  },
    // {
    //   path: '/reports/web/invoice',
    //   component:  () => import('../components/invoices/WebView.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },
    // {
    //   path: '/reports/web/payment/',
    //   component: () => import('../components/payments/WebView.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },
    {
      path: '/reports/view/:report_id',
      component: () => import('../components/reports/ReportView.vue'),
      meta: {
        requiresAuth: true,
        hasAccess: ['admin'],
        layout: 'master'
      }
    },
    // {
    //   path: '/reports/generated/:hash',
    //   component: () => import('../components/reports/Generated.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },
    {
        path: '/reports/:report_type',
        component: () => import('../components/reports/ReportView.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master'
        }
    },


    // {
    //   path: '/reports/web/management-summary',
    //   component:  () => import('../components/reports/SummaryReports/ManagementSummary.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },
    // {
    //   path: '/reports/web/summary/:type',
    //   component:  () => import('../components/reports/ReportViewer.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },

    // {
    //   path: '/reports/web/standard-report',
    //   component: () => import('../components/reports/SummaryReports/GenericReport.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },
  // {
  //   path: '/reports/web/management-summary',
  //   component:  () => import('../components/reports/ManagementSummary.vue'),
  //   meta: {
  //     layout: 'unauthenticated'
  //   }
  // },
  {
    path: '/reports/web/occupancy-report',
    component: () => import('../components/reports/Occupancy.vue'),
    meta: {
      layout: 'unauthenticated'
    }
  },

    // {
    //   path: '/reports/web/daily-deposits',
    //   component: () => import('../components/reports/SummaryReports/DailyDeposits.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // },
    // {
    //   path: '/reports/web/auto-pay',
    //   component: () => import('../components/reports/SummaryReports/AutoPay.vue'),
    //   meta: {
    //     layout: 'unauthenticated'
    //   }
    // }
]