<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <span style="width:100%;" class="hb-text-night">
        <v-row v-model="notification" class="pl-3 ma-0 hb-default-font-size hb-notification-wrapper" :class="[ notificationColorClass, colsLeft === 12 ? 'pr-3' : '' ]" ref="hbNotificationRow" v-resize="emitCondenseActions">
            <v-col :cols="colsLeft" class="ma-0 px-0 py-2 d-flex" :style="textAlignJustify ? 'text-align:justify' : ''">
                <hb-icon v-if="!iconOff" :color="computedIconColor" class="ml-0 mr-2 d-flex align-start" :mdi-code="notificationIcon"></hb-icon>
                <span>
                    <span class="font-weight-medium">{{ notificationTitle }}</span>
                    <span class="font-weight-regular hb-default-font-size" :class="{ 'pl-1' : !textAlignJustify }">
                        <slot></slot>
                    </span>
                </span>
            </v-col>
            <v-col :cols="colsRight" class="ma-0 pa-0 pl-3 d-flex justify-end">
                <span v-if="condenseActions && $slots.condensedActions" class="font-weight-regular hb-default-font-size hb-notification-actions" :class="{ 'hb-notifications-actions-not-dismissable' : notDismissable }">
                    <slot name="condensedActions"></slot>
                </span>
                <span v-else class="font-weight-regular hb-default-font-size hb-notification-actions" :class="{ 'hb-notifications-actions-not-dismissable' : notDismissable }">
                    <slot name="actions"></slot>
                </span>
                <span class="mr-0" v-if="!notDismissable">
                    <hb-btn icon tooltip="Close" active-state-off :hover-background-color="closeHoverColor" @click="close">mdi-close</hb-btn> 
                </span>
            </v-col>
        </v-row>
    </span>
</template>
<script type="text/babel">

    import HbBtn from './HbBtn.vue';
    import HbIcon from './HbIcon.vue';

    export default {
        name: "HbNotification",
        data: function() {
            return {
                show: true,
                buttonGroup: null,
                condenseActions: false,
            };
        },
        components: {
            HbBtn,
            HbIcon
        },
        mounted() {
            this.emitCondenseActions();
        },
        computed: {
            notification: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            colsLeft(){
                if(this.leftCols){
                    return this.leftCols;
                } else if(!this.hasActionsSlot){
                    return 11;
                } else if(this.type === 'quick-actions' && this.$vuetify.breakpoint.xs){
                    return 6;
                } else if(this.type === 'quick-actions' && this.$vuetify.breakpoint.smAndUp){
                    return 3;
                } else {
                    return 9;
                }
            },
            colsRight(){
                if(this.rightCols){
                    return this.rightCols;
                } else if(!this.hasActionsSlot){
                    return 1;
                } else if(this.type === 'quick-actions' && this.$vuetify.breakpoint.xs){
                    return 6;
                } else if(this.type === 'quick-actions' && this.$vuetify.breakpoint.smAndUp){
                    return 9;
                } else {
                    return 3;
                }
            },
            hasActionsSlot(){
                return !!this.$slots['actions'];
            },
            notificationColorClass(){
                if(this.type === 'caution'){
                    return 'hb-notification-caution';
                }
                else if(this.type === 'error' || this.type === 'warning' || this.type === 'auction'){
                    return 'hb-notification-error';
                }
                else if(this.type === 'success'){
                    return 'hb-notification-success';
                }
                else if(this.type === 'quick-actions'){
                    return 'hb-notification-quick-actions';
                }
                else if(this.type === 'guidance'){
                    return 'hb-notification-guidance'
                }
                else if(this.type === 'info'){
                    return 'hb-notification-info'
                }
                else {
                    return '';
                }
            },
            notificationIcon(){
                if(this.mdiCode){
                    return this.mdiCode;
                }
                else if(this.type === 'caution'){
                    return 'mdi-alert';
                }
                else if(this.type === 'error' || this.type === 'warning'){
                    return 'mdi-alert-circle';
                }
                else if(this.type === 'success'){
                    return 'mdi-check'
                }
                else if(this.type === 'guidance'){
                    return 'mdi-information'
                }
                else if(this.type === 'quick-actions'){
                    return 'mdi-flash-circle';
                }
                else if(this.type === 'auction') {
                    return 'gavel';
                }
                else if(this.type == 'info') {
                    return 'mdi-information';
                }
                else {
                    return '';
                }
            },
            computedIconColor(){
                if(this.iconColor){
                    return this.iconColor;
                }
                else if(this.type === 'caution'){
                    return '#FFD600';
                }
                else if(this.type === 'error' || this.type === 'warning' || this.type === 'auction'){
                    return '#FB4C4C';
                }
                else if(this.type === 'success'){
                    return '#02AD0F';
                }
                else if(this.type === 'guidance'){
                    return '#3D8FFF';
                }
                else if(this.type === 'quick-actions'){
                    return '#101318';
                }
                else if(this.type === 'info'){
                    return '#306fb6';
                }
                else {
                    return '';
                }
            },
            closeHoverColor(){
                if(this.type === 'caution'){
                    return '#F5EBB7';
                }
                else if(this.type === 'error' || this.type === 'warning'){
                    return '#EFCFCC';
                }
                else if(this.type === 'success'){
                    return '#C5EDC9';
                }
                else if(this.type === 'guidance'){
                    return '#BAD7FF';
                }
                else if(this.type === 'quick-actions'){
                    return '#C6E5E2';
                }
                else {
                    return '';
                }
            },
            notificationTitle(){
                if(this.haveTitle === false || this.titleOff) {
                    return '';
                } 
                if(this.title){
                    return this.title;
                }
                if(this.type === 'caution'){
                    return 'Warning:';
                }
                else if(this.type === 'error' || this.type === 'warning'){
                    return 'Error:';
                }
                else if(this.type === 'success'){
                    return 'Success:'
                }
                else if(this.type === 'quick-actions'){
                    return 'Quick Actions:';
                }
                else {
                    return '';
                }
            }
        },
        methods: {
            close(){
                this.notification = false;
                this.$emit('close');
            },
            emitCondenseActions() {
                setTimeout(() => {
                    if(this.$refs.hbNotificationRow){
                        var height = this.$refs.hbNotificationRow.clientHeight;
                        if(height > 50){
                            this.$emit('condense-actions', true);
                            this.condenseActions = true;
                        } else {
                            this.$emit('condense-actions', false);
                            this.condenseActions = false;
                        }
                    }
                }, 500);
            },
        },
        props: {
            type:{
                type: String
            },
            title:{
                type: String
            },
            haveTitle: {
                type: Boolean,
                default: true
            },
            titleOff: {
                type: Boolean,
                default: false
            },
            notDismissable:{
                type: Boolean,
                default: false
            },
            value:{
                type: Boolean,
                default: false
            },
            leftCols: {
                type: Number,
                default: undefined
            },
            rightCols: {
                type: Number,
                default: undefined
            },
            mdiCode: {
                type: String,
                default: undefined
            },
            iconOff: {
                type: Boolean,
                default: false
            },
            iconColor: {
                type: String,
                default: undefined
            },
            textAlignJustify: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

.hb-notification-caution {
  background-color: #FFF9DA;
  border: 1px solid #FFD600;
  border-radius: 4px;
}
.hb-notification-error {
  background-color: #F8E5E1;
  border: 1px solid #f96060;
  border-radius: 4px;
}
.hb-notification-success {
  background-color: #E1FAE3;
  border: 1px solid #72d479;
  border-radius: 4px;
}
.hb-notification-guidance {
  background-color: #E7F1FF;
  border: 1px solid #3d8fff;
  border-radius: 4px;
}
.hb-notification-quick-actions {
  background-color: #E0F5F5;
  border: 1px solid #b3dee0;
  border-radius: 4px;
}

.hb-notification-info {
  background-color: #e9f3ff;
  border: 1px solid #306fb6;
  border-radius: 4px;
}

.hb-notification-actions >>> .hb-btn-not-icon {
    position: relative;
    top: 6px !important;
}

.hb-notification-actions.hb-notifications-actions-not-dismissable >>> .hb-btn-not-icon {
    padding-right: 12px !important;
}

</style>