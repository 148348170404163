<template>
        
    
    <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
        <div class="primary-section-content">
            <hb-header
                :divider="false"
            >
                <template v-slot:left>

                    <hb-autocomplete
                        single-line
                        rounded
                        placeholder="Select"
                        :items="['Today','Past Week','Past Month','All Time']"
                        v-model="period"
                        @change="fetchData()"
                        width="370px"
                        style="display:inline-block"
                    ></hb-autocomplete>
                </template>
                <template v-slot:right>
                    <div>
                        <hb-text-field
                            @change="searchSelect"
                            search
                            v-model="searchField"
                            width="370px"
                        ></hb-text-field>
                    </div>
                    <div>
                        <hb-btn
                            class="ml-1"
                            icon
                            tooltip="Filters"
                            :active="showMasterFilterModal"
                            @click="showMasterFilter"
                            >mdi-filter-variant</hb-btn>
                    </div>
                </template>
            </hb-header>
                <GetFilter v-if="showMasterFilterModal" @DRFilters="filter" @close="showMasterFilterModal=false">
                    
                </GetFilter> 
        
           
            <v-card class="mt-0 mb-3" elevation="0">
                <v-layout column style="height: 90vh">
                    <v-flex md6 style="overflow: auto">
                <hb-data-table
                    :headers="headers"
                    :items="items.refunds"
                    :loading="loadingDocuments"
                    loading-text="Loading Documents..."
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    class="select-filter d-flex"
                >
                <template v-slot:item.direct_refund_id="{ item }">
                    {{item.id }}
                </template>
                <template   class="d-flex" v-slot:item.request_date="{ item }">
                    {{item.request_date | formatDateServices }}
                </template>
                <template v-slot:item.property_name="{ item }">
                    {{item.property_name}}
                </template>
                <template  v-slot:item.space_num="{ item }">
                    <hb-icon color="#101318" small class="pr-1"
                        mdi-icon="mdi-facility-custom-3"></hb-icon> {{item.space_num}}
                </template>
                <template v-slot:item.amount="{ item }">
                    {{"$ " + item.amount}}
                </template>
                <template v-slot:item.refund_types="{ item }">
                    {{'Rentals'}}
                </template>
                <template v-slot:item.Payment_method="{ item }">
                    <spam v-if="item.Payment_method=='directdebit'">Direct Debit</spam>
                    <spam v-if="item.Payment_method=='eftpos'"> EFTPOS </spam>
                    <spam v-if="item.Payment_method=='cash'">Cash</spam>
                    
                </template>
                <template v-slot:item.since_last_payment="{ item }">
                    {{calculateDateDifference(item.request_date) + " Days"}}
                </template>
                <template v-slot:item.ref_name="{ item }">
                    {{item.ref_name}}
                </template>
                <template v-slot:item.move_out_date="{ item }">
                    {{item.move_out_date | formatDateServices}}
                </template>
                <template v-slot:item.status="{ item }">
                    <spam v-if="item.status=='approval_pending'" class= "hb-font-caption-medium" ><hb-icon color="#FFD600" small class="pr-1"
                        mdi-icon="mdi-alert-octagon"></hb-icon> Approval Pending</spam>
                    <spam v-else-if="item.status=='approved'" class= "hb-font-caption-medium"><hb-icon color="#02AD0F" small class="pr-1"
                        mdi-icon="mdi-check-circle"></hb-icon> Approved</spam>
                    <spam v-else-if="item.status=='void'" class= "hb-font-caption-medium"  style="color: #FB4C4C"><hb-icon color="#FB4C4C" small class="pr-1"
                        mdi-icon="mdi-alert"></hb-icon> Voided</spam>
                    <spam v-else-if="item.status=='processed'" class= "hb-font-caption-medium"><hb-icon color="#3D8FFF" small class="pr-1"
                        mdi-icon="mdi-check-circle"></hb-icon> Processed</spam>
                    
                </template>
                <template v-slot:item.status_details="{ item }">
                    {{item.status_details}}
                </template>

                <!-- void Reason -->
                <template v-slot:item.void_reason="{ item }">
                    <v-tooltip bottom v-if="item.status==='void'" >
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot:default="{ hover }">
                                <span v-on="on" style="color: #0288D1; cursor: pointer; ">
                                    View Reason
                                </span>
                            </v-hover>
                        </template>
                        <span>{{ item.void_reason || 'No reason provided' }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <hb-menu options class="d-flex justify-end" v-if="item.status=='approved' || item.status=='approval_pending'">
                        <v-list v-if="item.status=='approval_pending'">

                            <v-list-item :ripple="false" @click="setStatus('approved', item.id)">
                                <v-list-item-title>Approve</v-list-item-title>
                            </v-list-item>

                            <v-list-item :ripple="false" @click="openVoidDialog(item.id)">
                                <v-list-item-title>Void</v-list-item-title>
                            </v-list-item>

                        </v-list>
                        <v-list v-if="item.status=='approved'">

                            <v-list-item :ripple="false" @click="setStatus('processed', item.id , item.payment_id, item.amount, item.invoices_payment_id)">
                                <v-list-item-title>Process</v-list-item-title>
                                    </v-list-item>

                                <v-list-item :ripple="false" @click="openVoidDialog(item.id)">
                                <v-list-item-title>Void</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </hb-menu>
                </template>
                
                </hb-data-table>
                    </v-flex>
                </v-layout>
                
                <display-void 
                    v-if="showVoidDialog" 
                    :refund_id="selectedItemId" 
                    :showVoidReason="showVoidDialog"  
                    @submit-void-reason="handleVoidReason"
                    @close="showVoidDialog = false" 
                />
                
                <v-divider v-if="infinityLoader"></v-divider>
                <div class="d-flex align-center justify-center" v-if="infinityLoader">
                    <v-progress-circular indeterminate width="2" size="24" class="mx-0 pa-0 my-4" color="primary"></v-progress-circular>
                </div>
            </v-card>
        </div>

    </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import { mapGetters, mapActions } from 'vuex';
    import Loader from '../assets/CircleSpinner.vue';
    import { notificationMixin } from '../../mixins/notificationMixin.js';
    import api from '../../assets/api.js';
    import GetFilter from './Getfilter.vue'; 
    import moment from 'moment';
    import DisplayVoid from './DisplayVoid.vue';

    export default {
        name: "FilesList",
        mixins: [ notificationMixin ],
        data() {
            return {
                todaysDate: '',
                showMasterFilterModal: false,
                showVoidDialog: false, 
                selectedItemId: null,
                loadingDocuments: false,
                filters:'',
                searchField: '',
                headers: [
                    { text: 'Id', value: 'direct_refund_id', sortable: false },
                    { text: 'Request Date', value: 'request_date', sortable: false },
                    { text: 'Property', value: 'property_name', sortable: false },
                    { text: 'Space', value: 'space_num' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Refund Category', value: 'refund_types' },
                    { text: 'Last Payment Method', value: 'Payment_method'},
                    { text: 'Days Since Last Payment', value: 'since_last_payment' },
                    { text: 'Tenant', value: 'ref_name' },
                    { text: 'Move-Out Date', value: 'move_out_date' },
                    { text: 'Status', value: 'status' },
                    { text: 'Status Details', value: 'status_details' },
                    { text: 'Void Reason', value: 'void_reason' },
                    { text: '', value: 'actions', align: 'right', sortable: false },
                ],
                property: '',
                items: [],
                infinityLoader: false,
                currentPage: 0,
                period: '',
                totalPages: 0,
                payment_id:''
            }
        },
        components:{
            HbDatePicker,
            GetFilter,
            DisplayVoid
        },
        mounted() {
        },
     async created (){
       
        await this.fetchData()
        },
        computed:{
          ...mapGetters({
              isAdmin: 'authenticationStore/isAdmin',
              properties: 'propertiesStore/filtered',
              getUserData: 'authenticationStore/getUserData'
          }),
          
        },
        methods:{
		    ...mapActions({
			    reversePayment: 'paymentsStore/reversePayment',
                setReversal: "paymentsStore/setReversal",
		    }),

            async fetchData(){
                if(this.period){
                    await api.get(this, api.DIRECT_REFUNDS, {period : this.period} ).then(r => {
                console.log("period ", this.period)
                this.items = r;       
                });
                }
                else {
                    await api.get(this, api.DIRECT_REFUNDS).then(r => {
                        this.items = r;      
                });
                }
            },
            async searchSelect(){
                let period = {
                                endDate : this.filters.endDate ?? '', 
                                property_id : this.filters.property ?? '',
                                startDate : this.filters.startDate ?? '',
                                status : this.filters.status ?? '', 
                                searchField : this.searchField ?? ''
                } 
                await api.get(this, api.DIRECT_REFUNDS, period ).then(r => {
                    this.items = r;       
                });


            },
            calculateDateDifference(date) {
                const given = moment(date);
                const current = moment();
                return current.diff(given, 'days');
            },
            showMasterFilter() {
                this.showMasterFilterModal = true;
            },
            onCloseDialog() {
                this.$emit("close");
            },
            async setStatus(status, id, payment_id, amount, invoices_payment_id){
                if(status==="processed"){
                    this.payment_id = payment_id
                    if(!this.payment_id) return;
                    let r = await api.get(this, api.GET_PAYMENT + this.payment_id + "?unit_info=true");

                    var invoices = [];

                    for(var i=0; i<r.payment.AppliedPayments.length; i++) {
                        const refundAmount =r.payment.AppliedPayments[i].amount;

                        if(invoices_payment_id == r.payment.AppliedPayments[i].id){

                            invoices.push({
                            invoices_payment_id: r.payment.AppliedPayments[i].id,
                            amount: amount,
                            number:r.payment.AppliedPayments[i].Invoice.number,
                            invoice_amount: r.payment.AppliedPayments[i].amount,

                        });
                        }

                      
                    }
                    
                    let data= {
                        amount: amount,
                        invoices: invoices ,
                        is_prepay: false,
                        reason:' ',
                        reversal_type: "directrefund"
                    }

                    try{  
                    let response = await api.post(this, api.GET_PAYMENT + this.payment_id + "/refund", data);
                    await api.post(this, api.DIRECT_REFUNDS+ 'update_status' , {refund_status: status, id: id , payment_id: payment_id} )
                    }catch(error){ 
                        this.showMessageNotification({ description : error });
                    }    
                }
                await api.post(this, api.DIRECT_REFUNDS+ 'update_status' , {refund_status: status, id: id , payment_id: payment_id} )
 
                await this.fetchData()

            },

            openVoidDialog(id) {
                this.selectedItemId = id;
                this.showVoidDialog = true;
            },
            async handleVoidReason(void_reason) {
                try {

                await api.post(this, api.DIRECT_REFUNDS+ 'void_reason' , {id: this.selectedItemId, void_reason: void_reason} );
                this.showMessageNotification({type: 'success', description: "You have voided a direct debit application."});

                await api.post(this, api.DIRECT_REFUNDS+ 'update_status' , {refund_status: 'void', id: this.selectedItemId } );
                
                this.showVoidDialog = false; 
                this.fetchData();
                } catch (error) {
                    this.showMessageNotification({ description : error });
                }
            },
            async filter(filters) {
                try {
                this.filters=filters
                let period = {  period : 'custom',
                                endDate : filters.endDate,
                                property_id : filters.property,
                                startDate : filters.startDate,
                                status : filters.status 
                } 
                await api.get(this, api.DIRECT_REFUNDS, period ).then(r => {
                    this.items = r;       
                });
                this.showMasterFilterModal=false
                } catch (error) {
                    this.showMessageNotification({ description : error });
                }
            },


        },
        watch: {
            
        }
    }



</script>

<style scoped>
    .select-filter {
        position: absolute;
        height: calc(100% - 100px);
        width: calc(100% - 50px);
        overflow-x: scroll;
    }

    .select-filter:hover {
      border: 1px solid #C4CDD5;
      cursor: pointer;
    }

    .select-filter.download-center-filters-active {
        border: 2px solid #00848E;
    }
</style>
