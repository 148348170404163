<template>
  <v-dialog v-model="sign_process" class="sign-modal" persistent fullscreen >
    <div class="sign-container">

      <hb-blade-header
        class="walkthrough-header"
        title="Sign Documents"
        :backButtonOff="true"
        title-icon="mdi-alert"
        @close="$emit('close')"
        :closeButtonOff="!close"
      >
      </hb-blade-header>
      <hb-stepper
        value="0"
        v-if="uploads.length > 1"
        class="pb-5"
      >
        <hb-stepper-header>
          <template v-for="(step, i) in uploads">
            <hb-stepper-step
              :complete="!!step.complete"
              :step="i"
              :key="i + '_step'"
            >
              {{step.name}} {{step.complete}}
            </hb-stepper-step>

            <v-divider
              v-if="i < uploads.length - 1"
              :key="i + '_step_divider'"
            />
          </template>
        </hb-stepper-header>
      </hb-stepper>
      <div class="sign-flow">

        <iframe v-if="session" width="100%" height="100%;"  :src="'https://app.pandadoc.com/s/' + session"></iframe>
        <div v-else class="document-loader">
          <v-progress-circular
            indeterminate
            size="48"
            width="2"
            color="primary"
          ></v-progress-circular>
        </div>
      </div>

    </div>
  </v-dialog>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';

    export default {
        name: "SignDocumentWidget",
        data() {
            return {
                sign_process: true,
                document_ready: false,
                session: null,
                upload_id: null,
                saving: false,
                signing_complete: false,
                active: 0,
                bladidoActionIcons: [ 'mdi-forum', 'mdi-currency-usd', 'mdi-information-outline', 'mdi-account-tie-voice', 'mdi-close' ],
                defualtStyleList: []
            }
        },
        props: {
            uploads: {
                type: Array,
                required: true
            },
            signer_id: {
                required: true
            },
            property_id: {
                required: true
            },
            close: {
                type: Boolean,
                default: true
            },
            remote: {
              type: Boolean,
              required: false
            },
            unit_id: {
              required: false
            },
            appId: {
              type: String,
              default: ""
            }
        },
        components:{
            Loader,
            Status
        },
        async created (){
            window.addEventListener('message',  this.eventCallback);
           // if(this.uploads.length){
            await this.setSession();

        },
        destroyed(){
            window.removeEventListener('message' ,  this.eventCallback);
            let modalsList = document.getElementsByClassName('v-dialog__content v-dialog__content--active')
            modalsList.forEach((element,index) => {
              element.setAttribute("style", this.defualtStyleList[index]);
            });
        },
        filters:{

        },
        computed:{
          active_document(){
              return this.uploads[this.active]
          }
        },
        mounted(){
          console.log("this.uploads", this.uploads);
          let modalsList = document.getElementsByClassName('v-dialog__content v-dialog__content--active')
          modalsList.forEach(element => {
            this.defualtStyleList.push(element.getAttribute("style"))
            element.setAttribute("style", "z-index: 252");
          });
        },
        methods: {

            async setSession(){
                if(this.uploads && this.uploads.length){
                  this.session = null;
                  this.session = await this.getSession();
                }
            },

            async getSession(){
                console.log("this.active_document", this.active_document);
                if(!this.active_document) {
                    this.signing_complete = true;
                    this.$emit('completed', this.active_document);
                    return;
                };

                if(!this.active_document) alert("An error occurred during signing, please close this window and try again.");

                let response = await api.post(this, api.UPLOADS + 'panda-docs/' +  this.active_document.id + '/session', {signer_id: this.active_document.signers[0].id});
                return response.session_id;

            },

            async eventCallback(e){
                if (e && e.data) {
                    try {
                        var message = {}
                        try{
                          message = JSON.parse(e.data);
                        } catch(err){
                          message = e.data;
                        }
                        switch( message.event) {
                            case 'session_view.document.loaded':
                                this.$emit('loaded');
                                break;
                            case 'session_view.document.completed':
                                await this.updateUpload(message.data);
                                this.active++;
                                this.setSession();
                                break;
                            case 'session_view.document.exception':
                                this.$emit('exception');
                                break;
                        }

                        if (message && message.event) {
                            console.log(message.event, message.data);
                        }
                    } catch(e) {
                        console.log(e);
                    }
                }
            },
            async updateUpload(data){
              let body = {
                  property_id: this.property_id,
                  signer_id: this.active_document.signers[0].id,
                  appId: this.appId || ""
                }

                if (this.remote) {
                  body['remote'] = this.remote
                  body["unit_id"] = this.unit_id
                }

                console.log("FINALIZED")
                console.log(body)
                console.log(this.remote)
                console.log(this.unit_id)


                let response = await api.put(this, api.UPLOADS + 'panda-docs/' + data.uuid + '/signed', body);

                
                // update status to complete
                this.active_document.complete = 1;
                this.$store.commit('onBoardingStore/updateChecklistItem', response);

            },
        }
    }
</script>

<style scoped>

  .walkthrough-header{
    flex-shrink: 1;
    z-index: 1000;
  }

  .sign-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content:stretch;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 100;
  }

  .img-view{
    position: relative;
    flex: 0 0 150px;

  }
  .img-view img{
    max-width: 40px;
    width: 100%;
  }
  .pdf-holder{
    padding: 0px;

  }

  .sign-flow{
    background: #F9FAFB;
    border: none;
    width: 100%;
    flex-grow: 1;
    z-index: 0;
    height: 100%;

  }
  .sign-modal-head{
    z-index: 10;
    position: relative;
  }
  .sign-modal{
    margin: 10px;
  }

  .document-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

</style>
