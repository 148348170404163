<template>
  <div>
    <hb-modal
      size="medium"
      title="Select action type"
      v-model="showModal"
      @close="closeModal()"
      show-help-link
    >
      <template v-slot:content>
        <v-row class="pa-0 ma-0">
          <v-col class="pt-0 pb-4 px-4 ma-0">
            <br />
            <hb-radio-group v-model="selected">
              <hb-radio label="Make Phone Call" value="outgoing-phone" />
              <hb-radio label="SMS" value="sms" />
            </hb-radio-group>
          </v-col>
        </v-row>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          color="primary"
          @click="voidConfirm"
          :disabled="selected == null"
          >Confirm</hb-btn
        >
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">

export default {
  name: "SpaceSelector",
  data() {
    return {
      selected: null,
      showModal: false
    };
  },
  created(){
    this.showModal = this.showSelectActionModal;
  },
  props:{
    showSelectActionModal: {
      type:Boolean,
      default: false
      }
  },
  methods: {
    closeModal() {
      this.selected = null;
      this.$emit('close');
    },
    voidConfirm() {
      this.$emit("confirmClicked", this.selected);
      this.closeModal();
    }
  }
};
</script>
