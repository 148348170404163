<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <v-card elevation="0" class="pt-3 pb-4 hb-info-card-default-font-size hb-info-card-wrapper">
        <div v-if="hasButtonSlot" :class="{'hb-info-card-button' : $vuetify.breakpoint.mdAndUp, 'mt-1 mb-2 mr-4 align-right' : !$vuetify.breakpoint.mdAndUp}">
            <slot name="button"></slot>
        </div>
        <div v-if="unit" class="px-6">
            <v-row class="ma-0 pa-0">
                <hb-link color="secondary" @click="$emit('click')">
                    <div class="d-flex align-center">
                        <div class="pr-1"> 
                            <HbSpaceIcon small :type="unit && unit.type ? unit.type : unit && unit.unit_type ? unit.unit_type : 'default'" color="#306FB6"/>
                        </div>
                        <div class="font-weight-medium">
                            #{{ unit.number }}
                        </div>
                    </div>
                </hb-link>
            </v-row>
            <v-row class="ma-0 px-0 pt-0 pb-1">
                <span v-if="status">
                    <HbStatus :status="status" />
                </span>
                <span v-else-if="unit.state">
                    <HbStatus :status="unit.state" />
                </span>
                <span v-else-if="unit.status">
                    <HbStatus :status="unit.status" />
                </span>
            </v-row>
            <v-row class="ma-0 pa-0">
                <span v-if="unit.Category">{{ unit.Category.name }}</span><span v-if="unit.Category && unit.Category.name && unit.type" class="mx-1">|</span>{{ unit.type | capitalize }}
            </v-row>
            <v-row class="ma-0 pa-0" v-if="property">
                {{ property.number }} - {{ property.Address.city }} - {{ property.Address.address }}
            </v-row>
            <v-row class="ma-0 pa-0" v-else>
                {{unit.Address.city}} {{unit.Address.state}} {{unit.Address.zip}} - {{ unit.Address.address}}
            </v-row>
            <v-row class="ma-0 pa-0">
                <span v-for="(a, k, i) in amenities" :key="'amenities' + i">
                    {{ k }}: {{ a[0].name }}<span class="mr-1" v-if="i !== Object.keys(amenities).length - 1">,</span> 
                </span>
            </v-row>
        </div>

        <div v-else-if="contact" class="px-6">
            <v-row class="ma-0 pa-0">
                <hb-link color="secondary" @click="$emit('click')">
                    <div class="d-flex align-center">
                        <div class="pr-1 pb-1"> 
                            <HbIcon v-if="contactIsBusiness" color="#306FB6" small mdi-code="mdi-domain" />
                            <HbIcon v-else color="#306FB6" small mdi-code="mdi-account" />
                        </div>
                        <div class="font-weight-medium">
                            {{ contact.salutation | capitalize }} {{ contact.first | capitalize }} {{ contact.middle | capitalize }} {{ contact.last | capitalize }} {{ contact.suffix | capitalize }}
                        </div>
                    </div>
                </hb-link>
            </v-row>
            <v-row class="ma-0 px-0 pt-0 pb-1">
                <span v-if="status">
                    <HbStatus :status="status" />
                </span>
                <span v-else-if="contact.status">
                    <HbStatus :status="contact.status" />
                </span>
            </v-row>
            <v-row class="ma-0 pa-0" v-for="(phone, i) in contact.Phones" :key="'phone' + i">
                {{ phone.phone | formatPhoneHeaderInfo }}
            </v-row>
            <v-row class="ma-0 pa-0">
                {{ contact.email }}
            </v-row>
            <v-row class="ma-0 pa-0" v-for="(address, i) in contact.Addresses" :key="'address' + i">
                {{ address.Address.address }},<br>
                {{ address.Address.city }}, {{ address.Address.state + ' ' + address.Address.zip }}
            </v-row>
            <v-row class="ma-0 pa-0" v-if="access && access.length">
                <span v-if="multipleAccessCodes">
                    Multiple Access Codes
                </span>
                <span v-else>
                    Access Code: {{ access }}
                </span>
            </v-row>
        </div>

        <div v-else-if="type !== 'contact'" class="px-6">
            <v-row class="ma-0 pa-0">
                <hb-link color="secondary" @click="$emit('click')">
                    <div class="d-flex align-center">
                        <div class="pr-1"> 
                            <HbSpaceIcon small :type="type" color="#306FB6" enable-tooltip />
                        </div>
                        <div class="font-weight-medium">
                            #{{ title }}
                        </div>
                    </div>
                </hb-link>
            </v-row>
            <v-row class="ma-0 px-0 pt-0 pb-1" v-if="status">
                <HbStatus :status="status" />
            </v-row>
            <v-row class="ma-0 pa-0" v-if="categoryOne || categoryTwo">
                <span v-if="categoryOne">{{ categoryOne }}</span><span v-if="categoryOne && categoryTwo" class="mx-1">|</span><span v-if="categoryTwo">{{ categoryTwo }}</span>
            </v-row>
            <v-row class="ma-0 pa-0" v-if="propertyInfo">
                {{ propertyInfo }}
            </v-row>
            <v-row class="ma-0 pa-0" v-if="amenities">
                {{ amenities }}
            </v-row>
        </div>

        <div v-else class="px-6">
            <v-row class="ma-0 pa-0">
                <hb-link color="secondary" @click="$emit('click')">
                    <div class="d-flex align-center">
                        <div class="pr-1 pb-1">
                            <hb-tooltip>
                                <template v-slot:item>
                                    <HbIcon v-if="contactIsBusiness" color="#306FB6" small mdi-code="mdi-domain" />
                                    <HbIcon v-else color="#306FB6" small mdi-code="mdi-account" />
                                </template>
                                <template v-slot:body>
                                    {{ contactIsBusiness ? 'Business' : 'Contact' }}
                                </template>
                            </hb-tooltip>
                        </div>
                        <div class="font-weight-medium">
                            {{ title }}
                        </div>
                    </div>
                </hb-link>
            </v-row>
            <v-row class="ma-0 px-0 pt-0 pb-1" v-if="status">
                <HbStatus :status="status" />
            </v-row>
            <v-row class="ma-0 pa-0" v-if="phone">
                {{ phone }}
            </v-row>
            <v-row class="ma-0 pa-0" v-if="email">
                {{ email }}
            </v-row>
            <v-row class="ma-0 pa-0" v-if="contactAddress">
                {{ contactAddress }}
            </v-row>
            <v-row class="ma-0 pa-0" v-if="accessCode">
                <span v-if="multipleAccessCodes">
                    Multiple Access Codes
                </span>
                <span v-else>
                    Access Code: {{ accessCode }}
                </span>
            </v-row>
        </div>

    </v-card>
</template>
<script type="text/babel">

    import HbIcon from './HbIcon.vue';
    import HbStatus from './HbStatus.vue';
    import HbLink from './HbLink.vue';
    import HbSpaceIcon from './HbSpaceIcon.vue';

    import { parsePhoneNumber } from 'libphonenumber-js';
    
    export default {
        name: "HbInfoCard",
        props: { 
            contact: {
                type: Object,
                default: null
            },
            unit: {
                type: Object,
                default: null
            },
            property: {
                type: Object,
                default: null
            },
            amenities: {
                type: [ Array, String ],
                default: null
            },
            type: {
                type: String,
                default: undefined
            },
            title: {
                type: String,
                default: undefined
            },
            status: {
                type: String,
                default: undefined
            },
            propertyInfo: {
                type: String,
                default: undefined
            },
            categoryOne: {
                type: String,
                default: undefined
            },
            categoryTwo: {
                type: String,
                default: undefined
            },
            email: {
                type: String,
                default: undefined
            },
            phone: {
                type: String,
                default: undefined
            },
            contactAddress: {
                type: String,
                default: undefined
            },
            access: {
                type: String,
                default: undefined
            },
            accessCode: {
                type: String,
                default: undefined
            },
            multipleAccessCodes: {
                type: String,
                default: undefined
            },
            contactIsBusiness: {
                type: Boolean,
                default: false
            },
        },
        components:{
            HbIcon,
            HbStatus,
            HbLink,
            HbSpaceIcon
        },
        computed: {
            hasButtonSlot(){
                return !!this.$slots['button'];
            },
        },
        filters:{
            formatPhoneHeaderInfo(value){
                if (!value) return '';
                    try {
                        var parsedPhoneNumber = parsePhoneNumber('+' + value);

                        if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                            if(parsedPhoneNumber.country === 'US'){
                                return '+1 ' + parsedPhoneNumber.formatNational();
                            } else {
                                return parsedPhoneNumber.formatInternational();
                            }
                        } else {
                            value = value.toString();

                            var numbers = value.replace(/\D/g, ''), char = {0: '(', 3: ') ', 6: '-'};
                            value = '';
                            for (var i = 0; i < numbers.length; i++) {
                                value += (char[i] || '') + numbers[i];
                            }
                        }
                    }   catch(err){
                            
                        }
                return value;
            }
        },
    }
</script>

<style scoped>

.hb-info-card-wrapper {
    box-shadow: 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important;
}

.hb-info-card-default-font-size {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    letter-spacing: normal;
}

.hb-info-card-button{
  position: absolute;
  top: 15px;
  right: 15px;
}
    
</style>