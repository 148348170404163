<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div class="hb-combobox-wrapper" :class="{'hb-combobox-width-370' : !full}">

        <v-select
            v-if="select"
            offset-y
            :value="value"
            @input="$emit('input', value)"
            multiple
            small-chips
            class="hb-combobox hb-combobox-select pt-0"
            :class="{'hb-combobox-has-chip' : value.length > 0}"
            v-bind="$attrs"
            v-on="$listeners"
            :menu-props="{
                contentClass: 'hb-combobox-menu',
                offsetY: true,
            }"
            single-line
            hide-details
            :clearable="true"
            @click:clear="$emit('input', [])"
            clear-icon="mdi-close-circle"
            background-color="white"
        >
            <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                <slot :name="scopedSlot" v-bind="slotData"/>
            </template>
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
                <slot :name="slot"/>
            </template>

            <template v-slot:prepend-item>
                <v-list-item
                    @click="toggle"
                    v-if="$attrs && $attrs.items && $attrs.items.length > 1"
                >
                    <v-hover v-slot:default="{ hover }">
                            <hb-icon :color="hover && value.length === 0 ? '#637381' : value.length === 0 ? '#DFE3E8' : '#00848E'" :mdi-code="icon"></hb-icon>
                        </v-hover>
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template v-slot:selection="{ item, parent }">
                <hb-chip
                    @click="parent.selectItem(item)"
                    editable
                >
                    <template v-if="item.name">{{ item.name }}</template>
                    <template v-else>{{ item }}</template>
                </hb-chip>
            </template>
        </v-select>

        <v-combobox
            v-else
            :value="value"
            @input="$emit('input', value)"
            multiple
            small-chips
            class="hb-combobox pt-0"
            :class="{'hb-combobox-has-chip' : value.length > 0}"
            v-bind="$attrs"
            v-on="$listeners"
            :menu-props="{ contentClass: 'hb-combobox-menu' }"
            single-line
            hide-details
            :clearable="true"
            @click:clear="$emit('input', [])"
            clear-icon="mdi-close-circle"
            @update:search-input="updateInput"
        >

            <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                <slot :name="scopedSlot" v-bind="slotData"/>
            </template>
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
                <slot :name="slot"/>
            </template>

            <template v-slot:prepend-item>
                <v-list-item
                    @click="toggle"
                    v-if="$attrs && $attrs.items && $attrs.items.length > 1 && !inputValue"
                >
                    <v-list-item-action>
                        <v-hover v-slot:default="{ hover }">
                            <hb-icon :color="hover && value.length === 0 ? '#637381' : value.length === 0 ? '#DFE3E8' : '#00848E'" :mdi-code="icon"></hb-icon>
                        </v-hover>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template v-slot:selection="{ item, parent }">
                <hb-chip
                    v-if="(item && item.name && item.name.length) || (item && item.length)"
                    @click="parent.selectItem(item)"
                    editable
                >
                    <template v-if="item.name">{{ item.name }}</template>
                    <template v-else>{{ item }}</template>
                </hb-chip>
            </template>
            
        </v-combobox>

    </div>
</template>

<script type="text/babel">

import HbChip from './HbChip.vue';
import HbIcon from './HbIcon.vue';

export default {
    name:'HbCombobox',
    data(){
        return {
            inputValue: null,
        }
    },
    components:{
        HbChip,
        HbIcon
    },
    computed: {
        /*
        combobox: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        */
        allSelected() {
            return this.$attrs.items.every(elem => this.value.includes(elem));
        },
        partialAllSelected() {
            return this.$attrs.items.every(elem => elem.disabled || this.value.includes(elem));
        },
        icon() {
            if (this.value.length === 0) return 'mdi-checkbox-blank-outline';
            if (this.allSelected) return 'check_box';
            if (!this.allSelected) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
    },
    props: {
        value: {

        },
        select:{
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggle() {
            this.$nextTick(() => {
                if(this.allSelected || this.partialAllSelected) {
                    this.$emit('input', []);
                } else {
                    let filteredArray = this.$attrs.items.filter(i => !i.disabled);
                    this.$emit('input', filteredArray.slice());
                }
            })
        },
        updateInput(value){
            this.inputValue = value;
        }
    },
    watch: {
        value (val, prev) {
            if (val.length === prev.length) return

            let tempValue = val.map(v => {
                if (v.length > 0 && this.$attrs.items.indexOf(v) === -1) {
                    this.$attrs.items.push(v)
                }

                return v
            });

            this.$emit('input', tempValue);
        },
    }
}
</script>

<style scoped>

.hb-combobox .hb-form-description-text-night-light {
    color: #637381;
}

.hb-combobox .hb-combobox-primary-color {
    color: #00848E;
}

.hb-combobox-menu >>> i.v-icon.notranslate.material-icons.theme--light {
  color: #DFE3E8 !important;
}

.hb-combobox {
    margin-top: -7px;
}

.hb-combobox.hb-combobox-has-chip {
    margin-top: 0px;
}

</style>

<style>

.hb-combobox-width-370 {
    max-width: 370px;
}

.v-menu__content.hb-combobox-menu {
    border-radius: 4px;
    box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;
}

.v-menu__content.hb-combobox-menu .v-select-list {
    margin-top: 1px;
}

.v-menu__content.hb-combobox-menu .v-select-list .v-list-item--link::before {
    background: transparent !important;
}

.v-menu__content.hb-combobox-menu .v-select-list .v-list-item--link:hover::before {
    background: rgba(0, 0, 0, 0.87) !important;
}

.v-menu__content.hb-combobox-menu .v-select-list .v-list-item__title {
    font-size: 14px;
    color: #101318;
}

.v-menu__content.hb-combobox-menu .v-select-list .v-list-item__action {
    margin-right: 7px;
}

.v-menu__content.hb-combobox-menu .v-list-item:not(.v-list-item--active) .v-simple-checkbox .v-icon {
    color: #DFE3E8 !important;
}

.v-menu__content.hb-combobox-menu .v-icon.primary--text {
    color: #00848E !important;
}

.v-menu__content.hb-combobox-menu .v-list-item {
    min-height: 40px !important;
}

.v-menu__content.hb-combobox-menu .v-list-item__action {
    margin-top: 8px;
    margin-bottom: 8px;
}

.v-menu__content.hb-combobox-menu .v-list-item:not(.v-list-item--active):hover .v-simple-checkbox i.v-icon  {
    color: #637381 !important;
}

.hb-combobox-wrapper .v-input__icon--clear .v-icon {
    font-size: 16px !important;
    color: #C4CDD5 !important;
    padding-bottom: 3px;
}

.hb-combobox-wrapper .v-input__icon--clear .v-icon:hover {
    color: #637381 !important;
}

.hb-combobox .v-label {
    font-size: 14px !important;
}

.hb-combobox:not(.v-select--is-menu-active):not(.hb-combobox.hb-combobox-has-chip) .v-select__selections {
    margin-bottom: 6px !important;
}

.hb-combobox .v-select__slot {
    margin-bottom: 2px !important;
}

.hb-combobox.hb-combobox-select:not(.v-select--is-menu-active):not(.hb-combobox.hb-combobox-has-chip) .v-select__slot {
    margin-bottom: -4px !important;
}

.hb-combobox-wrapper .v-text-field:not(.error--text):hover > .v-input__control > .v-input__slot::before {
    border-color: #C4CDD5 !important;
}

.hb-combobox-wrapper .v-text-field:not(.error--text) > .v-input__control > .v-input__slot::before {
    border-color: #DFE3E8 !important;
}

</style>