// import AmazonPayments from '../components/payments/AmazonPayments.vue'


export default [
	{
		path: '/payments/amazon/:encrypted',
		component: () => import('../components/payments/AmazonPayments.vue'),
		meta: {
			requiresAuth: false,
			layout: 'unauthenticated'
		}
	}
]
