<template>
    <div class="pa-0">
        <div class="interaction-section-body1">
          <hb-form label="Deny Online Payments" :active.sync="active"  @cancel="handleCancel" @save="showConfirmationDialogue" :editable="!nationalAccount && !is_closed && hasPermission('deny_online_payments')" :hideActions = "isLoading($options.name)" :empty="false">
            <template v-slot:display>
              <v-col class="ma-0 pa-0" cols="12" sm="12">{{denyOnlinePayments ? 'Yes' : 'No'}}
                <hb-tooltip v-if="nationalAccount">
                    <template v-slot:body>
                      This setting is not editable. Please contact the National Account Manager for more information.
                    </template>
                </hb-tooltip>
              </v-col>
              <div class="text-body-2 hb-text-light" v-if = "latestDenyOnlinePayment && latestDenyOnlinePayment.is_modified">
                Last Modified By: {{ latestDenyOnlinePayment.modified_source }} @ {{ latestDenyOnlinePayment.modified | formatDate }}
            </div>
            </template>
            <template v-slot:edit >
              <div>
                <hb-switch right v-model="denyOnlinePayments" :label="denyOnlinePayments ? 'Yes' : 'No'"></hb-switch>
                <span v-if="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                  </span>
              </div>
            </template>
          </hb-form>
        </div>
        <hb-modal
        size="small"
        title="Deny Online Payments"
        v-model="confirmUpdateDenyOnlinePayments"
        @close="handleCancel"
        >
        <template v-slot:content>
            <div v-if = "denyOnlinePayments" style="height:200px;" class="pa-4">
                Enabling this feature would not allow online payments until changed manually.
                <div class="pt-4">
                    Would you like to proceed?
                </div>
            </div>
            <div v-else style="height:200px;" class="pa-4">
                Disabling this feature would allow online payments until changed manually.
                <div class="pt-4">
                    Would you like to proceed?
                </div>
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn @click="save()"  :disabled="isLoading($options.name)" :loading="isLoading($options.name)">Confirm</hb-btn>
        </template>
    </hb-modal>
    </div>
</template>

<script type="text/babel">

import Loader from './../assets/CircleSpinner.vue';
import api from "../../assets/api.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import { mapGetters } from "vuex";
import moment from "moment";


export default {
  name: 'DenyOnlinePayments',

  mixins: [ notificationMixin ],

  data() {
    return {
      denyOnlinePayments: false,
      active: '',
      confirmUpdateDenyOnlinePayments: false,
      denyOnlinePaymentsHistory: {}
    };
  },

  props: {
    nationalAccount:{
      type: Boolean,
      default: false,
    },
    lease: {
      type: Object,
      required: true,
    },
    is_closed: {
      type: Boolean,
      default: false
    }
  },
  filters: {
      formatDate(date){
          return moment(date).format('MMM DD, YYYY hh:mm A');
      }
  },
  async created() {
    await this.setDenyOnlinePaymentData();
  },

  components: {
    Loader
  },

  computed: {
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission"
    }),
    latestDenyOnlinePayment() {
      return this.denyOnlinePaymentsHistory?.[0];
    }  
  },
  methods: { 
    async setDenyOnlinePaymentData(){
      try{
        this.denyOnlinePayments = this.lease.deny_payments;
        let res = await api.get(this, `${api.LEASES}/${this.lease.id}/deny_payments/history`);
        this.denyOnlinePaymentsHistory = res.history;
      }
      catch(err){
        this.showMessageNotification(err);
      }
    }, 
    async handleCancel(){
      await this.setDenyOnlinePaymentData();
      this.confirmUpdateDenyOnlinePayments = false;
      this.active = 'saved';
    },
    showConfirmationDialogue(){
      if(this.denyOnlinePayments !== this.lease.deny_payments){
        this.confirmUpdateDenyOnlinePayments = true;
      }
      else{
        this.showMessageNotification({ description: "You are trying to save without changing the setting." });
      }
    },
    async save() {
      
      try{
        this.startLoading(this.$options.name);
        let res = await api.put(this, `${api.LEASES}/${this.lease.id}/deny-online-payments`, { deny_payments: this.denyOnlinePayments ? 1 : 0 });
        this.lease = res.lease;
        this.stopLoading(this.$options.name);
      }
      catch(error){
        this.showMessageNotification({ description: `Failed to ${this.denyOnlinePayments? 'enable' : 'disable'} deny payments: ${error}.` })
      }
      finally{
        await this.setDenyOnlinePaymentData();
        this.refreshData();
        this.confirmUpdateDenyOnlinePayments = false; 
      }
      
      this.active = 'saved';
    },
    refreshData() {
      let deny = false;
      if (this.denyOnlinePayments === 1){
        if (this.denyOnlinePaymentsHistory?.[0].modified_source !== 'Delinquency'){
          deny = true;
        }
      }
      this.$emit('refreshData', deny);
    }
  }
}
</script>