import { mapActions } from 'vuex';
export const notificationMixin = {
  data() {
    return {
      notificationPersistent: false
    }
  },
  destroyed() {
    this.setNotificationPersistency(false);
  },
  methods: {
    ...mapActions({
      showCharmNotificationToast: "charmNotificationStore/showCharmNotificationToast",
      setInSnappedZone: "globalNotificationsStore/setInSnappedZone",
      removeNonPersistentGlobalNotifications: "globalNotificationsStore/removeNonPersistentGlobalNotifications",
      setGlobalNotification: "globalNotificationsStore/setGlobalNotification",
      removeGlobalNotificationByType: "globalNotificationsStore/removeGlobalNotificationByType",
      updateGlobalNotification: "globalNotificationsStore/updateGlobalNotification"
    }),
    showMessageNotification(payload){
      this.setGlobalNotification(payload);
    },
    closeMessageNotification(type = 'error') {
      this.removeGlobalNotificationByType({ type: type })
    },
    handleDismissMiniCharmNotifications() {
      this.showCharmNotificationToast({ value: false });
      this.removeNonPersistentGlobalNotifications()
    },
    handleInSnappedZone(value) {
      this.setInSnappedZone(value);
    },
    setNotificationPersistency(value){
      this.notificationPersistent = value;
    },
    updateMessageNotification(payload){
      this.updateGlobalNotification(payload)
    }
  },
  watch: {
    "errors.items"(val) {
      if(val && val.length) {
        this.showMessageNotification({list: val, persistent: this.notificationPersistent});
      } else {
        this.closeMessageNotification();
      }
    }
  }
};