<template>
  <hb-modal v-model="dialog" large :title="title" show-help-link>
        <template v-slot:subheader>
          {{ dialogDescription }}
          <br/><br/>
            Recurring rent changes will always happen at the end of your
            schedule plan.
        </template>
    <template v-slot:content>
      <v-form class="rent-plan-form" ref="form">
        <hb-form label="Plan Name" required medium>
          <hb-text-field
            v-model.trim="form.name"
            placeholder="Enter Plan Name"
            v-validate="'required|max:30'"
            data-vv-name="name"
            data-vv-as="Name"
            :error="errors.collect('name').length > 0"
          />
          <!-- @change="$validator.validate('name')" -->
        </hb-form>
        <hb-form label="Description" medium>
          <HbTextarea
            v-model="form.description"
            placeholder="Enter Description"
            v-validate="'max:255'"
            data-vv-name="description"
            data-vv-as="Description"
            :maxlength="255"
            :error="errors.collect('description').length > 0"
            placeholder-fix
          />
        </hb-form>
        <DescriptiveTagForm ref="tags" :medium="true" v-model="form.tags"/>
        <hb-form 
          label="Maximum Raise" 
          :description="rentRaiseDescription"
          required
          medium
          full 
        >
          <div class="rent-raise d-flex mt-n4">
            <span class="d-flex align-center">Rent raise cannot exceed a <strong class="px-1">maximum</strong> of </span>
            <div style="width: 210px" class="pt-5">
              <HbSelect
                v-model="form.maximum_raise.type"
                :items="getRaiseOptions('max_raise')"
                item-value="item"
                item-text="label"
                v-validate
                attach
                placeholder="Choose Method"
                :clearable="false"
                :error="errors.collect('maximum_raise_method').length > 0"
              />
            </div>
            <div class="pt-4">
              <hb-text-field
                box
                small
                v-model.trim="form.maximum_raise.value"
                :disabled="form.maximum_raise.type === ''"
                placeholder="0"
                v-validate
                data-vv-name="maximum_raise"
                data-vv-as="Maximum Raise"
                :error="errors.collect('maximum_raise').length > 0 || errors.collect('maximum_raise_method').length > 0"
              />
            </div>
          </div>
        </hb-form>
        <hb-form 
          label="Minimum Raise" 
          :description="rentRaiseDescription"
          medium
          required
          full 
        >
          <div class="rent-raise d-flex mt-n4">
            <span class="d-flex align-center">Rent raise must be a <strong class="px-1">minimum</strong> of </span>
            <div style="width: 258px" class="pt-5">
              <HbSelect
                v-model="form.minimum_raise.type"
                :items="getRaiseOptions('min_raise')"
                item-value="item"
                item-text="label"
                v-validate
                attach
                :clearable="false"
                placeholder="Choose Method"
                :error="errors.collect('minimum_raise_method').length > 0"
              />
            </div>
            <div class="pt-4">
              <hb-text-field
                box
                small
                v-model.trim="form.minimum_raise.value"
                :disabled="form.minimum_raise.type === ''"
                placeholder="0"
                data-vv-name="minimum_raise"
                data-vv-as="Minimum Raise"
                :error="errors.collect('minimum_raise').length > 0 || errors.collect('minimum_raise_method').length > 0"
              />
            </div>
          </div>
        </hb-form>
        <hb-form 
          label="Rent Cap" 
          :description="rentRaiseDescription"
          required
          medium
          full 
        >
          <div class="rent-raise d-flex mt-n4">
            <span class="d-flex align-center">Rent cannot exceed a <strong class="px-1">maximum</strong> of </span>
            <div style="width: 246px" class="pt-5">
              <HbSelect
                v-model="form.rent_cap.type"
                :items="getRaiseOptions('rent_cap')"
                item-value="item"
                item-text="label"
                v-validate
                attach
                :clearable="false"
                placeholder="Choose Method"
                :error="errors.collect('rent_cap_method').length > 0"
              />
            </div>
            <div class="pt-4">
              <hb-text-field
                box
                small
                v-model.trim="form.rent_cap.value"
                :disabled="['property_default'].includes(form.rent_cap.type)"
                :value = "form.rent_cap.type === 'property_default' || !form.rent_cap.type ?  form.rent_cap.value = null : ''"
                placeholder="0"
                data-vv-name="rent_cap"
                data-vv-as="Rent Cap"
                :error="errors.collect('rent_cap').length > 0"
              />
            </div>
          </div>
        </hb-form>
        <hb-form label="Prepay" medium>
          <hb-checkbox
            v-model="form.prepay_rent_raise"
            label="Raise rent for people who prepay"
            :false-value="false"
            :true-value="true"
          />
        </hb-form>
        <hb-form 
          label="Schedule Rent Plan Increase" 
          required 
          full 
          medium
        >
          <span class="hb-text-light">Choose between two rent change methods:</span>
          <ol class="hb-text-light mt-1">
            <li class="mb-0"><strong>Increase Rent by:</strong> Raise rent by a fixed dollar or percentage amount.</li>
            <li>
              <strong>Target:</strong> Set a target as a percentage of Rent/Sell or as a percentage of Sell Rate. 
              Until the target is reached, it will increase the rent within your specified limits. 
              Once the target is achieved, it will raise the rent by a fixed dollar or percentage amount.
            </li>
          </ol>
          <!-- Component for display schedule rent plan timeline -->
          <ScheduleRentPlan
            ref="scheduleRentPlan"
            :settings="settings"
            @updatedSettings="getUpdatedSettings"
          />
        </hb-form>
        <!-- Component for display rounding form section -->
        <!-- <RoundingForm v-model="form.round_to" /> -->
        <!-- <hb-form label="Prepay">
          <hb-checkbox
            v-model="form.disable_pre_pay_rent_raise"
            label="Do not raise rent for people who prepay"
          />
        </hb-form>
        <hb-form label="Maximum Raise" full>
          <hb-checkbox
            v-model="maximumRaise"
            label="Do not raise rent above"
          >
            <template v-slot:input>
              <hb-text-field
                class="pa-0"
                v-model="form.maximum_rent_raise_percentage"
                :disabled="!maximumRaise"
                style="width: 70px"
                v-validate="maximumRaise ? 'required|between:0,100|decimal:2' : ''"
                data-vv-name="maximum_rent_raise_percentage"
                data-vv-as="Maximum Rent Raise"
                :error="
                  errors.collect('maximum_rent_raise_percentage').length > 0
                "
              />
              <span class="pl-2">(%) percentage of sell rate.</span>
            </template>
          </hb-checkbox>
        </hb-form> -->
      </v-form>
      <ConfirmationPopup v-if="hasPlanId" v-once ref="confirmationPopup" />
    </template>
    <template v-slot:right-actions>
      <hb-btn @click="showConfirmationPopup">Save</hb-btn>
    </template>
  </hb-modal>
</template>

<script>
import RoundingForm from "../../assets/RoundingForm.vue";
import DescriptiveTagForm from "../utils/DescriptiveTagForm.vue";
import ScheduleRentPlan from "./ScheduleRentPlan.vue";
import { cloneDeep } from "lodash";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import { mapGetters } from "vuex";

export default {
  name: "RentPlanForm",
  components: {
    RoundingForm,
    ScheduleRentPlan,
    DescriptiveTagForm,
    ConfirmationPopup
  },
  props: {
    rent: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
    },
  },
  mixins: [ notificationMixin ],
  data() {
    return {
      raiseOptions: [
        {
          item: "dollar_amount",
          label: "$ amount",
          min_raise: true,
          rent_cap: false,
          max_raise: true,
        },
        {
          item: "rent_percent",
          label: "% of Current Rent",
          min_raise: true,
          rent_cap: false,
          max_raise: true,
        },
        {
          item: 'property_default',
          label: "Property Default",
          min_raise: false,
          rent_cap: true,
          max_raise: false,
        },
        {
          item: "sell_rate_percent",
          label: "% of Sell Rate",
          min_raise: false,
          rent_cap: true,
          max_raise: true,
        },
        {
          item: "set_rate_percent",
          label: "% of Set Rate",
          min_raise: false,
          rent_cap: true,
          max_raise: true,
        },
        {
          item: "rent_sell_variance_percent",
          label: "Variance % (Rent/Sell)",
          min_raise: false,
          rent_cap: false,
          max_raise: true,
        },
      ],
      rentRaiseDescription: 'When choosing your limit, pick a raise method and then designate the method value.',
      form: {
        name: "",
        description: "",
        prepay_rent_raise: false,
        maximum_raise: {
          type: '',
          value: null
        },
        minimum_raise: {
          type: '',
          value: null
        },
        rent_cap: {
          type: 'property_default',
          value: null
        },
        active: false,
        settings: [],
        tags: []
      },
      monthList: [],
      defaultData: {
        name: "",
        description: "",
        prepay_rent_raise: false,
        maximum_raise: {
          type: '',
          value: null
        },
        minimum_raise: {
          type: '',
          value: null
        },
        rent_cap: {
          type: 'property_default',
          value: null
        },
        // round_to: null,
        active: false,
        settings: [],
        tags: []
      },
      clonedData: {}
    };
  },
  watch: {
    dialog() {
      this.$validator?.reset();
      this.$refs?.scheduleRentPlan?.$validator?.reset();
      this.$validator?.errors?.clear();
      this.$refs?.scheduleRentPlan?.$validator?.errors?.clear();
      this.clonedData = cloneDeep(this.rent)
      if(this.clonedData?.maximum_raise === null || !this.clonedData?.maximum_raise?.type)
        this.clonedData.maximum_raise = { type: '',value: null }
      if(this.clonedData?.minimum_raise === null || !this.clonedData?.minimum_raise?.type)
        this.clonedData.minimum_raise = { type: '',value: null }
      if(this.clonedData?.rent_cap === null || !this.clonedData?.rent_cap?.type)
        this.clonedData.rent_cap = { type: 'property_default',value: null }
      if (this.rent?.id) this.form = cloneDeep(this.clonedData)
      else this.resetForm()
    }
  },
  computed: {
    ...mapGetters({
      getRentPlanNames: "revManStore/getRentPlanNames"
    }),
    rentPlanNames() {
      return this.getRentPlanNames(this.rent)
    },
    /** Property for handle dialog box v-model */
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    /** Property to return description according page type */
    dialogDescription() {
      let initialText = this.rent?.id ? "Edit" : "Create";
      return `${initialText} the rent plan below based on how often you would like this to increase. You can add as many rent changes as you want but you can only add a single recurring rent change.`;
    },
    /** Property to return title according page type */
    title() {
      return this.rent?.id
        ? "Edit Rent Plan"
        : "Add Rent Plan";
    },
    hasPlanId() {
      return Boolean(this.rent?.id )
    },
    settings() {
      return cloneDeep(this.clonedData.settings)
    },
    
  },
  methods: {
    /**
     * Function to filter raise methods for maximum_raise, minimum_raise, rent_cap
     */
    getRaiseOptions(option = 'default') {
      if(['min_raise', 'rent_cap', 'max_raise'].includes(option))
        return this.raiseOptions.filter(method => method[option])
    },
    /**
     * Function to get updated settings data from child component
     * @param {Array} settings List of settings data
     * */
    getUpdatedSettings(settings) {
      this.form.settings = settings;
    },

    /**
     * Function for clear form entries while closing model view
     * */
    resetForm() {
      this.$nextTick(() => {
        this.clonedData.settings = this.defaultData.settings
        this.clonedData.tags = this.defaultData.tags
        this.form = cloneDeep(this.defaultData)
        this.$refs.tags.isError = false
      });
    },

    /**
     * Function to display the confirmation modal.
     *
     * @param {String} type Type of the confirmation modal ( default/delete)
     */
    async showConfirmationPopup() {
      this.$validator.errors.clear();
      const valid = await this.$validator.validateAll();
      await this.validateName();
      await this.validateDescriptiveTags();
      await this.validateRaiseOptions();
      await this.validateMaxandMinRaise();
      await this.validateSettings();
      delete this.form.created_by

      if (!valid || this.errors?.items?.length) return; 
      if (this.hasPlanId) {
        let confirmed = await this.$refs.confirmationPopup.show({
            title: `Edit Rent Plan`,
            message: `You are about to modify <strong>${this.rent?.name}</strong> rent plan.
             Any changes made here will be applied to all existing leases that are currently assigned to this plan.
             <br/><br/>Are you sure you want to continue?`,
            buttonType: 'primary',
            resolver: `Continue`,
          })
          .catch(() => false);
          if (confirmed) this.submit()
      } else this.submit()
    },

    /**
     * Function to replace empty arrays with NULL on objects
     *
     * @param {*} obj target object
     */
    nullifyEmptyArrays(obj) {
      if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
        return Object.entries(obj).reduce((acc, [key, val]) => {
          if (Array.isArray(val) && !val.length) acc[key] = null
          else acc[key] = val
          return acc
        }, {})
      } else return obj
    },
    /**
     * Submit function used to emit updated data to parent component
     * - when clicking save button.
     * And also check form validation
     * */
    async submit() {
      this.form.settings.forEach((item) => {
        delete item.error;
      });
      //Logic to set maximum_raise, minimum_raise, rent_cap keys to null if value is not provided
      Object.keys(this.form).forEach(k => {
        (this.form[k]?.type === '' || this.form[k]?.type === 'property_default')  && this.form[k]?.value === null
          ? this.form[k] = null
          : ''
      })
      this.$emit("submit", this.nullifyEmptyArrays(cloneDeep(this.form)))
      this.dialog = false
    },

    /**
     * Function to validate duplicate rent plan name
    * */
    async validateName() {
      if(this.rentPlanNames.includes(this.form.name)) { 
        let msg = `A Rent Plan already exists with the name ${this.form.name}.`
        this.$validator.errors.add({
            field: "name",
            msg
        }) 
      }
    },
    /**
     * Function validate descriptive tags fields
     */
    async validateDescriptiveTags() {
      if(!this.form?.tags?.length) {
        this.$validator.errors.add({
          field: 'tags',
          msg:'Descriptive Tags field is required. Choose atleast one tag.'
        });
        this.$refs.tags.isError = true
      }
      else this.$refs.tags.isError = false
    }, 
    /**
     * Function to validate Maximum Raise and Minimum Raise 
     * If the chosen method for max and min raise is dollar,
     * value of Minimum raise should be always less than Maximum Raise
     */
    async validateMaxandMinRaise() {
      let errorMessages = []
      let isDollarValidation = this.form?.maximum_raise?.type === 'dollar_amount' && this.form?.minimum_raise?.type === 'dollar_amount'
      let isRentPercentValidation = this.form?.maximum_raise?.type === 'rent_percent' && this.form?.minimum_raise?.type === 'rent_percent'
      let validate = (isDollarValidation || isRentPercentValidation)
        && (Number(this.form?.maximum_raise?.value) <= Number(this.form?.minimum_raise?.value))
      if(validate)
        errorMessages.push(
          {
            msg:`The Minimum Rent Raise value must be less than the Maximum Rent Raise value if the chosen method for both is a fixed dollar amount or a percentage of the Current Rent.`,
            fieldName: `minimum_raise`
          }
        )
      errorMessages.forEach((field) => {
        this.$validator.errors.add({
          field: field.fieldName,
          msg:field.msg
        });
      })
    },
    /**
     * Function to validate maximum_raise, minimum_raise, rent_cap
     */
    async validateRaiseOptions() {
      let errorMessages = []
      let raiseOptions = [
        { label: 'Maximum Rent Raise', key: 'maximum_raise', ...this.form.maximum_raise },
        { label: 'Minimum Rent Raise', key: 'minimum_raise', ...this.form.minimum_raise },
        { label: 'Rent Cap', key: 'rent_cap', ...this.form.rent_cap },
      ]
      raiseOptions.forEach(option => {
        let required = false
        if(!option?.type && !option?.value) {
          errorMessages.push(
            {
              msg:`${option?.label} field is required.`,
              fieldName: `${option?.key}_method`
            }
          )
          required = true
        }
        if(!required) {
          if(option?.type && option?.type !== 'property_default') {
            let raiseError = this.hasRateByError(option?.value, option?.type)

            const validationMessages = {
              rent_sell_variance_percent: `Enter a value for the ${option?.label} field. The value should be between -99 and 999 without any decimal places.`,
              dollar_amount: `Enter a value for the ${option?.label} field. The value should be between 1 and 9,999,999 and should have a maximum of two decimal places.`,
            }
            if(raiseError)
              errorMessages.push({
                msg: validationMessages[option?.type] ?? `Enter a value for the ${option?.label} field. The value should be between 1 and 999 without any decimal places.`,
                fieldName: option?.key
              })
          }
          if(option?.value && !option?.type)
            errorMessages.push( 
              {
                msg:`${option?.label} method field is required.`,
                fieldName: `${option?.key}_method`
              }
            )
        }
      })
      errorMessages = [...new Set(errorMessages)];
      errorMessages.forEach((field) => {
        this.$validator.errors.add({
          field: field.fieldName,
          msg:field.msg
        });
      })
    },

    /**
     * Function for validate scheduled rent increase fields.
     * Invoking from submit function when clicking save button.
     * If any error conditional check is satisfied will add to
     * - corresponding error message to validator
     * */
    async validateSettings() {
      let errorMessages = [];
      this.monthList = [];
      let errorFields = [];
      const promises = this.$refs.scheduleRentPlan.rentPlanIncreases.map(
        async (stage, index) => {
          let monthError = this.hasMonthError(stage.month);
          let recurringMonthError = stage.recurrence_interval.active ? this.hasMonthError(stage.recurrence_interval.value) : false
          if (monthError || recurringMonthError) {
            errorMessages.push(
              "Month field is required with a positive integer with a maximum of 3 digits."
            );
          }
          let rentChangeMethodError = !stage?.increase_method?.type?.item
          let targetMethodError = stage?.increase_method?.type?.targetReached ? !stage?.target_method?.target_type : false
          if(rentChangeMethodError)
            errorMessages.push('Rent Change Method field is required.')
          let rentChangeError = !rentChangeMethodError ? this.hasRateByError(stage?.increase_method?.value, stage?.increase_method?.type?.value_type) : '';
          let afterTargetRentError =  stage?.increase_method?.type?.targetReached && !targetMethodError
            ? this.hasRateByError(stage?.target_method?.target_value, stage?.target_method?.target_type)
            : false
          const validationMessages = {
            rent_sell_variance_percent: `If the chosen method is a variance percentage type, the rent change value should not include any decimal places and value should be between -99 and 999.`,
            dollar_amount: `If the chosen method is a dollar type, the rent change value should have a maximum of two decimal places and value should be between 1 and 9,999,999.`,
          }
          if (rentChangeError)
            errorMessages.push(
              validationMessages[stage?.increase_method?.type?.value_type] ?? `If the chosen method is a percentage type, the rent change value should not include any decimal places and value should be between 1 and 999.`
            )
          if(targetMethodError)
            errorMessages.push('Target Method field is required.')
          if (afterTargetRentError)
            errorMessages.push(
              validationMessages[stage?.target_method?.target_type] ?? `If the chosen method is a percentage type, the rent change value should not include any decimal places and value should be between 1 and 999.`
            )
            // if((stage?.increase_method?.type?.value_type === 'percentage' && rentChangeError) || (stage?.target_method?.target_type?.value_type === 'percentage' && afterTargetRentError)) {
            //   errorMessages.push(
            //   "Rent Change field is required with a minimum value of 1 without any decimal places."
            // );
            // } else{
            //   errorMessages.push(
            //     "Rent Change field is required with a minimum value of 1 with maximum of two decimal places."
            //   );
            // }
          errorFields.push({
            month: monthError,
            rentChangeMethodError: rentChangeMethodError,
            rentValue: rentChangeError,
            targetMethodError: targetMethodError,
            afterTargetRentValue: afterTargetRentError,
            recurringMonth: recurringMonthError,
            index: index,
          });
        }
      );
      this.$refs.scheduleRentPlan.showError(errorFields);
      await this.$refs.scheduleRentPlan.$validator.validateAll();
      errorMessages = [...new Set(errorMessages)];
      errorMessages.forEach((msg) => {
        this.$validator.errors.add({
          field: "settings",
          msg,
        });
      });
      await Promise.all(promises);
    },

    /**
     * Function to check scheduled plan month field validation
     * @param {String} value month of the stage
     * @returns a boolean value
     * */
    hasMonthError(value) {
      if (value) {
        //Value must be a natural number less than 100
        // if (/^[1-9][0-9]?$/.test(value)) {
        //   let month = parseInt(value);
        //   //Occupancy cannot be repeated
        //   if (!this.monthList?.includes(month)) {
        //     this.monthList.push(month);
        //     return false;
        //   }
        // }
        let month = Number(value)
        return !(Number.isInteger(month) && month > 0 && month < 1000)
      }
      else return true
    },

    /**
     * Function to check scheduled plan value field validation
     * @param {String} value value of the stage
     * @returns a boolean value
     * */
    hasRateByError(value, value_type) {
      if(value === '')
        return true;
      if(
          value_type?.includes('rent_sell_variance_percent')
          && !(/^([-][0-9]|[-][1-9][0-9]|[0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/.test(value))
      )
        return true;
      if(value_type?.includes('percent') 
        && !value_type.includes('rent_sell_variance_percent') 
        && !(/^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])$/.test(value))
      ) 
        return true;
      else if (value_type?.includes('dollar_amount')) {
        if (/^[0-9]+(\.[0-9]{1,2})?$/.test(value) && Number(value) >= 1 && Number(value) <= 9999999) {
          return false;
        }
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rent-plan-form {
  .rent-raise {
    gap: 8px;
  }
}
</style>
<style lang="scss">
.rent-plan-form {
  .hb-aviary-v-checkbox.v-input--selection-controls:not(.v-input--is-label-active)
    .plan-icon
    i.v-icon {
    color: rgb(99, 115, 129) !important;
  }
  .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
}
</style>