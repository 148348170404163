<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div @click.capture="!disableClick ? $emit('click', $event) : ''">
        <div
            v-if="type === 'lead'"
            class="hb-mini-profile-item-row hb-mini-profile-default-font-size hb-mini-profile-overflow-handler"
            :class="{ 'hb-mini-profile-item-row-additional-padding-top' : !leadSpaceType }"
            :style="leadSpaceType && (leadSpaceType !== 'storage') ? 'position:relative;top:4px;' : 'position:relative;top:1px;'"
        >
            <span
                v-if="leadType"
                class="hb-mini-profile-item"
            >
                <hb-link
                    v-if="!disableLeadLink"
                    color="secondary"
                    @click="$emit('lead-click', $event)"
                >
                    <span class="font-weight-medium">{{ leadType }}</span>
                </hb-link>
                <span v-else>{{ leadType }}</span>
            </span>
            <span
                v-if="leadSpaceType || leadSpaceNumber"
                class="hb-mini-profile-item"
            >
                <HbSpaceIcon
                    v-if="leadSpaceType"
                    enable-tooltip
                    small
                    :type="leadSpaceType"
                    style="position:relative;top:1px;"
                />
                <span
                    v-if="leadSpaceNumber"
                    :class="{ 'pl-1' : leadSpaceType }"
                >#{{ leadSpaceNumber }}</span>
            </span>
            <span
                v-if="leadSpaceCategory"
                class="hb-mini-profile-item"
            >{{ leadSpaceCategory }}</span>
            <span
                v-if="leadFeaturedAmenity"
                class="hb-mini-profile-item"
            >{{ leadFeaturedAmenity }}</span>
            <span
                v-if="leadPropertyInfo"
                class="hb-mini-profile-item"
            >{{ leadPropertyInfo }}</span>
        </div>
        <div
            v-if="type === 'space'"
            class="hb-mini-profile-item-row hb-mini-profile-default-font-size hb-mini-profile-overflow-handler"
            :class="{ 'hb-mini-profile-item-row-additional-padding-top' : !spaceType || (!spaceAccessCode && spaceStatus && spaceType !== 'storage' && spaceType !== 'default') }"
        >
            <span
                v-if="spaceType || spaceNumber"
                class="hb-mini-profile-item"
            >
                <hb-link
                    v-if="!disableSpaceLink"
                    color="secondary"
                    @click="$emit('space-click', $event)"
                >
                    <HbSpaceIcon
                        v-if="spaceType"
                        enable-tooltip
                        small
                        :type="spaceType"
                        color="#306FB6"
                        :style="spaceType === 'storage' ? 'position:relative;top:1px;' : ''"
                    />
                    <span
                        v-if="spaceNumber"
                        class="font-weight-medium"
                        :class="{ 'pl-1' : spaceType }"
                    >#{{ spaceNumber }}</span>
                </hb-link>
                <span v-else>
                    <HbSpaceIcon
                            v-if="spaceType"
                            enable-tooltip
                            small
                            :type="spaceType"
                            :style="spaceType === 'storage' ? 'position:relative;top:1px;' : ''"
                        />
                    <span
                        v-if="spaceNumber"
                        :class="{ 'pl-1' : spaceType }"
                    >#{{ spaceNumber }}</span>
                </span>
            </span>
            <span
                v-if="spaceCategory"
                class="hb-mini-profile-item"
            >{{ spaceCategory }}</span>
            </span>
            <span
                v-if="spaceFeaturedAmenity"
                class="hb-mini-profile-item"
            >{{ spaceFeaturedAmenity }}</span>
            <span
                v-if="spacePropertyInfo"
                class="hb-mini-profile-item"
            >{{ spacePropertyInfo }}</span>
            <span
                v-if="spaceAccessCode || !disableSpaceStatus && spaceStatus"
                :class="{ 'hb-mini-profile-item-pointer' : !disableSpaceAccessClick, 'hb-mini-profile-item' : spaceAccessCode && !disableSpaceStatus && spaceStatus }"
            >
                <span v-if="spaceAccessCode" @click="!disableSpaceAccessClick ? $emit('access-click', $event) : ''">
                    <hb-tooltip>
                        <template v-slot:item>
                            <HbIcon
                                small
                                :mdi-code="spaceLockout ? 'mdi-custom-access-code-off' : 'mdi-custom-access-code'"
                                :color="spaceLockout ? '#FB4C4C' : '#02AD0F'"
                                style="padding-left:1px;padding-right:5px;"
                            />
                        </template>
                        <template v-slot:body>
                            {{ spaceLockout ? 'Lockout' : 'Access' }}
                        </template>
                    </hb-tooltip><span :style="spaceLockout ? 'color:#FB4C4C' : 'color:#02AD0F'">{{ spaceAccessCode }}</span>
                </span>

                <span
                    v-if="!disableSpaceStatus && spaceStatus"
                    class="hb-mini-profile-item-space-status"
                    :class="{ 'hb-mini-profile-item-space-status-padding' : spaceAccessCode }"
                >
                    <HbStatus :status="spaceStatus" />
                </span>
            </span>
        </div>

        <div
            v-if="type === 'closed'"
            class="hb-mini-profile-item-row hb-mini-profile-default-font-size hb-mini-profile-overflow-handler"
            :class="{ 'hb-mini-profile-item-row-additional-padding-top' : !closedSpaceType }"
            :style="closedSpaceType && (closedSpaceType !== 'storage') ? 'position:relative;top:2.5px;' : ''"
        >
            <span class="hb-mini-profile-item">
                <HbSpaceIcon
                    small
                    :type="closedSpaceType"
                    color="#101318"
                    :style="closedSpaceType === 'storage' ? 'position:relative;top:1px;' : ''"
                />
                <span class="px-1">{{ closedCount }} {{ closedCount === 1 ? "Space" : "Spaces" }}</span>
                <span
                    v-if="!disableClosedStatus && closedStatus"
                    class="hb-mini-profile-item-space-status pl-1"
                >
                    <HbStatus
                        :status="closedStatus" />
                </span>
            </span>
        </div>
    </div>
</template>
<script type="text/babel">

    import HbSpaceIcon from './HbSpaceIcon.vue';
    import HbIcon from './HbIcon.vue';
    import HbLink from './HbLink.vue';
    import HbTooltip from './HbTooltip.vue';

    export default {
        name: "HbMiniProfileItem",
        components:{
            HbSpaceIcon,
            HbIcon,
            HbLink,
            HbTooltip
        },
        props: {
            type: {
                type: String,
                default: undefined
            },
            disableClick : {
                type: Boolean,
                default: false
            },
            leadType: {
                type: String,
                default: undefined
            },
            disableLeadLink: {
                type: Boolean,
                default: false
            },
            leadSpaceType: {
                type: String,
                default: undefined
            },
            leadSpaceNumber: {
                type: String,
                default: undefined
            },
            leadSpaceCategory: {
                type: String,
                default: undefined
            },
            leadFeaturedAmenity: {
                type: String,
                default: undefined
            },
            leadPropertyInfo: {
                type: String,
                default: undefined
            },
            spaceType: {
                type: String,
                default: undefined
            },
            spaceNumber: {
                type: String,
                default: undefined
            },
            disableSpaceLink: {
                type: Boolean,
                default: false
            },
            spaceCategory: {
                type: String,
                default: undefined
            },
            spaceFeaturedAmenity: {
                type: String,
                default: undefined
            },
            spacePropertyInfo: {
                type: String,
                default: undefined
            },
            spaceAccessCode: {
                type: String,
                default: undefined
            },
            spaceLockout: {
                type: Boolean,
                default: false
            },
            disableSpaceAccessClick: {
                type: Boolean,
                default: false
            },
            spaceStatus: {
                type: String,
                default: undefined
            },
            disableSpaceStatus: {
                type: String,
                default: undefined
            },
            closedSpaceType: {
                type: String,
                default: 'storage'
            },
            closedCount: {
                type: Number,
                default: 0
            },
            closedStatus: {
                type: String,
                default: undefined
            },
            disableClosedStatus: {
                type: String,
                default: undefined
            },
        }
    }
</script>

<style scoped>

.hb-mini-profile-item-row {
    height: 24px;
}

.hb-mini-profile-default-font-size {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
}

.hb-mini-profile-item:not(:last-of-type)::after {
    content: '|';
    padding-left: 5px;
    padding-right: 5px;
}

.hb-mini-profile-item-row-additional-padding-top {
    padding-top: 2.75px;
}

.hb-mini-profile-overflow-handler {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hb-mini-profile-item-cursor {
    cursor: pointer;
}

.hb-mini-profile-item-space-status {
    position: relative;
    top: -1px;
    transform: scale(80%) !important;
}

.hb-mini-profile-item-storage-type-fix {
    position: relative;
    top: 2.5px;
}

.hb-mini-profile-item-space-status-padding {
    padding-left: 6px;
}

.hb-mini-profile-item-pointer {
    cursor: pointer;
}
</style>