<template>
  <v-row class="hb-timeline-item-sub-action-wrapper pl-7" no-gutters>
    <v-col class="hb-timeline-item-sub-action-icon-wrapper pa-0" :style="'top:' + iconVerticalAdjustment">
      <HbIcon
        v-if="icon"
        small
        :color="computedIconColor"
        :mdi-code="icon"
      />
    </v-col>
    <v-col class="pa-0 hb-timeline-item-sub-action-content">
      <span class="hb-timeline-item-sub-action-item">
        <span
          v-if="title"
          class="hb-font-caption"
          style="margin-top:3.5px;"
          :class="computedTitleColor"
        >{{ title }}<template v-if="description || linkText">:</template></span>
        <span
          v-if="description"
          class="hb-font-caption ml-1"
          style="margin-top:3.5px;"
          :class="computedDescriptionColor"
        >{{ description }}</span>
      </span>
      <span
        v-if="linkText"
        class="hb-timeline-item-sub-action-item ml-1"
      >
        <hb-link
          @click="$emit('link-clicked', $event)"
          :href="linkUrl"
          target="_blank"
          class="hb-font-caption"
        >{{ linkText }}</hb-link>
      </span>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: "HbTimelineItemSubAction",
  data() {
    return {
        dialog: false,
    }
  },
  props: {
    type: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    iconColor: {
      type: String,
      default: undefined
    },
    title: {
        type: String,
        default: undefined
    },
    titleColor: {
      type: String,
      default: undefined
    },
    description: {
        type: String,
        default: undefined
    },
    descriptionColor: {
      type: String,
      default: undefined
    },
    linkText: {
      type: String,
      default: undefined
    },
    linkUrl: {
      type: String,
      default: undefined
    },
    iconVerticalAdjustment: {
      type: String,
      default: '-1.5px',
    }
  },
  computed: {
    computedIconColor() {
      if (this.iconColor){
        return this.iconColor;
      }
      else if (this.type === 'success'){
        return '#02AD0F';
      }
      else if (this.type === 'error'){
        return '#FB4C4C';
      }
      else {
        return '#101318';
      }
    },
    computedTitleColor() {
      if (this.titleColor && this.titleColor === 'error'){
        return 'hb-text-error';
      }
      else if (this.type === 'success'){
        return 'hb-text-light';
      }
      else if (this.type === 'error'){
        return 'hb-text-error';
      }
      else {
        return 'hb-text-light';
      }
    },
    computedDescriptionColor() {
      if (this.descriptionColor && this.descriptionColor === 'error'){
        return 'hb-text-error';
      }
      else if (this.type === 'night'){
        return 'hb-text-night';
      }
      else if (this.type === 'error'){
        return 'hb-text-error';
      }
      else {
        return 'hb-text-light';
      }
    },
  }
};
</script>

<style scoped>

.hb-timeline-item-sub-action-item {
  display: inline;
}

.hb-timeline-item-sub-action-icon-wrapper {
  position: relative;
  margin: 0 6px 0 0;
  min-width: 18px;
  max-width: 18px;
}

.hb-timeline-item-sub-action-content {
  line-height: 16px;
  margin: 2px 0 0 0;
}

</style>