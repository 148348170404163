<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div class="hb-radio-top-level-wrapper">
        <div class="hb-radio-wrapper d-flex align-center">

            <v-radio
                class="hb-aviary-v-radio mt-0 pt-0"
                v-bind="$attrs"
                v-on="$listeners"
                color="#00848E"
                hide-details
            >
                <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                    <slot :name="scopedSlot" v-bind="slotData"/>
                </template>
                <template v-for="(_, slot) in $slots" v-slot:[slot]>
                    <slot :name="slot"/>
                </template>

                <template v-slot:label>
                    <div class="d-flex">
                        <span v-if="$attrs.label && !hasLabelSlot">{{$attrs.label}}</span>
                        <slot name="label" v-if="hasLabelSlot"></slot>
                    </div>
                </template>

            </v-radio>

            <div
                v-if="(tooltipHeader || $slots.tooltipHeader || tooltipBody || $slots.tooltipBody) && !tooltipAfterInput &&!tooltipAfterContent"
                class="ml-1 my-0 py-0"
                :class="$slots.input ? 'hb-radio-tooltip-margin-fix-when-has-input' : 'hb-radio-tooltip-margin-fix'"
            >
                <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                </hb-tooltip>
            </div>

            <div
                v-if="$slots.content"
                class="hb-radio-content-slot-wrapper"
                :class="{'hb-radio-component-disabled' : $attrs.disabled === '' || $attrs.disabled}"
            >
                <slot name="content"></slot>
            </div>

            <div
                v-if="$slots.input"
                class="hb-radio-input-slot-wrapper hb-radio-component-alignment-fix ml-2 d-flex align-top"
                :class="{'hb-radio-component-disabled' : $attrs.disabled === '' || $attrs.disabled}"
            >
                <slot name="input"></slot>
            </div>

            <div
                v-if="(tooltipHeader || $slots.tooltipHeader || tooltipBody || $slots.tooltipBody) && (tooltipAfterInput || tooltipAfterContent)"
                class="ml-1 my-0 py-0"
                :class="$slots.input ? 'hb-radio-tooltip-margin-fix-when-has-input' : 'hb-radio-tooltip-margin-fix'"
            >
                <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                </hb-tooltip>
            </div>

        </div>

        <div
            v-if="description || $slots.description"
            class="hb-radio-description-slot-wrapper mt-1 ml-8"
            :class="{'hb-radio-component-disabled' : $attrs.disabled === '' || $attrs.disabled, 'mt-3' : $slots.input}"
        >
            <span v-if="description && !hasDescriptionSlot">{{description}}</span>
            <slot name="description" v-if="hasDescriptionSlot"></slot>
        </div>
    </div>
</template>

<script type="text/babel">

import HbTooltip from './HbTooltip.vue';

export default {
    name:'HbRadio',
    data(){
        return {

        }
    },
    components:{
        HbTooltip
    },
    computed: {
        hasLabelSlot(){
            return !!this.$slots['label'];
        },
        hasDescriptionSlot(){
            return !!this.$slots['description'];
        },
    },
    props: {
        tooltipHeader: {
            type: String
        },
        tooltipBody: {
            type: String
        },
        description: {
            type: String
        },
        tooltipAfterInput: {
            type: Boolean,
            default: false
        },
        tooltipAfterContent: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

.hb-aviary-v-radio:not(.v-item--active) .v-input--selection-controls__input > i.v-icon {
    color: #DFE3E8 !important;
}

.hb-aviary-v-radio:not(.v-item--active) .v-input--selection-controls__input:hover > i.v-icon {
    color: #637381 !important;
}

.hb-aviary-v-radio.v-item--active.v-radio--is-disabled .v-input--selection-controls__input > i.v-icon {
    color: #00848E !important;
}

.hb-aviary-v-radio .v-label {
    color: #101318 !important;
    font-size: 14px !important;
}

.hb-aviary-v-radio.v-radio--is-disabled, .hb-aviary-v-radio.v-radio--is-disabled label, .hb-radio-component-disabled {
    opacity: .5 !important;
}

.hb-radio-component-alignment-fix {
    position: relative;
    top: 2px;
}

.hb-aviary-form-wrapper .hb-radio-tooltip-margin-fix {
    position: relative;
    top: -1px;
}

.hb-radio-input-slot-wrapper {
    color: #101318;
    font-size: 14px;
}

.hb-radio-wrapper .hb-radio-input-slot-wrapper .hb-text-field-wrapper, .hb-radio-wrapper .hb-radio-input-slot-wrapper .hb-select-wrapper, .hb-radio-wrapper .hb-radio-input-slot-wrapper .hb-autocomplete-wrapper, .hb-radio-wrapper .hb-radio-input-slot-wrapper .hb-textarea-wrapper {
    position: relative;
    top: 2.5px;
}

.hb-radio-wrapper .hb-radio-input-slot-wrapper > :not(.hb-text-field-wrapper):not(.hb-select-wrapper):not(.hb-autocomplete-wrapper):not(.hb-textarea-wrapper):not(.hb-file-input-wrapper):not(.hb-combobox-wrapper) {
    position: relative;
    top: .5px;
}

.hb-aviary-form-wrapper .hb-radio-wrapper .hb-radio-input-slot-wrapper > :not(.hb-text-field-wrapper):not(.hb-select-wrapper):not(.hb-autocomplete-wrapper):not(.hb-textarea-wrapper):not(.hb-file-input-wrapper):not(.hb-combobox-wrapper) {
    position: relative;
    top: 2px;
}

.hb-radio-wrapper .hb-radio-input-slot-wrapper > .hb-file-input-wrapper {
    position: relative;
    top: .5px;
}

.hb-aviary-form-wrapper  .hb-radio-wrapper .hb-radio-input-slot-wrapper > .hb-file-input-wrapper {
    position: relative;
    top: 2.5px;
}

.hb-radio-wrapper .hb-radio-input-slot-wrapper > .hb-combobox-wrapper {
    position: relative;
    top: 2.5px;
}

.hb-radio-description-slot-wrapper {
    color: #637381;
    font-size: 14px;
}

.hb-radio-content-slot-wrapper {
    position: relative;
    top: 9.5px;
    font-size: 14px;
}

</style>