

export default [
    {
        path: '/',
        redirect: '/login',
        meta: {
            layout: 'unauthenticated'
        }
    },
    {
        name: "callback",
        path: '/callback',
        component: () => import(/* webpackChunkName: "callback" */'../components/Callback.vue'),
        meta: {
            layout: 'unauthenticated'
        }
    },

    {
        name: "Test",
        path: '/test',
        component: () => import(/* webpackChunkName: "text" */ '../components/Test.vue'),
        meta:{
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master'
        }
    },
    {
        name: "Login",
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */'../components/Login.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "Loading",
        path: '/Loading',
        component: () => import(/* webpackChunkName: "login" */'../components/LoginLoading.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "LoginPreviewInactiveAnnouncements",
        path: '/login-preview-inactive-announcements',
        component: () => import(/* webpackChunkName: "login" */'../components/LoginPreviewInactiveAnnouncements.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "Logout",
        path: '/logout',
        component: () => import(/* webpackChunkName: "logout" */'../components/Logout.vue'),
    },
    {
        name: "ResetPassword",
        path: '/reset-password',
        component: () => import(/* webpackChunkName: "resetPassword" */'../components/ResetPassword.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "ForgotUsername",
        path: '/forgot-username',
        component: () => import(/* webpackChunkName: "forgotUsername" */'../components/ForgotUsername.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "Setup",
        path: '/setup/:hash',
        component: () => import(/* webpackChunkName: "setup" */'../components/SetPassword.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },

    {
      name: "PayNow",
      path: '/pay-now/:token',
      component: () => import(/* webpackChunkName: "paynow" */'../components/PayNow.vue'),
      meta:{
        layout: 'unauthenticated'
      }
    },
    {
        name: "Register",
        path: '/register',
        component:  () => import(/* webpackChunkName: "register" */'../components/Register.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "NewPassword",
        path: '/reset-password/:hash',
        component: () => import(/* webpackChunkName: "newPassword" */'../components/NewPassword.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    },
    {
        name: "Switch",
        path: '/switch',
        component: () => import(/* webpackChunkName: "switch" */'../components/Switch.vue'),
        meta:{
            hasAccess: ['admin']
        }

    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */'../components/NotFound.vue')
    },
    {
        path: '*',
        redirect: '/404'
    }, 
    {
        name: 'PayError',
        path: '/payerror',
        component: () => import(/* webpackChunkName: "payerror" */'../components/PayError.vue'),
        meta:{
            layout: 'unauthenticated'
        }
    }
    
].map(route => {
    return {
        ...route,
        beforeEnter: (to, from, next) => {
            const subdomain = window.location.hostname.split('.')[0];
            if (subdomain === 'tenantpay' && to.path !== '/payerror') {
                next({
                    path: '/payerror'
                });
            } else {
                next(); 
            }
        }
    };
});

