//import SignDocument from '../components/signing/SignDocument.vue'
// import SignDocuments from '../components/signing/SignDocuments.vue'

export default [
	{
		path: '/sign-documents/:hash',
		component: () => import('../components/signing/SignDocuments.vue'),
		meta: {
			layout: 'unauthenticated',
      blank: true,
			requiresAuth: false,
			hasAccess: ['sign']
		}
	}
]
