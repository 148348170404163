<template>
  <div>
    <hb-modal
      @close="$emit('close')"
      size="large"
      title="Email Document"
      v-model="dialog"
      show-help-link
    >
      <template v-slot:subheader>
        Enter a message to send to the tenant.
      </template>
      <template v-slot:content>
					<hb-form label="Email" required full>
						<v-text-field
							v-model="email.to"
							v-validate.disable="'required|max:200'"
							data-vv-scope="email"
							id="to"
							name="to"
							:error="errors.collect('email.to').length > 0"
							label="Email"
							single-line
							hide-details
						></v-text-field>
					</hb-form>

					<hb-form label="Document Name" required full>
						<v-text-field
							v-model="email.docName"
							v-validate.disable="'required|max:1000'"
							data-vv-scope="email"
							id="docName"
							name="docName"
							:error="errors.collect('email.docName').length > 0"
							label="Document Name"
							single-line
							hide-details
						></v-text-field>
					</hb-form>

					<hb-form label="Message" required full>
						<v-text-field
							v-model="email.message"
							v-validate.disable="'required'"
							data-vv-scope="email"
							id="message"
							name="message"
							:error="errors.collect('email.message').length > 0"
							label="Message"
							single-line
							hide-details
						></v-text-field>
					</hb-form>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="sendEmail">Send</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
export default {
  name: "emailDocument",

  data() {
    return {
      email: {
        to: "",
        docName: "",
        message: "",
      },
    };
  },

  created() {
	},

  methods: {},

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  components: {},

  props: {
		value: {
      type: Boolean,
      default: false,
    }
	},
};
</script>

<style scoped>
</style>