<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div>
        <div class="hb-checkbox-wrapper d-flex" :class="alignTop ? 'align-top' : 'align-center'">
            
            <v-checkbox
                v-model="vmodel"
                class="hb-aviary-v-checkbox mt-0 pt-0"
                v-bind="$attrs"
                v-on="$listeners"
                color="#00848E"
                hide-details
            >
                <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                    <slot :name="scopedSlot" v-bind="slotData"/>
                </template>
                <template v-for="(_, slot) in $slots" v-slot:[slot]>
                    <slot :name="slot"/>
                </template>

                <template v-slot:label>
                    <div class="d-flex">
                        <span v-if="$attrs.label && !hasLabelSlot">{{$attrs.label}}</span>
                        <slot name="label" v-if="hasLabelSlot"></slot>
                    </div>
                </template>

            </v-checkbox>

            <div
                v-if="(tooltipHeader || $slots.tooltipHeader || tooltipBody || $slots.tooltipBody) && !tooltipAfterInput && !tooltipAfterContent"
                class="ml-1"
                :style="lowerTooltipSlightly ? '' : 'margin-bottom:2px;'"
            >
                <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                </hb-tooltip>
            </div>

            <div
                v-if="$slots.content"
                class="hb-checkbox-content-slot-wrapper"
                :class="{'hb-checkbox-component-disabled' : $attrs.disabled === '' || $attrs.disabled}"
            >
                <slot name="content"></slot>
            </div>
    
            <div
                v-if="$slots.input"
                class="hb-checkbox-input-slot-wrapper hb-checkbox-component-alignment-fix ml-2 d-flex align-top"
                :class="{'hb-checkbox-component-disabled' : $attrs.disabled === '' || $attrs.disabled}"
            >
                <slot name="input"></slot>
            </div>

            <div
                v-if="(tooltipHeader || $slots.tooltipHeader || tooltipBody || $slots.tooltipBody) && (tooltipAfterInput || tooltipAfterContent)"
                class="ml-1"
                :style="lowerTooltipSlightly ? '' : 'margin-bottom:2px;'"
            >
                <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                    <template v-slot:body>
                        <span v-if="$slots.tooltipBody">
                            <slot name="tooltipBody"></slot>
                        </span>
                        <span v-else-if="tooltipBody">
                            {{ tooltipBody }}
                        </span>
                    </template>
                </hb-tooltip>
                <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
                    <template v-slot:header>
                        <span v-if="$slots.tooltipHeader">
                            <slot name="tooltipHeader"></slot>
                        </span>
                        <span v-else-if="tooltipHeader">
                            {{ tooltipHeader }}
                        </span>
                    </template>
                </hb-tooltip>
            </div>

        </div>

        <div
            v-if="description || $slots.description"
            class="hb-checkbox-description-slot-wrapper mt-1 ml-8"
            :class="{'hb-checkbox-component-disabled' : $attrs.disabled === '' || $attrs.disabled, 'mt-3' : $slots.input}"
        >
            <span v-if="description && !hasDescriptionSlot">{{description}}</span>
            <slot name="description" v-if="hasDescriptionSlot"></slot>
        </div>
    </div>
</template>

<script type="text/babel">

export default {
    name:'HbCheckbox',
    data(){
        return {

        }
    },
    computed: {
        vmodel: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        hasLabelSlot(){
            return !!this.$slots['label'];
        },
        hasDescriptionSlot(){
            return !!this.$slots['description'];
        },
    },
    props: {
        tooltipHeader: {
            type: String
        },
        tooltipBody: {
            type: String
        },
        description: {
            type: String
        },
        tooltipAfterInput: {
            type: Boolean,
            default: false
        },
        tooltipAfterContent: {
            type: Boolean,
            default: false
        },
        lowerTooltipSlightly: {
            type: Boolean,
            default: false
        },
        alignTop: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

.hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-label-active .v-input--selection-controls__input > i.v-icon {
  color: #00848E !important;
}

.hb-aviary-v-checkbox.v-input--selection-controls:not(.v-input--is-label-active) .v-input--selection-controls__input > i.v-icon, .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-label-active) .v-input--selection-controls__input > i.v-icon {
  color: #DFE3E8 !important;
}

.hb-aviary-v-checkbox.v-input--selection-controls:not(.v-input--is-label-active):hover .v-input--selection-controls__input > i.v-icon, .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-label-active):hover .v-input--selection-controls__input > i.v-icon {
  color: #637381 !important;
}

.hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-readonly:hover .v-input--selection-controls__input > i.v-icon {
  color: #DFE3E8 !important;
}

.hb-aviary-v-checkbox.v-input--selection-controls .v-input--selection-controls__input:hover .v-input--selection-controls__ripple::before {
    opacity: 0;
}

/* reduces background ripple, uncomment this to turn this back on
.hb-aviary-v-checkbox .v-input--selection-controls__ripple {
    height: 28px;
    width: 28px;
    left: -9px;
    top: calc(50% - 21px);
}
*/

.hb-aviary-v-checkbox .v-label {
    color: #101318 !important;
    font-size: 14px !important;
}

.hb-checkbox-wrapper .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-label-active .v-input--selection-controls__input > i.v-icon {
    color: #00848E !important;
}

.hb-checkbox-component-alignment-fix {
    position: relative;
    top: 2px;
}

.hb-checkbox-input-slot-wrapper {
    color: #101318;
    font-size: 14px;
}

.hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper .hb-text-field-wrapper, .hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper .hb-select-wrapper, .hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper .hb-autocomplete-wrapper, .hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper .hb-textarea-wrapper {
    position: relative;
    top: 2.5px;
}

.hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper > :not(.hb-text-field-wrapper):not(.hb-select-wrapper):not(.hb-autocomplete-wrapper):not(.hb-textarea-wrapper):not(.hb-file-input-wrapper):not(.hb-combobox-wrapper) {
    position: relative;
    top: .5px;
}

.hb-aviary-form-wrapper .hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper > :not(.hb-text-field-wrapper):not(.hb-select-wrapper):not(.hb-autocomplete-wrapper):not(.hb-textarea-wrapper):not(.hb-file-input-wrapper):not(.hb-combobox-wrapper) {
    position: relative;
    top: 2px;
}

.hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper > .hb-file-input-wrapper {
    position: relative;
    top: .5px;
}

.hb-aviary-form-wrapper  .hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper > .hb-file-input-wrapper {
    position: relative;
    top: 2.5px;
}

.hb-checkbox-wrapper .hb-checkbox-input-slot-wrapper > .hb-combobox-wrapper {
    position: relative;
    top: 2.5px;
}

.hb-aviary-v-checkbox.v-input--selection-controls.v-input--indeterminate .v-input--selection-controls__input > i.v-icon {
    color: #00848E !important;
}

.hb-aviary-v-checkbox.v-input--selection-controls.v-input--indeterminate:hover .v-input--selection-controls__input > i.v-icon {
    color: #00848E !important;
}

.hb-checkbox-description-slot-wrapper {
    color: #637381;
    font-size: 14px;
}

.hb-checkbox-content-slot-wrapper {
    position: relative;
    top: 9.5px;
}

.hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled label, .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-readonly:not(.v-input--is-label-active) label {
    opacity: .35 !important;
}

.hb-checkbox-wrapper .v-input--is-readonly .v-input--selection-controls__input, .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled .v-input--selection-controls__input > i.v-icon, .hb-checkbox-component-disabled {
    opacity: .5 !important;
}

.hb-checkbox-wrapper .v-input--is-readonly .v-input--selection-controls__ripple:hover, .hb-checkbox-wrapper .v-input--is-readonly label {
    cursor: default !important;
}
</style>