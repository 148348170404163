<template>
    <div>

        <p>The following promotions have not been applied to this invoice.  Select the ones you want to apply.</p>

        <div class="row" v-for="p, i in promotions">
            <div class="col-xs-1 text-right">
                <input type="checkbox"  :value="p.id" v-model="selectedPromotions" />
            </div>
            <div class="col-xs-10">
                <p>
                    <strong>{{p.Promotion.name}}</strong><br />
                    {{p.Promotion.description}}
                </p>
            </div>
        </div>

        <div class="modal-footer">
            <button class="btn btn-dark" @click="save">Save</button>
            <button class="btn btn-std" @click="$emit('close')">Cancel</button>
        </div>
    </div>

</template>

<script type="text/babel">

    export default {
        data() {
            return {
                selectedPromotions: []
            }
        },
        methods:{
          save(){
              this.$emit('input', this.selectedPromotions);
              this.$emit('close');
          }
        },
        props:[
            'promotions'
        ],

    }
</script>