export default Object.freeze({
  TYPES: {
    month_to_month: {
      text: 'Month to Month',
      value: 'Month to Month',
    },
    fixed_length: {
      text: 'Fixed Length',
      value: 'Fixed Length',
    },
  },
  STATUSES: {
    RESERVED: 'reserved',
    LEASED: 'leased',
    PENDING: 'pending',
    CLOSED: 'closed',
    OFFLINE: 'offline'
  },
  PAYMENT_CYCLES: {
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    ANNUAL: 'Annual'
  },
  RETIRE_LEAD_REASONS: [
    "Stored With Competitor",
    "Priced Too high",
    "Space/Amenities Didn't Meet Need",
    "No Longer Interested",
    "Can't afford storage",
    "Not a lead",
    "Duplicate"
  ],
  TOOLTIPS_MESSAGES: {
    PERMISSION: 'You do not have the access to perform this action. Please contact your administrator.',
    AUCTION_MOVE_OUT: 'The move-out performed after an auction cannot be reverted.',
    UNIT_AVAILABILITY: 'The space is no longer available. Reverse Move-Out cannot be performed.',
    UNABLE_TO_REVERSE: 'Reverse Move-Out cannot be performed as either the setting is disabled or the space is no longer available.',
  }
});
