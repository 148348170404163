<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <v-dialog
        v-model="dialog"
        :width="dialogSize"
        :fullscreen="fullscreen"
        :persistent="persistent"
        :scrollable="scrollable"
        slot="body"
        :content-class="computedContentClass"
        @keydown="getContentPosition"
    >
        <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
                <slot name="activator"></slot>
            </span>
        </template>

        <v-card @click.native="handleNativeClick">
            <v-card-title class="hb-modal-title d-flex align-center ma-0 pa-0" :class="computedTitleClass">
                <v-col :cols="$slots.header ? 3 : 10" class="ma-0 py-0 px-4 d-flex align-center">
                    <slot name="icon" v-if="$slots.icon"></slot>
                    <hb-icon v-if="(titleIcon || confirmation || guidance) && !$slots.icon" class="pr-2 mt-n1" :color="computedTitleIconColor" :mdi-code="icon"></hb-icon>
                    <span class="hb-modal-title-font-size">
                        <span v-if="$slots.title">
                            <slot name="title"></slot>
                        </span>
                        <span v-else>
                            {{ title }}
                        </span>
                    </span>
                </v-col>
                <v-col :cols="$slots.header ? 9 : 2" class="ma-0 pa-0 hb-modal-header-close-padding-right d-flex justify-end">
                    <slot name="header"></slot>
                    <hb-btn icon tooltip="Close" @click="close" active-state-off :hover-background-color="computedCloseHoverBackgroundColor">mdi-close</hb-btn>
                </v-col>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title v-if="$slots.presubheader && !noPreSubHeader && !hidePreSubHeader" class="hb-modal-pre-sub-header" ref="hbModalPreSubHeader">
                <div class="hb-modal-pre-sub-header-content">
                    <slot name="presubheader"></slot>
                </div>
            </v-card-title>
            <v-card-title v-if="$slots.sticky" class="hb-modal-sticky-section" :class="{'hb-modal-elevation-small' : $refs.hbModalContent && $refs.hbModalContent.scrollTop > 0 }" style="z-index:999">
                <div class="hb-modal-sticky-section-content">
                    <slot name="sticky"></slot>
                </div>
            </v-card-title>
            <v-card-text class="pa-0 ma-0" ref="hbModalContent" v-resize="getContentPosition" @scroll="handleContentScroll">
                <div v-if="$slots.subheader && !noSubHeader">
                    <div class="hb-modal-sub-header px-4">
                        <slot name="subheader"></slot>
                    </div>
                    <v-divider></v-divider>
                </div>
                <div :class="{'pa-4' : contentPadding}">
                    <span class="hb-modal-content">
                        <slot name="content"></slot>
                    </span>
                </div>
            </v-card-text>
            <slot name="secondary"></slot>
            <hb-bottom-action-bar v-if="!footerOff" :footerCancelOption="footerCancelOption" :closeInsteadOfCancel="closeInsteadOfCancel" @close="close">

                <template v-slot:left-actions>
                    <div class="d-flex align-center">
                        <span v-if="showHelpLink && isHelpLinkComponentAvailable"><help-juice /></span>
                        <slot name="left-actions"></slot>
                    </div>
                </template>
                <template v-slot:leftactions>
                    <slot name="leftactions"></slot>
                </template>

                <template v-slot:right-actions>
                    <slot name="right-actions"></slot>
                </template>
                <template v-slot:actions>
                    <slot name="actions"></slot>
                </template>

            </hb-bottom-action-bar>
            <div :style="footerOff ? 'position:absolute;bottom:24px;right:24px;' : 'position:absolute;bottom:83px;right:24px;'" v-show="showScrollHelper">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#DFE3E8" fill-opacity="0.8" style="mix-blend-mode:multiply"/>
                    <mask id="mask0_811_13528" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
                        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_811_13528)">
                        <path d="M20 30L13 23L14.4 21.575L19 26.175V10H21V26.175L25.6 21.6L27 23L20 30Z" fill="#637381"/>
                    </g>
                </svg>
            </div>
        </v-card>
    </v-dialog>
</template>
<script type="text/babel">

    import HbIcon from './HbIcon.vue';
    import HbBtn from './HbBtn.vue';
    import HbLink from './HbLink.vue';
    import HbBottomActionBar from './HbBottomActionBar.vue';
    import Vue from 'vue';
    
    export default {
        name: "HbModal",
        data: function() {
            return {
                showScrollHelper: false,
                hidePreSubHeader: true,
            };
        },
        components:{
            HbIcon,
            HbBtn,
            HbLink,
            HbBottomActionBar
        },
        mounted() {
            setTimeout(() => {
                if(this.$refs.hbModalContent){
                    this.showHidePreSubHeader();
                    this.getContentPosition();
                }
            }, 500);
        },
        computed: {
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            /*
            hasHeaderSlot(){
                return !!this.$slots['header'];
            },
            hasIconSlot(){
                return !!this.$slots['icon'];
            },
            */
            fullscreen(){
                if(this.size === 'fullscreen'){
                    return true;
                } else {
                    return false;
                }
            },
            dialogSize(){
                if(this.size === 'small'){
                    return '360';
                }
                else if(this.size === 'medium'){
                    return '480';
                }
                else if(this.size === 'large'){
                    return '720';
                }
                else {
                    return '720';
                }
            },
            hasSubheaderSlot(){
                return !!this.$slots['subheader'];
            },
            hasHeaderLeftSlot(){
                return !!this.$slots['header-left'];
            },
            computedCloseHoverBackgroundColor(){
                if(this.confirmation){
                    return '#F5EBB7';
                } else if(this.guidance){
                    return '#BAD7FF';
                } else {
                    return '#CAEAEA';
                }
            },
            icon(){
                if(this.titleIcon){
                    return this.titleIcon;
                } else if(this.guidance){
                    return 'mdi-information';
                } else {
                    return 'mdi-alert-circle-outline';
                }
            },
            computedTitleIconColor(){
                if(this.guidance){
                    return '#3D8FFF';
                } else {
                    return '#101318';
                }
            },
            computedTitleClass(){
                if(this.confirmation){
                    return 'hb-modal-title-confirmation';
                } else if(this.guidance){
                    return 'hb-modal-title-guidance';
                } else {
                    return 'hb-modal-title-default';
                }
            },
            computedContentClass(){
                var defaultClass = 'hb-modal-wrapper';
                var confirmationClass = '';
                var fullHeightClass = '';
                var mediumHeightClass = '';
                var smallHeightClass = '';

                if(this.confirmation){
                    confirmationClass = ' hb-modal-confirmation-border';
                } else if(this.guidance){
                    confirmationClass = ' hb-modal-guidance-border';
                }

                if(this.height === 'full'){
                    fullHeightClass = ' hb-modal-full-height';
                } else if(this.height === 'medium'){
                    mediumHeightClass = ' hb-modal-medium-height';
                } else if(this.height === 'small'){
                    smallHeightClass = ' hb-modal-small-height';
                }

                return defaultClass + confirmationClass + fullHeightClass + mediumHeightClass + smallHeightClass;
            },
            isHelpLinkComponentAvailable() {
                return Vue?.options?.components && Vue.options.components['HelpJuice'] ? true : false;
            }
        },
        methods: {
            close(){
                this.$emit('close');
                setTimeout(() => {
                    this.dialog = false;
                }, 200);
            },
            showHidePreSubHeader(){
                if(this.$refs.hbModalContent){
                    if(this.$refs.hbModalContent.scrollTop > 0){
                        this.hidePreSubHeader = true;
                    } else {
                        this.hidePreSubHeader = false;
                    }
                    var position = this.$refs.hbModalContent.getBoundingClientRect();
                    this.$emit('content-position', position);
                }
            },
            getContentPosition: _.debounce(function() {
                if(this.$refs.hbModalContent){
                    var position = this.$refs.hbModalContent.getBoundingClientRect();
                    this.$emit('content-position', position);
                } else {
                    setTimeout(() => {
                        if(this.$refs.hbModalContent){
                            var position = this.$refs.hbModalContent.getBoundingClientRect();
                            this.$emit('content-position', position);
                        }
                    }, 200);
                }

                setTimeout(() => {
                    if(this.$refs.hbModalContent && (this.$refs.hbModalContent.scrollTop + this.$refs.hbModalContent.clientHeight) >= this.$refs.hbModalContent.scrollHeight) {
                        this.showScrollHelper = false;
                    } else {
                        this.showScrollHelper = true;
                    }
                }, 500);

                setTimeout(() => {
                    if(this.$refs.hbModalContent && (this.$refs.hbModalContent.scrollTop + this.$refs.hbModalContent.clientHeight) >= this.$refs.hbModalContent.scrollHeight) {
                        this.showScrollHelper = false;
                    } else {
                        this.showScrollHelper = true;
                    }
                }, 2000);
            }, 200),
            handleNativeClick(){
                this.showHidePreSubHeader();
                this.getContentPosition();

                setTimeout(() => {
                    this.showHidePreSubHeader();
                    this.getContentPosition();
                }, 2000);
            },
            handleContentScroll(){
                this.showScrollHelper = false;
                this.showHidePreSubHeader();
                this.getContentPosition();

                setTimeout(() => {
                    this.showHidePreSubHeader();
                    this.getContentPosition();
                }, 2000);
            },
            handleForceScrollToTop(){
                if(this.$refs.hbModalContent){
                    this.$refs.hbModalContent.scrollTop = 0;
                } else {
                    setTimeout(() => {
                        if(this.$refs.hbModalContent){
                            this.$refs.hbModalContent.scrollTop = 0;
                        }
                    }, 200);
                }
            }
        },
        props: {
            size:{
                type: String
            },
            title:{
                type: String
            },
            titleIcon:{
                type: String
            },
            persistent:{
                type: Boolean,
                default: true
            },
            value:{
                type: Boolean,
                default: false
            },
            footerOff:{
                type: Boolean,
                default: false
            },
            scrollable:{
                type: Boolean,
                default: true
            },
            footerCancelOption: {
                type: Boolean,
                default: true
            },
            closeInsteadOfCancel:{
                type: Boolean,
                default: false
            },
            confirmation: {
                type: Boolean,
                default: false
            },
            noPreSubHeader: {
                type: Boolean,
                default: false
            },
            noSubHeader: {
                type: Boolean,
                default: false
            },
            contentPadding: {
                type: Boolean,
                default: false
            },
            minHeight: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                default: undefined
            },
            recalculateContentPosition: {
                type: Boolean,
                default: false
            },
            forceScrollToTop: {
                type: Boolean,
                default: false
            },
            showHelpLink: {
                type: Boolean,
                default: true
            },
            guidance: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            'recalculateContentPosition'(){
                if(this.recalculateContentPosition){
                    this.getContentPosition();
                    this.$emit('update:recalculateContentPosition', false);
                }
            },
            'forceScrollToTop'(){
                if(this.forceScrollToTop){
                    this.handleForceScrollToTop();
                    this.$emit('update:forceScrollToTop', false);
                }
            }
        }
    }
</script>

<style scoped>

.hb-modal-fullscreen-footer {
  position: fixed;
  border-top: 1px solid #DFE3E8;
  bottom: 0;
  width: 100%;
}

.hb-modal-sub-header {
  min-height: 44px;
  padding-top: 11px;
  padding-bottom: 14px;
  font-size: 14px;
  color: #637381;
}

.hb-modal-pre-sub-header {
    padding: 11px 16px 14px 16px !important;
    min-height: 44px;
    border-bottom: 1px solid #DFE3E8;
}

.hb-modal-pre-sub-header .hb-modal-pre-sub-header-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 19.25px;
    width: 100% !important;
    color: #637381;
}

.hb-modal-content {
    font-size: 14px;
    color: #101318;
}

.hb-modal-title-confirmation {
  background-color: #FFF9DA;
}

.hb-modal-title-guidance {
  background-color: #E7F1FF;
}

.hb-modal-header-close-padding-right {
  padding-right: 8px !important;
}

.hb-modal-content .v-input .v-label {
  line-height: 20px;
}

.hb-modal-title-font-size {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.hb-modal-title {
    min-height: 52px;
}

.hb-modal-title-default {
    background: #E0F5F5;
}

.v-card__actions.hb-modal-footer-wrapper {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    height: 60px;
    padding-left: 8px;
}

.hb-modal-sticky-section {
    padding: 0 !important;
}

.hb-modal-sticky-section >>> .v-stepper {
    border-radius: 0 !important;
}

.hb-modal-sticky-section .hb-modal-sticky-section-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 19.25px;
    width: 100% !important;
}

.hb-modal-elevation-small {
    box-shadow: 0px 2px 4px 2px rgba(71, 84, 116, 0.1);
}

</style>

<style>

.hb-modal-small-height {
    min-height: 375px !important;
    max-height: 375px !important;
}

.hb-modal-medium-height {
    min-height: 620px !important;
    max-height: 620px !important;
}

.hb-modal-full-height {
    min-height: 90% !important;
    max-height: 90% !important;
}

.v-dialog.hb-modal-wrapper.hb-modal-confirmation-border {
  border: 2px solid #FFD600;
  border-radius: 4px;
}

.v-dialog.hb-modal-wrapper.hb-modal-guidance-border {
  border: 2px solid #3D8FFF;
  border-radius: 4px;
}

</style>