import { mapGetters } from 'vuex';
const unitTypeMixin = {
  install(Vue) {
    Vue.mixin({
      computed:{
        ...mapGetters({
          unitTypes: "featureAndAmenitiesStore/unitTypesList"
        })
      },
      methods: {
        getUnitTypes(conf_name = null){
          let filteredUnitTypes = [];
          if(conf_name){
            filteredUnitTypes = this.unitTypes.filter(unitType => unitType[conf_name] !== 0);
          }else{
            filteredUnitTypes = this.unitTypes;
          }
          return filteredUnitTypes;
        },
        getUnitTypeTitle(unitObject){
          const unitTypeMeta = this.unitTypes.find(item => item.unit_type_id == unitObject.unit_type_id)
          return unitTypeMeta?.display_name;
        },
        getUnitTypeTitleBasedOnLabel(view){
          const typeMeta = this.unitTypes.find(item => item.unit_type_label == view)
          return typeMeta?.display_name;
        },
        getUnitObjectBasedOnLabel(view){
          const unitTypeMeta = this.unitTypes.find(item => item.unit_type_label == view)
          return unitTypeMeta;
        },
        getUnitTypeMeta(unitObject){
          const unitTypeMeta = this.unitTypes.find(item => item.unit_type_id == unitObject.unit_type_id)
          return unitTypeMeta;
        },
        getAllUnitTypeLabels(){
          const unit_type_labels = this.unitTypes.map(item => item.unit_type_label)
          return unit_type_labels;
        },
        getConfigurationStatus(unit_type_id,key){
          const unitTypeMeta = this.unitTypes.find(item => item.unit_type_id === unit_type_id)
          return unitTypeMeta[key];
        },
        getUnitTypeLabel(unitObject){
          const unitTypeMeta = this.unitTypes.find(item => item.unit_type_id == unitObject.unit_type_id)
          return unitTypeMeta?.unit_type_label;
        },
      }
    })
  }
};

export default unitTypeMixin;