<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div
        class="hb-text-field-wrapper"
        :class="{'hb-text-field-width-370' : !full && !search, 'hb-text-field-disabled' : disabled, 'hb-text-field-condensed-wrapper' : condensed}"
        :style="'width:' + computedWidth"
    >
        <v-text-field
            :value="value"
            @input="$emit('input', value)"
            class="ma-0 pa-0"
            v-bind="$attrs"
            v-on="$listeners"
            single-line
            hide-details
            :clearable="(clearable || search) ? true : false"
            clear-icon="mdi-close-circle"
            :disabled="disabled"
            :prepend-inner-icon="search ? 'mdi-magnify' : prependInnerIcon"
            :append-icon="appendIcon"
            :rounded="rounded"
            :outlined="rounded || box || search"
            :dense="rounded || box || search"
            :class="{'hb-text-field-rounded' : rounded || box || search, 'hb-text-field-condensed' : condensed, 'hb-text-field-x-small' : xSmall, 'hb-text-field-small' : small, 'hb-text-field-medium' : medium, 'hb-text-field-text-align-right' : computedAlignRight}"
            :background-color="backgroundColor"
        >
            <template v-for="(_, scopedSlot) in $scopedSlots" v-slot:[scopedSlot]="slotData">
                <slot :name="scopedSlot" v-bind="slotData"/>
            </template>
            <template v-for="(_, slot) in $slots" v-slot:[slot]>
                <slot :name="slot"/>
            </template>
            <template v-slot:progress>
                <v-progress-circular indeterminate size="18" :style="condensed && (box || rounded || search) ? 'margin-top: 12px;' : box || rounded || search ? 'margin-top: 11px;' : 'margin-top: -3px;'" class="mb-0 pa-0 ml-3 mr-0" color="#00848E" width="2"></v-progress-circular>
            </template>
        </v-text-field>

    </div>
</template>

<script type="text/babel">

export default {
    name:'HbTextField',
    data(){
        return {

        }
    },
    computed: {
        /*
        textfield: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
        */
       computedWidth() {
            if(this.xSmall){
               return '36px';
            }
            else if(this.small) {
               return '60px';
            }
            else if(this.medium) {
                return '108px';
            } else if(this.width) {
                return this.width
            } else {
                return 'auto';
            }
       },
       computedAlignRight(){
           if((!this.$slots['prepend-inner'] && !this.prependInnerIcon && (this.$slots['append'] || this.appendIcon)) && (this.xSmall || this.small || this.medium) || this.alignRight){
               return true;
           } else {
               return false;
           }
       }
    },
    props: {
        value: {

        },
        full: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        box: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false
        },
        prependInnerIcon: {
            type: String,
            default: undefined
        },
        appendIcon: {
            type: String,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: undefined
        },
        condensed: {
            type: Boolean,
            default: undefined
        },
        xSmall: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        medium: {
            type: Boolean,
            default: false
        },
        alignRight: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: '#FFFFFF'
        }
    }
}
</script>

<style>

.hb-text-field-wrapper {
    margin-top: -1px;
}

.hb-text-field-wrapper * {
    font-size: 14px;
}

.hb-text-field-width-370 {
    max-width: 370px;
}

.hb-text-field-wrapper input {
    color: #101318 !important;
    font-size: 14px !important;
    padding-top: 0px;
}


.hb-text-field-wrapper .hb-text-field-rounded:not(.hb-text-field-condensed) input {
    padding-top: 6px;
}

.hb-text-field-wrapper :not(.hb-text-field-rounded) .v-input__prepend-outer {
    padding-top: 3px !important;
}

.hb-text-field-wrapper .v-label {
    color: #637381 !important;
    top: 0;
    padding-bottom: 8px !important;
    font-size: 14px !important;
}

.hb-text-field-wrapper .v-input:not(.v-input--is-dirty) ::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
    opacity: 1;
}

.hb-text-field-wrapper .v-input__icon--clear .v-icon {
    font-size: 16px !important;
    color: #C4CDD5 !important;
    padding-bottom: 10px;
}

.hb-text-field-wrapper .hb-text-field-rounded .v-input__icon--clear .v-icon {
    padding-bottom: 8px;
}

.hb-text-field-wrapper .v-input__icon--clear .v-icon:hover {
    color: #637381 !important;
}

.hb-text-field-wrapper .v-input__icon.v-input__icon--prepend-inner .v-icon, .hb-text-field-wrapper .v-input__icon.v-input__icon--prepend .v-icon, .hb-text-field-wrapper .v-input__icon.v-input__icon--append-outer .v-icon, .hb-text-field-wrapper .v-input__icon.v-input__icon--append .v-icon {
    color: #637381 !important;
    padding-bottom: 8px;
}

.hb-text-field-wrapper .hb-text-field-rounded .v-input__icon.v-input__icon--prepend-inner .v-icon, .hb-text-field-wrapper .hb-text-field-rounded .v-input__icon.v-input__icon--prepend .v-icon, .hb-text-field-wrapper .hb-text-field-rounded .v-input__icon.v-input__icon--append-outer .v-icon, .hb-text-field-wrapper .hb-text-field-rounded .v-input__icon.v-input__icon--append .v-icon {
    padding-bottom: 0px;
}

.hb-text-field-wrapper >>> .v-input.error--text input, .hb-text-field-wrapper >>> .v-input.error--text label, .hb-aviary-form-row >>> .v-label.v-label--active.error--text, .hb-text-field-wrapper .v-input.v-text-field--placeholder.error--text ::placeholder {
  color: #FB4C4C !important;
}

.hb-text-field-wrapper .v-input__prepend-outer .v-input__icon .v-icon.error--text, .hb-text-field-wrapper .v-input__prepend-inner .v-input__icon .v-icon.error--text, .hb-text-field-wrapper .v-input__append-inner .v-input__icon .v-icon.error--text, .hb-text-field-wrapper .error--text .v-input__prepend-inner, .hb-text-field-wrapper .error--text .v-input__append-inner {
    color: #FB4C4C !important;
}

.hb-text-field-wrapper .v-text-field:not(.error--text):hover > .v-input__control > .v-input__slot::before {
    border-color: #C4CDD5 !important;
}

.hb-text-field-wrapper .v-text-field:not(.error--text) > .v-input__control > .v-input__slot::before {
    border-color: #DFE3E8 !important;
}

.hb-text-field-wrapper .v-input__prepend-inner {
    color: #637381;
    margin-top: 3px;
}

.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
    color: #637381;
    margin-top: 8.5px;
    
}

.hb-text-field-wrapper.hb-text-field-condensed-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
    margin-right: 4px;
}

.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
    color: #637381;
    margin-top: 12px;
}

.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner .v-input__icon--append {
    color: #637381;
    margin-top: -4px;
}

.hb-text-field-wrapper input[disabled] {
    opacity: 1;
}

.hb-text-field-wrapper.hb-text-field-disabled {
    opacity: .3 !important;
}

.hb-text-field-wrapper .hb-text-field-rounded:not(.hb-text-field-condensed) .v-text-field__slot {
    padding-left: 3px !important;
}

.hb-text-field-wrapper .hb-text-field-rounded .v-input__prepend-inner {
    padding-right: 1px;
}

.hb-text-field-wrapper .v-input__prepend-outer {
    margin-right: 4px !important;
}

.hb-text-field-wrapper .hb-text-field-rounded .v-input__prepend-outer .v-icon {
    margin-bottom: -15px;
}

.hb-text-field-wrapper .v-input__prepend-outer .v-icon {
    margin-bottom: -5px;
}

.hb-text-field-wrapper .v-input__prepend-outer .v-icon {
    margin-bottom: -4px;
}

.hb-text-field-wrapper .v-input__prepend-inner .v-icon {
    margin-bottom: 4px;
}

.hb-text-field-wrapper .hb-text-field-rounded .v-input__prepend-inner .v-icon {
    margin-bottom: 1px;
}

.hb-text-field-wrapper .v-text-field--rounded .v-input__slot {
    padding: 0 12px 0 15px;
}

.hb-text-field-wrapper .hb-text-field-condensed {
    top: -5px;
    margin-bottom: 5px !important;
}

.hb-text-field-wrapper .hb-text-field-condensed .v-input__slot {
    padding: 0 9.5px !important;
    min-height: 38px !important;
}

.hb-text-field-wrapper fieldset {
    border-color: #DFE3E8;
}

.hb-text-field-wrapper .hb-text-field-condensed fieldset {
    top: 0;
}

.hb-text-field-wrapper .v-text-field.hb-text-field-condensed input {
    padding-bottom: 0 !important;
    position:relative;
    top: 1.5px;
}

.hb-text-field-wrapper .hb-text-field-condensed .v-input__prepend-inner .v-input__icon--prepend-inner .v-icon {
    top: -1px;
}

.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined.hb-text-field-condensed .v-input__prepend-inner, .hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined.hb-text-field-condensed .v-input__append-inner {
    margin-top: 13.5px;
}

.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined.hb-text-field-condensed .v-input__prepend-inner .v-input__icon--prepend-inner, .hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined.hb-text-field-condensed .v-input__append-inner .v-input__icon--append {
    min-width: 18px;
    width: 18px;
    height: 18px;
}

.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined.hb-text-field-condensed .v-input__prepend-inner .v-input__icon--prepend-inner .v-icon, .hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined.hb-text-field-condensed .v-input__append-inner .v-input__icon--append .v-icon {
    font-size: 18px;
}

.hb-text-field-wrapper .v-text-field.hb-text-field-text-align-right input {
    text-align: right;
}

.hb-aviary-v-checkbox .hb-text-field-wrapper input {
    padding-top: 9px;
}

.hb-text-field-condensed-wrapper {
    margin-right: 8px;
}

.hb-text-field-wrapper fieldset {
    margin: 0;
}

.hb-text-field-wrapper .v-text-field:not(.error--text):not(.v-input--is-focused):not(.v-input--is-loading):hover fieldset {
    border-color: #C4CDD5 !important;
}

.hb-text-field-wrapper .v-text-field:not(.error--text):not(.v-input--is-focused):not(.v-input--is-loading) fieldset {
    border-color: #DFE3E8 !important;
}
</style>