<template>

    <div class="modal-padding">
        <!--<div class="slide-out-header-nav">-->
            <!--<div class="slide-out-header-nav-item close" @click="$emit('close')">-->
                <!--<div class="icon"><strong></strong></div>-->
            <!--</div>-->
        <!--</div>-->
        <!--<div class="apple-pay-button-with-text apple-pay-button-white-with-text">-->
            <!--<span class="text">Buy with</span>-->
            <!--<span class="logo"></span>-->
        <!--</div>-->
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <div v-if="!confirmPaymentModal" >
          <div class="form-section" v-if="isAdmin">
            <div class="radio" v-show="canApplyOpen">
              <input
                id="radio_apply_existing"
                name="radio_apply_existing"
                data-name="radio_apply_existing"
                v-model="payment_type"
                type="radio"
                value="existing"
                class="radio">
              <label
                for="radio_apply_existing"
                class="w-form-label"> Apply Existing Payment <span class="subdued">({{ outstandingPayments.reduce((a,b) => a + b.payment_remaining , 0) | formatMoney}} available)</span></label>
            </div>

            <div class="radio" v-show="paymentMethods.length">
              <input
                id="radio_charge"
                name="radio_charge"
                data-name="radio_charge"
                v-model="payment_type"
                type="radio"
                value="charge"
                class="radio">
              <label
                for="radio_charge"
                class="w-form-label">Charge an Existing Payment Method</label>
            </div>

            <div class="radio" v-show="canApplyOpen || paymentMethods.length" >
              <input
                id="radio_record"
                name="radio_record"
                data-name="radio_record"
                v-model="payment_type"
                type="radio"
                value="record"
                class="radio">
              <label
                for="radio_record"
                class="w-form-label">Record a Payment</label>
            </div>

          </div>

          <div class="form-section" style="border: 1px solid #e2e2e2;" v-if="!isAdmin || payment_type === 'charge' || payment_type === 'record' || (!canApplyOpen && !paymentMethods.length)">
            <div class="horizontal-form-row">
              <label class="horizontal-form-label">Payment Date:</label>
              <div class="form-input-container" v-if="payment_type === 'charge'">
                <div class="horizontal-form-text">{{ payment.date | formatDate }}</div>
              </div>
              <div class="form-input-container" v-else>
                <div class="horizontal-form-input" style="width: 200px;" :class="{'has-error': errors.has('payment_date') }">
                  <datepicker
                    v-validate ="'required'"
                    :class="{'has-error': errors.has('payment_date') }"
                    name="payment_date"
                    id="payment_date"
                    ref="payment_date"
                    data-vv-as="payment date"
                    v-model="payment.date"
                    format="MM/dd/yyyy"
                    style="width: 150px;"
                    wrapper-class="date-wrapper"
                    input-class="input w-input date-input light pl-7 pt-1"
                  ></datepicker>
                </div>
                <span v-show="errors.has('payment_date')" class="status-block error-block field-error">{{ errors.first('payment_date') }}</span>
              </div>
            </div>
            <div class="horizontal-form-row">
              <label class="horizontal-form-label" for="payment_amount">Payment Amount:</label>
              <div class="form-input-container">
                <div class="horizontal-form-input" style="width: 155px;" :class="{'has-error': errors.has('amount') }">
                  <div class="form-group">
                    <div class="form-group-icon icon">$</div>

                    <input
                      class="w-input input"
                      type="text"
                      v-model.lazy="payment.amount"
                      v-validate="'required|decimal:2|min_value:.01|max_value:99999999.99'"
                      name="amount"
                      id="payment_amount"
                      @change.lazy="applyPaymentAmount()"
                      :class="{'has-error': errors.has('amount') }"
                    />
                  </div>
                </div>
                <span v-show="errors.has('amount')" class="status-block error-block field-error">{{ errors.first('amount') }}</span>
              </div>
            </div>


            <div class="horizontal-form-row" v-if="isAdmin">
              <label class="horizontal-form-label">Payment Source</label>
              <div class="form-input-container">

                <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errors.has('payment_source') }">
                  <v-select
                    outlined
                    hide-details
                    dense
                    v-model="payment.source"
                    v-validate="'required'"
                    :items="payment_sources"
                  >
                  </v-select>
                </div>
                <span v-show="errors.has('payment_source')" class="status-block error-block field-error">{{ errors.first('payment_source') }}</span>
              </div>
            </div>


            <div class="horizontal-form-row">
              <label class="horizontal-form-label" for="payment_method">Payment Method</label>
              <div class="form-input-container">

                <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errorMethod }">

                  <dropdown
                    forceChoice
                    v-model="payment.method"
                    v-validate="'required'"
                    :options="paymentMethodOptions"
                    labelField="type"
                    id="payment_method"
                    name="payment_method"
                  >
                    <template slot="display" slot-scope="props">
                      <span v-show="payment.method.type">
                        <img class="cc-icon" :src="payment.method | getPaymentMethodIcon" />
                        <div class="card-type">
                          {{payment.method.type | capitalize}} &nbsp;&nbsp;
                          <span v-if="payment_type === 'charge' && payment.method.card_end && payment.method.card_end.length" class="subdued">{{payment.method.name_on_card}} *{{ payment.method.card_end.slice(payment.method.card_end.length - 4 ) }}</span>

                        </div>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <span>
                        <img class="cc-icon" :src="props.data | getPaymentMethodIcon" />
                        <div class="card-type">{{props.data.type | capitalize}} &nbsp;&nbsp;
                          <span v-if="payment_type === 'charge' && props.data.card_end && props.data.card_end.length" class="subdued">{{props.data.name_on_card}} *{{ props.data.card_end.slice(props.data.card_end.length - 4 ) }}</span>
                        </div>
                      </span>
                    </template>
                  </dropdown>
                </div>
                <span v-show="errorMethod" class="status-block error-block field-error">Please select a payment method.</span>
              </div>
            </div>


            <div class="horizontal-form-row"  v-if="payment_type !== 'charge' && isAdmin">
              <label class="horizontal-form-label" for="ref_name">
                <span v-if="payment.method.type.toLowerCase() == 'check'" >Name on Check</span>
                <span v-else>Payee Name</span>
              </label>
              <div class="form-input-container">
                <div class="horizontal-form-input" style="width: 155px;" :class="{'has-error': errors.has('ref_name') }">
                  <input
                    class="w-input input"
                    type="text"
                    v-model="payment.ref_name"
                    v-validate="'required|max:45'"
                    name="ref_name"
                    data-vv-as="reference name"
                    id="ref_name"
                    :class="{'has-error': errors.has('ref_name') }"
                  />
                </div>
                <span v-show="errors.has('ref_name')" class="status-block error-block field-error">{{ errors.first('ref_name') }}</span>
              </div>
            </div>


            <div class="horizontal-form-row" v-if="payment_type !== 'charge' && isAdmin">
              <label class="horizontal-form-label" for="number">
                <span v-if="payment.method.type.toLowerCase() == 'check'" >Check Number</span>
                <span v-else>Reference Number</span>
              </label>
              <div class="form-input-container">
                <div class="horizontal-form-input" style="width: 155px;" :class="{'has-error': errors.has('number') }">
                  <input
                    class="w-input input"
                    type="text"
                    v-model="payment.number"
                    v-validate="'max:45'"
                    name="number"
                    data-vv-as="reference number"
                    id="number"
                    :class="{'has-error': errors.has('number') }"
                  />
                </div>
                <span v-show="errors.has('number')" class="status-block error-block field-error">{{ errors.first('number') }}</span>
              </div>
            </div>

            <div class="horizontal-form-row" v-show="isAdmin">
              <label class="horizontal-form-label" for="notes">Notes</label>
              <div class="form-input-container">
                <div class="horizontal-form-input" style="width: 250px;" :class="{'has-error': errors.has('notes') }">
                  <textarea
                    id="notes"
                    name="notes"
                    v-model="payment.notes"
                    v-validate="'max:1000'"
                    class="w-input input"
                    rows="4"
                  >
                  </textarea>
                </div>
                <span v-show="errors.has('notes')" class="status-block error-block field-error">{{ errors.first('notes') }}</span>
              </div>
            </div>

          </div>



            <div class="info-section" v-if="payment_type === 'existing' && !payment.id">
                <a v-show="dontApplyOpen" @click.prevent="dontApplyOpen = false">
                    There are unused payments. Click here to apply them to invoices
                </a>

                <div v-show="!dontApplyOpen">
                    <apply-payments
                            :invoices="invoices"
                            :payments="outstandingPayments"
                            :lease_id="lease_id"
                            @close="dontApplyOpen = true;"
                            @select="setSelectedPayments"
                            @finish="$emit('finish')">
                    </apply-payments>
                </div>
            </div>

            <!-- PaymentType new or exisitng -->
            <!-- dontApplyOpen:  User doesnt want to apply existing payment -->

<!--            <div class="form-section" v-if="paymentType == 'new' && (!canApplyOpen || dontApplyOpen)">-->
<!--&lt;!&ndash;                <div class="horizontal-form-row">&ndash;&gt;-->
<!--&lt;!&ndash;                    <label class="horizontal-form-label" for="date">Payment Date:</label>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="form-input-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="horizontal-form-input" style="width: 200px;" :class="{'has-error': errors.has('payment_date') }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <datepicker&ndash;&gt;-->
<!--&lt;!&ndash;                                v-validate ="'required'"&ndash;&gt;-->
<!--&lt;!&ndash;                                :class="{'has-error': errors.has('payment_date') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                name="payment_date"&ndash;&gt;-->
<!--&lt;!&ndash;                                id="payment_date"&ndash;&gt;-->
<!--&lt;!&ndash;                                ref="payment_date"&ndash;&gt;-->
<!--&lt;!&ndash;                                data-vv-as="payment date"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-model="payment.date"&ndash;&gt;-->
<!--&lt;!&ndash;                                format="MM/dd/yyyy"&ndash;&gt;-->
<!--&lt;!&ndash;                                style="width: 150px;"&ndash;&gt;-->
<!--&lt;!&ndash;                                wrapper-class="date-wrapper"&ndash;&gt;-->
<!--&lt;!&ndash;                                input-class="input w-input date-input light"&ndash;&gt;-->
<!--&lt;!&ndash;                            ></datepicker>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="errors.has('payment_date')" class="status-block error-block field-error">{{ errors.first('payment_date') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="horizontal-form-row">&ndash;&gt;-->
<!--&lt;!&ndash;                    <label class="horizontal-form-label" for="amount">Payment Amount:</label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="form-input-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="horizontal-form-input" style="width: 155px;" :class="{'has-error': errors.has('amount') }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="form-group-icon icon">$</div>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input&ndash;&gt;-->
<!--&lt;!&ndash;                                        class="w-input input"&ndash;&gt;-->
<!--&lt;!&ndash;                                        type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                        v-model.lazy="payment.amount"&ndash;&gt;-->
<!--&lt;!&ndash;                                        v-validate="'required|decimal:2|min_value:.01|max_value:99999'"&ndash;&gt;-->
<!--&lt;!&ndash;                                        name="amount"&ndash;&gt;-->
<!--&lt;!&ndash;                                        id="amount"&ndash;&gt;-->
<!--&lt;!&ndash;                                        @change.lazy="applyPaymentAmount()"&ndash;&gt;-->
<!--&lt;!&ndash;                                        :class="{'has-error': errors.has('amount') }"&ndash;&gt;-->
<!--&lt;!&ndash;                                />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="errors.has('amount')" class="status-block error-block field-error">{{ errors.first('amount') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="horizontal-form-row">&ndash;&gt;-->
<!--&lt;!&ndash;                    <label class="horizontal-form-label" for="amount">Payment Method</label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="form-input-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="horizontal-form-input" style="width: 300px;" :class="{'has-error': errorMethod }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <dropdown&ndash;&gt;-->
<!--&lt;!&ndash;                                    forceChoice&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="payment.method"&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-validate="'required'"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :options="paymentMethodOptions"&ndash;&gt;-->
<!--&lt;!&ndash;                                    labelField="type"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="payment_method"&ndash;&gt;-->
<!--&lt;!&ndash;                                    name="payment_method"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                                <template slot="display" scope="props">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span v-if="payment.method.type == 'card'">&ndash;&gt;-->
<!--&lt;!&ndash;                                         <img class="cc-icon" :src="payment.method | getCCIcon" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="card-type">{{payment.method.name_on_card}} *{{payment.method.card_end}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span v-else-if="payment.method.type == 'ach'">&ndash;&gt;-->
<!--&lt;!&ndash;                                         <img class="cc-icon" src="/img/cc_icons/64/ach.png" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="card-type">{{payment.method.first}} {{payment.method.last}} *{{payment.method.card_end}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                     <span v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <img class="cc-icon" :src="payment.method.icon_src" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="card-type">{{payment.method.type | capitalize}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->

<!--&lt;!&ndash;                                </template>&ndash;&gt;-->


<!--&lt;!&ndash;                                <template slot="option" scope="props">&ndash;&gt;-->

<!--&lt;!&ndash;                                    <span v-if="props.data.type == 'card'">&ndash;&gt;-->
<!--&lt;!&ndash;                                         <img class="cc-icon" :src="props.data | getCCIcon" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span class="card-type">{{props.data.name_on_card}}<br />{{props.data.card_type | capitalize}} *{{props.data.card_end}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span v-else-if="props.data.type == 'ach'">&ndash;&gt;-->
<!--&lt;!&ndash;                                         <img class="cc-icon" src="/img/cc_icons/64/ach.png" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span class="card-type">{{props.data.first}} {{props.data.last}}<br />{{props.data.card_type | capitalize}} *{{props.data.card_end}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <img class="cc-icon" :src="props.data.icon_src" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="card-type">{{props.data.type | capitalize}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </template>&ndash;&gt;-->

<!--&lt;!&ndash;                            </dropdown>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="errorMethod" class="status-block error-block field-error">Please select a payment method.</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->



<!--&lt;!&ndash;                <div class="horizontal-form-row" v-if="payment.method.type.toLowerCase() == 'check' || payment.method.type.toLowerCase() == 'cash' ">&ndash;&gt;-->
<!--&lt;!&ndash;                    <label class="horizontal-form-label" for="ref_name">&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="payment.method.type.toLowerCase() == 'check'" >Name on Check</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="payment.method.type.toLowerCase() == 'cash'">Payee Name</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="form-input-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="horizontal-form-input" style="width: 155px;" :class="{'has-error': errors.has('ref_name') }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <input&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="w-input input"&ndash;&gt;-->
<!--&lt;!&ndash;                                    type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="payment.ref_name"&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-validate="'required'"&ndash;&gt;-->
<!--&lt;!&ndash;                                    name="ref_name"&ndash;&gt;-->
<!--&lt;!&ndash;                                    data-vv-as="reference name"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="ref_name"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :class="{'has-error': errors.has('ref_name') }"&ndash;&gt;-->
<!--&lt;!&ndash;                            />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="errors.has('ref_name')" class="status-block error-block field-error">{{ errors.first('ref_name') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->


<!--&lt;!&ndash;                <div class="horizontal-form-row" v-if="payment.method.type.toLowerCase() == 'check'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <label class="horizontal-form-label" for="number">Check Number</label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="form-input-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="horizontal-form-input" style="width: 155px;" :class="{'has-error': errors.has('number') }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <input&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="w-input input"&ndash;&gt;-->
<!--&lt;!&ndash;                                    type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="payment.number"&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-validate="'required'"&ndash;&gt;-->
<!--&lt;!&ndash;                                    name="number"&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="number"&ndash;&gt;-->
<!--&lt;!&ndash;                                    :class="{'has-error': errors.has('number') }"&ndash;&gt;-->
<!--&lt;!&ndash;                            />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="errors.has('number')" class="status-block error-block field-error">{{ errors.first('number') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->


<!--&lt;!&ndash;                <div class="horizontal-form-row" v-show="$store.getters.isAdmin">&ndash;&gt;-->
<!--&lt;!&ndash;                    <label class="horizontal-form-label" for="notes">Notes</label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="form-input-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="horizontal-form-input" style="width: 250px;" :class="{'has-error': errors.has('notes') }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <textarea&ndash;&gt;-->
<!--&lt;!&ndash;                                    id="notes"&ndash;&gt;-->
<!--&lt;!&ndash;                                    name="notes"&ndash;&gt;-->
<!--&lt;!&ndash;                                    v-model="payment.notes"&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="w-input input"&ndash;&gt;-->
<!--&lt;!&ndash;                                    rows="4"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                            </textarea>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-show="errors.has('notes')" class="status-block error-block field-error">{{ errors.first('notes') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->

            <div class="form-section info-section" v-if="payment_type === 'existing' && payment.id ">

                <button @click.prevent="reloadPage" class="w-button close-btn">
                    <span class="icon"></span>
                </button>

                <h4 class="notification">Selected Payment</h4>

                <div class="horizontal-form-row">
                       <span class="horizontal-form-label">
                           <strong>Payment Entered on:</strong>
                       </span>
                    <div class="form-input-container">
                        <div class="horizontal-form-label">
                            {{payment.date | formatDate}}
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row">
                       <span class="horizontal-form-label">
                           <strong>Payment Type:</strong>
                       </span>
                    <div class="form-input-container">
                        <div class="horizontal-form-label">
                            {{payment.method | displayPayMethod | capitalize}}
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row">
                       <span class="horizontal-form-label">
                           <strong>Payment Amount:</strong>
                       </span>
                    <div class="form-input-container">
                        <div class="horizontal-form-label">
                            {{payment.amount | formatMoney }}
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row">
                       <span class="horizontal-form-label">
                          <strong>Payment Remaining:</strong>
                       </span>
                    <div class="form-input-container">
                        <div class="horizontal-form-label">
                            {{payment.payment_remaining | formatMoney }}
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row" v-show="isAdmin">
                       <span class="horizontal-form-label">
                          <strong>Notes:</strong>
                       </span>
                    <div class="form-input-container">
                        <div class="horizontal-form-label">
                            {{payment.notes}}
                        </div>
                    </div>
                </div>

            </div>

            <div class="form-section" v-if="isAdmin && invoices.length && ( payment_type === 'charge' ||  ( payment_type === 'record' || (!canApplyOpen && !paymentMethods.length) ) || ( payment_type === 'existing' && payment.id ) ) ">
                <h4 class="notification">Apply to Invoices</h4>
                <br />
                <div class="table">
                    <div class="table-row">
                        <div class="table-head">Inv. #</div>
                        <div class="table-head">Inv. Date</div>
                        <div class="table-head">Invoice Amount</div>
                        <div class="table-head">Amt Due</div>
                        <div class="table-head" style="width: 200px;">Amt. To Apply</div>
                    </div>
                    <div class="table-row" v-for="inv in invoices">
                        <div class="table-cell">#{{inv.number}}</div>
                        <div class="table-cell">{{inv.due | formatDate}}</div>

                        <div class="table-cell">{{ inv.total_due | formatMoney }}</div>
                        <div class="table-cell">{{( inv.total_due - inv.total_payments) | formatMoney  }}</div>
                        <div class="table-cell" style="width: 200px;">
                            <div class="form-input-container">
                                <div class="horizontal-form-input" :class="{'has-error': errors.has('payment_amount_' + inv.id) }" style="width: 150px;">
                                    <div class="form-group">
                                        <div class="form-group-icon icon">$</div>
                                        <input
                                            class="w-input input"
                                            type="text"
                                            v-model="inv.payment_amount"
                                            v-validate="'decimal:2|min_value:0|max_value:99999999.99'"
                                            :name="'payment_amount_' + inv.id"
                                            :id="'payment_amount_' + inv.id"
                                            data-vv-as="payment amount"
                                            :class="{'has-error': errors.has('payment_amount') }"
                                        />
                                    </div>
                                </div>
                                <span v-show="errors.has('payment_amount_' + inv.id)" class="status-block error-block field-error">{{ errors.first('payment_amount_' + inv.id) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-section payment-summary skinny-row" v-if="isAdmin && ( payment_type === 'charge' ||  ( payment_type === 'record' || (!canApplyOpen && !paymentMethods.length) ) || ( payment_type === 'existing' && payment.id ) ) ">
                <h4 class="notification">Payment Totals</h4>
                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Total Applied:</span>
                    <span class="horizontal-form-label">{{ totalApplied | formatMoney }}</span>
                </div>

                <div class="horizontal-form-row">
                    <span class="horizontal-form-label">Remaining Payment:</span>
                    <span class="horizontal-form-label">{{ remainingPayment | formatMoney }}</span>
                </div>
            </div>

            <div class="modal-footer" v-if="isAdmin && ( payment_type === 'charge' ||  ( payment_type === 'record' || (!canApplyOpen && !paymentMethods.length) ) || ( payment_type === 'existing' && payment.id ) ) ">
                <div class="col-xs-12">
                    <a class="close-link" @click="recordPaymentCancel">Cancel</a>
                    <button class="w-button primary-btn" @click="savePayment()">Save Payment</button>
                </div>
            </div>

        </div>

        <br />

        <amazon-payments
          v-if="confirmPaymentModal && payment.method.type === 'amazon'"
          slot="body"
          :environment="integrations.amazon.environment || 'SANDBOX'"
          :amazonClientId="integrations.amazon.amazonClientId"
          :amazonSellerId="integrations.amazon.amazonSellerId"
          :invoices="invoices"
          :payment="payment"
          :paymentType="paymentType"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
        >
        </amazon-payments>
        <pay-pal
          v-else-if="confirmPaymentModal && payment.method.type === 'paypal'"
          slot="body"
          :environment="integrations.amazon.environment || 'SANDBOX'"
          :invoices="invoices"
          :payment="payment"
          :paymentType="paymentType"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
          :paypalClientId="integrations.paypal.paypalClientId"
        >
        </pay-pal>

        <apple-pay
          v-else-if="confirmPaymentModal && payment.method.type === 'apple'"
          slot="body"
          :invoices="invoices"
          :payment="payment"
          :paymentType="paymentType"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
        >
        </apple-pay>

        <google
          v-else-if="confirmPaymentModal && payment.method.type === 'google'"
          slot="body"
          :environment="integrations.amazon.environment || 'TEST'"
          :invoices="invoices"
          :payment="payment"
          :paymentType="paymentType"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
          :googleGatewayMerchantId="integrations.google.googleGatewayMerchantId"
          :googleMerchantName="integrations.google.googleMerchantName"
        >
        </google>

        <tsys-card
          v-else-if="confirmPaymentModal && payment.method.type === 'card' &&  payment.method.name === 'tsys' "
          slot="body"
          :environment="integrations.amazon.environment || 'TEST'"
          :invoices="invoices"
          :payment="payment"
          :paymentType="paymentType"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
          :googleGatewayMerchantId="integrations.google.googleGatewayMerchantId"
          :googleMerchantName="integrations.google.googleMerchantName"
        >
        </tsys-card>

        <tenant-payments-card
          v-else-if="confirmPaymentModal && payment.method.type === 'card' &&  payment.method.name === 'tenant_payments' "
          slot="body"
          :environment="integrations.amazon.environment || 'TEST'"
          :invoices="invoices"
          :payment="payment"
          :paymentType="paymentType"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
          :googleGatewayMerchantId="integrations.google.googleGatewayMerchantId"
          :googleMerchantName="integrations.google.googleMerchantName"
        >
        </tenant-payments-card>

        <confirm-payment
          v-else-if="confirmPaymentModal"
          slot="body"
          :invoices="invoices"
          :payment="payment"
          :payment_type="payment_type"
          :lease_id="lease_id"
          @close="confirmPaymentModal = false"
          @reload="reloadPage"
          @finish="$emit('close')"
        >
        </confirm-payment>
        <br />

        <modal v-if="showAddPaymentMethodModal" size="md" allow-click-away="true" @close="showAddPaymentMethodModal = false">
            <h3 slot="header">Add Payment Method</h3>
            <add-payment-method  slot="body" :lease_id="lease_id" @close="showAddPaymentMethodModal = false" @refetch="fetchPaymentMethods">
            </add-payment-method>
        </modal>

    </div>
</template>
<script type="text/babel">
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import Modal from '../assets/Modal.vue';
    import moment from 'moment';
    import AddPaymentMethod from '../leases/AddPaymentMethod.vue';
    import ConfirmPayment from '../leases/ConfirmPayment.vue';
    import AmazonPayments from '../payments/AmazonPayments.vue';
    import ApplePay from '../payments/ApplePay.vue';
    import PayPal from '../payments/PayPal.vue';
    import Google from '../payments/GooglePayments.vue';
    import ApplyPayments from '../leases/ApplyPayments.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        data() {
            return {
                payment_type: '',
                payment:{
                    id: null,
                    payment_method_id: '',
                    method: {
                        id: '',
                        label: '',
                        type: '',
                        name_on_card: '',
                        number: ''
                    },
                    source: '',
                    amount: '',
                    date: '',
                    transaction_id:'',
                },
                integrations: {},
                payment_sources: [
                    'Telephone/Moto',
                    'In-Store',
                    'Auto',
                ],
                outstandingPayments: [],
                invoices:[],
                paymentMethods:[],
                defaultPaymentMethods:[
                    {
                      type:'cash',
                      label: 'Cash',
                      icon_src:'/img/cc_icons/64/dollar.png'
                    },
                    {
                        type:'check',
                        label: 'Check',
                        icon_src:'/img/cc_icons/64/cheque.png'
                    },
                    // {
                    //     type:'card',
                    //     label: 'Credit Card',
                    //     icon_src: '/img/cc_icons/64/credit_card.png'
                    // },
                    // {
                    //     type:'ach',
                    //     label: 'ACH',
                    //     icon_src: '/img/cc_icons/64/ach.png'
                    // }
                ],
                paymentMethod:{},
                showAddPaymentMethodModal: false,
                confirmPaymentModal: false,
                applyPaymentsModal: false,
                hidePaymentsModal: false,
                chooseMethod: false,
                error: false,
                paymentType: null,
                dontApplyOpen: false,
                applyingOpen: false,
                endpoint:'',
                errorMethod: false,
                methodSelected: false
            }
        },
        components:{
            Modal,
            Dropdown,
            AddPaymentMethod,
            ConfirmPayment,
            AmazonPayments,
            ApplePay,
            ApplyPayments,
            PayPal,
            Google,
            Datepicker,
            Status,
            Loader
        },
        created (){
            this.reloadPage();
            this.lease.Tenants[0] ? this.payment.ref_name = this.lease.Tenants[0].Contact.first + ' ' + this.lease.Tenants[0].Contact.last : '';
        },
        destroyed(){
            this.reloadPage();
        },
        filters:{

            getPaymentMethodIcon(method){

                if(method.type === 'ach'){
                    return '/img/cc_icons/64/'+ method.type + '.png'
                } else if(method.type === 'card') {
                    if (method.card_type) {
                        return '/img/cc_icons/64/' + method.card_type + '.png'
                    } else {
                        return '/img/cc_icons/64/credit_card.png'
                    }
                } else if(method.type === 'cash'){
                    return '/img/cc_icons/64/dollar.png';
                } else if(method.type === 'check'){
                    return '/img/cc_icons/64/cheque.png';
                }

            },

            lineTotal(c){
                return c.qty * c.cost;
            },

            getIcon (method){
                var base = '/img/cc_icons/64/'+ method.type + '.png';
                return base;
            },
            getCCIcon (method){
                var base = '/img/cc_icons/64/'+ method.card_type + '.png';
                return base;
            },
            displayPayMethod(method){

                return method.type;
            }
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin'
            }),
            paymentMethodOptions(){
                if(this.isAdmin && (!this.payment_type || this.payment_type === 'record' )){
                    return this.defaultPaymentMethods
                }
                return this.paymentMethods;
            },
            canApplyOpen(){
                return this.outstandingPayments.length && this.invoices.length ;
            },

            allPaymentMethods(){
            },
            totalApplied(){
                if(this.payment.id){
                    return +this.payment.total_applied + (this.appliedNow || 0);
                } else {
                    return (this.appliedNow || 0);
                }
            },
            remainingPayment(){
                if(this.payment.id){
                    return +this.payment.amount - this.payment.total_applied - (this.appliedNow || 0);

                } else {
                    return +this.payment.amount - (this.appliedNow || 0);
                }
            },
            isPastDue(){
                return this.balanceDue && moment() > moment(this.invoice.due, 'MM/DD/YYYY');
            },
            paymentMethodNames(){
                this.paymentMethods.map(function(pm){
                    return pm.name_on_card;
                })
            },
            totalDue(){
                return this.invoices.reduce(function(a,b){
                    return a + (b.total_due - b.total_payments);
                }, 0)
            },

            appliedNow(){
                return this.invoices.reduce((a,b) => {
                    if(!b.payment_amount) return a;
                    return Math.round((a + +b.payment_amount) * 1e2) / 1e2;
                }, 0);
            },
            invoiceTotal(){
                return this.taxSum + this.subTotal;
            },
            // displayPaymentMethod(){
            //
            //     if(!this.payment.method || !this.payment.method.type) return "Choose Payment Method";
            //     if(this.payment.method.type === 'cash') return '<img class="dropdown-icon" src="/img/cc_icons/64/dollar.png" />Cash';
            //     if(this.payment.method.type === 'check') return '<img class="dropdown-icon" src="/img/cc_icons/64/cheque.png" />Check';
            //     if(this.payment.method.type === 'ach') return '<img class="dropdown-icon" src="/img/cc_icons/64/ach.png" />' +  this.payment.method.card_type + '&nbsp;&nbsp;*' +  this.payment.method.card_end;
            //     if(this.payment.method.type === 'card') return '<img class="dropdown-icon" src="/img/cc_icons/64/'+this.payment.method.card_type+'.png" /> *' +  this.payment.method.card_end;
            //     return "Choose Payment Method";
            // },

        },
        methods: {

            reloadPage(){
                this.payment = {
                    id: null,
                    payment_method_id: '',
                    method: {
                        id: '',
                        type: '',
                        name_on_card: '',
                        number: ''
                    },
                    source: '',
                    amount: '',
                    date:  moment().toDate(),

                };
                this.invoices = [];
                this.fetchOpenInvoices();
                this.fetchOpenPayments();
                this.fetchPaymentMethods();
                this.confirmPaymentModal = false;
                this.paymentType = 'new';
                this.dontApplyOpen = false;

                if(!this.isAdmin){
                    this.payment_type = 'charge'
                }

            },
            setSelectedPayments(payment){
                this.payment = payment;
                this.applyPaymentsModal = false;
                this.paymentType = 'open';
                this.setPaymentMethod(payment);
                this.applyPaymentAmount();
            },
            savePayment(){

                this.error = false;
                // Validate

                if(!this.payment.method.type){
                    this.errorMethod = true;
                }

                if(!this.payment.amount){
                    this.errors.add({
                        field: 'amount',
                        msg: 'Please enter a payment amount.'
                    })
                    return;
                }

                if(!this.payment.method.type){
                    this.errorSet(this.$options.name, "Please select a payment method.");
                    return;
                }

                var invoiceErrors = this.invoices.filter(function(i){
                    return ( Math.round((i.totalDue - i.totalPayments) * 1e2 ) / 1e2) <  (Math.round(i.payment_amount * 1e2) / 1e2);
                });

                var totalApplied = this.invoices.reduce(function(a,b){
                    return a + (Math.round(b.payment_amount * 1e2) /1e2);
                }, 0);


                if(invoiceErrors.length){
                    this.errorSet(this.$options.name, "You are trying to apply more than the amount due to an invoice. Please adjust the payment amounts.");
                    return;
                }


                if(totalApplied > Math.round( (this.payment.amount - this.payment.total_applied) * 1e2)  / 1e2){
                    this.errorSet(this.$options.name, "You are trying to apply more than the total payment amount. Please adjust the payment amount.");
                    return;
                }

                return this.$validator.validateAll().then(status => {
                    if(!status) throw "An error occurred";
                    if(this.payment.id){
                        this.saveApplyPayment();
                    } else {
                        this.confirmPaymentModal = true;
                        this.errorClear(this.$options.name);
                    }

                }).catch(err => {
                    this.errorSet(this.$options.name, "You have errors on your form. Please fix them and then continue.");
                })

            },

            saveApplyPayment(){
                var data = {
                    invoices: this.invoices.map(i => {
                        return {
                            id: i.id,
                            amount: i.payment_amount || 0
                        }
                    }),

                };

                api.post(this, api.PAYMENTS + this.payment.id + '/apply' , data).then(r => {
                    this.confirmPaymentModal = true;
                    EventBus.$emit('lease_payment_saved', this.payment.id);

                });

            },
            recordPaymentCancel(){
                EventBus.$emit('recordPaymentCanceled');
                this.$emit('close');
            },
            applyPaymentAmount(){

                this.payment.total_applied = this.payment.total_applied || 0;
                var toApply = '';
                // Existing payment should pull from remaining payment - calculated on API
                if(this.payment_type === 'existing'){
                  toApply = this.payment.payment_remaining;
                } else {
                // new Payment
                  toApply = this.payment.amount - this.payment.total_applied;
                }

                this.invoices.map(i =>{
                    i.totalPayments = i.totalPayments || 0;
                    var toSet = ( (i.balance) <= toApply) ? i.balance : toApply ;

                    this.$set(i, 'payment_amount', (Math.round(toSet * 1e2) / 1e2).toFixed(2));
                    toApply -= i.payment_amount;
                });
            },
            makePaymentMethod(type){
                var pm = {
                    method: type
                }
                this.setPaymentMethod(pm);
           },
            setPaymentMethod(payment){
                if(payment.method == 'cash'){
                    this.payment.method = {
                        type: 'cash',
                        id: '',
                        name_on_card: '',
                        number: ''
                    }
                } else if (payment.method == 'check'){
                    this.payment.method = {
                        type: 'check',
                        id: '',
                        name_on_card: '',
                        number: ''
                    }

                } else if (payment.method == 'credit'){
                    this.payment.method = {
                        type: 'credit',
                        id: '',
                        name_on_card: '',
                        number: ''
                    }

                } else {
                    this.payment.method = payment.PaymentMethod;
                }

            },
            closeDropdown(event){
                $(event.target).closest('.dropdown-menu').dropdown('toggle');
            },
            prevent(event){
                event.stopPropagation();
            },
            fetchOpenInvoices(){

                if(!this.lease_id) return;
                api.get(this, api.LEASES + this.lease_id + '/invoices/unpaid').then(r => {
                    this.invoices = r.invoices;
                    this.payment.amount = Math.round(this.invoices.reduce((a, inv) => a + inv.balance, 0) * 1e2) / 1e2;
                    this.applyPaymentAmount();
                });

            },
            fetchOpenPayments(){
                if(!this.lease_id) return;
                api.get(this, api.LEASES + this.lease_id + '/payments/open').then(r => {
                    this.outstandingPayments = r.payments

                });
            },
            fetchPaymentMethods(){
                if(!this.lease_id) return;
                api.get(this, api.LEASES + this.lease_id + '/payment-methods').then(r => {
                    this.paymentMethods = r.paymentMethods;

                    this.integrations = r.integrations;
                    if(!this.isAdmin){

                        if(r.integrations.google.enabled){
                            this.paymentMethods.push({
                                type:'google',
                                label:'Google Pay',
                                icon_src:'/img/cc_icons/64/dollar.png'
                            })
                        }

                        if(r.integrations.paypal.enabled){
                            this.paymentMethods.push({
                                type:'paypal',
                                label:'Paypal Pay',
                                icon_src:'/img/cc_icons/64/dollar.png',

                            })
                        }

                        if(r.integrations.amazon.enabled){
                            this.paymentMethods.push({
                                type:'amazon',
                                label:'Amazon Payments',
                                icon_src:'/img/cc_icons/64/dollar.png'
                            })
                        }
                        if (r.integrations.apple.enabled &&  window.ApplePaySession && ApplePaySession.canMakePayments()){
                            this.paymentMethods.push({
                                type:'apple',
                                label: 'Apply Pay',
                                icon_src:'/img/cc_icons/64/dollar.png'
                            })
                        }
                    }
                });

            },
            away(){
                return this.openSuggestion = false;
            }
        },
        props:{
            lease_id: {
                type: String,
                required: true
            },
            onboard: {
                type: Boolean,
                default: false
            },
            global: {
                type: Boolean,
                default: false
            }
        },
        watch:{
            payment_type(){
                this.payment.method =  {
                    id: '',
                    type: '',
                    name_on_card: '',
                    number: ''
                }
                this.payment.type = '';
                this.payment.number = '';
            },
            lease_id:function(){
                this.reloadPage();
            },
            "payment.method"(){
                if(this.payment.method.type){
                    this.methodSelected = true;
                }
                if(this.methodSelected){
                    this.errorMethod = false;
                    this.errorClear(this.$options.payment_method);
                }
            }
        },
        props:[
            'lease',
            'lease_id'
        ]
    }

</script>

<style scoped>

    img.dropdown-icon {
        width: 24px;
        margin-right: 5px;
    }


    h2{
        border-bottom: 0;
    }
    .payment-method-list{
        margin-top: 20px;
    }
    .payment-choose{
        padding-top: 20px;
    }

    .footer{
        margin-top: 50px;
    }
    h4{
        margin-bottom: 0;
    }



    .dropdown-menu .cc-icon-holder {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        margin-right: 15px;
        width: 34px;
    }

    span.cc-method-col {
        padding: 3px 0;
        vertical-align: top;
        display: inline-block;
    }
    button img.cc-icon {
        width: 24px;
        margin: 0 5px 0 0;
        padding: 0;
    }


    .dropdown-toggle{
        font-size: 12px;
    }
    .pay-method-list .row{
        padding-top: 0;
        padding-bottom: 0;
    }

    .row.enter-payment-row{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #invoices-payments-table th {
        background: #0277BD;
        color: white;
        border-bottom: 0;
    }

    .payment-summary .row {
        padding-top: 0;
        padding-bottom: 0;
    }
    span.card-type {
        padding-top: 2px;
    }
    .card-type {
        display: inline-block;
        vertical-align: top;
    }

    .above{
        position: absolute;
        z-index:999;
        width:100%;
        box-sizing: border-box;
        box-shadow: 3px 3px 3px #ccc;

    }


</style>
