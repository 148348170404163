import moment from 'moment';

export default {
  methods: {
    getPropertyCurrentDateByLease(lease,properties) {
      const property = properties.find(p => lease?.Unit?.property_id === p.id);
      const offSet = property?.utc_offset || "+00:00";
      const dateFormat = 'YYYY-MM-DD HH:mm:ss';
      return moment().utcOffset(offSet).format(dateFormat);
    }
  }
}