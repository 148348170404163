export const unitOptionsMixin = {
	data() {
		return {
			statusOptions: ['Available', "Leased", "Pending", "On Hold", "Reserved", "Offline"],
			petOptions: ['Allowed', 'Not allowed', 'Allowed with pet deposit'],
			parkingOptions: ["Included","Not included","Extra charge"],
			laundryOptions: ["In Space","On Premises","None"],
			storageTypeOptions: ["Self Storage", "Wine Storage", "Locker", "Outdoor Space", "Cold Storage"],
			doorTypeOptions: ["Roll-up Door", "Swing Door"],
			vehicleStorageOptions: ["Vehicles Only", "Storage or Vehicles", "No"],
			classificationOptions: ["Apartment", "Single Family Home", "Condo", "Townhouse", "Duplex", "Other"],
			furnishedOptions: [{
				val: 1,
				name: 'Yes'
			},{
				val: 0,
				name: 'No'
			}],
		}
	}
};