<template>
    <div class="v-card py-2 px-0">

            <div class="hb-link font-weight-medium">Rent Rules</div>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <div class="table table-sm rent-rules-table">
                <div class="table-row">
                    <div class="table-head" style="flex: 2">
                        API
                    </div>
                    <div class="table-head" style="flex: 2">
                        Change
                    </div>
                    <div class="table-head" style="flex: 1">
                        New Price
                    </div>
                    <div class="table-head align-right" style="flex: 1">
                        &nbsp;
                    </div>
                </div>

                <div class="table-row" v-for="rule in rules">
                    <div class="table-cell" style="flex: 2">
                        {{rule.ApiKey.name}}
                    </div>
                    <div class="table-cell" style="flex: 2">
                        <span v-show="rule.change_type == '$'">$</span>
                        {{rule.change}}
                        <span v-show="rule.change_type == '%'">%</span>
                    </div>
                    <div class="table-cell" style="flex: 1">
                        {{ calculatePrice(rule) | formatMoney }}
                    </div>

                    <div class="table-cell align-right" style="flex: 1">
                        <hb-btn icon tooltip="Delete" @click="deleteRule(rule)" active-state-off>mdi-delete</hb-btn>
                    </div>
                </div>

                <form @submit.prevent="saveRentRule" v-show="showAddRentRule">
                    <div class="table-row row ma-0">
                        <div class="table-cell col-4 pa-2" :class="{'has-error': errors.has('api_id') }">
                            <v-select
                                :items="keys"
                                v-model="newRule.api_id"
                                v-validate="'required'"
                                item-text="name"
                                :class="{'has-error': errors.has('api_id') }"
                                item-value="id"
                                name="api_id"
                                data-vv-as="api key"
                                single-line
                                style="z-index:99999;"
                                label="Api key"
                                hide-details
                                >
                            </v-select>
                            <span v-show="errors.has('api_id')" class="status-block error-block">{{ errors.first('api_id') }}</span>
                        </div>
                        <div class="table-cell col-4 pa-2" :class="{'has-error': errors.has('change') }">
                            <div style="display: inline-block; vertical-align: top" class="col-6 pa-0">
                                <v-text-field
                                    type="text"
                                    v-model="newRule.change"
                                    v-validate="validatePrice"
                                    name="change"
                                    id="change"
                                    :class="{'has-error': errors.has('change') }"
                                    hide-details
                                    single-line
                                    >
                                </v-text-field>

                            </div>

                            <div style="display: inline-block; vertical-align: top" class="col-6 pa-0">
                                <v-select
                                    :items="['%','$']"
                                    v-model="newRule.change_type"
                                    hide-details
                                    style="z-index:99999;"
                                    single-line
                                    >
                                </v-select>
                            </div>

                            <span v-show="errors.has('change')" class="status-block error-block">{{ errors.first('change') }}</span>


                        </div>
                        <div class="table-cell col-4 pa-2 d-flex align-end">
                            <strong>Price:</strong>&nbsp; {{ calculatePrice(newRule) | formatMoney}}
                        </div>
                        <v-col  md="12" class="table-cell pa-2 d-flex align-center justify-end">
                            <hb-link class="mr-2" @click="showAddRentRule = false">Cancel</hb-link>
                            <hb-btn color="primary" @click="saveRentRule">Save</hb-btn>
                        </v-col>
                        <!-- <div class="table-cell align-right col-3 pa-2">
                            <button class="close-link trash mr-2" @click.prevent="showAddRentRule = false "><span class="icon"></span></button>
                            <button class="w-button primary-btn btn-sm">Save</button>
                        </div> -->
                    </div>
                </form>
            </div>


        <br />
        <hb-btn color="secondary" class="mr-2" @click="showAddRentRule = true;" v-show="!showAddRentRule"><span class="icon mr-2"></span>Add Rule</hb-btn>
    </div>
</template>

<script type="text/babel">

    import Dropdown from '../assets/Dropdown.vue';
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    export default{
        name: "RentRule",
        data(){
            return {
                showAddRentRule: false,
                rules:[],
                keys: [],
                newRule:{
                    api_id: '',
                    change: 0,
                    change_type: "$"
                }
            }
        },
        created(){
            this.fetchApis();
            this.fetchRules();
        },
        components:{
            Dropdown,
            Status,
            Loader
        },
        computed:{
            validatePrice(){
                if(this.newRule.change_type == "$"){
                    return 'required|decimal:2|max_value:9999999|min_value:' + -this.price;
                } else if(this.newRule.change_type == "%"){
                    return 'required|decimal:2|max_value:9999999|min_value:-100';
                }

            }
        },
        filters:{

        },
        methods:{
            calculatePrice(rule){
                var newPrice = 0;
                if(rule.change_type == "$"){
                    newPrice = +this.price + +rule.change;
                } else if (rule.change_type == "%"){
                    newPrice = +this.price + +(this.price * rule.change / 100);
                }
                return +newPrice < 0 ? 0 : newPrice;
            },
            fetchRules(){
                api.get(this, api.UNITS + this.unit_id + '/rent-rules' ).then(r=> {
                    console.log(r.rules);
                    this.rules = r.rules;
                });
            },
            saveRentRule(){
                this.validate(this).then(status => {
                    if(!status) return;
                    api.post(this, api.UNITS + this.unit_id + '/rent-rule', this.newRule).then(r => {
                        this.fetchRules();
                        this.resetRule();
                    });
                });
            },
            deleteRule(rule){
                api.delete(this, api.UNITS + this.unit_id + '/rent-rule/', rule.id).then(r => {
                    this.fetchRules();
                });
            },
            fetchApis(){
                api.get(this, api.SETTINGS + 'api-keys/').then(r => {
                    this.keys = r.keys;
                });
            },
            resetRule(){
                this.newRule = {
                    api_id: '',
                    change: 0,
                    change_type: "$"
                }
            }
        },
        props:['price', 'unit_id'],
        watch:{
            unit_id(){
                this.fetchRules();
            }
        }


    }

</script>

<style>
    .rent-rules-table {
        background: #FFFFFF;
        /* box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1); */
        border-radius: 4px;
    }
    .rent-rules-table .table-head{
        background: #FFFFFF;
        border-bottom: 1px solid rgba(0, 0, 0, 0.22);
    }
    .rent-rules-table .table-head{
        color: #101318;
        font-size: 14px;
        font-weight: 500;
    }
    .rent-rules-table .table-row {
        cursor: inherit;
    }
    .rent-rules-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
</style>

