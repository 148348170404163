<template>
	<div class="timeline-wrapper">
		<v-stepper vertical class="mt-4 pb-2 elevation-0">
			<v-stepper-step
				v-for="(item, index) in rentIncreaseStages"
				:key="index"
				:complete="isCompleted(index)"
				:complete-icon="completeIcon"
				:step="index + 1"
				:class="getNthChildClass(index)"
				:style="stepperStyles"
				@click="$emit('click')"
			>
				<div class="stage-item">
					<div
						class="item-value increment-month"
						:class="{ 'first-child': isCompleted(index) }"
					>
						{{ getStageMonth(item) }}
						<div v-if="index > 0" class="recurring-text hb-text-night-light">
							Month {{ item.month_start_of_lease }}
						</div>
					</div>
					<div class="item-value" v-if="item.increase_by || item.target">
						{{ getStageValue(item) }}
						<div 
							v-if="item.target && item.target.after.change_value"
							class="recurring-text hb-text-night"
						>
							{{ `If >= Target, increase rent by ${ getAfterTargetStageValue(item) } ` }}
						</div>
						<div
							v-if="item.recurrence_interval"
							class="recurring-text hb-text-night-light"
						>
							{{ `Recurring Every ${item.recurrence_interval} Months` }}
						</div>
					</div>
				</div>
			</v-stepper-step>
		</v-stepper>
	</div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
	name: "RentIncreaseTimeline",
	props: {
		stages: {
			type: Array,
			required: true,
			default: () => ([])
		},
		completeIcon: {
			type: String,
			default: "mdi-check-circle-outline"
		},
		isSelected: {
      type: Boolean,
      default: false
    },
		isManageable: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			rentIncreaseStages: [],
			initialRentStep: {
        month: "Start of Lease",
        type: "Recurring",
      },
		}
	},
	computed: {
		stepperStyles() {
			return {
				"--stepBgColor": !this.isManageable ? "transparent" : "#FFFFFF",
				"--stepDividerColor": "#dfe3e8"
			}		
		},
		getStageList() {
			let stagedMonth = 0
			let stageList = cloneDeep(this.list)
			stageList.forEach(stage => {
				stagedMonth += stage?.month
				stage['month_start_of_lease'] = stagedMonth
			})
			stageList.unshift(this.initialRentStep)
			return stageList
		},
	},
	watch: {
		stages: {
			immediate: true,
			handler(data) {
			//this.rentIncreaseStages = cloneDeep(data)
			//this.rentIncreaseStages.unshift(this.initialRentStep);
			let stagedMonth = 0
			let stageList = cloneDeep(data)
			stageList.forEach(stage => {
				stagedMonth += stage?.month
				stage['month_start_of_lease'] = stagedMonth
			})
			this.rentIncreaseStages = stageList
			this.rentIncreaseStages.unshift(this.initialRentStep);
			//stageList.unshift(this.initialRentStep)
			//return stageList
			},
			deep: true
		}
	},
	methods: {
		/**
	 * Function for get corresponding classes to first or last element of stepper.
	 * @param {Number} index index of the element
	 * @returns corresponding class name
	 */
		getNthChildClass(index) {
			if (index == this.rentIncreaseStages.length - 1) return "last-child";
			if (this.isCompleted(index)) return "first-child";
			return "";
		},

		/**
		 * Function for get corresponding Month of the stage.
		 * @param {Object} item data of the corresponding stage
		 * @returns month of the stage
		 */
		getStageMonth(item) {
			let monthNoun = item?.month > 1 ? 'Months' : 'Month'
			return item?.increase_by || item?.target ? `In ${item?.month} ${monthNoun}` : item?.month;
		},

		/**
		 * Function for get corresponding value of the stage.
		 * @param {Object} item data of the stage
		 * @returns prepend and append corresponding symbol with the value of the stage
		 */
		getStageValue(item) {
			// return item?.value_type == "dollar"
			// 	? `+$${item?.value.toFixed(2)}`
			// 	: `+${item?.value}%`;
			let stageLabels = {
				dollar_amount: '$',
				rent_percent: '% of Current Rent',
				rent_sell_variance_percent: '% of Variance (Rent/Sell)',
				sell_rate_percent: '% of Sell Rate',
				set_rate_percent: '% of Set Rate',
			}
			if(item?.increase_by) {
				return item?.increase_by?.change_type === 'dollar_amount'
					? `Increase Rent by : $${item?.increase_by?.change_value.toFixed(2).replace(/\.00$/, '')}`
					: `Increase Rent by : ${item?.increase_by?.change_value}%`;
			}
			else if (item?.target) {
				return item?.target?.before?.target_type === 'dollar_amount'
					? `Target : $${item?.target?.before?.target_value.toFixed(2).replace(/\.00$/, '')}`
					: `Target : ${item?.target?.before?.target_value}${stageLabels[item?.target?.before?.target_type]}`
			}
		},

		getAfterTargetStageValue(item) {
			if(item?.target) {
				return item?.target?.after?.change_type === 'dollar_amount'
					? `$${item?.target?.after?.change_value.toFixed(2).replace(/\.00$/, '')}`
					: `${item?.target?.after?.change_value}%`
			}
		},

		isCompleted(index) {
			return Boolean(index == 0)
		}
	}
}
</script>

<style lang="scss">
.timeline-wrapper {
	.theme--light.v-stepper {
		background: unset !important;
	}
	.v-stepper__step:not(.last-child):after {
		content: "";
		background: var(--stepDividerColor);
		position: absolute;
		bottom: -9px;
		left: 32px;
		top: 33px;
		width: 1px;
	}
	.v-stepper--vertical .v-stepper__step {
		padding-bottom: 10px;
		padding-top: 10px;
		align-items: start;
	}
	.v-stepper__step .v-stepper__step__step {
    background-color: var(--stepBgColor) !important;
		color: var(--stepBgColor) !important;
  }
  .v-stepper__step:not(.first-child) .v-stepper__step__step {
    border: 2px solid var(--stepDividerColor) !important;
  }
	.v-stepper__step.first-child .v-stepper__step__step .v-icon.v-icon {
    font-size: 18px !important;
  }
	.v-stepper__step.v-stepper__step--complete:not(.v-stepper__step--error)
    .v-stepper__step__step
    .v-icon {
    color: #00848e !important;
  }
	.v-stepper__step__step {
    min-width: 16px;
    width: 16px;
    height: 16px;
    margin-top: 3px;
  }
	.stage-item {
		display: flex;
		flex-direction: row;
		align-items: start;
		padding: 0 0 8px;
		gap: 24px;
		// width: 345px;
	}
	.v-stepper__step .stage-item .item-value {
    font-size: 14px;
    color: #101318;
    font-weight: 500;
    line-height: 21px;
  }
	.v-stepper__step
  .stage-item
  .item-value.increment-month:not(.first-child) {
    min-width: 97px;
  }
	.v-stepper__step .stage-item .item-value .recurring-text {
    font-size: 12px;
    line-height: 15.6px;
    font-weight: 400;
  }
}
</style>