<template>
    <div>
        <hb-form 
            label="Payment Cycle"
            :empty="false"
            :full="true"
            :editable="isClosed || isAuctioned || !hasPermissionToChangePaymentCycle ? false : true"
            :active.sync="active"
            @editStart="editStart('payment_cycle')"
            @save="showPaymentCycleChangeModel"
            @cancel="cancel"
        >
            <!-- Payment Cycle On Edit mode -->
            <template v-slot:edit>
                <div>
                <HbSelect
                    v-model="getCurrentPaymentCycle"
                    :items="paymentCycles"
                    placeholder="Payment Cycle"
                    id="payment_cycle"
                    name="payment_cycle"
                    hide-details
                    :clearable='false'
                >
                    <template v-slot:selection="{ item }">
                        {{ item }}
                    </template>
                    <template v-slot:item="{ item }">
                        {{ item }}
                    </template>
                </HbSelect>
                </div>
            </template>
            <!-- Payment Cycle On Read mode -->
            <template v-slot:display>
                <div>
                {{ getCurrentPaymentCycle }}
                </div>
            </template>
        </hb-form>

        <!-- Payment Cycle change model -->
        <hb-modal
            title="Change Payment Cycle"
            size="medium"
            confirmation
            v-model="show_payment_cycle_change_model"
            v-if="show_payment_cycle_change_model"
            @close="cancelPaymentCycleChange"
            show-help-link
        >
            <template v-slot:content>
                <div class="px-6 pt-4">The Payment Cycle will be changed to {{ getCurrentPaymentCycle }}</div>
                <div class="px-6 pb-3">Would you like change it now? </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="savePaymentCycle" :loading="loading" :disabled="loading">Change</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../EventBus.js";
import LEASE from "@/constants/lease.js";
import api from "../../assets/api.js";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters } from "vuex";

export default {
    name: "PaymentCycle",
    props: ["lease", "payment_cycles"],
    mixins: [ notificationMixin ],
    data (){
        return {
            active: "",
            loading: false,
            show_payment_cycle_change_model: false,
            old_payment_cycle: '',
            paymentCycles: ['Monthly', ...this.payment_cycles],
            PAYMENT_CYCLES: LEASE.PAYMENT_CYCLES,
        }
    },
    computed: {
        ...mapGetters({
            hasPermission: 'authenticationStore/rolePermission'
        }),
        getCurrentPaymentCycle: {
            get(){
                return  this.lease.payment_cycle === null ? this.PAYMENT_CYCLES.MONTHLY : this.lease.payment_cycle;
            },
            set(payment_cycle = null){
                this.lease.payment_cycle = payment_cycle === this.PAYMENT_CYCLES.MONTHLY ? null : payment_cycle;
            }
        },
        hasPermissionToChangePaymentCycle(){
            return this.hasPermission('change_payment_cycle');
        },
        isClosed() {
            return moment(this.lease.end_date).startOf("day") <= moment().startOf("day");
        },
        isAuctioned(){
            return ( ['auction_payment','move_out'].includes(this.lease.auction_status));
        },
    },
    created() {
        this.old_payment_cycle = this.getCurrentPaymentCycle;
    },
    methods: {
        cancel() {
            EventBus.$emit('activeEdit', '');
            this.active = '';
            EventBus.$emit('reload_tenant_profile_leases');
        },
        showPaymentCycleChangeModel(){
            console.log("Pc - OLD", this.old_payment_cycle);
            console.log("PC - NEW", this.getCurrentPaymentCycle);

            if(this.old_payment_cycle !== this.getCurrentPaymentCycle)
                this.show_payment_cycle_change_model = true;
            else
                this.active = '';
        },
        async savePaymentCycle(){
            try {
                this.loading = true;
                let data = { new_payment_cycle: this.getCurrentPaymentCycle === this.PAYMENT_CYCLES.MONTHLY ? null : this.getCurrentPaymentCycle };

                let r = await api.put(this,`${api.LEASES}${this.lease.id}/change-payment-cycle`, data);

                this.loading = false;

                this.old_payment_cycle = this.getCurrentPaymentCycle;
                this.show_payment_cycle_change_model = false;
                this.active = '';
                this.showMessageNotification({type: 'success', description: 'Payment Cycle has been changed Successfully.'});
                EventBus.$emit('reload_tenant_profile_leases');
            } catch (error) {
                this.showMessageNotification({type: 'error', description: error});
                this.loading = false;
            }

        },

        cancelPaymentCycleChange(){
            console.log("Now Canceling Payment Cycle");
            this.active = null;
            this.show_payment_cycle_change_model = false;
        },
        editStart(name) {
            EventBus.$emit('activeEdit', name);
        },
    }
}
</script>