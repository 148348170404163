<template>
  <hb-form :label="label" :description="noDescription ? '' : description">
    <span v-if="readOnly">{{ getSelectedText }}</span>
    <hb-select
      v-else
      v-model="selected"
      :items="items"
      v-bind="$attrs"
      item-text="text"
      item-value="value"
      :clearable="false"
      class="pa-0 ma-0 pb-1"
    >
    </hb-select>
  </hb-form>
</template>
<script>
export default {
  name: "RoundingForm",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: "Rounding",
    },
    noDescription: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "Define how the rent amount will be rounded.",
    },
    items: {
      type: Array,
      default: () => [
        { text: "No Rounding", value: null },
        { text: "Round to nearest Full Dollar", value: "nearest_full" },
        { text: "Round to nearest Half Dollar", value: "nearest_half" },
        { text: "Round up to Full Dollar", value: "up_full" },
        { text: "Round up to Half Dollar", value: "up_half" },
        { text: "Round down to Full Dollar", value: "down_full" },
        { text: "Round down to Half Dollar", value: "down_half" },
      ],
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getSelectedText() {
      return this.items.find(item => item.value == this.selected)?.text ?? ''
    }
  },
};
</script>