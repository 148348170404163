export default [
    {
        name:'Reports',
        path: '/reports-library',
        component: () => import('../components/report_library/Index.vue'),
        meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
        }
        
    },
    {
        path: '/reports-library/view/:report_id',
        component: () => import('../components/reports/ReportView.vue'),
        meta: {
          requiresAuth: true,
          hasAccess: ['admin'],
          layout: 'master'
        }
      },
      {
          name:'DynamicReports',
          path: '/reports-library/:report_type',
          component: () => import('../components/reports/ReportView.vue'),
          meta: {
              requiresAuth: true,
              hasAccess: ['admin'],
              layout: 'master',
              re_fetch: true
          }
      },
  
]