export default [
	{
		name: "User Index",
		path: '/users',
		component: () => import('../components/users/Index.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      layout: 'master'
		}
	}
]
