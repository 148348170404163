<template>
    <div>

          <div class="hb-font-header-2-medium mb-5">Payment Summary</div>

          <v-expansion-panels v-model="panel">

            <hb-expansion-panel title-icon="mdi-cart" :top-margin="false">

              <template v-slot:title>
                  Cart
              </template>

              <template v-slot:content>
                <v-row no-gutters style=" margin-bottom: 0;">
                  <v-col class="invoice_display py-0 my-0 mx-6">

                    <v-row class="invoice-row" v-show="invoice.InvoiceLines">
                      <v-col class="px-0 mx-0">
                        <v-row class="invoice-line-row py-2 px-10" v-for="(line, i) in invoice.InvoiceLines" :key="line.id"   >
                          <v-col style="flex: 1 0 50%;">
                            <span style="font-weight: 500">{{line.Product.name}}</span><br/>
                            {{line.Product.description}}
                            <span class="caption font-regular" v-if="line.Service && line.Service.recurring"> (Recurring from {{line.Service.start_date | formatSmallDate}})</span>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col style="flex: 0 1 10%;" class="text-right">
                            <span v-show="line.Product.type === 'product'">{{line.qty}}</span>
                          </v-col>
                          <v-col style="flex: 0 1 75px" class="align-right">{{line.cost | formatMoney}}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="invoice-row pb-1">
                      <v-col class="px-0 mx-0">
                        <v-row class="invoice-line-row px-10 py-1">
                          <v-spacer></v-spacer>
                          <v-col class="text-right" style="flex: 1;">Subtotal</v-col>
                          <v-col class="align-right " style="flex: 0 1 75px;">{{invoice.sub_total | formatMoney }}</v-col>
                        </v-row>
                        <v-row class="invoice-line-row px-10 py-1">
                          <v-spacer></v-spacer>
                          <v-col class="text-right" style="flex: 1;">Taxes</v-col>
                          <v-col class="align-right" style="flex: 0 1 75px;">{{invoice.total_tax | formatMoney }}</v-col>
                        </v-row>
                        <v-row class="invoice-line-row px-10 py-1">
                          <v-spacer></v-spacer>
                          <v-col class="text-right font-weight-medium" style="flex: 1; ">Total Due</v-col>
                          <v-col class="align-right font-weight-medium" style="flex: 0 1 75px">{{invoice.balance | formatMoney }}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>


              </template>
            </hb-expansion-panel>
          </v-expansion-panels>

    </div>

</template>


<script type="text/babel">

    export default {
        name: "ProductInformation",
        data() {
            return {
                panel: 0,
            }
        },
        props: ['invoice'],
        components:{
        },
        created (){

        },
        filters:{
        },
        
        methods: {
            async getContactPaymentMethods(){
                if(this.contact.id){
                  let r = await api.get(this, api.CONTACTS + this.contact.id + '/payment-methods', {property_id: this.property.id});
                  this.payment_methods = r.payment_methods;
                }

            },
            validatePaymentMethod(){
                let errors = [];
                let response = {
                    status: true,
                    msg: '',
                }

                switch(this.payment.type.toLowerCase()){
                    case 'card':
                        if(!this.payment_method.card_number) errors.push("Card number is invalid");
                        if(!this.payment_method.exp_mo || !this.payment_method.exp_yr) errors.push("Expiration date is invalid");
                        if(!this.payment_method.cvv2 ) errors.push("Cvv2 is invalid");
                        if(!this.payment_method.address ) errors.push("Address is invalid");
                        if(!this.payment_method.city ) errors.push("City is invalid");
                        if(!this.payment_method.zip ) errors.push("Zip is invalid");
                        break;
                    case 'ach':
                        if(!this.payment_method.account_number) errors.push("Account number is invalid");
                        if(!this.payment_method.routing_number) errors.push("Routing number is invalid");
                        if(!this.payment_method.account_type) errors.push("ACH account type is invalid");
                        break;
                    case 'check':
                        if(!this.payment.number) errors.push("Check number is invalid");
                        if(!this.payment.ref_name) errors.push("Reference name is invalid");
                        break;
                    case 'cash':
                        if(!this.payment.ref_name) errors.push("Reference name is invalid");
                        break;
                        break;
                    default:
                        response = {
                            status: false,
                            msg: 'Payment method not supported',
                        }
                }

                if(errors.length){
                    response.status = false;
                    response.msg = errors.join(", ");

                }
                return response;

            },
            async payNow(){

                // should authenticate first
                let payment_method = this.formattedPaymentMethod;
                let payment = this.payment;
                payment.amount = this.invoice.balance;

                if(!payment.payment_method_id){
                    let valid = this.validatePaymentMethod();
                    if(!valid.status) {
						this.errorSet('authorize', valid.msg);
                        return;
                    }

                    if(payment_method){
                        payment_method.save_to_account = !!payment_method.save_to_account;
                        payment_method.auto_charge = !!payment_method.auto_charge;
                    }
                }

                let payment_info = {
                    payment: payment,
                    paymentMethod: payment_method
                }


                this.$emit('processPayment', payment_info);

            },
            showCharges(product_id){
                this.show_charge = this.show_charge === product_id ? null : product_id;
            },
           
        },
        watch:{
           
        },
    }
</script>

<style scoped>

    .new-tenant-walkthrough-footer{
        padding: 3px 0 3px 30px;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 20px 50px 100px 50px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }
    .row {
        margin-bottom: 10px;
    }

    .payments-container{
        border: 1px solid #C4CDD5;
        background: white;
        border-radius: 4px;
        padding: 30px 50px;
    }
    .v-expansion-panel-content__wrap,
    .v-expansion-panel-content.no-padding .v-expansion-panel-content__wrap{
        padding: 0;
    }
    .v-expansion-panel-content__wrap {
        padding: 0px;
    }

    .invoice_display{
        padding: 20px;
        font-size: 14px;
    }
    .breakdown{
        display:inline-block;
        flex:0 0 100%;
        padding: 10px;
        width: 100%;
        background:#f2f9fd;
        border: 1px solid #dce8ef;
    }
    .new-tenant-form-data{
      border: 1px solid #DFE3E8;
    }

    .v-expansion-panel-content .form-label{
      background: #F4F6F8;
      padding: 20px;
      font-weight: bold;
    }

    .invoice_display{
      padding:  10px 0px;
      font-size: 14px;
    }

    .invoice-line-row{
      margin-bottom: 0;
      padding: 0px 20px;
    }
    .invoice-line-row .col {
      padding: 0;
    }

    .invoice-total-row >.col{
      padding: 10px;
    }

    .invoice-row .caption{
      color: #637381
    }

    .invoice-row > .col{
      padding-bottom: 0;
    }

    .invoice-line-row.total{
      border-top: 1px solid #C4CDD5;
      margin-top: 20px;
    }
    .invoice-line-row.total > .col{
      padding-top: 15px;
    }


    .payment-data-header .v-input--checkbox,
    .form-label .v-input--checkbox{
      margin-top: 0;
      padding-top: 0;
    }

    .payment-data-header{
      border-bottom: 1px solid #DFE3E8;
      padding: 15px 20px;
      margin-bottom: 0;
    }

    .container.payment-data-container {
      padding: 0 12px;
    }

    .card-options{
      border-right: 1px solid #C4CDD5;
      padding: 10px;
      font-size: 14px;
    }

    .payment-method-holder{
      padding: 10px;
    }
</style>
